import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import { classes, CustomAppStyle } from "./CustomAppStyle";

import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { calculateCartTotalCostString, calculateServerCartTotalCostString } from "../../store/CartUtils";
import Constants from "../../utils/Constants";
import CustomFormErrorAlertRedux from "../../utils/forms/CustomFormErrorAlertRedux";
import useCommonData from "../../utils/hooks/useCommonData";

const RootPaper = styled(Paper)(({ theme }) => CustomAppStyle(theme));
const StackRoot = styled(Stack)(({ theme }) => CustomAppStyle(theme));

const BottomButton = ({ isDisabled, onClick, buttonContent, centerText, leftText, leftTextBorderHide, rightText }) => {
	return (
		<Button fullWidth onClick={onClick} variant="contained" disabled={isDisabled}>
			{buttonContent ? (
				buttonContent
			) : (
				<StackRoot direction="row" justifyContent={"space-between"} alignItems="center" className={classes.menuButtonRoot}>
					<div className={clsx(classes.menuButtonWidth, { [classes.menuButtonBoxBorder]: leftText && !leftTextBorderHide })}>
						{leftText}
					</div>
					{centerText}
					<div>{rightText}</div>
				</StackRoot>
			)}
		</Button>
	);
};

const CustomFooterBar = ({
	disableFooterBarButton,
	footerBarButtonTextLeft,
	footBarButtonTextLeftBorderHide = false,
	footerBarButtonText,
	footerBarButtonTextRight,
	footerBarLocation,
	footerBarOnClickButton,
	setFooterRef,
	errorAlertData
}) => {
	const navigate = useNavigate();
	const { carts, campusID, locationID, location, locationCart } = useCommonData();

	const { checkout_gotocheckout_button_title } = location;

	const [subTotalCostString, setSubTotalCostString] = useState(calculateCartTotalCostString(carts, locationID));
	const [totalCostString, setTotalCostString] = useState(calculateServerCartTotalCostString(carts, locationID));

	useEffect(() => {
		setSubTotalCostString(calculateCartTotalCostString(carts, locationID));
		setTotalCostString(calculateServerCartTotalCostString(carts, locationID));
	}, [carts, locationID]);

	const { error } = errorAlertData;
	const isErrorEmpty = _.isEmpty(error);

	const { CHECKOUT_PAGE, CUSTOMIZATION_PAGE, MENU_PAGE } = Constants.APP_LOCATION;

	const handleGotoCart = () => {
		navigate(`/${campusID}/${locationID}/checkout`);
	};

	return (
		<RootPaper variant={"outlined"} square ref={(node) => setFooterRef(node)} className={classes.customFooterRootPaperContainer}>
			<Stack direction="row" divider={<Divider role="presentation" orientation="vertical" flexItem />} padding={1}>
				{footerBarLocation === MENU_PAGE && (
					<BottomButton
						centerText={checkout_gotocheckout_button_title || "Checkout"}
						leftText={locationCart?.items?.length}
						rightText={subTotalCostString}
						onClick={handleGotoCart}
						isDisabled={locationCart?.items?.length === 0}
					/>
				)}
				{footerBarLocation === CUSTOMIZATION_PAGE && (
					<BottomButton
						onClick={() => footerBarOnClickButton()}
						leftText={footerBarButtonTextLeft}
						leftTextBorderHide={footBarButtonTextLeftBorderHide}
						centerText={footerBarButtonText}
						rightText={footerBarButtonTextRight}
					/>
				)}
				{footerBarLocation === CHECKOUT_PAGE &&
					(isErrorEmpty ? (
						<BottomButton
							centerText={footerBarButtonText}
							rightText={totalCostString}
							onClick={() => footerBarOnClickButton()}
							isDisabled={disableFooterBarButton}
						/>
					) : (
						<CustomFormErrorAlertRedux noCollapse />
					))}
			</Stack>
		</RootPaper>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(CustomFooterBar);
