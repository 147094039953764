import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import TransactionItem from "../views/transactions/TransactionItem";
import CustomPaperContainer from "../views/custom/CustomPaperContainer";
import CustomFormPagination from "../utils/forms/CustomFormPagination";
import { setAppPageTitle } from "../utils/Utils";
import Constants from "../utils/Constants";
import { IconButton, Stack, Tooltip } from "@mui/material";
import ApiEndpoints from "../api/ApiEndpoints";
import ConstantsLabels from "../utils/ConstantsLabels";
import useCommonData from "../utils/hooks/useCommonData";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import FeatureConstants from "../utils/FeatureConstants";

const TransactionPage = ({ userData, doApiCall }) => {
	const navigate = useNavigate();
	const { getLocationWithDisabledFromId, user } = useCommonData();
	const { orders, pending_orders } = userData;
	const theme = useTheme();

	const [ordersArray, setOrdersArray] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const orderid = searchParams.get("orderid");
	const page_location = searchParams.get("page_location");

	useEffect(() => {
		setAppPageTitle("Transactions");
	}, [navigate]);

	useEffect(() => {
		const _ordersArray = page_location === Constants.APP_LOCATION.PENDING_ORDERS ? pending_orders : orders;
		setOrdersArray(_ordersArray);
		const _currentOrderIdx = _ordersArray.findIndex((order) => String(order.orderid) === orderid);
		setCurrentOrderIdx(_currentOrderIdx);
		setCurrentOrder(_ordersArray[_currentOrderIdx]);
	}, []);

	const [currentOrderIdx, setCurrentOrderIdx] = useState();
	const [currentOrder, setCurrentOrder] = useState();
	const [busy, setBusy] = useState(false);

	const location = getLocationWithDisabledFromId(currentOrder?.locationid);
	const hideBarcode =
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_RECEIPT_BARCODE) ||
		(hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_RECEIPT_BARCODE_WHEN_COMPLETE) && currentOrder?.iscomplete === 1) ||
		currentOrder?.automatedcheckout === 1;

	const handleButtonNav = (e, idx) => {
		setCurrentOrderIdx(idx);
		setCurrentOrder(ordersArray[idx]);

		setSearchParams({'orderid': ordersArray[idx]?.orderid})
	};

	const handleClickSendEmailReceipt = () => {
		doApiCall(
			ApiEndpoints.EmailOrderReceipt,
			{ orderid: orderid?.toString() },
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				showSuccessToast: true,
				successMessage: ConstantsLabels.labelEmailReceiptMessage,
				toastTitle: ConstantsLabels.labelEmailReceiptTitle
			},
			(data) => {},
			(errorMsg, errorReason) => {}
		);
	};

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!user || ordersArray.length === 0} showLoadingOnTop={busy}>
			<CustomPaperContainer styleClasses={[classes.contentRootDiv]}>
				<Grid container direction={"column"}>
					<Grid item padding={1}>
						<Stack direction="row" alignItems={"center"} justifyContent="space-between">
							<Tooltip aria-hidden="true" title={"Email Receipt."} disableFocusListener id={`tooltip-Email Receipt`}>
								<IconButton
									aria-hidden="false"
									role={"button"}
									aria-label={"Email Receipt"}
									onClick={handleClickSendEmailReceipt}
									aria-describedby={`tooltip-Email Receipt`}
								>
									<MailOutlineIcon />
								</IconButton>
							</Tooltip>
							<CustomFormPagination
								ariaLabelId="order"
								currentIndex={currentOrderIdx}
								handleClickFirst={(e) => handleButtonNav(e, 0)}
								handleClickLast={(e) => handleButtonNav(e, ordersArray?.length - 1)}
								handleClickNext={(e) => handleButtonNav(e, currentOrderIdx + 1)}
								handleClickPrevious={(e) => handleButtonNav(e, currentOrderIdx - 1)}
								paginationArrayLength={ordersArray?.length}
							/>
						</Stack>
					</Grid>

					<Grid item>
						<TransactionItem
							appLocation={Constants.APP_LOCATION.TRANSACTION_HISTORY_PAGE}
							currentOrder={currentOrder}
							hideBarcode={hideBarcode}
							user={user}
						/>
					</Grid>
				</Grid>
			</CustomPaperContainer>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(TransactionPage);
