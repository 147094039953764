import List from "@mui/material/List";
import React from "react";
import { useNavigate } from "react-router-dom";

import { classes } from "../../theme/GlobalStyle";
import { LogDebug } from "../../utils/Utils";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";

import CustomPaperContainer from "../custom/CustomPaperContainer";

const MessagesList = ({ messages }) => {
	const navigate = useNavigate();

	const goToSelectedMessage = (e, message) => {
		LogDebug(`show html message`);
		e && e.preventDefault();

		navigate(`/user/message?inboxid=${message.inboxid}`);
	};

	return (
		<CustomPaperContainer className={classes.contentRootDiv}>
			<List disablePadding className={classes.listTransparentNoBorder}>
				{messages?.map((message, i) => {
					return (
						<CustomFormLabelItem
							key={message.inboxid}
							icon_url={message.icon_image_url_with_base}
							label={message.title}
							onClick={(e) => {
								goToSelectedMessage(e, message);
							}}
							hideDivider={i === messages.length - 1}
							isSkipLink={i === 0}
						/>
					);
				})}
			</List>
		</CustomPaperContainer>
	);
};

export default MessagesList;
