import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { defaultCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import useCommonData from "../../utils/hooks/useCommonData";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";
import Loading from "../../utils/components/Loading";

const TenderSelect = ({ locationID, doUpdateCartTender }) => {
	const { getMenu } = useCommonApiCalls();
	const { getCartFromLocationId, getMenuFromLocationId } = useCommonData();
	const locationCart = getCartFromLocationId(locationID) || {};
	const { ct_id, ct_id2 } = locationCart;
	const menu = getMenuFromLocationId(locationID);
	const [tenders, setTenders] = useState([]);
	const [busy, setBusy] = useState(false);

	useEffect(() => {
		if (!menu) {
			getMenu(locationID, { setBusy });
		}
	}, []);

	useEffect(() => {
		let _tenders =
			menu?.tenders.filter((t) => (t.locationid === 0 || t.locationid === locationID) && t.is_hidden === 0 && t.currently_available === 1) ||
			[];

		if (ct_id === "0" && _tenders.length > 0) {
			doUpdateCartTender(locationID, _tenders[0].ct_id, ct_id2);
		}

		setTenders(_tenders);
	}, [menu?.tenders, locationID]);

	const handleOnChange = (e) => {
		doUpdateCartTender(locationID, e.target.value, ct_id2);
	};

	return (
		<>
			<Loading open={busy} />
			<RadioGroup
				aria-labelledby={"tender-select-radio-buttons-group"}
				name={"tender-select-radio-buttons-group"}
				value={ct_id}
				onChange={(e) => handleOnChange(e)}
			>
				{tenders?.map((radio) => {
					return <FormControlLabel control={<Radio value={radio.ct_id} />} label={radio.name} key={radio.ct_id} />;
				})}
			</RadioGroup>
		</>
	);
};

export default connect(defaultMapStateToProps, defaultCartActionsToProps)(TenderSelect);
