import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import useCommonData from "../../utils/hooks/useCommonData";

import CustomPaperContainer from "../custom/CustomPaperContainer";

const EmailReqdModal = ({ doApiCall, isFirstAttempt }) => {
	const { user } = useCommonData();

	const handleSendEmail = () => {
		if (!user) return;

		doApiCall(
			ApiEndpoints.RegisterSendVerificationEmail,
			{},
			undefined,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Email sent to ${user.email}`,
				toastTitle: `Email Sent`
			},
			(data) => {},
			(errorMsg) => {}
		);
	};

	return (
		<CustomPaperContainer>
			<Stack direction="column" padding={1} alignItems="center" spacing={2}>
				<Typography variant="body1" mb={0.1}>
					{ConstantsLabels.labelEmailRequired.join("\n")}
				</Typography>
				<CustomFormButton
					buttonText={`Didn't get it? Resend to ${user?.email}`}
					clsxClasses={clsx(classes.confirmButton)}
					onClick={handleSendEmail}
					marginTop={1}
				/>
				{!isFirstAttempt && (
					<Typography className={classes.errorTextRedItalicsBold}>Email Verification unsuccessful. Please retry or resend.</Typography>
				)}
			</Stack>
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(EmailReqdModal);
