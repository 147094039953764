import { isDarkThemeWithObj, Log } from "../Utils";
import StyleConstants from "../../theme/StyleConstants";
import { primaryTextColor } from "../../theme/CustomTheme";

const SnackbarStyles = (theme) => {
	const p = theme.palette;
	const isDarkTheme = isDarkThemeWithObj(theme);

	const important = " !important";
	const white = "#FFF" + important;
	const fontSize = "1rem";

	const padding = "10px 15px";
	const border = "1px solid " + (isDarkTheme ? "#afafaf" : "#505050");
	const borderRadius = "5px";

	const generalProps = { padding: padding, fontSize: fontSize, border: border, borderRadius: borderRadius };

	return {
		root: {
			"& div#notistack-snackbar .MuiSvgIcon-root": {
				fontSize: "30px" + important,
				marginInlineEnd: "15px" + important
			}
		},
		success: {
			color: white + important,
			backgroundColor: p.secondary.main + "F2" + important,
			...generalProps
		},
		error: {
			color: white + important,
			backgroundColor: p.error.main + "F2" + important,
			...generalProps
		},
		warning: {
			color: white + important,
			backgroundColor: p.warning.main + "F2" + important,
			...generalProps
		},
		info: {
			color: p.text.primary + important,
			backgroundColor: p.background.backgroundPopUpMenu + important,
			...generalProps
		},
		closeButton: {
			color: "inherit",
			padding: 10,
			"& .MuiSvgIcon-root": {
				fontSize: 18
			},
			"&:hover": {
				color: "inherit",
				backgroundColor: "#00000052"
			}
		}
	};
};

export default SnackbarStyles;
