import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SignInPageStyle, classes } from "./SignInPageStyle";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsAndCartActionsToProps } from "../../store/Actions";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { getBrowserInfoForInitialization, getHashedChecksumForApiCall, isDeviceMobileFx } from "../../utils/Utils";
import ValidateInput from "../../utils/ValidatorUtils";
import TransactCompanyInfoFooter from "../../utils/components/TransactCompanyInfoFooter";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import CustomFormPassword from "../../utils/forms/CustomFormPassword";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";

import CustomPaperContainer from "../custom/CustomPaperContainer";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";
import useCommonData from "../../utils/hooks/useCommonData";
import usePushNotification from "../../utils/firebase/fb-pushNotification";
import useGoogleAnalytics from "../../utils/firebase/fb-analytics";

const RootGrid = styled(Grid)(({ theme }) => SignInPageStyle(theme));

const LoginPage = ({
	settingsData,
	setIsShowingModal,
	setIsMainModalPage,
	isForgotPassword,
	setIsForgotPassword,
	doApiCall,
	doUpdateRememberMe,
	doEnqueueSnackbar,
	doUpdateCurrentUserEmail,
	doClearAllCarts
}) => {
	const { rememberMe } = settingsData;
	const { getPastOrders } = useCommonApiCalls();
	const { campusID, currentUserEmail } = useCommonData();
	const { requestPushToken } = usePushNotification();
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const theme = useTheme();
	const { logAnalyticEvent } = useGoogleAnalytics();

	const [busy, setBusy] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [email, setEmail] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState("");

	const doInitializeApiCall = (_campusID) => {
		doApiCall(
			ApiEndpoints.LoginForWeb,
			{ campusid: "" + _campusID },
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: true, addDeviceInfoToBody: true },
			(data) => {},
			(errorMsg, errorReason) => {}
		);
		getPastOrders();
	};

	const loginClicked = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!ValidateInput(email, setErrorEmail, { isEmail: true })) return;
		if (!ValidateInput(password, setErrorPassword, { minLength: 5, maxLength: 50 })) return;

		// const rememberme = settingsData.rememberMe ? "ON" : "OFF";
		const device = getBrowserInfoForInitialization();
		const scrubbedEmail = email.trim().toLowerCase();
		const hashChecksum = getHashedChecksumForApiCall(scrubbedEmail + password, Constants.loginHashKey);
		// API_LOGINWITHEMAILANDPASSWORD
		// ("hash", "email", "password", "push_token", "os_type", "os_version", "app_version", "timezone", "device_model", "device_name", "os_language", "carrier_name", "app_bundle_name", "campusid")
		// (hash, theemail, thepass, pushToken, Constants.OSTYPE, os_ver, app_version, timezone, device_model, device_name, language, carriername, app_bundle_name, thecampusid + "")
		const reqBody = {
			hash: hashChecksum ? hashChecksum : "",
			email: scrubbedEmail ? scrubbedEmail : "",
			password: password ? password : "",
			campusid: "" + (campusID ? campusID : "")
		};

		//passing setBusy to the api call function will call setBusy in the 'finally' of api middleware
		//this resulted in React state update on an unmounted component error. so by doing it outside and then in the callback,
		//we prevent the component being onloaded because of routerHistory and then the callback happening
		setBusy(true);

		doApiCall(
			ApiEndpoints.LoginWithEmailAndPassword,
			reqBody,
			undefined,
			{ logRequest: false, logResponse: true, hideErrorToast: true, setFormErrorMessage: setFormErrorMessage, addDeviceInfoToBody: true },
			async (data) => {
				setBusy(false);
				setIsMainModalPage(true);
				setIsShowingModal(false);

				await requestPushToken();

				doInitializeApiCall(data.user.campusid);

				if (data.user.email !== currentUserEmail && currentUserEmail !== "") {
					doClearAllCarts();
				}
				doUpdateCurrentUserEmail(data.user.email);

				logAnalyticEvent("login with email");
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const forgotPasswordClicked = () => {
		setIsForgotPassword(true);
	};

	const resetPassword = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!ValidateInput(email, setErrorEmail, { isEmail: true })) return;

		const scrubbedEmail = email.trim().toLowerCase();
		const hashChecksum = getHashedChecksumForApiCall(scrubbedEmail + scrubbedEmail, Constants.apiHashSalt);
		const reqBody = {
			hash: hashChecksum ? hashChecksum : "",
			email: scrubbedEmail ? scrubbedEmail : ""
		};

		doApiCall(
			ApiEndpoints.ResetPassword,
			reqBody,
			setBusy,
			{ logRequest: false, logResponse: true, hideErrorToast: true, setFormErrorMessage: setFormErrorMessage },
			(data) => {
				setIsForgotPassword(false);
				doEnqueueSnackbar({
					message: "If your email matches our records, you will receive a reset password link.",
					title: "RESET PASSWPORD",
					excludeFromHistory: false,
					options: { variant: "success" }
				});
			},
			(errorMsg) => {}
		);
	};

	const rememberMeToggleClicked = () => {
		const newFlag = !settingsData.rememberMe;
		doUpdateRememberMe(newFlag);
	};

	const textFieldProps = {
		maxLength: 50
	};

	if (!theme.isThemeLoaded) return <></>;

	return (
		<RootGrid
			container
			direction="column"
			padding={theme.spacing(2, isDeviceMobile ? 1 : 5)}
			alignItems="stretch"
			className={classes.root}
			wrap="nowrap"
		>
			<Backdrop className={classes.backdrop} open={busy} onClick={() => {}}>
				<CircularProgress aria-label="Attempting auto login. Please wait..." color="secondary" size={100} />
			</Backdrop>
			<Grid item xs container direction="column" justifyContent="center" alignItems="stretch">
				<CustomPaperContainer rounded className={classes.paperBackgroundWidgetTheme} variant="elevation">
					<form
						noValidate
						autoComplete="on"
						id={"login-form"}
						aria-describedby={formErrorMessage.length > 0 ? "login-form-error" : undefined}
					>
						<Typography variant="subtitle1" variantMapping={{ subtitle1: "h3" }} align={"center"}>
							{ConstantsLabels.labelEditAsterixRequired}
						</Typography>
						<Stack direction="column" padding={2} spacing={isDeviceMobile ? 2 : 3}>
							<CustomFormTextInput
								inputID={"email"}
								inputLabel={"Email"}
								setValue={setEmail}
								value={email}
								setError={setErrorEmail}
								errorText={errorEmail}
								{...textFieldProps}
								autoCompleteType={"email"}
							/>

							{!isForgotPassword && (
								<>
									<CustomFormPassword
										inputID={"password"}
										inputLabel={"Password"}
										setValue={setPassword}
										value={password}
										setError={setErrorPassword}
										errorText={errorPassword}
										{...textFieldProps}
										allowAutoComplete
									/>

									<FormControl variant="outlined" className={classes.formControl}>
										<FormControlLabel
											control={
												<Switch
													checked={rememberMe}
													onChange={rememberMeToggleClicked}
													name="remembermeswitch"
													color="secondary"
													inputProps={{
														role: "switch",
													}}
												/>
											}
											label="Keep Me Logged In"
										/>
									</FormControl>

									<CustomFormButton
										buttonText={ConstantsLabels.labelMenuLogin}
										className={classes.formLoginButton}
										disabled={busy || !email || email.length < 5 || !password || password.length < 5}
										disableDefaultStyle
										onClick={loginClicked}
										autoFocus={rememberMe}
										marginTop={theme.spacing(3, "!important")} // Fix bug in Stack that adds/removes margin
									/>
								</>
							)}
							<CustomFormButton
								buttonText={
									isForgotPassword
										? ConstantsLabels.labelMenuLoginForgotPasswordReset
										: ConstantsLabels.labelMenuLoginForgotPassword
								}
								className={classes.formLoginButton}
								disableDefaultStyle
								onClick={isForgotPassword ? resetPassword : forgotPasswordClicked}
								variant="text"
								marginTop={theme.spacing(2, "!important")} // Fix bug in Stack that adds/removes margin
							/>

							<CustomFormErrorAlert
								theID={"login-form-error"}
								setFormErrorMessage={setFormErrorMessage}
								formErrorMessage={formErrorMessage}
							/>
						</Stack>
					</form>
				</CustomPaperContainer>
			</Grid>

			<Grid item xs="auto">
				<TransactCompanyInfoFooter showVersion={true}/>
			</Grid>
		</RootGrid>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(LoginPage);
