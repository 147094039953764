import * as Actions from "../store/Actions";

export const logRocketInitObj = {
	console: {
		isEnabled: {
			info: false,
			debug: false
		}
	},
	network: {
		requestSanitizer: (request) => {
			request.headers["login_token"] = null;
			request.headers["api_key"] = null;
			request.headers["sessionid"] = null;
			request.headers["Content-Type"] = null;

			if (
				request.url.toLowerCase().includes("login") ||
				request.url.toLowerCase().includes("password") ||
				request.url.toLowerCase().includes("register")
			) {
				request.body = null;
			}

			//only our requests
			if (
				request.url.toLowerCase().includes("/api_weborder/") &&
				request.body &&
				typeof request.body === "string" &&
				request.body.length > 0
			) {
				const jsonobj = JSON.parse(request.body);
				if (jsonobj) {
					delete jsonobj.hash;
					delete jsonobj.new_password;
					delete jsonobj.old_password;
					delete jsonobj.password;
					delete jsonobj.email;
					delete jsonobj.image_data;
					request.body = JSON.stringify(jsonobj);
				}
			}

			return request;
		},
		responseSanitizer: (response) => {
			response.body = null;
			return response;
		}
	}
};

export const logRocketActionSanatizer = (action) => {
	switch (action.type) {
		case Actions.API:
		case Actions.SNACKBAR_REMOVE:
		case Actions.SNACKBAR_CLOSE:
		case "persist/REHYDRATE":
		case "persist/PERSIST":
			return null;
		default:
	}

	return {
		...action,
		payload: undefined
	};
};

export const logRocketStateSanatizer = (state) => {
	return {
		...state,
		userData: undefined,
		campusData: undefined
	};
};
