import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import ValidateInput from "../../utils/ValidatorUtils";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";

const ProfileEditName = forwardRef((props, ref) => {
	const { userData, doApiCall } = props;

	const theme = useTheme();
	const { user } = userData;

	const [busy, setBusy] = useState(false);
	const [dirtyBit, setDirtyBit] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [firstName, setFirstName] = useState("");
	const [errorFirstName, setErrorFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [errorLastName, setErrorLastName] = useState("");

	useImperativeHandle(ref, () => ({
		handleNameUpdate(closeModalCallback) {
			setFormErrorMessage("");
			if (!user) return;
			if (!ValidateInput(firstName, setErrorFirstName, { inputName: "First name", minLength: 5, maxLength: 50 })) return;
			if (!ValidateInput(lastName, setErrorLastName, { inputName: "Last name", minLength: 5, maxLength: 50 })) return;

			setFormErrorMessage("");
			setBusy(true);

			const reqBody = {
				first_name: firstName,
				last_name: lastName
			};

			doApiCall(
				ApiEndpoints.ChangeName,
				reqBody,
				setBusy,
				{
					logRequest: true,
					logResponse: true,
					hideErrorToast: false,
					successMessage: `${user.full_name} name updated.`,
					toastTitle: `User #${user.userid}`,
					setFormErrorMessage: setFormErrorMessage
				},
				(data) => {
					setBusy(false);
					closeModalCallback && closeModalCallback();
				},
				(errorMsg) => {
					setBusy(false);
					setFormErrorMessage(errorMsg);
				}
			);
		}
	}));

	useEffect(() => {
		if (!user) {
			setFirstName(undefined);
			setLastName(undefined);
			return;
		}
		setFirstName(user.first_name);
		setLastName(user.last_name);
	}, [user]);

	const textFieldProps = {
		setDirtyBit: setDirtyBit,
		isDisabled: busy
	};

	if (!theme.isThemeLoaded || !user) return <></>;

	return (
		<Stack spacing={4} paddingTop={1}>
			<CustomFormTextInput
				inputID={"firstName"}
				inputLabel={"First Name"}
				setValue={setFirstName}
				value={firstName}
				setError={setErrorFirstName}
				errorText={errorFirstName}
				{...textFieldProps}
				autoCompleteType={"given-name"}
			/>
			<CustomFormTextInput
				inputID={"lastName"}
				inputLabel={"Last Name"}
				setValue={setLastName}
				value={lastName}
				setError={setErrorLastName}
				errorText={errorLastName}
				{...textFieldProps}
				autoCompleteType={"family-name"}
			/>
			<CustomFormErrorAlert theID={"error"} setFormErrorMessage={setFormErrorMessage} formErrorMessage={formErrorMessage} />
		</Stack>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ProfileEditName);
