const PREFIX = "ProfileImageUploader";

export const classes = {
	outterContainer: `${PREFIX}-outterContainer`,
	selectFileDivProfile: `${PREFIX}-selectFileDivProfile`,
	selectFileButton: `${PREFIX}-selectFileButton`,
	selectFileButtonInnerDiv: `${PREFIX}-selectFileButtonInnerDiv`,
	selectFileButtonIcon: `${PREFIX}-selectFileButtonIcon`,
	selectFileButtonIconText: `${PREFIX}-selectFileButtonIconText`,
	cropperDiv: `${PREFIX}-cropperDiv`,
	cropperDivLight: `${PREFIX}-cropperDivLight`,
	cropperDivDark: `${PREFIX}-cropperDivDark`,
	bottomButtonsInnerDiv: `${PREFIX}-bottomButtonsInnerDiv`,
	bottomButton: `${PREFIX}-bottomButton`,
	bottomButtonsDivProfile: `${PREFIX}-bottomButtonsDivProfile`
};

const ImageUploaderStyle = (theme) => {
	const p = theme.palette;

	return {
		[`& .${classes.outterContainer}`]: {
			display: "flex",
			position: "relative",
			height: "100%"
		},

		[`& .${classes.selectFileDivProfile}`]: {
			width: "100%"
		},

		[`& .${classes.selectFileButton}`]: {
			height: 300,
			width: 300,
			border: "1px dashed " + p.text.tertiary,
			margin: "auto",
			display: "flex",
			color: p.text.secondary,
			background: p.background.backgroundInput,
			"&:hover, &:focus": {
				color: "#FFF",
				backgroundColor: p.background.buttonBackgroundHover, //p.background.selectBackgroundHover,
				border: "1px dashed " + p.primary.main
			},
			[theme.breakpoints.down("sm")]: {
				height: 205,
				width: 200
			}
		},

		[`& .${classes.selectFileButtonInnerDiv}`]: { margin: "auto", color: "inherit" },
		[`& .${classes.selectFileButtonIcon}`]: { display: "flex", margin: "auto", fontSize: 150, color: "inherit" },
		[`& .${classes.selectFileButtonIconText}`]: { textAlign: "center", marginBottom: 15, color: "inherit" },

		[`& .${classes.cropperDiv}`]: {
			width: "100%",
			height: "auto",
			textAlign: "center",
			padding: 20,
			border: "1px solid " + p.dividerDark,
			borderRadius: 5
		},

		[`& .${classes.cropperDivLight}`]: { background: "repeating-conic-gradient(#f3f3f3 0% 25%, #fbfbfbe0 0% 50%) 50% / 30px 30px" },
		[`& .${classes.cropperDivDark}`]: { background: "repeating-conic-gradient(#f3f3f30a 0% 25%, #0000000f 0% 50%) 50% / 30px 30px" },

		[`& .${classes.bottomButtonsInnerDiv}`]: { display: "flex", justifyContent: "space-between" },

		[`& .${classes.bottomButton}`]: { margin: 0 },

		[`& .${classes.bottomButtonsDivProfile}`]: { width: "100%", marginTop: 50 }
	};
};

export default ImageUploaderStyle;
