import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import clsx from "clsx";
import React, { forwardRef, useEffect, useRef } from "react";

import { classes, CustomFormStyled } from "./CustomFormStyles";

const ButtonRoot = styled(Button)(({ theme }) => CustomFormStyled(theme));

const CustomFormButton = forwardRef(
	(
		{
			autoFocus = false,
			handleClick,
			inputID,
			buttonText,
			clsxClasses = "",
			disableFullWidth,
			isDisabled,
			isDanger,
			isCancelButton,
			margin,
			width,
			marginTop,
			padding,
			paddingTop,
			paddingBottom,
			paddingLeft,
			paddingRight,
			minWidth,
			maxWidth,
			minHeight,
			startIcon,
			color,
			float,
			ariaLabel,
			variant = "contained",
			size = "large",
			disableDefaultStyle = false,
			...other
		},
		ref
	) => {
		const buttonRef = useRef(null);
		const theStyle = {
			margin,
			marginTop,
			padding,
			paddingTop,
			paddingBottom,
			paddingLeft,
			paddingRight,
			minWidth,
			maxWidth,
			minHeight,
			float,
			width
		};
		useEffect(() => {
			// Need to add timeout here as when in a modal, focus seems to go somewhere else on the LoginPage. Might need to rework this if we need this elsewhere.
			const timeout = setTimeout(() => {
				if (autoFocus && buttonRef.current) {
					buttonRef.current.focusVisible();
				}
			}, 300);

			return () => clearTimeout(timeout);
		}, []);

		return (
			<ButtonRoot
				action={buttonRef}
				ref={ref}
				color={color || isDanger ? color : "primary"}
				id={inputID}
				aria-label={ariaLabel}
				variant={isCancelButton ? "outlined" : variant}
				size={size}
				className={clsx(clsxClasses, { [classes.formButton]: !disableDefaultStyle, [classes.cancelButton]: isCancelButton })}
				sx={theStyle}
				fullWidth={!disableFullWidth}
				disabled={isDisabled}
				disableElevation
				onClick={(e) => {
					e && e.preventDefault();
					handleClick();
				}}
				startIcon={startIcon}
				{...other}
			>
				{buttonText}
			</ButtonRoot>
		);
	}
);

export default CustomFormButton;
