import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import CustomFormPagination from "../utils/forms/CustomFormPagination";
import { setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import CustomPaperContainer from "../views/custom/CustomPaperContainer";
import MessageItem from "../views/messages/MessageItem";

const MessagePage = ({ campusData, userData, doApiCall, doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const inboxid = searchParams.get("inboxid");
	const { campus } = campusData;
	const { messages, user } = userData;
	const theme = useTheme();

	const [currentMessageIdx, setMessageOrderIdx] = useState(messages.findIndex((message) => String(message.inboxid) === inboxid));
	const [currentMessage, setCurrentMessage] = useState(messages[currentMessageIdx]);

	useEffect(() => {
		setAppPageTitle("Message");
	}, [navigate]);

	useEffect(() => {
		doUpdateAppBarTitle(currentMessage.title);
	}, []);

	const handleButtonNav = (e, idx) => {
		setMessageOrderIdx(idx);
		setCurrentMessage(messages[idx]);

		setSearchParams({'inboxid': messages[idx]?.inboxid})
	};

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!user || !messages}>
			<CustomPaperContainer styleClasses={[classes.contentRootDiv, classes.fullHeight]}>
				<Grid container padding={1} direction={"column"} justifyContent="stretch" alignItems={"center"} className={classes.fullHeight}>
					<Grid item container justifyContent="flex-end" padding={1}>
						<CustomFormPagination
							ariaLabelId="order"
							currentIndex={currentMessageIdx}
							handleClickFirst={(e) => handleButtonNav(e, 0)}
							handleClickLast={(e) => handleButtonNav(e, messages?.length - 1)}
							handleClickNext={(e) => handleButtonNav(e, currentMessageIdx + 1)}
							handleClickPrevious={(e) => handleButtonNav(e, currentMessageIdx - 1)}
							paginationArrayLength={messages?.length}
						/>
					</Grid>

					<Grid item xs>
						<MessageItem campus={campus} currentMessage={currentMessage} user={user} doApiCall={doApiCall} />
					</Grid>
				</Grid>
			</CustomPaperContainer>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(MessagePage);
