import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import LocationsMap from "../views/location/LocationsMap";
import ConstantsLabels from "../utils/ConstantsLabels";
import { setAppPageTitle } from "../utils/Utils";

const MapPage = ({ userData, campusData, doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const [busy, setBusy] = useState(false);
	const { user } = userData;
	const { campus } = campusData;

	const theme = useTheme();

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.map);
	}, []);

	useEffect(() => {
		setAppPageTitle("Map");
	}, [navigate]);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!campus || !user} showLoadingOnTop={busy}>
			<LocationsMap />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(MapPage);
