import { cloneDeep } from "lodash";
import { v1 as uuidv4 } from "uuid";
import { getMenuComboValuesFromMenuOption, getMenuOptionsFromMenuItem, getMenuValuesFromMenuOption } from "../utils/ObjectUtils";
import EnvConfig from "../utils/EnvConfig";
import { getCurrencyString, getIsHidden, getManualOnline, LogDebug } from "../utils/Utils";
import FeatureConstants from "../utils/FeatureConstants";
import { hasFeatureFlag } from "../utils/PermissionUtils";

export const createNewCart = (locationid) => {
	return {
		locationid: locationid,
		items: [],
		pickupOrDelivery: 0,
		pickupSpotId: -1,
		ct_id: "0",
		ct_id2: "0",
		date_local: "",
		date_display: "",
		end_time: "",
		start_time: "",
		server: { cart: {} }
	};
};

export const addNewCartToCarts = (cartsArray, newCart, locationid) => {
	const cartIndex = getCartIndex(cartsArray, locationid);
	if (cartIndex !== -1) return undefined;
	const allCarts = cloneDeep(cartsArray) || [];
	allCarts.push(newCart);
	return allCarts;
};

export const clearAllCarts = (cartsArray) => {
	let allCarts = cloneDeep(cartsArray) || [];
	allCarts = allCarts.map((c) => {
		return createNewCart(c.locationid);
	});
	return allCarts;
};

export const getCart = (cartsArray, locationid) => {
	if (!cartsArray || !locationid) return undefined;
	return cartsArray.find((cart) => cart.locationid === locationid);
};

export const getCartIndex = (cartsArray, locationid) => {
	if (!cartsArray || !locationid) return -1;
	return cartsArray.findIndex((cart) => cart.locationid === locationid);
};

export const updateCartArrayFromID = (cartsArray, newCart, locationid) => {
	const cartIndex = getCartIndex(cartsArray, locationid);
	if (cartIndex === -1 || !newCart) return undefined;
	const allCarts = cloneDeep(cartsArray) || [];
	allCarts[cartIndex] = newCart;
	return allCarts;
};

export const emptyLocationCart = (cartsArray, locationid) => {
	const cartIndex = getCartIndex(cartsArray, locationid);
	const allCarts = cloneDeep(cartsArray) || [];
	allCarts.splice(cartIndex, 1, createNewCart(locationid));
	return allCarts;
};

export const addItemToCart = (cartsArray, newCartItem, locationid) => {
	if (!newCartItem) return undefined;
	let cartIndex = getCartIndex(cartsArray, locationid);
	if (cartIndex === -1) {
		cartsArray.push(createNewCart(locationid));
		cartIndex = getCartIndex(cartsArray, locationid);
	}
	const allCarts = cloneDeep(cartsArray) || [];
	const newCart = { ...allCarts[cartIndex] };
	newCart?.items?.push(newCartItem);
	allCarts[cartIndex] = newCart;
	return allCarts;
};

export const updateCartItem = (cartsArray, newCartItem) => {
	if (!newCartItem) return undefined;
	let cartIndex = getCartIndex(cartsArray, newCartItem.locationid);
	const allCarts = cloneDeep(cartsArray) || [];
	const locationCart = allCarts.at(cartIndex);
	const itemIndex = locationCart.items.findIndex((i) => i.cartItemId === newCartItem.cartItemId);
	locationCart.items[itemIndex] = newCartItem;
	allCarts[cartIndex] = locationCart;
	return allCarts;
};

export const removeItemFromCart = (cartsArray, cartItemId, locationid) => {
	const cartIndex = getCartIndex(cartsArray, locationid);
	if (cartIndex === -1 || cartItemId < 0) return undefined;
	const allCarts = cloneDeep(cartsArray) || [];
	const newCart = allCarts.at(cartIndex);
	const cartItemIndex = newCart.items.findIndex((item) => item.cartItemId === cartItemId);
	if (cartItemIndex === -1) return undefined;
	newCart?.items?.splice(cartItemIndex, 1); //remove specific item from array
	allCarts[cartIndex] = newCart;
	return allCarts;
};

export const removeAllItemsFromCart = (cartsArray, locationid) => {
	const cartIndex = getCartIndex(cartsArray, locationid);
	const allCarts = cloneDeep(cartsArray) || [];
	const newCart = allCarts.at(cartIndex);
	newCart.items = [];
	allCarts[cartIndex] = newCart;
	return allCarts;
};

export const createNewCartItem = (menuItem, sectionid, location, locationid, retrievalType, menu, upsell_upsellid, upsell_variantid) => {
	const cartItem = { ...menuItem, sectionid, locationid, cartItemId: uuidv4() };
	const options = getMenuOptionsFromMenuItem(location, cartItem, retrievalType);
	//set the default modifers to selected
	options.forEach((o) => {
		if (o.is_combo) {
			o.isError = false;
			const values = getMenuValuesFromMenuOption(location, o, retrievalType);
			setDefaultValueToSelected(values, retrievalType);
			// valueid && combo_itemid && comboitems
			values.forEach((v) => {
				if (v.combo_itemid > 0) {
					const item = getItemFromItemId(v.combo_itemid, menu);
					v.cover_picture_url = item.cover_picture_url;
					v.description = item.description;
					v.options = getMenuOptionsFromMenuItem(location, item, retrievalType);
					v.sectionid = item.sectionid;
					v.options.forEach((opt) => {
						opt.values = getMenuComboValuesFromMenuOption(location, opt, retrievalType);
						setDefaultValueToSelected(opt.values, retrievalType);
					});
				} else {
					const values = getMenuValuesFromMenuOption(location, o, retrievalType);
					setDefaultValueToSelected(values, retrievalType);
				}
			});
			o.values = values;
		} else {
			const values = getMenuValuesFromMenuOption(location, o, retrievalType);
			setDefaultValueToSelected(values, retrievalType);
			o.values = values;
		}
	});

	if ((upsell_upsellid, upsell_variantid)) {
		cartItem.upsell_upsellid = upsell_upsellid;
		cartItem.upsell_variantid = upsell_variantid;
	}

	cartItem.options = options;
	return cartItem;
	//each value in the options array needs to have a 'selected' property
};

export const getItemFromItemId = (itemid, menu) => {
	const { sections_1, sections_2, sections_3 } = menu;
	if (sections_1 == null) return null;
	let _item;

	sections_1.forEach((s) => {
		const item = s.items.find((item) => itemid === item.itemid);
		if (item) _item = item;
	});
	if (_item) return _item;

	sections_2.forEach((s) => {
		const item = s.items.find((item) => itemid === item.itemid);
		if (item) _item = item;
	});
	if (_item) return _item;

	sections_3.forEach((s) => {
		const item = s.items.find((item) => itemid === item.itemid);
		if (item) _item = item;
	});

	return _item;
};

// Strip a combo item option down to just what is needed to send through to the server
export const stripComboItemOption = (option) => {
	const values = option.values
		.filter((v) => v.selected)
		.map((v) => {
			let comboOptions = cloneDeep(v.options) || [];
			if (comboOptions.length > 0) {
				comboOptions = comboOptions.map((co) => {
					const comboOptionValues = [];
					co.values.forEach((v) => {
						if (v.selected) {
							comboOptionValues.push({
								valueid: v.valueid,
								combo_itemid: v.combo_itemid,
								combo_items: []
							});
						}
					});
					return {
						optionid: co.optionid,
						values: comboOptionValues
					};
				});
			}
			return {
				valueid: v.valueid,
				combo_itemid: v.combo_itemid,
				combo_items: [
					{
						itemid: v.combo_itemid,
						sectionid: v.sectionid,
						meal_ex_applied: false,
						upsell_upsellid: 0,
						upsell_variantid: 0,
						options: comboOptions.filter((o) => o.values.length > 0)
					}
				]
			};
		});
	return { optionid: option.optionid, values };
};

export const createReorderedCartItem = (menuItem, sectionid, location, retrievalType, reorderid, reorderMenuItemOptions, menu) => {
	const locationid = location.locationid;
	const cartItem = { ...menuItem, sectionid, locationid, cartItemId: uuidv4(), reorderid };
	const unavailableItems = [];

	if (checkItemOnlineFlag(location, menuItem, retrievalType)) {
		unavailableItems.push({ value: `${menuItem.name}: Entire Item`, key: menuItem.itemid });
		return { cartItem: null, unavailableItems };
	} else {
		const options = cloneDeep(getMenuOptionsFromMenuItem(location, cartItem, retrievalType));

		const unavailableValues = [];
		options.forEach((o) => {
			const reorderOption = reorderMenuItemOptions.find((option) => option.menu_optionid === o.optionid);

			const values = getMenuValuesFromMenuOption(location, o, retrievalType);

			if (o.is_combo === 1) {
				o.isError = false;
				values.forEach((v) => {
					if (v.combo_itemid > 0) {
						const item = getItemFromItemId(v.combo_itemid, menu);
						v.cover_picture_url = item.cover_picture_url;
						v.description = item.description;
						v.sectionid = item.sectionid;
						v.selected = reorderOption.values[0].menu_valueid === v.valueid;
						v.options = cloneDeep(getMenuOptionsFromMenuItem(location, item, retrievalType));
						v.options.forEach((opt) => {
							const ro = reorderOption.values[0].options.find((o1) => o1.menu_optionid === opt.optionid);
							opt.values.forEach((v1) => {
								if (ro) {
									const rov = ro.values.find((v2) => v2.menu_valueid === v1.valueid);
									v1.selected = !!rov;
								} else {
									v1.selected = false;
								}
							});
						});
					} else {
						v.selected = v.valueid === reorderOption.values[0].menu_valueid;
					}
				});
				o.values = values;
			} else {
				values.forEach((v) => {
					const reorderValue = reorderOption?.values?.find((value) => value.menu_valueid === v.valueid);
					v.selected = reorderValue !== undefined;
					const onlineFlag = retrievalType === 0 ? v.manual_online : v.manual_online_delivery;
					if (reorderValue !== undefined && onlineFlag === 1) {
						v.selected = true;
					} else if (reorderValue !== undefined && onlineFlag === 0) {
						v.selected = false;
						unavailableValues.push(v.name);
					} else {
						v.selected = false;
					}
				});
				if (o.minimum > 0 && !values.some((v) => v.selected)) {
					setDefaultValueToSelected(values, retrievalType);
				}

				o.values = values;
			}
		});

		if (unavailableValues.length > 0) {
			unavailableItems.push({
				value: `${menuItem.name}: ${unavailableValues.join(", ")}`,
				key: `${menuItem.itemid}-${options[0].optionid}`
			});
		}
		cartItem.options = options;
	}

	return { cartItem, unavailableItems };
};

const checkItemOnlineFlag = (location, menuItem, retrievalType) => {
	return (
		!getManualOnline(location, menuItem, retrievalType) ||
		getIsHidden(location, menuItem, retrievalType) ||
		menuItem.is_deleted === 1 ||
		menuItem.mobile_stock_available === 0
	);
};

const setDefaultValueToSelected = (values, retrievalType, setDefaultIfNone) => {
	values.forEach((v) => {
		const onlineFlag = retrievalType === 0 ? v.manual_online : v.manual_online_delivery;
		const hasStock = v.mobile_stock_total === 0 || (v.mobile_stock_total !== 0 && v.mobile_stock_available > v.mobile_stock_total);
		v.selected = v.is_default === 1 && onlineFlag === 1 && hasStock;
	});
	if (setDefaultIfNone && values.length > 0 && !values.find((v) => v.selected)) {
		values[0].selected = true;
	}
};

// Calculate cost from items in cart only.
export const calculateCartTotalCostString = (carts, locationID) => {
	const cart = getCart(carts, locationID);
	const totalCost = cart?.items?.reduce((partial, a) => partial + a["price_total"] || 0, 0);
	const costString = getCurrencyString(totalCost >= 0 ? totalCost : 0);
	return costString;
};

// Get cost from server object returned from server if it exists, otherwise, from items in cart.
export const calculateServerCartTotalCostString = (carts, locationID) => {
	const cart = getCart(carts, locationID);
	const serverCart = cart?.server?.cart;
	const total = serverCart?.grand_total || cart?.items?.reduce((partial, a) => partial + a["price_total"] || 0, 0);
	const costString = getCurrencyString(total >= 0 ? total : 0);
	return costString;
};

// Calculate calories from selected item
export const calculateItemCalories = (item) => {
	let newCals = item.nutrition_calories;
	item.options.forEach((option) => {
		if (option.is_combo) {
			option.values.forEach((v) => {
				if (v.selected === true && v.combo_itemid > 0 && v.options.length > 0) {
					newCals += calculateItemCalories(v);
				} else if (v.selected === true) {
					newCals += v.nutrition_calories;
				}
			});
		} else {
			option.values.forEach((value) => {
				if (value.selected) {
					newCals += value.nutrition_calories;
				}
			});
		}
	});

	return newCals;
};

export const calculateCartItemCost = (updateItem) => {
	let newPrice = updateItem.price_base;
	updateItem.options.forEach((option) => {
		if (option.is_combo) {
			option.values.forEach((v) => {
				if (v.selected === true && v.combo_itemid > 0 && v.options.length > 0) {
					newPrice += calculateCartItemCost(v);
				} else if (v.selected === true) {
					newPrice += v.price_total;
				}
			});
		} else {
			option.values.forEach((value) => {
				if (value.selected) {
					newPrice += value.price;
				}
			});
		}
	});
	updateItem["price_total"] = newPrice;
	return newPrice;
};

export const getCartJson = (
	cart,
	location,
	user,
	specialComment = "",
	pickupSpotId = "0",
	checkoutSelectChoiceIds = [],
	promoCode,
	ct_id,
	tender,
	ct_id2,
	cash_eq_swipes,
	order, // Object will be populated if this is a `reorder`
	latitudeLongitude,
	pushToken
) => {
	const priceTotal = cart.price_total ? cart.price_total + "" : "0";

	const body = {};
	body.locationid = location.locationid + "";
	body.longitude = latitudeLongitude.longitude;
	body.latitude = latitudeLongitude.latitude;
	body.os_version = ""; // Will be browser version, i.e. chrome
	body.os_type = "2"; // 2 is web
	body.app_bundle_name = "";
	body.app_version = EnvConfig.version + "";
	body.push_token = pushToken;

	body.payment_method_default = user ? user.payment_method_default + "" : "1"; // 0 = campus card, 1 = credit card
	body.grand_total = priceTotal;
	body.credit_total = "0"; // From the account
	body.promo_credit_total = "0"; // From a promo
	body.campaign_credit_total = "0";
	body.punchcard_credit_total = "0";
	body.servicefee = "0";
	body.servicefee_tax = "0";
	body.subtotal = priceTotal;
	body.subtotal_tax = "0";
	body.pickup_time_min = "0";
	body.pickup_time_max = "0";
	body.reorderid = order?.orderid || "0";
	body.promo_code = promoCode[0] || "";
	body.code_description = "";
	body.campaign_title = "";
	body.campaign_description = "";

	body.redeem_punchid = "0"; //if user chose to use one of their punch rewards
	body.upsell_upsellid = "0"; //sending this back to the server. if it exists, the server will bounce it back
	body.upsell_variantid = "0"; //we dont want to send a new upsell item back (since it randomized the variants)
	body.upsell_itemid = "0"; //so to maintain consistency on the frontend, once we send back an item, thats the upsell item
	body.upsell_message = "";
	body.special_comment = specialComment;

	body.checkout_select_choiceids = checkoutSelectChoiceIds.map((choice) => "" + choice); //convert to string array or will not work

	body.target_time = cart.end_time || "";
	body.target_date = cart.date_local || "";
	body.retrieval_type = cart.pickupOrDelivery + ""; // Delivery type - 0 pickup or 1 delivery
	body.pickupspotid = pickupSpotId + "";

	body.cash_eq_swipes = cash_eq_swipes + "";
	body.meal_ex_swipes = "0";
	body.ct_id = ct_id + "";
	// body.ct_id2 = "0"; // -1 CC 1, -2 CC2, pick first tender?? can pass in all the time
	if (!tender || tender.swipe === 0) {
		body.ct_id2 = "0";
	} else {
		body.ct_id2 = ct_id2 + "";
	}

	body.items = getItemsArrayForJson(cart);

	LogDebug(body);

	return body;
};

const getItemsArrayForJson = (cart) => {
	const itemsArray = [];
	cart.items.forEach((item) => {
		let i = {};
		i.itemid = item.itemid;
		i.sectionid = item.sectionid;
		i.upsell_upsellid = item.upsell_upsellid || 0;
		i.upsell_variantid = item.upsell_variantid || 0;

		i.options = [];
		item.options.forEach((option) => {
			let o = {};
			if (option.is_combo === 1) {
				o = stripComboItemOption(option);
			} else {
				o.optionid = option.optionid;
				o.values = [];
				option.values.forEach((value) => {
					const v = {};
					v.valueid = value.valueid;
					if (value.selected) {
						v.combo_itemid = 0;

						o.values.push(v);
					}
				});
			}

			if (o.values.length > 0) {
				i.options.push(o);
			}
		});

		itemsArray.push(i);
	});

	return itemsArray;
};

export const userCheckinRequiredBeforeProcessingOrder = (location, cart) => {
	///only for asap orders!
	return !(cart?.end_time?.length > 0) && hasFeatureFlag(location, FeatureConstants.FEA_LOC_USER_CHECKIN_REQUIRED);
};
