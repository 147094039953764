import dayjs from "dayjs";

const ConstantsLabels = {
	labelAdd: "Add",
	labelAppBarTitleSupport: "Support",
	labelAppBarTitleCheckout: "Confirm Order",
	labelAppBarTitlePendingOrders: "Pending Orders",
	labelApplyPromo: "Apply Promo",
	labelActionCannotBeUndone: "This action cannot be undone.",

	labelButtonBack: "Back",
	labelButtonLocations: "Go to Locations",
	labelButtonHome: "Click to view Home page.",
	labelButtonLoginRegister: "Log In / Register",
	labelButtonProfile: "Access your profile.",
	labelButtonSearchClear: "Clear the search box.",
	labelButtonSearchRun: "Click button or press Enter to run search.",
	labelButtonDeleteItemFromCart: "Delete Item",
	labelButtonDeleteCreditCard: "Delete Card",
	labelButtonEditItemInCart: "Edit Item",

	labelCampusSelectButton: "Select Campus",
	labelCannotBeUndone: "This cannot be undone!",
	labelCheckoutSelectTender: "SELECT TENDER",
	labelConfirm: "Confirm",
	labelConfirmCheckin: "Thanks for checking in. Your order will be ready shortly.",
	labelCurrentlyUnavailable: "Currently Unavailable",

	labelDelivery: "Delivery",

	labelEditAsterixRequired: "Asterisks (*) indicate required fields.",
	labelEditName: "Edit Name",
	labelEditEmail: "Edit Email",
	labelEditPassword: "Edit Password",
	labelEmailReceiptMessage: "This receipt has been emailed to you",
	labelEmailReceiptTitle: "Receipt Emailed",
	labelEmailRequired: [
		"We sent you an email confirmation.",
		"Click the link in the email to verify your address.",
		"You may need to check your spam folder."
	],
	labelEmptyCart: "Cart is Empty",
	labelEmptyCartRedirecting: "Cart is Empty - Redirecting to menu...",
	labelExistingPendingOrder: ["You currently have a pending order.", "Would you like to place an additional order?"],

	labelInvite: "Invite",
	labelInviteAFriend: "Invite a Friend",

	labelMenuLogOut: "Log Out",
	labelMenuLogin: "Log In",
	labelMenuLoginForgotPassword: "Forgot Password",
	labelMenuLoginForgotPasswordReset: "Reset",
	labelMenuRegister: "Register",
	labelMenuRegistration: "Registration",
	labelMenuProfile: "Profile",
	labelMenuMessages: "Messages",
	labelMenuTransactionHistory: "Transaction History",

	labelNoAvailableScheduleTimes: "No Available Schedule Times",
	labelNutritionDisclaimer:
		"Adults & youth (ages 13 & older) need an average of 2,000 calories a day, and children (ages 4-12) need an average of 1,500 calories a day. However, individual needs vary.",
	labelPageTitles: {
		checkout: "Checkout",
		confirmation: "Order Confirmation",
		legal: "Legal",
		map: "Map",
		messages: "Inbox",
		profile: "Profile",
		support: "Support",
		transactions: "Transactions",
		inviteafriend: "Invite a Friend"
	},
	labelPaymentMethods: "Payment Methods",
	labelPhoneRequired: ["Enter your mobile number to receive a verification test.", "It will only be used to contact you regarding an order."],
	labelPickup: "Pickup",

	labelRateOrder: "Rate Order",
	labelRateReservation: "Rate Reservation",
	labelRightsReserved: "© 2022 Hangry Solutions Inc. All Rights Reserved.",

	labelToggleDarkMode: "Toggle Between Dark and Light Mode.",
	labelTogglePickupOrDelivery: `Order Pickup or Delivery.`,
	labelToggleSidebar: "Expand or Collapse the Sidebar.",
	labelToggleViewCart: "Click to View Cart",
	labelTransactionConfirmationMessage: "You will be notified when your transaction is ready.",
	labelTypeDeleteToConfirm: "Type delete here to confirm",

	labelVerificationCodeRequired: "We sent you a 6 digit verification code."
};

export default ConstantsLabels;
