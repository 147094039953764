const Constants = {
	uuidCookieName: "hangry_uuid",
	onLaunchCookieName: "on_launch",
	sessionidCookieName: "hangry_sessionid",
	loginHashKey: "8k39bwPF8aqs3QY9PgWQYFFDspd6Fs",
	initialVisitStorageName: "createdon",
	apiHashSalt: "dFz9Dq435BT3xCVU2PCy",

	localString: "Local",
	developmentString: "Development",
	productionString: "Production",
	themeDarkString: "dark",
	themeLightString: "light",

	dateFormatMonthDay: "MMMM Do",
	dateFormatWithDOW: "dddd, MMMM Do",
	timeFormat: "h:mm A",
	isoWeekFormat: "isoWeek",
	startDayFormat: "day",

	placeholderProfilePicture: "/images/placeholders/profile_placeholder.jpg",
	placeholderMessagePicture: "/images/placeholders/inbox_placeholder.png",
	placeholderFood: "/images/placeholders/food_placeholder.jpg",
	placeholderFoodRectangle: "/images/placeholders/food_placeholder_rectangle.jpg",
	placeholderBuilding: "/images/placeholders/building_placeholder.jpg",
	placeholderSchool: "/images/placeholders/school_placeholder.jpg",
	placeholderTicket: "/images/placeholders/ticket_placeholder.jpg",
	placeholderStar: "/images/placeholders/star_placeholder.jpg",

	pickupDeliveryTypeEnum: { PICKUP_0: 0, DELIVERY_1: 1 },

	APP_LOCATION: {
		CHECKOUT_PAGE: "CHECKOUT",
		CONFIRMATION_PAGE: "CONFIRMATION",
		CUSTOMIZATION_PAGE: "CUSTOMIZATION",
		MENU_PAGE: "MENU",
		PENDING_ORDERS: "PENDING_ORDERS",
		TRANSACTION_HISTORY_PAGE: "TRANSACTION_HISTORY"
	},

	ORDER_TAKEOUT_STATUS_ENUM: { RECEIVED: 0, PRINTED: 1, COMPLETE: 3, PICKED_UP: 4, CHECK_IN: 5 },

	ORDER_DELIVERY_STATUS_ENUM: { RECEIVED: 0, PRINTED: 1, COMPLETE_NOT_STARTED_DELIVERY: 3, COMPLETE_DELIVERY_IN_PROGRESS: 4, DELIVERED: 5 },

	ORDER_PROGRESS_STATE_ENUM: {
		STATE_1: 1,
		STATE_2: 2,
		STATE_3: 3
	},

	freedomPay: "FreedomPay",
	paymentExpress: "PaymentExpress",

	maxDietaryFlags: 7,

	OPEN_FILTER_PRIORITY_TYPE_ENUM: {
		OFP_CLOSED_FOR_TRAINING: 0,
		OFP_CLOSED: 1,
		OFP_TEST_MODE: 2,
		OFP_OPEN: 3
	}
};

export default Constants;
