import { isWebOrderLocal } from "./EnvUtils";

export const readCookie = (name) => {
	let n = name + "=";
	let cookies = decodeURIComponent(document.cookie);

	let a = cookies.split(";");

	for (let i = 0; i < a.length; i++) {
		let c = a[i];

		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}

		if (c.indexOf(n) === 0) {
			return c.substring(n.length, c.length);
		}
	}
	return "";
};

export const saveCookie = (name, value, expminutes) => {
	let cookieHttpsSecure = "Secure;"; //dont put secure when running locally
	if (isWebOrderLocal()) cookieHttpsSecure = "";

	let d = new Date();
	d.setTime(d.getTime() + (expminutes || 1) * 60 * 1000);

	let e = "expires=" + d.toUTCString();

	document.cookie = name + "=" + value + ";" + e + ";path=/" + ";" + cookieHttpsSecure + " SameSite=Strict;";
};

export const removeCookie = (name) => {
	let cookieHttpsSecure = "Secure;";
	if (isWebOrderLocal()) cookieHttpsSecure = "";

	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" + cookieHttpsSecure + " SameSite=Strict;";
};
