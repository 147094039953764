import List from "@mui/material/List";
import { cloneDeep } from "lodash";
import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";

import SellIcon from "@mui/icons-material/Sell";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import { classes } from "../../theme/GlobalStyle";

import CustomPaperContainer from "../custom/CustomPaperContainer";

import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CheckoutOptionItemModalForm from "./CheckoutOptionItemModalForm";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";

const getSelectedChoiceIds = (checkoutSelectBoxes) => {
	const checkoutIdsArrays = [];
	checkoutSelectBoxes.forEach((c) => {
		c.checkout_select_choices?.forEach((c) => {
			if (c.selected) {
				checkoutIdsArrays.push(c.choiceid);
			}
		});
	});
	return checkoutIdsArrays;
};

const displaySelectedCheckboxValues = (valuesArray) => {
	const newDisplayValue = [];
	valuesArray.forEach((c) => {
		if (c.selected) {
			newDisplayValue.push(c.choice_label);
		}
	});
	return newDisplayValue.join(" · ");
};

const validate = (box, choices) => {
	delete box.showValidateModal;
	const { minimum_selections, maximum_selections } = box;
	const selectedChoices = choices.filter((b) => b.selected);
	box.validationPassed =
		(minimum_selections === 0 || selectedChoices.length >= minimum_selections) &&
		(maximum_selections === 0 || selectedChoices.length <= maximum_selections);
};

const CheckoutSectionOptions = forwardRef((props, ref) => {
	const modalFormRef = useRef();
	const {
		campus,
		location,
		specialComment,
		setSpecialComment,
		setCheckoutSelectChoiceIds,
		savePromoCode,
		promoCode,
		pickupOrDelivery,
		showSpecialCommentModal,
		setShowSpecialCommentModal,
		doEnqueueSnackbar
	} = props;
	const [checkoutSelectBoxes, setCheckoutSelectBoxes] = useState([]);

	const specialInstructionsLabel =
		pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0
			? location.special_comment_instructions
			: location.special_comment_instructions_delivery;
	const specialInstructionsRequired =
		pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0
			? location.special_comment_required === 1
			: location.special_comment_required_delivery === 1;

	const hideApplyPromo =
		hasFeatureFlag(campus, FeatureConstants.FEA_CHECKOUT_HIDEAPPLYPROMO) ||
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_HIDEAPPLYPROMO);

	useEffect(() => {
		const csb = location.checkout_select_boxes;
		// Set initial selected values on each choice
		csb?.forEach((c) => {
			c.checkout_select_choices?.forEach((c) => {
				c.selected = c.is_default === 1;
			});
			validate(c, c.checkout_select_choices);
		});
		setCheckoutSelectBoxes(csb);
		setCheckoutSelectChoiceIds(getSelectedChoiceIds(csb));
	}, []);

	const handleSave = (closeModalCallback) => {
		const { optionType, updateValue, boxid } = modalFormRef.current.getSaveValues();
		if (optionType === "text") {
			setSpecialComment(updateValue);
			setShowSpecialCommentModal(false);
		} else {
			const replaceIndex = checkoutSelectBoxes.findIndex((boxes) => boxes.boxid === boxid);
			const clonedCheckoutSelectBoxes = cloneDeep(checkoutSelectBoxes);
			const updateCheckoutSelectBox = clonedCheckoutSelectBoxes.find((boxes) => boxes.boxid === boxid);
			validate(updateCheckoutSelectBox, updateValue);
			const selectedChoicesLength = updateValue.filter((b) => b.selected).length;
			const minSelections = updateCheckoutSelectBox.minimum_selections;
			const maxSelections = updateCheckoutSelectBox.maximum_selections;
			if (
				(selectedChoicesLength > 0 && !updateCheckoutSelectBox.validationPassed) ||
				(updateCheckoutSelectBox.is_required && selectedChoicesLength === 0)
			) {
				let error = `Please  select a valid number of options between ${minSelections} - ${maxSelections}`;
				if ((updateCheckoutSelectBox.is_required === 1 && selectedChoicesLength === 0) || selectedChoicesLength < minSelections) {
					error = "You must select at least " + minSelections;
				} else if (selectedChoicesLength > maxSelections && maxSelections !== 0) {
					error = "You cannot select more than " + maxSelections;
				}
				doEnqueueSnackbar({
					message: error,
					title: "Options Error",
					excludeFromHistory: false,
					options: { variant: "error" }
				});
				return;
			}
			updateCheckoutSelectBox.checkout_select_choices = updateValue;
			clonedCheckoutSelectBoxes.splice(replaceIndex, 1, updateCheckoutSelectBox);
			setCheckoutSelectChoiceIds(getSelectedChoiceIds(clonedCheckoutSelectBoxes));
			setCheckoutSelectBoxes(cloneDeep(clonedCheckoutSelectBoxes));
		}
		closeModalCallback();
	};

	const handleSavePromoCode = (closeModalCallback) => {
		savePromoCode(modalFormRef.current.getPromoCodeValue(), closeModalCallback);
	};

	// ShowValidate is used to show a modal that's in error when we try to process the order.
	// This resets the value if the modal is closed without save, i.e. clicks off of the modal
	const resetShowValidateModal = (boxid) => {
		const replaceIndex = checkoutSelectBoxes.findIndex((boxes) => boxes.boxid === boxid);
		const clonedCheckoutSelectBoxes = cloneDeep(checkoutSelectBoxes);
		const updateCheckoutSelectBox = clonedCheckoutSelectBoxes.find((boxes) => boxes.boxid === boxid);
		delete updateCheckoutSelectBox.showValidateModal;
		clonedCheckoutSelectBoxes.splice(replaceIndex, 1, updateCheckoutSelectBox);
		setCheckoutSelectBoxes(cloneDeep(clonedCheckoutSelectBoxes));
	};

	useImperativeHandle(ref, () => ({
		// validateOptions() called from ProcessOrder() in parent file, i.e. CheckoutPage
		validateOptions() {
			const boxInError = checkoutSelectBoxes.find((box) => box.validationPassed === false);
			if (boxInError) {
				const replaceIndex = checkoutSelectBoxes.findIndex((boxes) => boxes.boxid === boxInError.boxid);
				const clonedCheckoutSelectBoxes = cloneDeep(checkoutSelectBoxes);
				boxInError.showValidateModal = true;
				clonedCheckoutSelectBoxes.splice(replaceIndex, 1, boxInError);
				setCheckoutSelectBoxes(cloneDeep(clonedCheckoutSelectBoxes));
				return true;
			}
			return false;
		}
	}));

	return specialInstructionsLabel.length === 0 && checkoutSelectBoxes.length === 0 && hideApplyPromo ? null : (
		<CustomPaperContainer>
			<List className={classes.listTransparentNoBorder} disablePadding>
				{!hideApplyPromo && (
					<CustomFormLabelItem
						dense
						Icon={SellIcon}
						IconProps={{ alt: "Promo" }}
						label={promoCode.length > 0 ? `${promoCode[0]}` : ConstantsLabels.labelApplyPromo}
						subLabel={promoCode.length > 0 && promoCode[1]}
						modalForm={CheckoutOptionItemModalForm}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef,
							optionType: "text",
							initialTextValue: promoCode[0],
							hideClearButton: true,
							textFieldMaxLength: 15,
							placeholder: "Enter promo code",
							title: ConstantsLabels.labelApplyPromo
						}}
						modalProps={{
							buttonText: "SAVE",
							closeModalOnButtonClick: true,
							preventOutsideClick: true,
							showButton: true,
							onButtonClick: handleSavePromoCode,
							title: ConstantsLabels.labelApplyPromo,
							modalLabel: ConstantsLabels.labelApplyPromo
						}}
					/>
				)}

				{checkoutSelectBoxes.length > 0 &&
					checkoutSelectBoxes?.map((csb) => {
						let displayText = csb.popup_title;

						if (csb.is_multiselect === 0) {
							const selectedValue = csb?.checkout_select_choices?.find((c) => c.selected);
							displayText =
								selectedValue?.checkout_display_label === ""
									? selectedValue?.choice_label
									: selectedValue?.checkout_display_label || csb.popup_title;
						} else if (csb.is_multiselect === 1) {
							displayText = displaySelectedCheckboxValues(csb.checkout_select_choices) || csb.popup_title;
						}

						return (
							<CustomFormLabelItem
								dense
								key={csb.boxid}
								icon_url={csb.checkout_display_icon_with_base}
								label={displayText}
								modalForm={CheckoutOptionItemModalForm}
								required={csb.is_required === 1}
								showModal={csb.showValidateModal}
								useCustomModal2
								modalFormProps={{
									modalFormRef: modalFormRef,
									optionType: csb.is_multiselect,
									selectChoices: csb.checkout_select_choices,
									minimum: csb.minimum_selections,
									maximum: csb.maximum_selections,
									showHelperMessageError: true,
									helperMessage:
										csb.is_required === 1 &&
										`Please select between ${csb.minimum_selections} & ${csb.maximum_selections} `,
									boxid: csb.boxid
								}}
								modalProps={{
									buttonText: "SAVE",
									closeModalOnButtonClick: true,
									preventOutsideClick: true,
									showButton: true,
									onButtonClick: handleSave,
									onClickCloseButton: () => resetShowValidateModal(csb.boxid),
									title: csb.popup_title,
									subtitle: csb.popup_description,
									modalLabel: csb.popup_title
								}}
							/>
						);
					})}

				{specialInstructionsLabel.length !== 0 && (
					<CustomFormLabelItem
						dense
						Icon={TextSnippetIcon}
						IconProps={{ alt: "Special Instructions" }}
						label={specialComment || specialInstructionsLabel}
						modalForm={CheckoutOptionItemModalForm}
						showModal={showSpecialCommentModal}
						required={specialInstructionsRequired}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef,
							optionType: "text",
							initialTextValue: specialComment,
							hideClearButton: true,
							textFieldMaxLength: 100,
							placeholder: `Enter${specialInstructionsRequired ? " required" : ""} value`,
							title: specialInstructionsLabel
						}}
						modalProps={{
							buttonText: "SAVE",
							closeModalOnButtonClick: true,
							preventOutsideClick: true,
							showButton: true,
							onButtonClick: handleSave,
							onClickCloseButton: () => setShowSpecialCommentModal(false),
							title: specialInstructionsLabel,
							modalLabel: specialInstructionsLabel
						}}
					/>
				)}
			</List>
		</CustomPaperContainer>
	);
});
export default CheckoutSectionOptions;
