import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getPickupTime } from "./pendingOrdersUtils";

import ApiEndpoints from "../../api/ApiEndpoints";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import FeatureConstants from "../../utils/FeatureConstants";
import { getObjectFromArray } from "../../utils/ObjectUtils";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";

import CustomValuesList from "../custom/CustomValuesList";

const PendingOrderItem = ({ item }) => {
	return (
		<Stack direction="column" spacing={1}>
			<Divider role="presentation" />
			<Typography variant="h6" variantMapping={{ h6: "h2" }}>
				{item.name}
			</Typography>
			<CustomValuesList valueArray={item.flattened_option_values} labelKey={"flattened_name"} keyId={"valueid"} />
		</Stack>
	);
};

const PendingOrder = ({ cafeteriaGroups, campus, pendingOrder, setBusy, doApiCall, doEnqueueSnackbar }) => {
	const navigate = useNavigate();
	const { getPastOrders } = useCommonApiCalls();
	const cafeteriaGroup = cafeteriaGroups.find(
		(g) =>
			g.locations.some((l) => l.locationid === pendingOrder.locationid) ||
			g.disabled_locations.some((l) => l.locationid === pendingOrder.locationid)
	);

	const location =
		getObjectFromArray(pendingOrder.locationid, cafeteriaGroup.locations, "locationid") ||
		getObjectFromArray(pendingOrder.locationid, cafeteriaGroup.disabled_locations, "locationid");

	const locationIconUrl = location.icon_picture_url_with_base;

	const [isConfirmCancelOrderVisible, setIsConfirmCancelOrderVisible] = useState(false);
	const [dialogTitle, setDialogTitle] = useState("Cancel Order");
	const [dialogMessage, setDialogMessage] = useState(`Would you like to cancel and refund order #${pendingOrder.orderid_display}?`);

	const handleOnClickViewReceipt = () => {
		getPastOrders();
		navigate(`/user/transaction?orderid=${pendingOrder.orderid}&page_location=${Constants.APP_LOCATION.PENDING_ORDERS}`);
	};

	const canCancel =
		pendingOrder.asap === 0 && pendingOrder.iscancelled === 0 && !hasFeatureFlag(location, FeatureConstants.FEA_LOC_DISABLE_CANCEL_ORDER);

	const handleOnClickCancelOrder = () => {
		if (!pendingOrder || pendingOrder.orderid === 0) {
			doEnqueueSnackbar({
				message: "This order is no longer available",
				title: "ITEM NOT AVAILABLE",
				excludeFromHistory: true,
				options: { variant: "error" }
			});
			return;
		}

		if (pendingOrder.isVirtualQueue) {
			setDialogTitle("Cancel Reservation");
			setDialogMessage(`Would you like to cancel reservation #${pendingOrder.orderid_display}?`);
		}

		setIsConfirmCancelOrderVisible(true);
	};

	const cancelOrder = () => {
		doApiCall(
			ApiEndpoints.CancelPendingOrder,
			{ orderid: pendingOrder.orderid.toString() },
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				successMessage: "Successfully Cancelled",
				//hideErrorToast: true,
				toastTitle: "Cancelled "
			},
			(data) => {},
			(errorMsg, errorReason) => {}
		);
	};

	return (
		<Card variant="outlined" tabIndex={0}>
			<CardHeader
				avatar={<Avatar alt="Location" src={locationIconUrl} />}
				action={
					<Typography variant="h6" variantMapping={{ h5: "h2" }} align="center" paddingRight={1}>
						{`#${pendingOrder.orderid}`}
					</Typography>
				}
				title={location.name}
				subheader={cafeteriaGroup.group_display_name}
				className={classes.pendingOrdersCardHeader}
			/>
			<CardContent>
				<>
					<Divider role="presentation" />
					<Typography variant="h3" variantMapping={{ h3: "h2" }} align="center" padding={1}>
						{getPickupTime(pendingOrder, campus, location)}
					</Typography>
				</>
				{pendingOrder.items
					.filter((item) => !item.comboHide)
					.map((item) => {
						return <PendingOrderItem item={item} key={item.itemid} />;
					})}
			</CardContent>
			<CardActions className={clsx(classes.pendingOrdersCardActions, classes.justifyCenter)}>
				{canCancel && <CustomFormButton buttonText={`CANCEL`} handleClick={handleOnClickCancelOrder} isCancelButton />}
				<CustomFormButton
					maxWidth={"300px"}
					buttonText={`RECEIPT #${pendingOrder.orderid_short_string}`}
					handleClick={handleOnClickViewReceipt}
				/>
			</CardActions>

			<ConfirmAlertDialog
				isVisible={isConfirmCancelOrderVisible}
				setIsVisible={setIsConfirmCancelOrderVisible}
				title={dialogTitle}
				description={dialogMessage}
				confirmButtonText={dialogTitle}
				cancelCallback={() => setIsConfirmCancelOrderVisible(false)}
				cancelButtonText="Go Back"
				confirmCallback={cancelOrder}
				confirmDanger
			/>
		</Card>
	);
};

export default PendingOrder;
