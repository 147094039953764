import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import StarsOutlinedIcon from "@mui/icons-material/StarOutline";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { GlobalStyle, classes } from "../../theme/GlobalStyle";
import { getImageFromURL } from "../../utils/EnvUtils";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { LogDebug, openLink, isDeviceMobileFx } from "../../utils/Utils";
import SignInPage from "../signin/SignInPage";
import CustomModal2 from "../modals/CustomModal2";
import useCommonData from "../../utils/hooks/useCommonData";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import PaymentMethodModalForm from "../paymentMethod/PaymentMethodModalForm";
import ApplyPromoModalForm from "../modals/AppBarMenuModals/ApplyPromoModalForm";
import Notifications from "../notifications/Notifications";
import { isPhoneNoReqCheck, isPhoneVerifiedCodeReqCheck, isEmailReqCheck } from "../signin/signInUtils";
import { InputReqDialog } from "../signin/InputReqDialog";
import EmailReqdModal from "../signin/EmailReqdModal";
import PhoneNumberReqModal from "../signin/PhoneNumberReqModal";
import PhoneReqdVerifyCodeModal from "../signin/PhoneReqdVerifyCodeModal";
import Image from "mui-image";
import { useTheme } from "@mui/material/styles";
import SkipLink from "../../utils/components/SkipLink";

const RootAppBar = styled(AppBar)(({ theme }) => GlobalStyle(theme));
const RootMenu = styled(Menu)(({ theme }) => GlobalStyle(theme));

const CustomAppBar = ({ settingsData, doApiCall, doEnqueueSnackbar, doUpdateCurrentUserEmail, doClearAllCarts }) => {
	const routerLocation = useLocation();
	const navigate = useNavigate();
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const theme = useTheme();
	const { campus, campusID, locationID, locations, user, carts } = useCommonData();
	const { card_added, card_added_failed } = routerLocation?.state || {};

	const links = campus?.links || [];
	let menuLinks = links.filter((l) => l.more_visible === 1)?.sort((a, b) => a.more_position - b.more_position);

	const path = routerLocation.pathname.split("/"); // TODO Is this the right thing to do here? Do we have to have location to get to the checkout?

	const { title } = settingsData;

	const [anchorLoggedInMenu, setAnchorLoggedInMenu] = useState(null);
	const [isSignInModalShowing, setIsSignInModalShowing] = useState(false);
	const [isApplyPromoModalShowing, setIsApplyPromoModalShowing] = useState(false);
	const [isPaymentMethodModalShowing, setIsPaymentMethodModalShowing] = useState(false);

	const [isHomePage, setIsHomePage] = useState(false);
	const appBarTitle = isHomePage && !campus?.brand_logo_picture_url && campus?.name ? campus.name : title;

	const hideApplyPromoMenuItem = hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDEAPPLYPROMOCELL);

	const hidePaymentMethodMenuItem =
		hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDEPAYMENTMETHODSCELL) &&
		user?.has_mealplancard === 1 &&
		user?.payment_method_default === 0;

	const hideSupportMenuItem = hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDESUPPORTCELL);

	const hideInviteFriendCell = hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDEINVITEFRIENDCELL);

	useEffect(() => {
		if (card_added) {
			setIsPaymentMethodModalShowing(!!user && card_added === true);
		} else if (card_added_failed) {
			doEnqueueSnackbar({
				message: "Failed to add Credit Card",
				title: "Card Error",
				excludeFromHistory: false,
				options: { variant: "error" }
			});
		}
	}, [card_added, card_added_failed]);

	useEffect(() => {
		setIsHomePage(locationID === undefined && campusID !== "legal");
	}, [locationID]);

	const [isMainModalPage, setIsMainModalPage] = useState(true);
	const [isForgotPassword, setIsForgotPassword] = useState(false);

	const isShowBackButton = () => {
		return locationID || path.indexOf("profile") > -1 || path.indexOf("user") > -1;
	};

	const goToHome = (e) => {
		LogDebug(`goToHome`);
		e && e.preventDefault();
		navigate(`/${campusID}`);
	};

	const handleOnClickBack = () => {
		if (path.length === 5 && locationID) {
			navigate(`/${campusID}/${locationID}`, { replace: true });
		} else if (routerLocation.key !== "default") {
			navigate(-1);
		} else {
			navigate(`/${campusID}`, { replace: true });
		}
	};

	// Logged in handlers
	const handleMenuLoggedIn = (event) => {
		setAnchorLoggedInMenu(event.currentTarget);
	};

	const handleMenuLoggedInClose = () => {
		setAnchorLoggedInMenu(null);
	};

	const handleSignIn = () => {
		setAnchorLoggedInMenu(false);
		setIsSignInModalShowing(true);
	};

	const handleLogout = (e) => {
		if (!user) return;

		doApiCall(
			ApiEndpoints.Logout,
			{},
			undefined,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `${user.full_name} logged out.`,
				toastTitle: `User #${user.userid}`
			},
			(data) => {
				handleMenuLoggedInClose();
				doUpdateCurrentUserEmail("");
				doClearAllCarts();
			},
			(errorMsg) => {}
		);
	};

	const handleRewards = () => {
		// handleMenuLoggedInClose();
		navigate(`/user/rewards`);
	};

	const handleProfile = () => {
		handleMenuLoggedInClose();
		navigate(`/user/profile`);
	};

	const handleInviteAFriend = () => {
		handleMenuLoggedInClose();
		navigate("/user/inviteafriend");
	};

	const handleMessages = () => {
		handleMenuLoggedInClose();
		navigate(`/user/messages`);
	};

	const handleHistory = () => {
		handleMenuLoggedInClose();
		navigate(`/user/transactions`);
	};

	const handleSupport = () => {
		handleMenuLoggedInClose();
		navigate(`/${campusID}/support`);
	};

	return (
		<>
			<RootAppBar position="fixed">
				<Toolbar style={{ padding: "0px" }} className={classes.appBar}>
					{isShowBackButton() ? (
						<Tooltip aria-hidden="true" title={ConstantsLabels.labelButtonBack} disableFocusListener id={`tooltip-${ConstantsLabels.labelButtonBack}`}>
							<Button
								aria-hidden="false"
								role={"button"}
								className={clsx(classes.appBarIconButton, classes.appBarIconButtonLeft, classes.appBarIconButtonRound)}
								size="large"
								aria-label="back"
								onClick={handleOnClickBack}
								aria-describedby={`tooltip-${ConstantsLabels.labelButtonBack}`}
							>
								<ArrowBackIcon />
							</Button>
						</Tooltip>
					) : (
						<IconButton
							role={"button"}
							className={clsx(classes.appBarIconButton, classes.appBarIconButtonLeft)}
							size="large"
							edge="start"
							aria-label="home logo"
							disabled
							style={{
								border: "0px",
								padding: "0px"
							}}
						>
							{campus?.icon_picture_url?.length === 0 && <HomeIcon />}
							{campus?.icon_picture_url?.length > 0 && (
								<Avatar
									className={classes.userProfileImageAppBar}
									alt={campus?.name}
									src={getImageFromURL(campus?.icon_picture_url)}
								/>
							)}
						</IconButton>
					)}
					{isHomePage && campus?.brand_logo_picture_url ? (
						<div className={clsx(classes.justifyLeft)}>
							<Image
								className={clsx(classes.appBarBrandLogoImg)}
								alt={campus?.name}
								src={getImageFromURL(campus?.brand_logo_picture_url)}
								height={theme.spacing(5)}
								duration={350}
							/>
						</div>
					) : (
						<Tooltip
							aria-hidden="true"
							title={ConstantsLabels.labelButtonLocations}
							disableFocusListener
							id={`tooltip-${ConstantsLabels.labelButtonLocations}`}
						>
							<Button
								aria-hidden="false"
								className={classes.appBarBrandLogoButton}
								aria-label="goto home"
								onClick={goToHome}
								aria-describedby={`tooltip-${ConstantsLabels.labelButtonLocations}`}
							>
								<Typography
									variant="h3"
									variantMapping={{ h3: "h1" }}
									align="justify"
									pl={1}
									className={classes.appBarTextColor}
								>
									{appBarTitle}
								</Typography>
							</Button>
						</Tooltip>
					)}
					<SkipLink />
					<div style={{ flexGrow: 1 }}></div>
					{user && (
						<>
							{/* <Tooltip title={`Rewards`} disableFocusListener>
								<span>
									<IconButton
										role={"button"}
										className={clsx(classes.appBarIconButton, classes.appBarIconButtonRight)}
										size="large"
										edge="start"
										aria-label="rewards"
										onClick={handleRewards}
									>
										<StarsOutlinedIcon />
									</IconButton>
								</span>
							</Tooltip> */}
							{user && <Notifications />}
							<Tooltip aria-hidden="true" title={`${user.full_name}s Account`} disableFocusListener id={`tooltip-${user.full_name}s Account`}>
								<Button
									aria-hidden="false"
									role={"button"}
									className={clsx(classes.appBarIconButton, classes.appBarIconButtonRight, classes.appBarIconButtonRound)}
									size="large"
									aria-label={`${user.full_name}s Profile`}
									aria-haspopup="true"
									onClick={handleMenuLoggedIn}
									aria-describedby={`tooltip-${user.full_name}s Account`}
								>
									<MenuIcon />
								</Button>
							</Tooltip>
							<RootMenu
								id="menu-appbar-loggedin"
								anchorEl={anchorLoggedInMenu}
								keepMounted
								open={Boolean(anchorLoggedInMenu)}
								onClose={handleMenuLoggedInClose}
								disableRestoreFocus
								PaperProps={{ classes: { root: classes.appBarPopoverPlacement } }}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left"
								}}
							>
								<MenuItem onClick={handleProfile}>
									<ListItemAvatar>
										{user?.picture_url_with_base?.length === 0 && <HomeIcon />}
										{user?.picture_url_with_base?.length > 0 && (
											<Avatar src={user.picture_url_with_base} alt={user.full_name + " Profile Picture"} />
										)}
									</ListItemAvatar>
									<ListItemText primary="My Profile" />
								</MenuItem>
								{isHomePage && !hidePaymentMethodMenuItem && (
									<MenuItem
										onClick={() => {
											handleMenuLoggedInClose();
											setIsPaymentMethodModalShowing(true);
										}}
									>
										{ConstantsLabels.labelPaymentMethods}
									</MenuItem>
								)}
								<Divider role="presentation" />
								{isHomePage && !hideInviteFriendCell && (
									<MenuItem onClick={handleInviteAFriend}>{ConstantsLabels.labelInviteAFriend}</MenuItem>
								)}
								{isHomePage && !hideApplyPromoMenuItem && (
									<MenuItem
										onClick={() => {
											handleMenuLoggedInClose();
											setIsApplyPromoModalShowing(true);
										}}
									>
										{ConstantsLabels.labelApplyPromo}
									</MenuItem>
								)}
								{!hasFeatureFlag(campus, FeatureConstants.FEA_MESSAGES_INBOX_DISABLED) && (
									<MenuItem onClick={handleMessages}>{ConstantsLabels.labelMenuMessages}</MenuItem>
								)}
								<MenuItem onClick={handleHistory}>{ConstantsLabels.labelMenuTransactionHistory}</MenuItem>
								{!hideSupportMenuItem && (
									<MenuItem onClick={handleSupport}>{ConstantsLabels.labelAppBarTitleSupport}</MenuItem>
								)}

								{menuLinks.length > 0 && [
									<Divider role="presentation" key="custom-link-divider" />,
									menuLinks.map((l) => {
										return (
											<MenuItem onClick={() => openLink(l.url)} key={l.ccl_id}>
												{l.label}
											</MenuItem>
										);
									})
								]}

								<Divider role="presentation" />
								<MenuItem onClick={handleLogout}>{ConstantsLabels.labelMenuLogOut}</MenuItem>
							</RootMenu>
						</>
					)}
					{!user && (
						<Button
							className={clsx(classes.appBarIconButton, classes.appBarIconButtonRight)}
							startIcon={isDeviceMobile ? undefined : <LoginIcon />}
							onClick={handleSignIn}
							aria-label={ConstantsLabels.labelMenuLogin}
							disabled={locations.length === 0 || !hasFeatureFlag(campus, FeatureConstants.FEA_WEBORDER_ENABLED)}
						>
							Login
						</Button>
					)}
				</Toolbar>
			</RootAppBar>
			<CustomModal2
				modalLabel={ConstantsLabels.labelMenuLogin}
				setIsShowing={setIsSignInModalShowing}
				isShowing={isSignInModalShowing}
				title={`${campus?.name} ${ConstantsLabels.labelMenuLogin}`}
				leftCornerButtonIcon={isMainModalPage ? undefined : <ArrowBackIcon></ArrowBackIcon>}
				leftCornerButtonTooltip={"Back"}
				leftCornerButtonCallback={() => {
					if (isForgotPassword) {
						setIsForgotPassword(false);
					} else {
						setIsMainModalPage(true);
					}
				}}
				onClose={() => {
					setIsMainModalPage(true);
				}}
				onClickCloseButton={() => {
					setIsMainModalPage(true);
				}}
				disableAutoFocus
			>
				<SignInPage
					setIsShowingModal={setIsSignInModalShowing}
					isMainModalPage={isMainModalPage}
					setIsMainModalPage={setIsMainModalPage}
					isForgotPassword={isForgotPassword}
					setIsForgotPassword={setIsForgotPassword}
				/>
			</CustomModal2>

			<PaymentMethodModalForm doApiCall={doApiCall} setIsShowing={setIsPaymentMethodModalShowing} isShowing={isPaymentMethodModalShowing} />

			<ApplyPromoModalForm doApiCall={doApiCall} setIsShowing={setIsApplyPromoModalShowing} isShowing={isApplyPromoModalShowing} />

			{isPhoneNoReqCheck(campus, user) && (
				<InputReqDialog
					isVisible={!isSignInModalShowing}
					title={"PHONE NUMBER"}
					dialogContentComponent={PhoneNumberReqModal}
					isOptional={false}
					cancelCallback={handleLogout}
					isPhoneNoReqCheck={isPhoneNoReqCheck(campus, user)}
				/>
			)}

			{isPhoneVerifiedCodeReqCheck(campus, user) && (
				<InputReqDialog
					isVisible={!isSignInModalShowing}
					title={"VERIFICATION CODE"}
					dialogContentComponent={PhoneReqdVerifyCodeModal}
					isOptional={false}
					cancelCallback={handleLogout}
					isContinueDisabled={isPhoneVerifiedCodeReqCheck(campus, user)}
				/>
			)}

			{isEmailReqCheck(campus, user) && (
				<InputReqDialog
					isVisible={!isSignInModalShowing}
					title={"EMAIL VERIFICATION"}
					dialogContentComponent={EmailReqdModal}
					isOptional={false}
					cancelCallback={handleLogout}
					callGetUserOnClickConfirm
				/>
			)}
		</>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(CustomAppBar);
