import { Toolbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, useNavigate, Routes, useLocation, Navigate, useSearchParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import CheckoutPage from "./CheckoutPage";
import ConfirmationPage from "./ConfirmationPage";
import LocationPage from "./LocationPage";
import MapPage from "./MapPage";
import MenuCustomizationPage from "./MenuCustomizationPage";
import MenuPage from "./MenuPage";
import SupportPage from "./SupportPage";

import ApiEndpoints from "../api/ApiEndpoints";

import { defaultMapStateToProps, enqueueSnackbar, defaultActionsAndCartActionsToProps } from "../store/Actions";

import { GlobalStyle, classes } from "../theme/GlobalStyle";

import Constants from "../utils/Constants";
import FeatureConstants from "../utils/FeatureConstants";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import { LogDebug, getHashedChecksumForApiCall } from "../utils/Utils";
import Loading from "../utils/components/Loading";
import useCommonData from "../utils/hooks/useCommonData";
import CustomAppBar from "../views/custom/CustomAppBar";
import { readCookie, saveCookie } from "../utils/CookieUtils";
import { v4 as uuidv4 } from "uuid";

const ContainerDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const CampusRoutingPage = ({ doApiCall, doUpdateCartPickupDelivery, doUpdateCurrentUserEmail, doClearAllCarts }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { campus, location, locationID, locations, locationCart, campusID, user, currentUserEmail } = useCommonData();
	let [searchParams, setSearchParams] = useSearchParams();
	let temp = searchParams.get("temp");
	const routerLocation = useLocation();

	const [busy, setBusy] = useState(true);

	const locationIds = locations.map((l) => l.locationid);

	if (campus?.features) {
		if (hasFeatureFlag(campus, FeatureConstants.FEA_HOME_SHOWMAPS)) locationIds.push("map");
		if (!hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDESUPPORTCELL)) locationIds.push("support");
	}

	locationIds.push("addcard");
	locationIds.push("failedcard");

	const invalidLocation = locationID && !locationIds.includes(locationID);

	const doInitializeApiCall = () => {
		doApiCall(
			ApiEndpoints.LoginForWeb,
			{ campusid: "" + campusID },
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: true, addDeviceInfoToBody: true },
			(data) => {
				// Only want to update the backend device info if we're logged in
				if (data.user) {
					// Need to see if we update the backend with our device information or not
					const onLaunchCookie = readCookie(Constants.onLaunchCookieName);
					if (!onLaunchCookie) {
						saveCookie(Constants.onLaunchCookieName, uuidv4(), 60); // expire in 60 minutes
					}
				}
			},
			(errorMsg, errorReason) => {}
		);
	};

	useEffect(() => {
		const path = routerLocation.pathname.split("/"); // useParams doesn't seem to work here, so using this instead...
		const inputcampusid = path[1];
		if (!!user && user.campusid.toString() !== inputcampusid) {
			LogDebug(`mismatching user and url campus id (${user.campusid} : ${inputcampusid}). Redirecting to user.campusid`);
			navigate(`/${user.campusid}`, { replace: true, state: { incorrectCampusIdRedirect: true } });
		} else if (!user && locations.length > 0 && invalidLocation) {
			navigate(`/${campusID}`, { replace: true });
		}
	}, [locations, user]);

	useEffect(() => {
		if (routerLocation.state?.incorrectCampusIdRedirect) {
			// Clear state so we only get this message once
			window.history.replaceState({}, document.title);
			dispatch(
				enqueueSnackbar({
					message: `Sorry, you do not have access to this campus. Redirecting to ${campus.name}`,
					title: "INCORRECT_CAMPUS_ID",
					excludeFromHistory: false,
					options: { variant: "error" }
				})
			);
		}
	}, [routerLocation.state]);

	useEffect(() => {
		LogDebug(`useEffect CampusRoutingPage`);
		if (campusID === 0) {
			LogDebug("campusID = 0, should go to a blank / error page");
			setBusy(false);
			navigate(`/`, { replace: true });
		} else if (locations.length > 0 && invalidLocation) {
			setBusy(false);
			doInitializeApiCall();
			navigate(`/${campusID}`, { replace: true });
		} else {
			doInitializeApiCall();
		}
	}, [campusID]);

	useEffect(() => {
		// It's possible that the backend has been updated to only allows delivery OR pickup.  If this has happened, we need to make sure the cart has the correct option selected
		if (location && locationCart) {
			const { delivery, takeout, locationid } = location;
			if (delivery === 1 && takeout === 0 && locationCart?.pickupOrDelivery !== Constants.pickupDeliveryTypeEnum.DELIVERY_1) {
				doUpdateCartPickupDelivery(locationid, Constants.pickupDeliveryTypeEnum.DELIVERY_1);
			} else if (delivery === 0 && takeout === 1 && locationCart?.pickupOrDelivery !== Constants.pickupDeliveryTypeEnum.PICKUP_0) {
				doUpdateCartPickupDelivery(locationid, Constants.pickupDeliveryTypeEnum.PICKUP_0);
			}
		}
	}, [location?.delivery, location?.takeout]);

	useEffect(() => {
		if (temp) {
			const hashChecksum = getHashedChecksumForApiCall(temp, Constants.apiHashSalt);
			// API_REGISTERWITHCAMPUSSSOTOKEN
			// ("hash", "temp_token", "language","os_type", "campusid", "app_bundle_name")
			// (hash, {temp}, "EN", Constants.OSTYPE, campusid + "", BundleIdentifier.getAppBundleName()})
			const reqBody = {
				hash: hashChecksum ? hashChecksum : "",
				temp_token: temp,
				campusid: "" + (campusID ? campusID : "")
			};

			//passing setBusy to the api call function will call setBusy in the 'finally' of api middleware
			//this resulted in React state update on an unmounted component error. so by doing it outside and then in the callback,
			//we prevent the component being onloaded because of routerHistory and then the callback happening
			setBusy(true);

			doApiCall(
				ApiEndpoints.RegisterWithCampusSSOToken,
				reqBody,
				undefined,
				{ logRequest: false, logResponse: true, hideErrorToast: true, addDeviceInfoToBody: true },
				(data) => {
					setBusy(false);

					if (data.user.email !== currentUserEmail && currentUserEmail !== "") {
						doClearAllCarts();
					}
					doUpdateCurrentUserEmail(data.user.email);
				},
				(errorMsg) => {
					setBusy(false);
				}
			);
			setSearchParams({});
		}
	}, [temp]);

	if (!theme.isThemeLoaded) return <></>;

	// if campusid is not supplied, then invalid url
	if (campusID === 0) return <></>;

	if (!locations) {
		setBusy(true);
		return;
	}

	return (
		<>
			<Loading open={busy} />
			<CustomAppBar />
			{/* Second Toolbar used for spacing */}
			<Toolbar sx={{ padding: 0 }} />
			<ContainerDiv className={classes.defaultPageContainer} role="main">
				{!invalidLocation && (
					<Routes>
						<Route path={":inputcampusid"} element={<LocationPage />} />
						<Route path={":inputcampusid/*"}>
							<Route path={"addcard"} element={<Navigate to={`/${campusID}`} state={{ card_added: true }} replace />} />
							<Route
								path={"failedcard"}
								element={<Navigate to={`/${campusID}`} state={{ card_added_failed: true }} replace />}
							/>
							<Route path={"map"} element={<MapPage />} />
							<Route path={"support"} element={<SupportPage />} />
							<Route path={":inputlocationid"} element={<MenuPage />} />
							<Route path={":inputlocationid/checkout"} element={<CheckoutPage />} />
							<Route
								path={":inputlocationid/checkout/addcard"}
								element={
									<Navigate to={`/${campusID}/${locationID}/checkout`} state={{ card_added_checkout: true }} replace />
								}
							/>
							<Route
								path={":inputlocationid/checkout/failedcard"}
								element={
									<Navigate
										to={`/${campusID}/${locationID}/checkout`}
										state={{ card_added_checkout_failed: true }}
										replace
									/>
								}
							/>
							<Route path={":inputlocationid/confirmation"} element={<ConfirmationPage />} />
							<Route path={":inputlocationid/:inputsectionid/:inputitemid/"} element={<MenuCustomizationPage />}>
								<Route path={":inputcartitemid/"} element={<MenuCustomizationPage />} />
							</Route>
						</Route>
					</Routes>
				)}
			</ContainerDiv>
		</>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(CampusRoutingPage);
