import List from "@mui/material/List";
import clsx from "clsx";
import React from "react";
import { connect } from "react-redux";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";

import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import { openLink } from "../../utils/Utils";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";

const LegalList = ({ campusData }) => {
	const { campus } = campusData;

	return (
		<div className={clsx(classes.contentRootDiv, classes.fullHeight)}>
			<List className={classes.listTransparentNoBorder}>
				<CustomFormLabelItem
					Icon={ArticleOutlinedIcon}
					IconProps={{ alt: "Terms of Use" }}
					label={"Terms of Use"}
					onClick={() => {
						openLink(campus.terms_url);
					}}
				/>
				<CustomFormLabelItem
					Icon={PolicyOutlinedIcon}
					IconProps={{ alt: "Privary Policy" }}
					label={"Privacy Policy"}
					onClick={() => {
						openLink(campus.privacy_url);
					}}
				/>
				<CustomFormLabelItem
					Icon={StarsOutlinedIcon}
					IconProps={{ alt: "Contests & Rewards" }}
					label={"Contests & Rewards"}
					onClick={() => {
						openLink(campus.rewards_url);
					}}
				/>
				<CustomFormLabelItem
					Icon={GroupsOutlinedIcon}
					IconProps={{ alt: "Acknowledgements" }}
					label={"Acknowledgements"}
					// onClick={() => {
					// 	openLink(campus.rewards_url);
					// }}
				/>
			</List>
		</div>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LegalList);
