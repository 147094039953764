import React, { useState, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import ValidateInput from "../../utils/ValidatorUtils";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import CustomFormPassword from "../../utils/forms/CustomFormPassword";

const ProfileEditPassword = forwardRef((props, ref) => {
	const { userData, doApiCall } = props;

	const theme = useTheme();
	const { user } = userData;

	const [busy, setBusy] = useState(false);
	const [dirtyBit, setDirtyBit] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [currentPassword, setCurrentPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [errorNewPassword, setErrorNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

	useImperativeHandle(ref, () => ({
		handlePasswordUpdate(closeModalCallback) {
			setFormErrorMessage("");
			setErrorPassword("");
			setErrorNewPassword("");
			setErrorConfirmPassword("");

			if (!user) return;
			if (!ValidateInput(currentPassword, setErrorPassword, { inputName: "Current password", minLength: 5, maxLength: 50 })) return;
			if (!ValidateInput(newPassword, setErrorNewPassword, { inputName: "New password", minLength: 5, maxLength: 50 })) return;
			if (!ValidateInput(confirmPassword, setErrorConfirmPassword, { inputName: "Confirm password", minLength: 5, maxLength: 50 })) return;

			if (newPassword !== confirmPassword) {
				setErrorConfirmPassword("New password and Confirm password do not match");
				return;
			}

			setBusy(true);

			const reqBody = {
				old_password: currentPassword,
				new_password: newPassword
			};

			doApiCall(
				ApiEndpoints.ChangePassword,
				reqBody,
				setBusy,
				{
					logRequest: true,
					logResponse: true,
					hideErrorToast: false,
					successMessage: `${user.full_name} password updated.`,
					toastTitle: `User #${user.userid}`,
					setFormErrorMessage: setFormErrorMessage
				},
				(data) => {
					setBusy(false);
					closeModalCallback && closeModalCallback();
				},
				(errorMsg) => {
					setBusy(false);
					setFormErrorMessage(errorMsg);
				}
			);
		}
	}));

	const textFieldProps = {
		setDirtyBit: setDirtyBit,
		isDisabled: busy
	};

	if (!theme.isThemeLoaded || !user) return <></>;

	return (
		<form>
			<Stack>
				<CustomFormPassword
					inputID={"current-password"}
					inputLabel={"Current Password"}
					setValue={setCurrentPassword}
					value={currentPassword}
					setError={setErrorPassword}
					errorText={errorPassword}
					allowAutoComplete={false}
					{...textFieldProps}
					hiddenUserNameValue={user.userid}
				/>

				<CustomFormPassword
					inputID={"new-password"}
					inputLabel={"New Password"}
					setValue={setNewPassword}
					value={newPassword}
					setError={setErrorNewPassword}
					errorText={errorNewPassword}
					allowAutoComplete={false}
					{...textFieldProps}
				/>

				<CustomFormPassword
					inputID={"confirm-new-password"}
					inputLabel={"Confirm Password"}
					setValue={setConfirmPassword}
					value={confirmPassword}
					setError={setErrorConfirmPassword}
					errorText={errorConfirmPassword}
					allowAutoComplete={false}
					{...textFieldProps}
				/>

				<CustomFormErrorAlert theID={"error"} setFormErrorMessage={setFormErrorMessage} formErrorMessage={formErrorMessage} />
			</Stack>
		</form>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ProfileEditPassword);
