import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";

import { useTheme } from "@mui/material/styles";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { getHashedChecksumForApiCall } from "../../utils/Utils";
import Constants from "../../utils/Constants";
import ValidateInput from "../../utils/ValidatorUtils";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";
import CustomFormPassword from "../../utils/forms/CustomFormPassword";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const ProfileEditEmail = forwardRef((props, ref) => {
	const { userData, doApiCall } = props;

	const theme = useTheme();
	const { user } = userData;

	const [busy, setBusy] = useState(false);
	const [dirtyBit, setDirtyBit] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [email, setEmail] = useState("");
	const [errorEmail, setErrorEmail] = useState("");

	const [isEmailDifferent, setIsEmailDifferent] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

	useImperativeHandle(ref, () => ({
		handleEmailUpdate(closeModalCallback) {
			setFormErrorMessage("");
			if (!user) return;
			if (!ValidateInput(email, setErrorEmail, { isEmail: true })) return;

			setFormErrorMessage("");
			setBusy(true);

			const scrubbedEmail = email.trim().toLowerCase();
			const hashChecksum = getHashedChecksumForApiCall(scrubbedEmail + confirmPassword, Constants.loginHashKey);
			const reqBody = {
				hash: hashChecksum ? hashChecksum : "",
				email: scrubbedEmail ? scrubbedEmail : "",
				password: confirmPassword ? confirmPassword : ""
			};

			doApiCall(
				ApiEndpoints.ChangeEmail,
				reqBody,
				setBusy,
				{
					logRequest: true,
					logResponse: true,
					hideErrorToast: false,
					successMessage: `${user.full_name} email updated.`,
					toastTitle: `User #${user.userid}`,
					setFormErrorMessage: setFormErrorMessage
				},
				(data) => {
					setBusy(false);
					closeModalCallback && closeModalCallback();
				},
				(errorMsg) => {
					setBusy(false);
					setFormErrorMessage(errorMsg);
				}
			);
		}
	}));

	useEffect(() => {
		if (!user) {
			setEmail(undefined);
			return;
		}
		setEmail(user.email);
	}, [user]);

	useEffect(() => {
		setIsEmailDifferent(email !== user.email);
	}, [email]);

	const textFieldProps = {
		setDirtyBit: setDirtyBit,
		isDisabled: busy
	};

	if (!theme.isThemeLoaded || !user) return <></>;

	return (
		<form>
			<Stack direction="column" alignItems="stretch" justifyContent="center" spacing={4} paddingTop={1}>
				<CustomFormTextInput
					inputID={"email"}
					inputLabel={"Email"}
					setValue={setEmail}
					value={email}
					setError={setErrorEmail}
					errorText={errorEmail}
					{...textFieldProps}
					autoCompleteType={"email"}
				/>

				{isEmailDifferent && (
					<>
						<Divider role="presentation" />
						<CustomFormPassword
							inputID={"confirmPassword"}
							inputLabel={"Confirm Password"}
							setValue={setConfirmPassword}
							value={confirmPassword}
							setError={setErrorConfirmPassword}
							errorText={errorConfirmPassword}
							allowAutoComplete={false}
							{...textFieldProps}
							helperText={"Note: This is required to confirm you have authority to make this change."}
						/>
					</>
				)}
				<CustomFormErrorAlert theID={"error"} setFormErrorMessage={setFormErrorMessage} formErrorMessage={formErrorMessage} />
			</Stack>
		</form>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ProfileEditEmail);
