import React, { createContext, useState } from 'react'

export const SearchContext = createContext();

export default function Contexts({children}) {
	const [searchValue, setSearchValue] = useState("");

  // Compare the given value to see if it includes the search value. n.b. "" returns true
  // Using context as redux was taking too long.
  const compareSearchValue = (value) => {
    return value.toUpperCase().includes(searchValue.toUpperCase());
  }

  return (
		<SearchContext.Provider value={{ searchValue, setSearchValue, compareSearchValue }}>
      {children}
    </SearchContext.Provider>
  )
}
