import { LogDebug, getCurrencyString } from "../../utils/Utils";
import { getDateFormats, getDateObjFromUTCString } from "../../utils/DateUtils";

import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import List from "@mui/material/List";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { classes } from "../../theme/GlobalStyle";
import clsx from "clsx";
import { getObjectFromArray } from "../../utils/ObjectUtils";
import { useNavigate } from "react-router-dom";

const TransactionsList = ({ campus, locations, orders }) => {
	const navigate = useNavigate();

	const goToSelectedOrder = (e, order) => {
		LogDebug(`show order history detail`);
		e && e.preventDefault();

		navigate(`/user/transaction?orderid=${order.orderid}`);
	};

	const handleGotoLocations = () => {
		navigate(`/${campus.campusid}`);
	};

	return (
		<CustomPaperContainer className={clsx(classes.contentRootDiv)}>
			{orders?.length > 0 ? (
				<List className={classes.listTransparentNoBorder} disablePadding>
					{orders?.map((order, i) => {
						const location = getObjectFromArray(order.locationid, locations, "locationid");
						const orderDate = order?.order_datetime || null;
						const localOrderDateObj = orderDate ? getDateObjFromUTCString(orderDate) : null;
						const localOrderDate = getDateFormats(localOrderDateObj)?.localDateTimeMonthDayYearHourMin;

						return (
							<CustomFormLabelItem
								key={order.orderid}
								icon_url={location?.icon_picture_url_with_base}
								showLargeIcon
								label={order.orderid_display}
								subLabel={`${localOrderDate}`}
								rightDisplayText={getCurrencyString(order.total_net_payout)}
								onClick={(e) => {
									goToSelectedOrder(e, order, location);
								}}
								isSkipLink={i === 0}
							/>
						);
					})}
				</List>
			) : (
				<Stack spacing={2} padding={2} alignItems={"center"} justifyContent={"center"}>
					<Typography variant="h6" variantMapping={{ h6: "h2" }} align="center">
						You haven't ordered anything yet.
					</Typography>
					<CustomFormButton
						onClick={handleGotoLocations}
						disableDefaultStyle
						disableFullWidth
						buttonText="Start First Order"
					></CustomFormButton>
				</Stack>
			)}
		</CustomPaperContainer>
	);
};

export default TransactionsList;
