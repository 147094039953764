const apiDirectory = "api_weborder/";

const apiBaseURL = process.env.REACT_APP_API_URL + apiDirectory;

const EnvConfig = {
	muiProLicenseKey: "77facf0253a0a729c5588977d0c9e21dT1JERVI6MzE5NzIsRVhQSVJZPTE2Njc1Njc0MzkwMDAsS0VZVkVSU0lPTj0x",
	version: process.env.REACT_APP_VERSION,
	customer: process.env.REACT_APP_CUSTOMER,
	websiteName: process.env.REACT_APP_WEBSITE_NAME,
	apiBaseURL: apiBaseURL,
	webOrderURL: process.env.REACT_APP_WEBORDER_URL,
	webOrderEnvironmentType: process.env.REACT_APP_WEBORDER_ENV,
	apiEnvironmentType: process.env.REACT_APP_API_ENV,
	baseImageURL: process.env.REACT_APP_BASE_IMAGE_URL,
	googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
	logRocketID: process.env.REACT_APP_LOGROCKET_ID
};

export default EnvConfig;
