import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";

export const getMenuItemDetailsStockText = (menuItem, location) => {
	const { mobile_stock_total, mobile_stock_current, qty_per_timeslot, qty_per_timeslot_available } = menuItem;
	let stockText;
	let lowStock = false;
	if (
		((mobile_stock_total > 0 && mobile_stock_current > 0) || qty_per_timeslot > 0) &&
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_ITEM_SHOW_STOCK_REMAINING)
	) {
		stockText = getStockStatusText(menuItem, location);
		if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_SHOW_TOTAL))
			stockText = mobile_stock_current + " of " + mobile_stock_total + " remaining.";

		if (qty_per_timeslot > 0) {
			stockText = getStockStatusTextForVirtualQueue(menuItem);
			if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_SHOW_TOTAL))
				stockText = qty_per_timeslot_available + " of " + qty_per_timeslot + " remaining.";
		}

		if (mobile_stock_current <= 5 && mobile_stock_total - mobile_stock_current > 5) {
			stockText = "Only " + stockText;
			lowStock = true;
		}
	}
	return { stockText, lowStock };
};

export const getMenuItemOrValueStockStatusText = (menuItem, location) => {
	const { mobile_stock_total, qty_per_timeslot, mobile_stock_current } = menuItem;

	const isShowingStock =
		(mobile_stock_total > 0 || qty_per_timeslot > 0) &&
		location !== null &&
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_LIST_SHOW_STOCK_REMAINING);
	let stockText;
	let lowStock = false;
	if (isShowingStock) {
		stockText = getStockStatusText(menuItem, location);

		if (qty_per_timeslot > 0) {
			stockText = getStockStatusTextForVirtualQueue(menuItem, location);
		} else if (mobile_stock_current <= 5 && mobile_stock_total - mobile_stock_current > 5) {
			lowStock = true;
		}

		return { stockText, lowStock };
	}

	return { stockText: null };
};

export const getStockStatusText = (item, location) => {
	let stockText = item.mobile_stock_current + " in stock";
	if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE)) {
		stockText = item.mobile_stock_current + " available";
	}

	if (item.mobile_stock_available === 0 && hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE)) {
		stockText = "Not available";
	} else if (item.mobile_stock_available === 0) {
		stockText = "Out of stock";
	}

	return stockText;
};

const getStockStatusTextForVirtualQueue = (item, location) => {
	if (item.qty_per_timeslot === 0) return "";

	let stockText = item.qty_per_timeslot_available + " in stock";
	if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE)) {
		stockText = item.qty_per_timeslot_available + " available";
	}

	if (item.qty_per_timeslot_available === 0 && hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE)) {
		stockText = "Not available";
	} else if (item.qty_per_timeslot_available === 0) {
		stockText = "Out of stock";
	}

	return stockText;
};
