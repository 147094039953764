import { getDateFormats, getDateObjFromUTCString, getMinutesBetweenDates, getNowDate } from "../../utils/DateUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";

export const getPickupTime = (order, campus, location) => {
	let status = "";

	let preferred_datetime = getDateFormats(getDateObjFromUTCString(order.preferred_datetime)).localToUtc;

	let kitchenAdjustMinutes = order.kitchen_adjustment_minutes;

	if (kitchenAdjustMinutes > 0) {
		preferred_datetime = preferred_datetime.add(kitchenAdjustMinutes, "minutes");
	} else if (kitchenAdjustMinutes < 0) {
		preferred_datetime = preferred_datetime.subtract(Math.abs(kitchenAdjustMinutes), "minutes");
	}

	let now_datetime = getNowDate().utc();

	let minutes = getMinutesBetweenDates(preferred_datetime, now_datetime);

	if (campus.timezone_offset_minutes > 0) {
		preferred_datetime = preferred_datetime.add(campus.timezone_offset_minutes, "minutes");
	} else if (campus.timezone_offset_minutes < 0) {
		preferred_datetime = preferred_datetime.subtract(Math.abs(campus.timezone_offset_minutes), "minutes");
	}

	let countdownTimerEnabled = true;
	if (location && hasFeatureFlag(location, FeatureConstants.FEA_LOC_STATUS_DISABLE_COUNTDOWN)) countdownTimerEnabled = false;

	let titlePickupString = "PICK UP";
	if (location) {
		if (location.virtualqueue === 1) titlePickupString = "THANK YOU";
		else if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY)) titlePickupString = "READY";
	}

	if (order.pickup_datetime !== "" && order.iscomplete === 1)
		//pickedup
		status = "ENJOY";
	else if (
		order.iscomplete === 1 &&
		location &&
		(location.virtualqueue === 1 || hasFeatureFlag(location, FeatureConstants.FEA_LOC_STATUS_COMPLETE_USE_STAGETEXT))
	)
		//complete but not picked up
		status = location.status_takeout_text_3.toUpperCase();
	else if (order.iscomplete === 1 && location.virtualqueue !== 1)
		//complete but not picked up
		status = titlePickupString + " NOW";
	else if (order.requires_checkin === 1 && order.isTakeout) status = "CHECK IN REQUIRED";
	else if (!countdownTimerEnabled) {
		if (order.printed_datetime === "") {
			status = location.status_takeout_text_1.toUpperCase();
		} else {
			status = location.status_takeout_text_2.toUpperCase();
		}
	} else {
		if (minutes > 0) {
			let minsStr = "mins";
			if (minutes < 2) minsStr = "min";

			if (order.asap === 1) {
				status = +minutes + " " + minsStr; //titlePickupString + " in "

				if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_TIME_IF_ONE_SLOT)) {
					status = getDateFormats(preferred_datetime).localDateDowMonthDay;
				}
			} else if (order.asap === 0) {
				if (order.preferred_datetime.length === 0) status = titlePickupString;
				else if (order.future_day === 1) {
					status = getDateFormats(preferred_datetime).localDateDowMonthDay;
					if (!hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_TIME_IF_ONE_SLOT)) {
						status += getDateFormats(preferred_datetime).localTimeHourMin;
					}
				} else {
					status = getDateFormats(preferred_datetime).localTimeHourMin || "ORDERED AHEAD";
				}
			}
		} else {
			if (order.asap === 0) status = "WAITING FOR RELEASE";
			else if (order.requires_checkin === 1 && order.isTakeout) status = "CHECK-IN REQUIRED";
			else if (order.isVirtualQueue)
				status =
					location === null ||
					location.status_takeout_almostready_text === null ||
					location.status_takeout_almostready_text.length === 0
						? "TIME IS UP"
						: location.status_takeout_almostready_text.toUpperCase();
			else {
				if (order.isTakeout)
					status =
						location === null ||
						location.status_takeout_almostready_text === null ||
						location.status_takeout_almostready_text.length === 0
							? "ALMOST READY"
							: location.status_takeout_almostready_text.toUpperCase();
				//delivery
				else
					status =
						location === null ||
						location.status_delivery_almostready_text === null ||
						location.status_delivery_almostready_text.length === 0
							? "ALMOST READY"
							: location.status_delivery_almostready_text.toUpperCase();
			}
		}
	}

	//cancelled + refunded orders should not appear in the queue but in case they do, set an appropriate status
	if (order.iscancelled === 1) status = "CANCELLED";
	else if (order.isrefunded === 1) status = "REFUNDED";

	status = status.toUpperCase();

	return status;
};
