import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { getObjectFromArray } from "../../utils/ObjectUtils";
import StyleConstants from "../../theme/StyleConstants";
import clsx from "clsx";
import { classes } from "../../theme/GlobalStyle";
import { LogDebug } from "../../utils/Utils";
import useCommonData from "../../utils/hooks/useCommonData";

import CustomPaperContainer from "../custom/CustomPaperContainer";

const { topnavbarHeight, tabbarHeight } = StyleConstants;
const heightOffset = topnavbarHeight + tabbarHeight;
const kGoogleMapsKey = "AIzaSyD0fWIN5euw-F6hNyDa2Y-faxjdNNrR1Og";

const containerStyle = {
	width: "100%",
	height: "calc(100vh - " + heightOffset + "px)"
};

const getCafeteriaCoord = (cafeterias, cafeteriaid, isLat) => {
	const c = getObjectFromArray(cafeteriaid, cafeterias, "cafeteriaid");
	if (c === undefined) return 0;
	if (isLat) return c.latitude;
	else return c.longitude;
};
//https://www.google.com/maps?q=43.643097,-79.40048
//https://react-google-maps-api-docs.netlify.app/#marker
const getInitialCoord = (cafeterias, locations) => {
	let startLoc = undefined;
	locations.forEach((l) => {
		const c = getObjectFromArray(l.cafeteriaid, cafeterias, "cafeteriaid");
		if (c && !startLoc && c.latitude !== 0 && c.longitude !== 0) startLoc = { lat: parseFloat(c.latitude), lng: parseFloat(c.longitude) };
	});

	if (startLoc !== undefined) return startLoc;
	return { lat: 0.0, lng: 0.0 };
};

const getFilteredLocations = (cafeterias, locations) => {
	return locations.filter((l) => {
		const c = getObjectFromArray(l.cafeteriaid, cafeterias, "cafeteriaid");
		return c && c.latitude !== 0 && c.longitude !== 0;
		//only return locations that actually have a longitude and latitude
	});
};

const LocationsMap = () => {
	const { campus, cafeterias, locations } = useCommonData();
	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: kGoogleMapsKey
		// ...otherOptions
	});

	const [iconSize, setIconSize] = useState(undefined);

	const [center, setCenter] = useState(() => {
		return getInitialCoord(cafeterias, locations);
	});

	const [locationsForMap, setLocationsForMap] = useState(() => getFilteredLocations(cafeterias, locations));

	useEffect(() => {
		const timeoutRef = setTimeout(() => {
			setLocationsForMap(getFilteredLocations(cafeterias, locations));
		}, 300);

		return () => {
			clearTimeout(timeoutRef);
		};
	}, [campus]);

	const onLoad = useCallback(function onLoad(mapInstance) {
		// do something with map Instance
		LogDebug("map onload called");
		LogDebug(mapInstance);
		setIconSize(new window.google.maps.Size(50, 50));
	});

	if (!campus || cafeterias.length === 0 || locations.length === 0) return <></>;

	const renderMap = () => {
		// wrapping to a function is useful in case you want to access `window.google`
		// to eg. setup options or create latLng object, it won't be available otherwise
		// feel free to render directly if you don't need that

		return (
			<div role="region" aria-live="polite" aria-label="locations map">
				<GoogleMap id="map-locations-google" onLoad={onLoad} mapContainerStyle={containerStyle} center={center} zoom={18}>
					{iconSize && (
						<>
							{locationsForMap.map((l) => {
								return (
									<Marker
										key={l.locationid}
										name={l.name}
										title={l.name}
										locationid={l.locationid}
										cafeteriaid={l.cafeteriaid}
										position={{
											lat: getCafeteriaCoord(cafeterias, l.cafeteriaid, true),
											lng: getCafeteriaCoord(cafeterias, l.cafeteriaid, false)
										}}
										icon={{
											url: l.icon_picture_url_with_base,
											scaledSize: iconSize
										}}
										zIndex={100 + l.locationid}
									/>
								);
							})}
						</>
					)}
				</GoogleMap>
			</div>
		);
	};

	return <CustomPaperContainer className={clsx(classes.contentRootDiv)}>{isLoaded ? renderMap() : <></>}</CustomPaperContainer>;
};

export default LocationsMap;
