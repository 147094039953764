import { Stack } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SignInPageStyle, classes } from "./SignInPageStyle";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsAndCartActionsToProps } from "../../store/Actions";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { getHashedChecksumForApiCall, isDeviceMobileFx } from "../../utils/Utils";
import ValidateInput from "../../utils/ValidatorUtils";
import TransactCompanyInfoFooter from "../../utils/components/TransactCompanyInfoFooter";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import CustomFormPassword from "../../utils/forms/CustomFormPassword";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";

import CustomPaperContainer from "../custom/CustomPaperContainer";
import useCommonData from "../../utils/hooks/useCommonData";
import usePushNotification from "../../utils/firebase/fb-pushNotification";
import useGoogleAnalytics from "../../utils/firebase/fb-analytics";

const RootGrid = styled(Grid)(({ theme }) => SignInPageStyle(theme));

const RegistrationPage = ({
	settingsData,
	setIsShowingModal,
	setIsMainModalPage,
	doApiCall,
	doUpdateRememberMe,
	doUpdateCurrentUserEmail,
	doClearAllCarts
}) => {
	const { requestPushToken } = usePushNotification();
	const { campus, currentUserEmail } = useCommonData();
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const theme = useTheme();
	const { logAnalyticEvent } = useGoogleAnalytics();

	const [busy, setBusy] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [firstName, setFirstName] = useState("");
	const [errorFirstName, setErrorFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [errorLastName, setErrorLastName] = useState("");
	const [email, setEmail] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState("");

	const registerClicked = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!ValidateInput(firstName, setErrorFirstName, { inputName: "First name", minLength: 2, maxLength: 50 })) return;
		if (!ValidateInput(lastName, setErrorLastName, { inputName: "Last name", minLength: 2, maxLength: 50 })) return;
		if (!ValidateInput(email, setErrorEmail, { isEmail: true })) return;
		if (!ValidateInput(password, setErrorPassword, { inputName: "Password", minLength: 5, maxLength: 50 })) return;

		const scrubbedEmail = email.trim().toLowerCase();
		const hashChecksum = getHashedChecksumForApiCall(scrubbedEmail + password, Constants.loginHashKey);
		// API_REGISTERWITHEMAIL
		// ("hash", "language", "email", "password", "first_name", "last_name", "os_type", "campusid", "app_bundle_name"}
		// (hash, "EN", theemail, thepass, thefirstname, thelastname, Constants.OSTYPE, campusid + "", BundleIdentifier.getAppBundleName()})
		const reqBody = {
			hash: hashChecksum ? hashChecksum : "",
			email: scrubbedEmail ? scrubbedEmail : "",
			password: password ? password : "",
			first_name: firstName ? firstName : "",
			last_name: lastName ? lastName : "",
			campusid: "" + (campus.campusid ? campus.campusid : "")
		};

		//passing setBusy to the api call function will call setBusy in the 'finally' of api middleware
		//this resulted in React state update on an unmounted component error. so by doing it outside and then in the callback,
		//we prevent the component being onloaded because of routerHistory and then the callback happening
		setBusy(true);

		doApiCall(
			ApiEndpoints.RegisterWithEmail,
			reqBody,
			undefined,
			{ logRequest: false, logResponse: true, hideErrorToast: true, setFormErrorMessage: setFormErrorMessage, addDeviceInfoToBody: true },
			(data) => {
				setBusy(false);
				setIsMainModalPage(true);
				setIsShowingModal(false);
				requestPushToken();

				if (data.user.email !== currentUserEmail && currentUserEmail !== "") {
					doClearAllCarts();
				}
				doUpdateCurrentUserEmail(data.user.email);
				logAnalyticEvent("register with email");
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const rememberMeToggleClicked = () => {
		const newFlag = !settingsData.rememberMe;
		doUpdateRememberMe(newFlag);
	};

	const textFieldProps = {
		// isDisabled: busy,
		// marginBottom: isDeviceMobile ? 20 : 40,
		maxLength: 50
	};

	if (!theme.isThemeLoaded) return <></>;

	return (
		<RootGrid
			container
			direction="column"
			padding={theme.spacing(2, isDeviceMobile ? 1 : 5)}
			alignItems="stretch"
			className={classes.root}
			wrap="nowrap"
		>
			<Grid item xs container direction="column" justifyContent="center" alignItems="stretch">
				<CustomPaperContainer className={classes.paperBackgroundWidgetTheme} variant="elevation">
					<form
						noValidate
						autoComplete="on"
						id={"login-form"}
						aria-describedby={formErrorMessage.length > 0 ? "login-form-error" : undefined}
					>
						<Typography variant="subtitle1" variantMapping={{ subtitle1: "h3" }} align={"center"}>
							{ConstantsLabels.labelEditAsterixRequired}
						</Typography>
						<Stack direction="column" padding={2} spacing={isDeviceMobile ? 2 : 3}>
							<CustomFormTextInput
								inputID={"firstName"}
								inputLabel={"First Name"}
								setValue={setFirstName}
								value={firstName}
								setError={setErrorFirstName}
								errorText={errorFirstName}
								{...textFieldProps}
								autoCompleteType={"given-name"}
							/>

							<CustomFormTextInput
								inputID={"lastName"}
								inputLabel={"Last Name"}
								setValue={setLastName}
								value={lastName}
								setError={setErrorLastName}
								errorText={errorLastName}
								{...textFieldProps}
								autoCompleteType={"family-name"}
							/>

							<CustomFormTextInput
								inputID={"email"}
								inputLabel={"Email"}
								setValue={setEmail}
								value={email}
								setError={setErrorEmail}
								errorText={errorEmail}
								{...textFieldProps}
								autoCompleteType={"email"}
							/>

							<CustomFormPassword
								inputID={"password"}
								inputLabel={"Password"}
								setValue={setPassword}
								value={password}
								setError={setErrorPassword}
								errorText={errorPassword}
								{...textFieldProps}
								allowAutoComplete
							/>

							<FormControl variant="outlined" className={classes.formControl}>
								<FormControlLabel
									control={
										<Switch
											checked={settingsData.rememberMe}
											onChange={rememberMeToggleClicked}
											name="remembermeswitch"
											color="secondary"
											inputProps={{
												role: "switch",
											}}
										/>
									}
									label="Keep Me Logged In"
								/>
							</FormControl>

							<CustomFormButton
								buttonText={ConstantsLabels.labelMenuRegister}
								className={classes.formLoginButton}
								disabled={busy || !email || email.length < 5 || !password || password.length < 5}
								disableDefaultStyle
								onClick={registerClicked}
								marginTop={theme.spacing(3, "!important")} // Fix bug in Stack that adds/removes margin
							/>

							<CustomFormErrorAlert
								theID={"login-form-error"}
								setFormErrorMessage={setFormErrorMessage}
								formErrorMessage={formErrorMessage}
							/>
						</Stack>
					</form>
				</CustomPaperContainer>
			</Grid>

			<Grid item xs="auto">
				<TransactCompanyInfoFooter showVersion={true}/>
			</Grid>
		</RootGrid>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(RegistrationPage);
