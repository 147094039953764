import StarIcon from "@mui/icons-material/Star";
import { Avatar, Checkbox, Divider, Grid, Paper, Radio, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { connect } from "react-redux";

import { defaultCartActionsToProps, defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import EnvConfig from "../../utils/EnvConfig";
import { getCurrencyString, getManualOnline } from "../../utils/Utils";
import { getMenuItemOrValueStockStatusText } from "./menuUtils";
import useCommonData from "../../utils/hooks/useCommonData";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const CheckboxOption = ({ value, handleUpdateSelectedCheckboxOption, location, pickupOrDelivery }) => {
	const { dietary_restrictions, name, nutrition_calories, price, point_bonus, mobile_stock_available, selected = false } = value;
	const restrictions = dietary_restrictions.slice(0, Constants.maxDietaryFlags).filter((r) => r.icon_url !== "");
	const isAvailable = getManualOnline(location, value, pickupOrDelivery) && mobile_stock_available > 0;
	const { stockText, lowStock } = getMenuItemOrValueStockStatusText(value, location);
	return (
		<Grid
			container
			direction="row"
			padding={2}
			alignItems="center"
			onClick={() => isAvailable && handleUpdateSelectedCheckboxOption(!selected, value.valueid, value.optionid)}
			className={clsx(classes.menuItemOptionBorder, classes.fullHeight, { [classes.hoverPointerWithBackground]: isAvailable })}
			tabIndex={0}
			onKeyUp={(event) => {
				if (isAvailable && event.key === "Enter") {
					handleUpdateSelectedCheckboxOption(!selected, value.valueid, value.optionid);
				}
			}}
		>
			<Grid item xs="auto" pr={1}>
				<Checkbox checked={selected} disabled={!isAvailable} tabIndex={-1} />
			</Grid>
			<Grid item xs>
				<Stack direction="column" className={clsx({ [classes.disabledGrey]: !isAvailable })}>
					<Typography variant="body1">{name}</Typography>
					{(nutrition_calories > 0 || restrictions.length > 0 || point_bonus > 0) && (
						<Stack direction="row" spacing={1}>
							{nutrition_calories > 0 && <Typography variant="caption">{`+${nutrition_calories} Cals`}</Typography>}

							{restrictions.length > 0 &&
								restrictions.map((r) => {
									return (
										<Avatar
											srcSet={EnvConfig.baseImageURL + r.icon_url}
											alt={r.label}
											sx={{ height: "1rem", width: "1rem" }}
											key={r.label + " Icon"}
											className={clsx({ [classes.grayScaleFilter]: !isAvailable })}
										/>
									);
								})}

							{point_bonus > 0 && (
								<Typography variant={"caption"}>
									{`+${point_bonus}`} <StarIcon className={classes.locationItemStarIcon} alt="Star" />
								</Typography>
							)}
						</Stack>
					)}
					{stockText && (
						<Typography variant={"caption"} className={clsx({ [classes.errorTextRed]: lowStock })}>
							{stockText}
						</Typography>
					)}
				</Stack>
			</Grid>
			{isAvailable && price > 0 && (
				<Grid item xs="auto">
					<Typography variant="caption" disabled className={clsx({ [classes.disabledGrey]: !isAvailable })}>{`+${getCurrencyString(
						price
					)}`}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const RadioOption = ({ location, onClick, pickupOrDelivery, value }) => {
	const { dietary_restrictions, name, nutrition_calories, price, point_bonus, mobile_stock_available, selected = false } = value;
	const restrictions = dietary_restrictions.slice(0, Constants.maxDietaryFlags).filter((r) => r.icon_url !== "");
	const isAvailable = getManualOnline(location, value, pickupOrDelivery) && mobile_stock_available > 0;

	const { stockText, lowStock } = getMenuItemOrValueStockStatusText(value, location);
	return (
		<Grid
			container
			direction="row"
			padding={2}
			alignItems="center"
			onClick={isAvailable ? onClick : () => {}}
			className={clsx(classes.menuItemOptionBorder, { [classes.hoverPointerWithBackground]: isAvailable })}
			tabIndex={0}
			onKeyUp={(event) => {
				if (isAvailable && event.key === "Enter") {
					onClick(event);
				}
			}}
		>
			<Grid item xs="auto" pr={1} tabIndex={-1}>
				<Radio checked={selected} value={value.valueid} disabled={!isAvailable} tabIndex={-1} />
			</Grid>
			<Grid item xs>
				<Stack direction="column" className={clsx({ [classes.disabledGrey]: !isAvailable })}>
					<Typography variant="body1">{name}</Typography>
					{(nutrition_calories > 0 || restrictions.length > 0 || point_bonus > 0) && (
						<Stack direction="row" spacing={1}>
							{nutrition_calories > 0 && <Typography variant="caption">{`+${nutrition_calories} Cals`}</Typography>}

							{restrictions.length > 0 &&
								restrictions.map((r) => {
									return (
										<Avatar
											srcSet={EnvConfig.baseImageURL + r.icon_url}
											alt={r.label}
											sx={{ height: "1rem", width: "1rem" }}
											key={r.label + " Icon"}
											className={clsx({ [classes.grayScaleFilter]: !isAvailable })}
										/>
									);
								})}

							{point_bonus > 0 && (
								<Typography variant={"caption"}>
									{`+${point_bonus}`} <StarIcon className={classes.locationItemStarIcon} alt="Star" />
								</Typography>
							)}
						</Stack>
					)}
					{stockText && (
						<Typography variant={"caption"} className={clsx({ [classes.errorTextRed]: lowStock })}>
							{stockText}
						</Typography>
					)}
				</Stack>
			</Grid>
			{price > 0 && (
				<Grid item xs="auto">
					<Typography variant="caption" className={clsx({ [classes.disabledGrey]: !isAvailable })}>{`+${getCurrencyString(
						price
					)}`}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const MenuItemOptions = forwardRef((props, ref) => {
	const { widgetSpacingSmall, widgetPaddingSmall } = useAppDefaults();
	const { handleUpdateSelectedCheckboxOption, handleUpdateSelectedRadioOption, isCombo, location, options } = props;
	const { locationCart } = useCommonData();
	const { pickupOrDelivery } = locationCart;
	const { isError, name, maximum, minimum, values } = options;
	const required = minimum === 1 ? "Required" : `Select up to ${maximum}`;
	const isRadioButtons = minimum === 1 && maximum === 1;

	if (!options) return <></>;

	return (
		<Paper elevation={0} variant="outlined" square ref={ref}>
			<div role="tabpanel">
				<Stack direction="column" divider={<Divider role="presentation" flexItem />}>
					<div className={classes.groupHeader}>
						<Stack direction="row" justifyContent="space-between">
							<Typography
								padding={2}
								variant="h6"
								variantMapping={{ h6: "h2" }}
								className={clsx({ [classes.errorTextRedItalicsBold]: isError })}
							>
								{name.toUpperCase()}
							</Typography>
							{required && (
								<Typography
									padding={2}
									variant="subtitle1"
									variantMapping={{ subtitle1: "span" }}
									className={clsx({ [classes.errorTextRedItalicsBold]: isError })}
								>
									{required}
								</Typography>
							)}
						</Stack>
					</div>
					<Grid container padding={widgetPaddingSmall} spacing={widgetSpacingSmall}>
						{!isRadioButtons &&
							values.map((value) => {
								return (
									// Need to check isCombo here as its a modal and as such takes the full page width into account - not just the modal width
									<Grid item xs={12} sm={isCombo ? 12 : 4} key={value.valueid}>
										<CheckboxOption
											value={value}
											handleUpdateSelectedCheckboxOption={handleUpdateSelectedCheckboxOption}
											location={location}
											pickupOrDelivery={pickupOrDelivery}
										/>
									</Grid>
								);
							})}
						{isRadioButtons &&
							values.map((value) => {
								return (
									<Grid item xs={12} sm={isCombo ? 12 : 4} key={value.valueid}>
										<RadioOption
											checked={value.selected}
											value={value}
											onClick={() => handleUpdateSelectedRadioOption(value.valueid, options.optionid)}
											location={location}
											pickupOrDelivery={pickupOrDelivery}
										/>
									</Grid>
								);
							})}
					</Grid>
				</Stack>
			</div>
		</Paper>
	);
});

export default connect(defaultMapStateToProps, Object.assign({}, defaultCartActionsToProps, defaultActionsToProps), null, { forwardRef: true })(
	MenuItemOptions
);
