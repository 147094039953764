import { Box, Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";

import MenuListItem from "./MenuListItem";

import { classes } from "../../theme/GlobalStyle";
import { getMenuItemsFromMenuSection } from "../../utils/ObjectUtils";
import { SearchContext } from "../../utils/contexts/Contexts";
import useAppDefaults from "../../utils/hooks/useAppDefaults";
import { useState } from "react";
import CustomExpandMoreIcon from "../custom/CustomExpandMoreIcon";
import CustomFormHeaderCollapse from "../../utils/forms/CustomFormHeaderCollapse";

const MenuSection = ({
	checkItemNameDescriptionAgainstSearch,
	index,
	scrollToRef,
	section,
	isPointsEnabled,
	location,
	pickupOrDelivery,
	widgetSpacingSmall,
	widgetPaddingLarge,
	widgetPaddingSmall
}) => {
	const [open, setOpen] = useState(true);
	return (
		<Box ref={(el) => (scrollToRef.current[index] = el)}>
			<Paper elevation={0} variant="outlined" square>
				<div className={classes.groupHeader}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" p={widgetPaddingLarge} pr={widgetPaddingSmall}>
						<Typography variant="h4" variantMapping={{ h4: "h2" }}>
							{section.name.toUpperCase()}
						</Typography>
						<div>
							<CustomExpandMoreIcon expand={open} onClick={() => setOpen(!open)} aria-expanded={open} aria-label="show more" />
						</div>
					</Stack>
				</div>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Grid container padding={widgetPaddingSmall} spacing={widgetSpacingSmall}>
						{getMenuItemsFromMenuSection(location, section, pickupOrDelivery)
							.filter((item) => checkItemNameDescriptionAgainstSearch(item))
							.map((i) => {
								return (
									<Grid item xs={12} sm={6} key={i.itemid}>
										<MenuListItem
											menuItem={i}
											isPointsEnabled={isPointsEnabled}
											location={location}
											pickupOrDelivery={pickupOrDelivery}
										/>
									</Grid>
								);
							})}
					</Grid>
				</Collapse>
			</Paper>
		</Box>
	);
};

const MenusList = ({ hideMenuThumbnails, isPointsEnabled, location, pickupOrDelivery, sections, scrollToRef }) => {
	const { isDeviceMobile, widgetSpacingSmall, widgetPadding, widgetPaddingLarge, widgetPaddingSmall } = useAppDefaults();
	const { compareSearchValue } = useContext(SearchContext);

	// Compare both name and description to see if they include the search value
	const checkItemNameDescriptionAgainstSearch = (item) => {
		return compareSearchValue(item.name) || compareSearchValue(item.description);
	};

	return (
		<Stack spacing={widgetSpacingSmall}>
			{sections &&
				sections.map((section, i) => {
					const _scrollToRef = (el) => (scrollToRef.current[i] = el);
					return (
						<div role="tabpanel" id={`div_${section.sectionid}`} key={`div_${section.sectionid}`}>
							<CustomFormHeaderCollapse
								id={section.sectionid}
								key={section.sectionid}
								title={section.name.toUpperCase()}
								isDeviceMobile={isDeviceMobile}
								widgetPadding={widgetPaddingLarge}
								widgetPaddingSmall={widgetPaddingSmall}
								scrollToRef={_scrollToRef}
							>
								<Grid
									id={`grid_${section.sectionid}`}
									key={`grid_${section.sectionid}`}
									container
									padding={widgetPaddingSmall}
									spacing={widgetSpacingSmall}
									role="region"
									aria-live="polite"
									aria-labelledby={section.sectionid}
								>
									{getMenuItemsFromMenuSection(location, section, pickupOrDelivery)
										.filter((item) => checkItemNameDescriptionAgainstSearch(item))
										.map((i) => {
											return (
												<Grid item xs={12} sm={6} key={i.itemid}>
													<MenuListItem
														key={i.id}
														id={i.id}
														menuItem={i}
														hideMenuThumbnails={hideMenuThumbnails}
														isPointsEnabled={isPointsEnabled}
														location={location}
														pickupOrDelivery={pickupOrDelivery}
													/>
												</Grid>
											);
										})}
								</Grid>
							</CustomFormHeaderCollapse>
						</div>
					);
				})}
		</Stack>
	);
};

export default MenusList;
