import { alpha } from "@mui/material";
import StyleConstants from "../../theme/StyleConstants";

const PREFIX = "CustomApp";

export const classes = {
	menuButtonRoot: `${PREFIX}-menuButtonRoot`,
	menuButtonBoxBorder: `${PREFIX}-menuButtonBoxBorder`,
	menuButtonWidth: `${PREFIX}-menuButtonWidth`,
	customFooterButtonText: `${PREFIX}-customFooterButtonText`,
	customFooterButtonTextDisabled: `${PREFIX}-customFooterButtonTextDisabled`,
	customFooterRootPaperContainer: `${PREFIX}-customFooterRootPaperContainer`,
	searchDiv: `${PREFIX}-searchDiv`,
	searchInputBase: `${PREFIX}-inputBase`
};

export const CustomAppStyle = (theme) => {
	return {
		[`&.${classes.menuButtonRoot}`]: {
			width: "100%"
		},
		[`& .${classes.menuButtonBoxBorder}`]: {
			border: "solid 1px",
			padding: theme.spacing(0, 0.5)
		},
		[`& .${classes.menuButtonWidth}`]: {
			minWidth: "1.5rem"
		},
		[`& .${classes.customFooterButtonText}`]: {
			color: "white"
		},
		[`& .${classes.customFooterButtonTextDisabled}`]: {
			color: alpha("#000", 0.26)
		},
		[`&.${classes.customFooterRootPaperContainer}`]: {
			margin: "0 auto",
			[theme.breakpoints.down("md")]: {
				maxWidth: StyleConstants.maxWidthMediumBreakpointDown
			},
			[theme.breakpoints.up("md")]: {
				maxWidth: StyleConstants.maxWidthMediumBreakpointUp
			},
			[theme.breakpoints.down("sm")]: { position: "fixed", top: "auto", bottom: 0, width: "100%" }
		},
		[`&.${classes.searchDiv}`]: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			[theme.breakpoints.down("xs")]: { display: "none" }
		},
		[`& .${classes.searchInputBase}`]: {
			"&.MuiInputBase-adornedStart": {
				padding: theme.spacing(0, 1),
				width: "160px"
			}
		}
	};
};
