import EnvConfig from "../utils/EnvConfig";
import Constants from "../utils/Constants";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import FeatureConstants from "../utils/FeatureConstants";
import { getOpenPriorityType } from "../views/location/LocationUtils";

// import dayjs from "dayjs";
// import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// dayjs.extend(isSameOrAfter);

export const processArray = (array, stateData, processIndividualFx) => {
	if (!array) return;
	array.forEach((obj) => {
		processIndividualFx(obj, stateData);
	});
};

export const processUser = (u) => {
	if (!u) return;
	u.picture_url_with_base = Constants.placeholderProfilePicture;
	if (u.picture_url !== "") u.picture_url_with_base = EnvConfig.baseImageURL + u.picture_url;
	if (!!u.last_order) {
		u.last_order.isTakeout = u.last_order.retrieval_type === 0;
		u.last_order.isDelivery = u.last_order.retrieval_type === 1;
		u.last_order.isVirtualQueue = u.last_order.virtualqueue === 1;
	}
	u.isAdmin = u.admin_account === 1;
};

export const processCampus = (c) => {
	if (!c) return;
	c.cover_picture_url_with_base = Constants.placeholderBuilding;
	c.icon_picture_url_with_base = Constants.placeholderBuilding;
	c.brand_logo_picture_url_with_base = Constants.placeholderBuilding;

	if (c.cover_picture_url !== "") c.cover_picture_url_with_base = EnvConfig.baseImageURL + c.cover_picture_url;
	if (c.icon_picture_url !== "") c.icon_picture_url_with_base = EnvConfig.baseImageURL + c.icon_picture_url;
	if (c.brand_logo_picture_url !== "") c.brand_logo_picture_url_with_base = EnvConfig.baseImageURL + c.brand_logo_picture_url;

	if (c.links?.length > 0) {
		c.links.forEach((l) => {
			l.icon_url_with_base = EnvConfig.baseImageURL + l.icon;
		});
	}
};

export const processLocation = (l, stateData) => {
	if (!l) return;
	l.icon_picture_url_with_base = Constants.placeholderFood;
	if (l.icon_picture_url !== "") l.icon_picture_url_with_base = EnvConfig.baseImageURL + l.icon_picture_url;

	l.checkout_select_boxes.forEach((csb) => {
		if (csb.checkout_display_icon) csb.checkout_display_icon_with_base = EnvConfig.baseImageURL + csb.checkout_display_icon;
	});

	l.isVirtualQueue = l.virtualqueue === 1;

	if (hasFeatureFlag(l, FeatureConstants.FEA_LOC_PICKUPSPOTS_SORTBYPOSITION)) {
		l.pickup_spots.sort((a, b) => a.position - b.position);
	} else {
		// default: sort by name
		l.pickup_spots.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
	}
	l.openPriorityType = getOpenPriorityType(l, stateData.user);
};

export const processCafeteria = (c, stateData) => {
	if (!c) return;
	c.cover_picture_url_with_base = Constants.placeholderBuilding;
	c.openPriorityType = getOpenPriorityType(c, stateData.user);
	if (c.cover_picture_url !== "") c.cover_picture_url_with_base = EnvConfig.baseImageURL + c.cover_picture_url;
};

export const processCafeteriaGroups = (g) => {};

export const processMenu = (m) => {
	const processSection = (section) => {
		section.forEach((s) => {
			s.items.forEach((i) => {
				i.options.forEach((o) => {
					if (o.is_combo === 1) {
						o.values.forEach((v) => {
							v.price_base = v.price;
							v.price_total = v.price;
						});
					}
				});
				if (i.icon_picture_url) {
					i.icon_picture_url_with_base = EnvConfig.baseImageURL + i.icon_picture_url;
				}
				if (i.cover_picture_url) {
					i.cover_picture_url_with_base = EnvConfig.baseImageURL + i.cover_picture_url;
				}
			});
		});
	};

	const { sections_1, sections_2, sections_3 } = m;
	processSection(sections_1);
	processSection(sections_2);
	processSection(sections_3);
};

export const processMenuSlots = (menuslot) => {
	menuslot.menu_time_slots = menuslot.menu_time_slots.filter((mts) => (mts.is_available = 1));
};

export const processOrder = (o) => {
	if (!o) return;
	o.id = o.orderid;

	o.orderid_display = o.orderid_short_string?.length > 0 ? o.orderid_short_string : "" + o.orderid;

	const comboItemIds = [];
	// add the nested values of combo options
	o.items.forEach((item) => {
		item.options.forEach((option) => {
			if (option.is_combo) {
				let value = o.items.find((i) => i.itemid === option.values[0].combo_itemid);
				if (value) {
					comboItemIds.push(value.itemid);
					option.values[0].options = value.options;
					option.values[0].price = value.price_base;
				}
			}
		});
	});

	// Add comboHide - used to prevent items showing up as both a combo item and individual item on order details
	o.items.forEach((item) => {
		if (comboItemIds.includes(item.itemid)) {
			item.comboHide = true;
		}

		// Flatten all option.values into 1 array of just the values.  Useful for displaying the values for each item on receipts etc
		item.flattened_option_values = item.options?.flatMap((o) => {
			if (o.is_combo === 1 && o.values[0].combo_itemid > 0 && o.values[0].options.length > 0) {
				// Combine combo value name's for displaying
				const valueNames = o.values[0].options.map((option) => {
					return option.values[0].name;
				});
				o.values[0].flattened_name = `${o.values[0].name} (${valueNames.join(", ")})`;
			} else {
				o.values[0].flattened_name = o.values[0].name;
			}
			return o.values;
		});
	});
};

export const processInboxMessage = (i) => {
	if (!i) return;
	i.icon_image_url_with_base = Constants.placeholderMessagePicture;
	if (i.icon_image_url !== "") i.icon_image_url_with_base = EnvConfig.baseImageURL + i.icon_image_url;
};
