import React from "react";
import CustomGridContainer from "../custom/CustomGridContainer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import StarIcon from "@mui/icons-material/Star";
import clsx from "clsx";
import { Grid, Stack, Typography } from "@mui/material";
import { classes } from "../../theme/GlobalStyle";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import { getIsLocationClosed, getLocationStatusToDisplay } from "./LocationUtils";
import useCommonData from "../../utils/hooks/useCommonData";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";

const getEstimatedWaitTime = (location, campus) => {
	let estimatedWaitTime = null;
	let accessibilityWait = null;
	if (!location.isVirtualQueue && location.estimated_wait_time > 0) {
		estimatedWaitTime = location.estimated_wait_time + " min";
		accessibilityWait = `Estimated wait time is ${location.estimated_wait_time} minutes`;
	} else if (location.isVirtualQueue && location.virtualqueue_max_capacity > 0) {
		estimatedWaitTime = `${location.order_queue_count}/${location.virtualqueue_max_capacity}\ncapacity`;
		accessibilityWait = `Current queue capacity is ${location.order_queue_count} of ${location.virtualqueue_max_capacity}`;
	}

	if (
		hasFeatureFlag(campus, FeatureConstants.FEA_HOME_HIDEWAITESTIMATE) ||
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_WAIT_ESTIMATE)
	) {
		estimatedWaitTime = null;
	}

	return { estimatedWaitTime, accessibilityWait };
};

const LocationItem = ({ location, isPointsEnabled, goToSelectedLocation, doEnqueueSnackbar }) => {
	const { campus, user } = useCommonData();
	const { pickupStatus, deliveryStatus } = getLocationStatusToDisplay(location);
	const { estimatedWaitTime, accessibilityWait } = getEstimatedWaitTime(location, campus);
	const bonusPoints = isPointsEnabled && location.point_bonus ? `+${location.point_bonus}` : null;
	const isLocationClosed = getIsLocationClosed(location, user);

	const handleClickLocation = () => {
		if (isLocationClosed) {
			doEnqueueSnackbar({
				message: `${location.name} is closed.`,
				title: "Location-Closed",
				excludeFromHistory: false,
				options: { variant: "error" }
			});
		} else {
			goToSelectedLocation(location.locationid, location);
		}
	};

	return (
		<Grid item xs={100} sm={50} onClick={handleClickLocation} className={classes.locationItem}>
			<CustomPaperContainer
				className={classes.fullHeight}
				onKeyUp={(event) => {
					if (event.key === "Enter") handleClickLocation(event);
				}}
			>
				<CustomFormLabelItem
					icon_url={location.icon_picture_url_with_base}
					showLargeIcon
					hideDivider
					fullHeight
					Icon={RestaurantIcon}
					IconProps={{ alt: "" }}
					isAltPresentation
					label={location.name}
					subLabelElement={
						<CustomGridContainer direction="row" alignItems="center" className={clsx(classes.fullHeight, classes.widthInherit)}>
							<Grid item xs>
								{pickupStatus && (
									<Typography variant={"subtitle2"} variantMapping={{ subtitle2: "span" }}>
										{pickupStatus}
									</Typography>
								)}
								{deliveryStatus && (
									<Typography variant={"subtitle2"} variantMapping={{ subtitle2: "span" }}>
										{deliveryStatus}
									</Typography>
								)}
							</Grid>
						</CustomGridContainer>
					}
					rightDisplayElement={
						<CustomGridContainer direction="row" alignItems="center" className={clsx(classes.fullHeight, classes.widthInherit)}>
							<Grid item xs={"auto"}>
								<Stack>
									{estimatedWaitTime && (
										<Typography variant={"caption"} align="right" style={{ whiteSpace: "pre" }}>
											{estimatedWaitTime}
										</Typography>
									)}
									{bonusPoints && (
										<Typography variant={"caption"} align="right">
											{bonusPoints} <StarIcon className={classes.locationItemStarIcon} alt="" />
										</Typography>
									)}
								</Stack>
							</Grid>
						</CustomGridContainer>
					}
				/>
			</CustomPaperContainer>
		</Grid>
	);
};

export default LocationItem;
