const ApiEndpoints = {
	ApplyPromoRegistration: "applypromoregistration",
	AddCreditCardGetUrl: "addcreditcardgeturl",
	CalculateCart: "calculatecart",
	CancelPendingOrder: "cancelpendingorder",
	ChangeEmail: "changeemail",
	ChangeEmailReceipts: "changeemailreceipts",
	ChangePhoto: "changephoto",
	ChangeLoyaltyOptIn: "changeloyaltyoptin",
	ChangeMarketingOptIn: "changemarketingoptin",
	ChangeName: "changename",
	ChangePassword: "changepassword",
	ChangeDefaultPaymentMethod: "changedefaultpaymentmethod",
	CheckinOrder: "checkinorder", // "orderid", "checkin_barcode"
	EmailOrderReceipt: "emailorderreceipt", // orderid:
	GetFaqs: "getfaqs ", // {}
	GetChallenges: "getchallenges", // {}
	GetMenu: "getmenu",
	GetMessages: "getinboxmessages", // {}
	getPushMessages: "getpushmessages", // {}
	GetPastOrders: "getpastorders",
	GetPendingOrders: "getpendingorders",
	GetUser: "getuser",
	LoginForWeb: "loginforweb", //initialize call
	LoginWithEmailAndPassword: "loginwithemailandpassword",
	Logout: "logout",
	LogoutAndDelete: "logoutanddelete",
	OpenedMessage: "openedmessage", // [messageid: , new String[]{"" + inboxID});
	ProcessOrderStage1: "processorderstaged", // ["orderid"]
	ProcessOrderStage2: "processorderstatuscheck", // ["orderid"]
	RateOrder: "rateorder", // [orderid: "" + orderID, hangry_rating: "" + hangryRating, food_rating: "" + foodRating, hangry_comment: hangryComment, foodComment: foodComment]
	RegisterWithEmail: "registerwithemail", // ["hash", "language", "email", "password", "first_name", "last_name", "os_type", "campusid", "app_bundle_name"]
	RegisterWithCampusSSOToken: "registerwithcampusssotoken", // ["hash", "temp_token", "language","os_type", "campusid", "app_bundle_name"]
	RegisterWithGoogle: "registerwithgoogle", // ["hash", "language", "email", "google_auth_token", "google_userid", "full_name", "os_type", "campusid", "app_bundle_name" ]
	RemovePaymentMethod: "removepaymentmethod",
	ResetPassword: "resetpassword ", //["email", "hash"]
	UpdateUserPushToken: "updateuserpushtoken",
	ValidatePromoCodeOneTime: "validatepromocodeonetime",
	RegisterEnterPhoneNumber: "registerenterphonenumber", // ["phone_number"]
	RegisterEnterPhoneVerificationCode: "registerenterphoneverificationcode", // ["code"]
	RegisterSendVerificationEmail: "registersendverificationemail" // []
};

export default ApiEndpoints;
