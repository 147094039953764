const PREFIX = "SupportModalStyle";

export const classes = {
	faqAccordion: `${PREFIX}-faqAccordion`,
	faqAccordionDetails: `${PREFIX}-faqAccordionDetails`,
	faqAccordionSummary: `${PREFIX}-faqAccordionSummary`,
	faqSectionCard: `${PREFIX}-faqSectionCard`,
	faqSectionCardContent: `${PREFIX}-faqSectionCardContent`,
	faqSectionCardHeader: `${PREFIX}-faqSectionCardHeader`
};

export const SupportModalStyle = (theme) => {
	const p = theme.palette;

	return {
		[`& .${classes.faqAccordion}`]: {
			borderTop: `1px solid ${p.divider}`,
			"&:not(:last-child)": {
				borderBottom: 0
			},
			"&:before": {
				display: "none"
			}
		},
		[`& .${classes.faqAccordionDetails}`]: {
			padding: theme.spacing(2)
		},
		[`& .${classes.faqAccordionSummary}`]: {
			backgroundColor: p.background.backgroundWidget,
			flexDirection: "row-reverse",
			"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
				transform: "rotate(-90deg)"
			},
			"& .MuiAccordionSummary-content": {
				marginLeft: theme.spacing(1)
			}
		},
		[`&.${classes.faqSectionCard}`]: {
			minWidth: 275,
			marginBottom: theme.spacing(2),
			[theme.breakpoints.down("sm")]: {
				marginBottom: 0
			}
		},
		[`& .${classes.faqSectionCardContent}`]: {
			padding: 0,
			paddingBottom: "0 !important"
		},
		[`& .${classes.faqSectionCardHeader}`]: {
			backgroundColor: p.background.tableHeaderBackground
		}
	};
};
