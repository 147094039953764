import { Card, CardContent, CardHeader, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import ApiEndpoints from "../api/ApiEndpoints";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes as globalStyleClasses } from "../theme/GlobalStyle";

import ConstantsLabels from "../utils/ConstantsLabels";
import useCommonData from "../utils/hooks/useCommonData";
import { setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import Faq from "../views/support/Faq";
import { classes, SupportModalStyle } from "../views/support/SupportStyle";

const RootCard = styled(Card)(({ theme }) => SupportModalStyle(theme));

const SupportPage = ({ doApiCall, doUpdateAppBarTitle }) => {
	const { campusID } = useCommonData();
	const navigate = useNavigate();
	const [faqSections, setFaqSections] = useState([]);
	const [faqExpanded, setFaqExpanded] = React.useState("");
	const [busy, setBusy] = useState(false);

	useEffect(() => {
		setAppPageTitle("Support");
	}, [navigate]);

	const handleExpandFaq = (panel) => (event, newExpanded) => {
		setFaqExpanded(newExpanded ? panel : false);
	};

	const getFaqs = () => {
		const body = {
			campusid: campusID
		};

		doApiCall(
			ApiEndpoints.GetFaqs,
			body,
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				setFaqSections(data.faq_sections);
			},
			() => {}
		);
	};

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.support);
		getFaqs();
	}, []);

	const theme = useTheme();

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[globalStyleClasses.contentDivContainer]} showLoading={busy || !campusID}>
			<div className={globalStyleClasses.contentRootDiv}>
				{faqSections
					?.sort((a, b) => a.position - b.position)
					.map((section, sIdx) => {
						return (
							<RootCard variant="outlined" square className={classes.faqSectionCard} key={section.sectionid}>
								<CardHeader
									title={section.section_name.toUpperCase()}
									className={classes.faqSectionCardHeader}
									titleTypographyProps={{ variant: "h3" }}
								/>
								<CardContent className={classes.faqSectionCardContent}>
									{section.faqs
										?.sort((a, b) => a.position - b.position)
										.map((faq, fIdx) => {
											return (
												<Faq
													faq={faq}
													key={faq.faqid}
													faqExpanded={faqExpanded}
													handleExpandFaq={handleExpandFaq}
													isSkipLink={sIdx === 0 && fIdx === 0}
												/>
											);
										})}
								</CardContent>
							</RootCard>
						);
					})}
			</div>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(SupportPage);
