import { cloneDeep } from "lodash";
import { combineReducers } from "redux";
import FeatureConstants from "../utils/FeatureConstants";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import * as Actions from "./Actions";
import {
	addItemToCart,
	addNewCartToCarts,
	clearAllCarts,
	createNewCart,
	emptyLocationCart,
	getCart,
	removeAllItemsFromCart,
	removeItemFromCart,
	updateCartArrayFromID,
	updateCartItem
} from "./CartUtils";
import * as Process from "./ProcessServerData";
import { reduceLocationData, reduceToArray } from "./ReducersHelper";

function arrayProcess(state, payload, key, arrayKey, objID, processFx) {
	if (payload[key]) return reduceToArray(payload[key], state, arrayKey, objID, processFx);
	else return state[arrayKey];
}

function campusReducer(
	state = { campus: undefined, cafeteria_groups: [], locations: [], disabled_locations: [], menus: [], menuslots: [] },
	{ type, payload }
) {
	switch (type) {
		case Actions.UPDATE_CAMPUS:
			Process.processCampus(payload.campus, state);

			// Clear menus & menuslots when we change campus to try and reduce our redux storage size
			const newReduxState = { ...state, campus: payload.campus };
			if (payload.campus.campusid !== state.campus?.campusid) {
				Object.assign(newReduxState, { menuslots: [], menus: [] });
			}

			return newReduxState;
		case Actions.UPDATE_LOCATIONS_ARRAY: {
			//handle 'standalone' groups
			payload.cafeterias.forEach((c) => {
				if (c.groupid === 0) {
					const g = { groupid: -1 * c.cafeteriaid, group_display_name: c.name, group_display_subtitle: c.address1 };
					payload.cafeteria_groups.push(g);
				}
			});

			Process.processArray(payload.locations, payload, Process.processLocation);

			// filter location dependant on whether web is enabled & if the user is logged in
			// 0 = Disabled
			// 1 = Enabled
			// 2 = Web Only
			const _locations = cloneDeep(
				payload.locations.filter((l) => {
					return l.web_order_enabled > 0 && l.is_hidden === 0;
				})
			);

			// We might need disabled locations that have been filtered out above - example could be PendingOrder.
			let disabled_locations = cloneDeep(
				payload.locations.filter((l) => {
					return l.web_order_enabled === 0 || l.is_hidden === 1;
				})
			);
			disabled_locations = reduceLocationData(disabled_locations);

			Process.processArray(payload.cafeterias, payload, Process.processCafeteria);
			Process.processArray(payload.cafeteria_groups, payload, Process.processCafeteriaGroups);

			//put cafs into groups
			if (hasFeatureFlag(state.campus, FeatureConstants.FEA_HOME_SORTBYPRIORITY)) {
				// sort groups by priority if set
				payload.cafeteria_groups.sort((a, b) => b.display_priority - a.display_priority);
			}
			payload.cafeteria_groups.forEach((g) => {
				g.cafeterias = [];
				g.locations = [];
				g.disabled_locations = [];
				if (hasFeatureFlag(state.campus, FeatureConstants.FEA_HOME_SORTBYOPENTYPE)) {
					// sort cafeterias by open type if not sort by priority
					payload.cafeterias.sort((a, b) => b.openPriorityType - a.openPriorityType);
				}
				payload.cafeterias.forEach((c) => {
					if (c.groupid === g.groupid || c.cafeteriaid === g.groupid * -1) g.cafeterias.push(c);

					let reducedLocations = _locations.filter(
						(l) => l.cafeteriaid === c.cafeteriaid && (c.groupid === g.groupid || c.cafeteriaid === g.groupid * -1)
					);
					reducedLocations = reduceLocationData(reducedLocations);
					g.locations = [...g.locations, ...reducedLocations];

					const disabledLocations = disabled_locations.filter(
						(l) => l.cafeteriaid === c.cafeteriaid && (c.groupid === g.groupid || c.cafeteriaid === g.groupid * -1)
					);
					g.disabled_locations = [...g.disabled_locations, ...disabledLocations];

					if (hasFeatureFlag(state.campus, FeatureConstants.FEA_HOME_SORTBYOPENTYPE)) {
						g.locations.sort((a, b) => b.openPriorityType - a.openPriorityType);
					}
				});
			});

			return { ...state, locations: _locations, disabled_locations, cafeteria_groups: payload.cafeteria_groups };
		}
		case Actions.UPDATE_LOCATION_INDIVIDUAL: {
			const newLocArray = arrayProcess(state, payload, "location", "locations", "locationid", Process.processLocation);
			return { ...state, locations: newLocArray };
		}
		case Actions.UPDATE_LOCATION_SLOTS: {
			const newMenuSlots = arrayProcess(state, payload, "menuslot", "menuslots", "locationid", Process.processMenuSlots);
			return {
				...state,
				menuslots: newMenuSlots
			};
		}
		case Actions.UPDATE_MENU_INDIVIDUAL: {
			const newMenuArray = arrayProcess(state, payload, "menu", "menus", "locationid", Process.processMenu);
			return { ...state, menus: newMenuArray };
		}
		default:
			return state;
	}
}
function errorAlertReducer(state = { error: {} }, { type, payload }) {
	switch (type) {
		case Actions.UPDATE_ERROR_ALERT: {
			return { ...state, error: { ...payload } };
		}
		case Actions.CLEAR_ERROR_ALERT: {
			return { ...state, error: {} };
		}
		default:
			return state;
	}
}

function userReducer(
	state = {
		user: undefined,
		messages: [],
		orders: [],
		pending_orders: [],
		pushToken: "",
		latitudeLongitude: { latitude: "0", Longitude: "0" }
	},
	{ type, payload }
) {
	switch (type) {
		case Actions.UPDATE_USER: {
			Process.processUser(payload.user, state);
			return { ...state, user: payload.user };
		}
		case Actions.LOGOUT_USER:
			return {
				user: undefined,
				messages: [],
				orders: [],
				pending_orders: [],
				pushToken: "",
				latitudeLongitude: { latitude: "0", Longitude: "0" }
			};
		case Actions.UPDATE_ORDERS: {
			Process.processArray(payload.orders, state, Process.processOrder);
			return { ...state, orders: payload.orders };
		}
		case Actions.UPDATE_PENDING_ORDERS: {
			Process.processArray(payload.pending_orders, state, Process.processOrder);
			return { ...state, pending_orders: payload.pending_orders };
		}
		case Actions.UPDATE_INBOX_MESSAGES: {
			Process.processArray(payload.messages, state, Process.processInboxMessage);
			return { ...state, messages: payload.messages };
		}
		case Actions.UPDATE_LATITUDE_LONGITUDE:
			return { ...state, latitudeLongitude: payload.latitudeLongitude };
		case Actions.UPDATE_PUSH_TOKEN:
			return { ...state, pushToken: payload.pushToken };
		default:
			return state;
	}
}

function cartReducer(state = { carts: [] }, { type, payload }) {
	switch (type) {
		case Actions.UPDATE_LOCATIONS_ARRAY: {
			const allCarts = cloneDeep(state.carts) || [];
			const allLocs =
				payload.locations.filter((l) => {
					return l.web_order_enabled > 0 && l.is_hidden === 0;
				}) || [];
			allLocs.forEach((l) => {
				const locCart = getCart(state.carts, l.locationid);
				if (!locCart) {
					//create the cart
					const newCart = createNewCart(l.locationid);
					allCarts.push(newCart);
				}
			});
			return { ...state, carts: allCarts };
		}
		// case Actions.UPDATE_LOCATION_INDIVIDUAL: {
		// 	const allCarts = cloneDeep(state.carts) || [];
		// 	const locID = payload?.location?.locationid || 0;
		// 	const locCart = getCart(state.carts, payload?.location?.locationid);
		// 	if (!locCart && locID > 0) {
		// 		//create a cart if one doesn exist
		// 		const newCart = createNewCart(locID);
		// 		allCarts.push(newCart);
		// 		return { ...state, carts: allCarts };
		// 	} else {
		// 		//no change since all locations created
		// 		return state;
		// 	}
		// }
		case Actions.UPDATE_CART: {
			const locationid = payload?.locationid;
			const newCartsArray = updateCartArrayFromID(state.carts, payload.cart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.CREATE_CART: {
			const locationid = payload?.locationid;
			const newCart = createNewCart(locationid);
			const newCartsArray = addNewCartToCarts(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.CLEAR_ALL_CARTS: {
			const newCartsArray = clearAllCarts(state.carts);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.CLEAR_CART: {
			const locationid = payload?.locationid;
			const newCartsArray = emptyLocationCart(state.carts, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.CLEAR_CART_ALL_ITEMS: {
			const locationid = payload?.locationid;
			const newCartsArray = removeAllItemsFromCart(state.carts, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_ADD_ITEM: {
			const locationid = payload?.locationid;
			const newCartsArray = addItemToCart(state.carts, payload.item, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_UPDATE_ITEM: {
			const newCartsArray = updateCartItem(state.carts, payload.item);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_REMOVE_ITEM: {
			const locationid = payload?.locationid;
			const newCartsArray = removeItemFromCart(state.carts, payload.cartItemId, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_FROM_SERVER: {
			const locationid = payload?.locationid;
			const currentCart = getCart(state.carts, locationid);
			if (!currentCart || !payload?.serverData) return state;

			const serverData = { ...payload.serverData, user: undefined, location: undefined, menu: undefined };
			const newCart = { ...currentCart, server: serverData };
			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.CLEAR_CART_SERVER: {
			const locationid = parseInt(payload?.locationid);
			const currentCart = getCart(state.carts, locationid);

			const newCart = { ...currentCart, server: { cart: {} } };
			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_PICKUP_OR_DELIVERY: {
			const locationid = payload?.locationid;
			const newCart = getCart(state.carts, locationid);
			newCart.pickupOrDelivery = payload.pickupOrDelivery;
			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_PICKUP_SPOT_ID: {
			const locationid = payload?.locationid;
			const newCart = getCart(state.carts, locationid);
			newCart.pickupSpotId = payload.pickupSpotId;
			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_TENDER: {
			const locationid = payload?.locationid;
			const newCart = getCart(state.carts, locationid);
			newCart.ct_id = payload.ct_id;
			newCart.ct_id2 = payload.ct_id2;
			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid);
			if (!newCartsArray) return state;
			return { ...state, carts: newCartsArray };
		}
		case Actions.UPDATE_CART_SCHEDULED_TIME: {
			const locationid = parseInt(payload.locationid);
			const newCart = getCart(state.carts, locationid) || createNewCart(locationid);
			newCart.date_display = payload.date_display;
			newCart.date_local = payload.date_local;
			newCart.end_time = payload.end_time;
			newCart.start_time = payload.start_time;

			const newCartsArray = updateCartArrayFromID(state.carts, newCart, locationid) || [newCart];
			return { ...state, carts: newCartsArray };
		}
		default:
			return state;
	}
}

function settingsReducer(
	state = {
		themeType: "light",
		rememberMe: false,
		title: "",
		pickupOrDelivery: 0,
		lastReadMessageTime: "",
		locationDisabledShown: false,
		highContrast: false,
		extendNotificationDuration: false,
		currentUserEmail: ""
	},
	{ type, payload }
) {
	// pickupOrDelivery: 0 = pickup, 1 = delivery
	switch (type) {
		case Actions.UPDATE_THEME_TYPE:
			return { ...state, themeType: payload.themeType };
		case Actions.UPDATE_REMEMBER_ME:
			return { ...state, rememberMe: payload.rememberMe };
		case Actions.UPDATE_APP_BAR_TITLE:
			return { ...state, title: payload.title };
		case Actions.UPDATE_LAST_READ_MESSAGE_TIME:
			return { ...state, lastReadMessageTime: payload.lastReadMessageTime };
		case Actions.UPDATE_LOCATION_DISABLED_SHOWN:
			return { ...state, locationDisabledShown: payload.locationDisabledShown };
		case Actions.UPDATE_HIGH_CONTRAST:
			return { ...state, highContrast: payload.highContrast };
		case Actions.UPDATE_EXTEND_NOTIFICATION_DURATION:
			return { ...state, extendNotificationDuration: payload.extendNotificationDuration };
		case Actions.UPDATE_CURRENT_USER_EMAIL:
			return { ...state, currentUserEmail: payload.currentUserEmail };
		default:
			return state;
	}
}

function snackbarReducer(state = { notifications: [], history: [] }, action) {
	switch (action.type) {
		case Actions.LOGOUT_USER:
		case Actions.SNACKBAR_CLEAR_HISTORY:
			return { ...state, history: [] };
		case Actions.SNACKBAR_ENQUEUE:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						key: action.key,
						...action.notification
					}
				],
				history: action.notification.excludeFromHistory ? [...state.history] : [...state.history, { ...action.notification }]
			};
		case Actions.SNACKBAR_CLOSE:
			return {
				...state,
				notifications: state.notifications.map((notification) =>
					action.dismissAll || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }
				)
			};
		case Actions.SNACKBAR_REMOVE:
			return {
				...state,
				notifications: state.notifications.filter((notification) => notification.key !== action.key)
			};

		default:
			return state;
	}
}

const AllReducers = combineReducers({
	campusData: campusReducer,
	cartData: cartReducer,
	errorAlertData: errorAlertReducer,
	settingsData: settingsReducer,
	snackbarData: snackbarReducer,
	userData: userReducer
});

const RootReducer = (state, action) => {
	return AllReducers(state, action);
};

export default RootReducer;
