import { isDarkTheme } from "../utils/Utils";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { DataGridScrollDark } from "./scrollbars/darkmodescrolltable";
import { DataGridScrollLight } from "./scrollbars/lightmodescrolltable";

const important = " !important";
const white = "#FFF";
const black = "#000";

export const primaryColor = (themeType) => (isDarkTheme(themeType) ? "#0365AE" : "#00568B");
export const secondaryColor = (themeType) => (isDarkTheme(themeType) ? "#008162" : "#006549");
export const successColor = (themeType) => (isDarkTheme(themeType) ? "#008162" : "#006549");
export const dangerColor = (themeType) => (isDarkTheme(themeType) ? "#C80000" : "#a00000");
export const warningColor = (themeType) => (isDarkTheme(themeType) ? "#FE9A76" : "#FE9A76");

export const primaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#FFF" : "#121212");
export const secondaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#CCC" : "#444");
export const tertiaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#999" : "#707070");
export const quaternaryTextColor = (themeType) => (isDarkTheme(themeType) ? "#606060" : "#c7c7c7");

export const dividerColor = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.12)");
export const dividerColorDark = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.22)" : "rgba(0, 0, 0, 0.22)");
export const dividerColorFaint = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.04)" : "rgba(0, 0, 0, 0.04)");

export const backgroundFullPage = (themeType) => (isDarkTheme(themeType) ? "#0d0d0d" : "#FCFCFC");
export const widgetBorder = (themeType) => (isDarkTheme(themeType) ? "#0d0d0d" : "#F5F5F5");
export const backgroundWidget = (themeType) => (isDarkTheme(themeType) ? "#151515" : white);

export const inputBoxBackground = (themeType) => (isDarkTheme(themeType) ? "#101010" : "#FAFAFA");
export const inputBoxBorder = (themeType) => (isDarkTheme(themeType) ? "#474747" : "#c0c0c0");

export const menuPopUpBackground = (themeType) => (isDarkTheme(themeType) ? "#181818" : "#F6F6F6");
export const menuBorder = (themeType) => "1px solid " + (isDarkTheme(themeType) ? "#444" : "#BBB");

const defaultBackgroundHover = (themeType) => (isDarkTheme(themeType) ? "#282828" : "#E6E6E6");

export const normalButtonBackgroundHover = (themeType) => (isDarkTheme(themeType) ? "#00243e" : "#00568B");
export const normalButtonTextHover = (themeType) => (isDarkTheme(themeType) ? white : white);
export const iconButtonBackgroundHover = (themeType) => defaultBackgroundHover(themeType);

export const selectBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#262626" : "#E6E6E6");
export const selectBackgroundSelectedPlusHover = (themeType) => (isDarkTheme(themeType) ? "#333333" : "#E0E0E0");
export const selectBackgroundHover = (themeType) => defaultBackgroundHover(themeType);

export const tabBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#202020" : "transparent");
export const tabSelectedText = (themeType) => (isDarkTheme(themeType) ? white : primaryColor(themeType));
export const tabBackgroundHover = (themeType) => defaultBackgroundHover(themeType);
export const tabHoverText = (themeType) => primaryTextColor(themeType);

export const dataGridRowBackgroundSelected = (themeType) => (isDarkTheme(themeType) ? "#252525" : "#E8E8E8");
export const dataGridRowBackgroundSelectedPlusHover = (themeType) => (isDarkTheme(themeType) ? "#303030" : "#E2E2E2");
export const dataGridRowSelectedTextColor = (themeType) => primaryTextColor(themeType);

export const tableCellFocusColor = (themeType) => (isDarkTheme(themeType) ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)");
export const tableHeaderBackground = (themeType) => (isDarkTheme(themeType) ? "#0e0e0e" : "#e8e8e8");

export const getDataGridCustomScrollBars = (themeType) => (isDarkTheme(themeType) ? DataGridScrollDark : DataGridScrollLight);

export const getCustomScrollBars = (themeType) => (isDarkTheme(themeType) ? DataGridScrollDark : DataGridScrollLight);

export const defaultFocusBoxCss = (themeType) => {
	return {
		"&:focus": {
			outline: "none",
			border: "1px solid " + primaryTextColor(themeType),
			boxShadow: "inset 0 0 0 1px " + primaryTextColor(themeType)
		}
	};
};

export const CustomTheme = (themeType, campusData) => {
	const { brand_hexcolor_primary, brand_hexcolor_navbar_background, brand_hexcolor_navbar_text } = campusData?.campus || {};
	let primaryMainColor = brand_hexcolor_primary ? `#${brand_hexcolor_primary}` : primaryColor(themeType);
	let navbarBrandTextColor = brand_hexcolor_navbar_text ? `#${brand_hexcolor_navbar_text}` : primaryTextColor(themeType);
	let navbarBrandBackgroundColor = brand_hexcolor_navbar_background ? `#${brand_hexcolor_navbar_background}` : primaryColor(themeType);

	let theCurrentTheme = createTheme({
		palette: {
			mode: themeType,
			primary: {
				main: primaryMainColor
			},
			secondary: {
				main: secondaryColor(themeType)
			},
			success: {
				main: successColor(themeType)
			},
			error: {
				main: dangerColor(themeType)
			},
			warning: {
				main: warningColor(themeType)
			},
			divider: dividerColor(themeType),
			dividerDark: dividerColorDark(themeType),
			dividerFaint: dividerColorFaint(themeType),
			text: {
				primary: primaryTextColor(themeType),
				secondary: secondaryTextColor(themeType),
				tertiary: tertiaryTextColor(themeType),
				quaternary: quaternaryTextColor(themeType)
			},
			background: {
				default: backgroundFullPage(themeType),
				backgroundFullPage: backgroundFullPage(themeType),
				backgroundWidget: backgroundWidget(themeType),
				backgroundPopUpMenu: menuPopUpBackground(themeType),
				selectBackgroundHover: selectBackgroundHover(themeType),
				selectBackgroundSelected: selectBackgroundSelected(themeType),
				selectBackgroundSelectedPlusHover: selectBackgroundSelectedPlusHover(themeType),
				backgroundInput: inputBoxBackground(themeType),
				tableHeaderBackground: tableHeaderBackground(themeType),
				buttonBackgroundHover: normalButtonBackgroundHover(themeType),
				iconButtonBackgroundHover: iconButtonBackgroundHover(themeType),
				tabBackgroundHover: tabBackgroundHover(themeType)
			},
			border: {
				widgetBorder: widgetBorder(themeType),
				inputBoxBorder: inputBoxBorder(themeType)
			},
			navbar: {
				backgroundColor: navbarBrandBackgroundColor,
				color: navbarBrandTextColor
			}
		},
		isThemeLoaded: true,
		border: {
			menuBorder: menuBorder(themeType)
		},
		shadow: {
			widgetShadow: "inset 0px 0px 2px " + (isDarkTheme(themeType) ? "#444" : "#AAA")
		},
		breakpoints: {
			values: {
				xs: 300, //0 to 800 = mobile, 800 to 1280 = compact; > 1280 = desktop
				sm: 800, //this is our defined cut off for complete mobile -- hidden / adjusted columns
				md: 1280, //this is our defined cut off for when we hide / modify some elements and adjust some cols like with table since its compact
				lg: 1600,
				xl: 1920
			}
		},
		typography: {
			h1: { fontWeight: 400, fontSize: "2.5rem", color: primaryTextColor(themeType) },
			h2: { fontWeight: 400, fontSize: "2.1rem", color: primaryTextColor(themeType) },
			h3: { fontWeight: 400, fontSize: "1.8rem", color: primaryTextColor(themeType) },
			h4: { fontWeight: 400, fontSize: "1.6rem", color: primaryTextColor(themeType) },
			h5: { fontWeight: 400, fontSize: "1.35rem", color: primaryTextColor(themeType) },
			h6: { fontWeight: 400, fontSize: "1.20rem", color: primaryTextColor(themeType) },
			subtitle1: { fontWeight: 400, fontSize: "1.0rem", color: secondaryTextColor(themeType) },
			subtitle2: { fontWeight: 400, fontSize: "0.9rem", color: secondaryTextColor(themeType) }
		},
		components: {
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true // No more ripple across the whole application!
				},
				styleOverrides: {
					root: {
						"&.MuiListItemButton-root": {
							"&.Mui-focusVisible": {
								boxShadow: "inset 0 0 0 2px " + primaryTextColor(themeType)
							}
						},
						"&.MuiButton-root.MuiButton-text": {
							"&.MuiButton-disableElevation": {
								color: secondaryTextColor(themeType)
							},
							"&.Mui-focusVisible": {
								boxShadow: "inset 0 0 0 2px " + primaryTextColor(themeType)
							}
						}
					}
				}
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						border: "1px solid transparent",
						color: secondaryTextColor(themeType),
						"&:hover": {
							backgroundColor: iconButtonBackgroundHover(themeType),
							color: primaryTextColor(themeType),
							border: "1px solid " + primaryTextColor(themeType)
						},
						"&:focus": {
							border: "1px solid " + primaryColor(themeType),
							boxShadow: "inset 0 0 0 1px " + primaryColor(themeType)
						}
					}
				}
			},
			MuiButton: {
				defaultProps: {
					disableRipple: true // No more ripple, on the whole application!
				},
				styleOverrides: {
					startIcon: { paddingLeft: 10 },
					outlined: {
						border: "1px solid " + quaternaryTextColor(themeType),
						color: primaryTextColor(themeType),
						"&:hover": {
							backgroundColor: normalButtonBackgroundHover(themeType),
							color: normalButtonTextHover(themeType),
							border: "1px solid " + primaryTextColor(themeType)
						},
						"&:focus": {
							boxShadow: `0 0 0 4px ${primaryTextColor(themeType)}`,
							outline: `${backgroundFullPage(themeType)} auto 4px`
						}
					},
					contained: {
						"&:hover, &:focus": {
							boxShadow: `0 0 0 4px ${primaryTextColor(themeType)}`,
							outline: `${backgroundFullPage(themeType)} auto 4px`
						}
					},
					text: {
						"&:hover, &:focus": {
							boxShadow: `0 0 0 4px ${primaryTextColor(themeType)}`,
							outline: `${backgroundFullPage(themeType)} auto 4px`
						}
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: { background: menuPopUpBackground(themeType), border: menuBorder(themeType), borderRadius: 5 }
				}
			},
			MuiListItemIcon: {
				styleOverrides: {
					root: {
						color: "inherit",
						minWidth: 40
					}
				}
			},
			MuiListItem: {
				styleOverrides: {
					root: {
						borderRight: "5px solid transparent",
						borderLeft: "2px solid transparent",
						borderTop: "2px solid transparent",
						borderBottom: "2px solid transparent",
						"&.Mui-focusVisible": {
							border: "2px solid " + primaryColor(themeType),
							color: primaryTextColor(themeType)
							// boxShadow: "inset 0 0 0 1px " + primaryColor(themeType)
						},
						"&.Mui-selected": {
							borderRight: "5px solid " + primaryColor(themeType),
							backgroundColor: tabBackgroundSelected(themeType),
							color: tabSelectedText(themeType),
							fontWeight: 500,
							"&:hover": {
								backgroundColor: tabBackgroundHover(themeType),
								color: tabSelectedText(themeType)
							},
							"&.Mui-focusVisible": {
								color: tabSelectedText(themeType),
								border: "2px solid " + primaryColor(themeType),
								borderRight: "5px solid " + primaryColor(themeType),
								backgroundColor: selectBackgroundSelectedPlusHover(themeType)
							}
						}
					},
					button: {
						"&:hover": {
							backgroundColor: tabBackgroundHover(themeType),
							color: tabHoverText(themeType)
						}
					}
				}
			},
			MuiSwitch: {
				styleOverrides: {
					root: {
						marginLeft: 5
					},
					switchBase: {
						// Controls default (unchecked) color for the thumb
						color: secondaryTextColor(themeType),
						"&:hover": {
							boxShadow: "inset 0 0 0 2px " + primaryTextColor(themeType)
						},
						"&.MuiSwitch-switchBase": {
							"&.Mui-focusVisible": {
								boxShadow: "inset 0 0 0 2px " + primaryTextColor(themeType)
							}
						}
					},
					colorPrimary: {
						"&.Mui-checked": {
							// Controls checked color for the thumb
							color: secondaryTextColor(themeType)
						}
					}
				}
			},
			MuiTab: {
				styleOverrides: {
					textColorInherit: {
						opacity: 1.0,
						color: secondaryTextColor(themeType)
					},
					root: {
						maxWidth: 250,
						minWidth: 100,
						textTransform: "none",
						lineHeight: "normal",
						fontWeight: 400,
						fontSize: "0.9rem",
						border: "2px solid transparent",
						padding: "0px 6px 0px 5px",
						"&:hover": {
							backgroundColor: tabBackgroundHover(themeType),
							color: tabHoverText(themeType)
						},
						"&.Mui-focusVisible": {
							border: "2px solid " + primaryColor(themeType),
							color: primaryTextColor(themeType),
							backgroundColor: tabBackgroundHover(themeType),
							paddingRight: "5px"
						},
						"&.MuiTab-root.Mui-selected": {
							fontWeight: 500,
							backgroundColor: tabBackgroundSelected(themeType),
							color: tabSelectedText(themeType),
							"&:hover": {
								backgroundColor: tabBackgroundHover(themeType)
							},
							"&.Mui-focusVisible": {
								border: "2px solid " + primaryColor(themeType),
								backgroundColor: tabBackgroundSelected(themeType),
								paddingRight: "5px"
							}
						}
					}
				}
			},
			MuiToggleButton: {
				styleOverrides: {
					root: {
						"&.Mui-selected": {
							color: tabSelectedText(themeType),
							border: `solid 1px ${primaryColor(themeType)} !important`,
							backgroundColor: tabBackgroundSelected(themeType),
							"&:hover": {
								backgroundColor: tabBackgroundHover(themeType)
							}
						},
						"&:not(.Mui-selected)": {
							color: tertiaryTextColor(themeType),
							"&:hover": {
								backgroundColor: tabBackgroundHover(themeType)
							}
						}
					}
				}
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						...getCustomScrollBars(themeType)
					}
				}
			},
			MuiDataGrid: {
				styleOverrides: {
					root: {
						border: "none",
						"& .MuiDataGrid-cell": {
							lineHeight: "normal " + important,
							whiteSpace: "normal",
							alignContent: "center",
							display: "grid",
							overflowWrap: "anywhere"
						},
						"& .MuiDataGrid-cell:focus": {
							outlineColor: tableCellFocusColor(themeType),
							outline: "solid",
							outlineWidth: "1px",
							outlineOffset: "-1px"
						},
						"& .MuiDataGrid-colCell:focus": {
							outlineColor: tableCellFocusColor(themeType),
							outline: "solid",
							outlineWidth: "1px",
							outlineOffset: "-1px"
						},
						"& .MuiDataGrid-window": {
							overflowY: "hidden " + important
						},
						"& .MuiTablePagination-selectRoot": {
							display: "none " + important
						},
						"& .MuiDataGrid-columnHeader": {
							minHeight: 30
						},
						"& .MuiDataGrid-columnHeader:focus-within": {
							outlineColor: tableCellFocusColor(themeType),
							outline: "solid",
							outlineWidth: "1px",
							outlineOffset: "-2px"
						},
						"& .MuiDataGrid-columnHeaderTitleContainer": {
							padding: 0
						},
						"& .MuiDataGrid-virtualScroller": {
							overflowX: "hidden"
						},
						"& .MuiDataGrid-footerContainer": {
							minHeight: 30,
							alignItems: "flex-end"
						},
						"& .MuiDataGrid-row": {
							borderLeft: "5px solid transparent",
							borderRight: "5px solid transparent"
						},
						"& .MuiDataGrid-row.Mui-selected": {
							backgroundColor: dataGridRowBackgroundSelected(themeType) + important,
							color: dataGridRowSelectedTextColor(themeType),
							borderLeft: "5px solid " + primaryColor(themeType),
							fontWeight: 500,
							"&:hover": {
								backgroundColor: dataGridRowBackgroundSelectedPlusHover(themeType) + important,
								color: dataGridRowSelectedTextColor(themeType)
							}
						},
						...getDataGridCustomScrollBars(themeType)
					}
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {
						"&:hover": {
							backgroundColor: "inherit"
						}
					}
				}
			},
			MuiDataGridPanelWrapper: {
				styleOverrides: {
					root: {
						background: menuPopUpBackground(themeType),
						border: menuBorder(themeType),
						borderRadius: 5,
						padding: 10
					}
				}
			},
			MuiDataGridPanel: {
				styleOverrides: {
					root: {
						marginTop: "-60px"
					}
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						borderLeft: "3px solid transparent",
						color: secondaryTextColor(themeType),
						"&.Mui-selected": {
							fontWeight: 500,
							borderLeft: "3px solid " + primaryColor(themeType),
							backgroundColor: selectBackgroundSelected(themeType),
							color: primaryTextColor(themeType),
							"&:hover": {
								backgroundColor: selectBackgroundSelectedPlusHover(themeType),
								color: primaryTextColor(themeType)
							}
						},
						"&:hover": {
							backgroundColor: selectBackgroundHover(themeType),
							color: primaryTextColor(themeType)
						}
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						backgroundColor: inputBoxBackground(themeType)
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					input: {
						"&::placeholder": {
							// color: isDarkTheme(themeType) ? "#FEFEFE" : "black",
							opacity: 0.75
						}
					}
				}
			},
			MuiInputAdornment: {
				styleOverrides: {
					root: {
						color: "inherit"
					}
				}
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						overflowWrap: "break-word"
					}
				}
			},
			MuiTypography: {
				styleOverrides: {
					root: {
						fontWeight: "inherit"
					}
				}
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: "0.9rem",
						fontWeight: 400,
						color: isDarkTheme(themeType) ? "#FEFEFE" : black,
						backgroundColor: isDarkTheme(themeType) ? "#0a0a0a" : "#F3F3F3",
						border: "1px solid " + (isDarkTheme(themeType) ? "#CCC" : "#444"),
						borderRadius: 8,
						padding: 12
					}
				}
			},
			MuiCalendarPicker: {
				styleOverrides: {
					root: {
						backgroundColor: backgroundWidget(themeType),
						border: "1px solid " + dividerColor(themeType),
						borderRadius: 5,
						height: 327,
						overflow: "hidden",
						"& .MuiCalendarPicker-viewTransitionContainer": {
							overflow: "hidden"
						}
					}
				}
			},
			MuiPickersCalendarHeader: {
				styleOverrides: {
					dayLabel: {
						color: primaryTextColor(themeType)
					},
					iconButton: {
						backgroundColor: backgroundWidget(themeType)
					}
				}
			},
			MuiTimeline: {
				styleOverrides: {
					root: { padding: 0 }
				}
			}
		}
	});
	theCurrentTheme = responsiveFontSizes(theCurrentTheme);
	return theCurrentTheme;
};

//defaults
//These breakpoint values are used to determine breakpoint ranges. A range starts from the breakpoint value inclusive, to the next breakpoint value exclusive
// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
//https://material-ui.com/customization/breakpoints/
