import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";
import useCommonData from "../utils/hooks/useCommonData";
import CustomDivContainer from "../views/custom/CustomDivContainer";
import ConstantsLabels from "../utils/ConstantsLabels";
import { setAppPageTitle } from "../utils/Utils";
import InviteAFriend from "../views/rewards/InviteAFriend";

const InviteAFriendPage = ({ doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const { campus, user } = useCommonData();
	const theme = useTheme();

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.inviteafriend);
	}, []);

	useEffect(() => {
		setAppPageTitle("Invite a Friend");
	}, [navigate]);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!campus || !user}>
			<InviteAFriend />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(InviteAFriendPage);
