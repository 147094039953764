import PhotoIcon from "@mui/icons-material/ImageOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import { Grid, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import ImageUploaderStyle, { classes } from "../../utils/imageupload/ImageUploaderStyle";
import { canvasPreview, centerAspectCrop, getCroppedBase64ImgDataUrl, useDebounceEffect } from "../../utils/ImageUtils";
import { isDarkThemeWithObj, isDeviceMobileFx, LogDebug } from "../../utils/Utils";

const Root = styled("div")(({ theme }) => ImageUploaderStyle(theme));

const kMaxAllowedInputFileSize = 5 * 1024 * 1024; //5MB
const defaultCropProps = { aspect: 1.0, unit: "%", width: 100 };

const ProfileImageUploader = React.forwardRef(({ userData, doApiCall, successCallback }, ref) => {
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const { user } = userData;
	const theme = useTheme();
	const isDarkTheme = isDarkThemeWithObj(theme);
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);

	const [busy, setBusy] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [cropperBackgroundLight, setCropperBackgroundLight] = useState(!isDarkTheme);
	const [imageData, setImageData] = useState("");
	const [fileInput, setFileInput] = useState(null);
	const [convertFromPngToJpeg, setConvertFromPngToJpeg] = useState(false);

	const [crop, setCrop] = useState(defaultCropProps);
	const [completedCrop, setCompletedCrop] = useState();

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0);
			}
		},
		100,
		[completedCrop]
	);

	const saveClicked = () => {
		setFormErrorMessage("");

		const croppedImageData = getCroppedBase64ImgDataUrl(previewCanvasRef, false, convertFromPngToJpeg);

		const reqBody = {
			image_data: croppedImageData
		};
		if (croppedImageData.length < 20) return;

		doApiCall(
			ApiEndpoints.ChangePhoto,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				successMessage: "Profile Picture Uploaded",
				//hideErrorToast: true,
				setFormErrorMessage: setFormErrorMessage,
				toastTitle: "" + user.full_name
			},
			(data) => {
				//should return user onbject
				successCallback && successCallback(data);
			}
		);
	};

	const fileSelectedHandler = (event) => {
		if (!event.target.files || event.target.files.length === 0) {
			return;
		}
		setCrop(undefined); // Makes crop preview update between images.
		const file = event.target.files[0];
		LogDebug(event.target.files[0]);
		if (!file.type) {
			return;
		}

		if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
			LogDebug("Only PNG or JPG files are supported.");
			return;
		}
		if (file.size > kMaxAllowedInputFileSize) {
			LogDebug("Maximum file size is 5 MB. Please try again.");
			return;
		}

		setConvertFromPngToJpeg(file.type === "image/png");

		if (file)
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				file.type === "image/png" ? "PNG" : "JPEG",
				100,
				0,
				(uriresult) => {
					saveBinaryDataToState(uriresult);
				},
				"base64"
			);
	};

	const saveBinaryDataToState = (imageData) => {
		LogDebug("saveBinaryDataToState");
		setImageData(imageData);
	};

	const doSelectAgain = () => {
		setImageData(""); //clear
		fileInput.click();
	};

	const onImageLoaded = (imageRef) => {
		const { width, height } = imageRef.currentTarget;
		setCrop(centerAspectCrop(width, height, 1));
	};

	const onCropChange = (crop, percentCrop) => {
		if (crop.width === 0 && crop.height === 0) setCrop(defaultCropProps);
		else setCrop(percentCrop);
	};

	const onComplete = (c) => {
		setCompletedCrop(c);
	};

	const hasSelectedFile = imageData.length > 0;
	const errorAlertAria = "profile-upload-image-form-error";

	return (
		<Root className={classes.outterContainer}>
			<div className={classes.selectFileDivProfile}>
				<input
					autoFocus
					style={{ display: "none" }}
					type="file"
					accept="image/*"
					onChange={fileSelectedHandler}
					ref={(fi) => setFileInput(fi)}
				/>
				{!hasSelectedFile && (
					<>
						<Button
							className={classes.selectFileButton}
							variant="contained"
							color="inherit"
							size="large"
							disabled={busy}
							disableElevation
							onClick={() => fileInput.click()}
						>
							<div className={classes.selectFileButtonInnerDiv}>
								<PhotoIcon className={classes.selectFileButtonIcon} />
								<div className={classes.selectFileButtonIconText}>
									<Typography variant="h6" variantMapping={{ h6: "h2" }} color="inherit">
										SELECT FILE...
									</Typography>
								</div>
							</div>
						</Button>
					</>
				)}

				{hasSelectedFile && (
					<>
						<div
							className={clsx(classes.cropperDiv, {
								[classes.cropperDivLight]: cropperBackgroundLight,
								[classes.cropperDivDark]: !cropperBackgroundLight
							})}
						>
							<ReactCrop disabled={busy} crop={crop} onChange={onCropChange} onComplete={onComplete} circularCrop aspect={1}>
								<img
									ref={imgRef}
									src={imageData}
									alt={"New Profile Upload"}
									style={{ margin: "0 auto", maxHeight: 380, minHeight: 100 }}
									onLoad={onImageLoaded}
								/>
							</ReactCrop>
							<canvas
								ref={previewCanvasRef}
								style={{
									display: "none"
								}}
							/>
						</div>
						<div className={classes.bottomButtonsDivProfile}>
							<Grid
								container
								spacing={2}
								direction="row"
								alignItems="center"
								justifyContent={isDeviceMobile ? "center" : "space-between"}
							>
								<Grid item>
									<CustomFormButton
										buttonText="SELECT NEW FILE...."
										className={classes.bottomButton}
										variant="outlined"
										style={{ minWidth: 200, height: 56 }}
										disabled={busy}
										disableElevation
										disableDefaultStyle
										disableFullWidth
										onClick={doSelectAgain}
									/>
								</Grid>
								<Grid item>
									<CustomFormButton
										buttonText="SAVE"
										className={classes.bottomButton}
										variant="contained"
										style={{ minWidth: 200, height: 56 }}
										startIcon={<SaveIcon />}
										disabled={busy}
										disableElevation
										disableDefaultStyle
										disableFullWidth
										onClick={saveClicked}
									/>
								</Grid>
							</Grid>
							<div className={classes.bottomButtonsInnerDiv}>
								{formErrorMessage.length > 0 && (
									<CustomFormErrorAlert
										theID={errorAlertAria}
										setFormErrorMessage={setFormErrorMessage}
										formErrorMessage={formErrorMessage}
										margin={"20px auto 0"}
									/>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</Root>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ProfileImageUploader);
