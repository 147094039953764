import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useRef } from "react";

import ConstantsLabels from "../ConstantsLabels";
import { getFormattedTimeString } from "../DateUtils";

import Constants from "../../utils/Constants";
import FeatureConstants from "../../utils/FeatureConstants";
import { getObjectFromArray } from "../../utils/ObjectUtils";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CustomFormModalScheduleTime from "../../utils/forms/CustomFormModalScheduleTime";

import useCommonData from "../hooks/useCommonData";
import { useEffect } from "react";

const getScheduleTimeDisplayText = (location, menuslots, end_time, pickupOrDelivery, server, page, isLoggedIn) => {
	let scheduleTimeDisplayText = "";
	const { locationid, virtualqueue } = location;
	const { cart } = server || {};
	if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_PICKUP_TIME_ESTIMATE)) {
		// Do nothing
	} else if (page === Constants.APP_LOCATION.CHECKOUT_PAGE && isLoggedIn) {
		if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 && end_time !== "") {
			scheduleTimeDisplayText = "Pick up at";
			if (virtualqueue === 1) {
				scheduleTimeDisplayText = "Arrive at";
			} else if (
				hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY) ||
				pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1
			) {
				scheduleTimeDisplayText = "Ready at";
			}
			const time = getFormattedTimeString(end_time);
			if (time === "Invalid Date") {
				scheduleTimeDisplayText = ConstantsLabels.labelNoAvailableScheduleTimes;
			} else {
				scheduleTimeDisplayText += ` ${getFormattedTimeString(end_time)}`;
			}
		} else {
			if (virtualqueue === 1) {
				scheduleTimeDisplayText = `Arrive in: ${cart.pickup_time_max} minutes`;
			} else if (
				hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY) ||
				pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1
			) {
				scheduleTimeDisplayText = `Ready in: ${cart.pickup_time_max} minutes`;
			} else {
				scheduleTimeDisplayText = `Pick up in: ${cart.pickup_time_max} minutes`;
			}
		}
	} else {
		let { asap_min, asap_max } = getObjectFromArray(locationid, menuslots, "locationid", {});
		scheduleTimeDisplayText = end_time !== "" ? getFormattedTimeString(end_time) : `Estimated time: ${asap_min} - ${asap_max} mins`;
	}

	return scheduleTimeDisplayText;
};

const CustomFormScheduleTimePicker = (props) => {
	const modalFormRef = useRef();
	const { scheduleProps, doCreateCart, isSkipLink } = props;
	const { locationCart, location, menuSlots, user } = useCommonData();

	const { allow_scheduling_orders, delivery, takeout } = location || {};
	const isPickupAndDeliveryDisabled = delivery === 0 && takeout === 0;
	const isLoggedIn = !!user;

	let { date_display, end_time, pickupOrDelivery, server } = locationCart || {};

	let scheduleTimeLabel;
	let scheduleTimeSubLabel;
	let rightDisplayText;
	let modalLabel = "Select Date/Time";

	useEffect(() => {
		// We might not have a cart if we come directly to menu - so create one
		if (!locationCart) {
			doCreateCart(location.locationid);
		}
	}, []);

	if (isPickupAndDeliveryDisabled) {
		scheduleTimeLabel = `${ConstantsLabels.labelPickup} and ${ConstantsLabels.labelDelivery} are currently disabled.  Please try again later`;
	} else {
		scheduleTimeLabel = date_display !== "" ? date_display : `Today ${location.allow_asap_orders === 1 ? "- ASAP" : ""}`;
		scheduleTimeSubLabel = getScheduleTimeDisplayText(location, menuSlots, end_time, pickupOrDelivery, server, scheduleProps?.page, isLoggedIn);
		rightDisplayText =
			pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 ? ConstantsLabels.labelDelivery : ConstantsLabels.labelPickup;

		if (delivery === 1 && takeout === 0) {
			modalLabel = `Select ${ConstantsLabels.labelDelivery} Date/Time`;
		} else if (delivery === 0 && takeout === 1) {
			modalLabel = `Select ${ConstantsLabels.labelPickup} Date/Time`;
		}
	}

	const onButtonClick = (closeModalCallback) => {
		modalFormRef.current.saveScheduledTime();
		scheduleProps.scheduleCallBack && scheduleProps.scheduleCallBack();
		closeModalCallback && closeModalCallback();
	};

	return (
		<CustomFormLabelItem
			dense
			label={scheduleTimeLabel}
			subLabel={scheduleTimeSubLabel}
			subLabelError={scheduleTimeSubLabel === ConstantsLabels.labelNoAvailableScheduleTimes}
			hideRightChevron={isPickupAndDeliveryDisabled}
			Icon={AccessTimeIcon}
			IconProps={{ alt: "Schedule Time" }}
			showLargeIcon
			isDisabled={allow_scheduling_orders === 0 || isPickupAndDeliveryDisabled}
			rightDisplayText={rightDisplayText}
			modalForm={CustomFormModalScheduleTime}
			useCustomModal2
			modalFormProps={{
				modalFormRef: modalFormRef
			}}
			modalProps={{
				buttonText: "OK",
				closeModalOnButtonClick: true,
				preventOutsideClick: true,
				showButton: true,
				onButtonClick,
				onClickCloseButton: onButtonClick,
				title: modalLabel,
				modalLabel: modalLabel
			}}
			isAltPresentation
			isSkipLink
		/>
	);
};

export default CustomFormScheduleTimePicker;
