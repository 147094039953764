import { Box, Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import LocationItem from "./LocationItem";

import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { GlobalStyle, classes } from "../../theme/GlobalStyle";
import { isPointsEnabled } from "../../utils/Utils";

import CustomGridContainer from "../custom/CustomGridContainer";
import useAppDefaults from "../../utils/hooks/useAppDefaults";
import useCommonData from "../../utils/hooks/useCommonData";
import FeatureConstants from "../../utils/FeatureConstants";
import Constants from "../../utils/Constants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import { getMenuSectionsFromMenu } from "../../utils/ObjectUtils";
import { createNewCartItem } from "../../store/CartUtils";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";
import CustomModal2 from "../modals/CustomModal2";
import TenderSelect from "../paymentMethod/TenderSelect";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomExpandMoreIcon from "../custom/CustomExpandMoreIcon";
import CustomFormHeaderCollapse from "../../utils/forms/CustomFormHeaderCollapse";

const RootStack = styled(Stack)(({ theme }) => GlobalStyle(theme));

const LocationsList = ({ doAddItemToCart, doCreateCart, doClearAllCartItems, setBusy, doEnqueueSnackbar }) => {
	const navigate = useNavigate();
	const { getMenu } = useCommonApiCalls();
	const { cafeteriaGroups, campus, campusID, carts, location, locationCart, user, getMenuFromLocationId, cafeteriaGroupsSortByLocation } =
		useCommonData();
	const { isDeviceMobile, widgetPaddingLarge, widgetPaddingSmall, widgetSpacing, widgetSpacingSmall } = useAppDefaults();
	const _isPointsEnabled = isPointsEnabled(campus, user);

	useEffect(() => {
		cafeteriaGroupsSortByLocation();
	}, []);

	const [selectedLocationId, setSelectedLocationId] = useState(null);

	const createCartItemFromSectionThenNavigate = (locID, menu) => {
		const pickupOrDelivery = locationCart ? locationCart.pickupOrDelivery : Constants.pickupDeliveryTypeEnum.PICKUP_0;
		const menuSection = getMenuSectionsFromMenu(location, menu, pickupOrDelivery);
		const firstItem = menuSection[0].items[0];
		const cartItem = createNewCartItem(firstItem, menuSection.sectionid, location, locID, pickupOrDelivery, menu);

		doAddItemToCart(locID, cartItem);
		navigate(`/${campusID}/${locID}/checkout`);
	};

	const showTenderModal = (locID, menu) => {
		const tenders = menu.tenders.filter(
			(t) => (t.locationid === 0 || t.locationid === locID) && t.is_hidden === 0 && t.currently_available === 1
		);
		if (tenders?.length > 0) {
			setSelectedLocationId(locID);
		} else {
			createCartAndNavigate(locID);
		}
	};

	const goToSelectedLocation = (locID, location) => {
		if (carts.findIndex((cart) => cart.locationid === locID) === -1) {
			doCreateCart(locID);
		}

		if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_SINGLE_ITEM_DIRECT_TO_CHECKOUT)) {
			doClearAllCartItems(locID);
			const menu = getMenuFromLocationId(locID);

			// When we first load or change campus, we might not have the correct menu.  If this is the case, we need to fetch/load it from the backend
			if (menu) {
				createCartItemFromSectionThenNavigate(locID, menu);
			} else {
				const onSuccess = (data) => {
					createCartItemFromSectionThenNavigate(locID, data.menu);
				};
				getMenu(locID, { setBusy, onSuccess });
			}
		} else if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_SHOW_TENDER_SELECT)) {
			const menu = getMenuFromLocationId(locID);
			if (!menu) {
				const onSuccess = (data) => {
					showTenderModal(locID, data.menu);
				};
				getMenu(locID, { setBusy, onSuccess });
			} else {
				showTenderModal(locID, menu);
			}
		} else {
			createCartAndNavigate(locID);
		}
	};

	const createCartAndNavigate = (locID) => {
		doCreateCart(locID);
		navigate(`/${campusID}/${locID}`);
	};

	return (
		<RootStack spacing={widgetSpacing}>
			{cafeteriaGroups &&
				cafeteriaGroups
					.filter((g) => g.locations.length > 0)
					.map((group, i) => {
						return (
							<CustomFormHeaderCollapse
								title={group.group_display_name}
								subtitle={group.group_display_subtitle}
								isDeviceMobile={isDeviceMobile}
								widgetPadding={widgetPaddingLarge}
								widgetPaddingSmall={widgetPaddingSmall}
								key={group.groupid}
								isSkipLink={i === 0 ? true : undefined}
							>
								<CustomGridContainer padding={widgetPaddingSmall} spacing={widgetSpacingSmall}>
									{group.locations.map((l) => {
										return (
											<LocationItem
												location={l}
												isPointsEnabled={_isPointsEnabled}
												goToSelectedLocation={goToSelectedLocation}
												key={l.locationid}
												doEnqueueSnackbar={doEnqueueSnackbar}
											/>
										);
									})}
								</CustomGridContainer>
							</CustomFormHeaderCollapse>
						);
					})}
			<CustomModal2
				buttonText={"OK"}
				isShowing={!!selectedLocationId}
				setIsShowing={() => {}}
				closeModalOnButtonClick={true}
				modalLabel={ConstantsLabels.labelCheckoutSelectTender}
				onButtonClick={() => {
					setSelectedLocationId(null);
					createCartAndNavigate(selectedLocationId);
				}}
				onClickCloseButton={() => setSelectedLocationId(null)}
				preventOutsideClick={true}
				showButton={true}
				title={ConstantsLabels.labelCheckoutSelectTender}
			>
				<Box paddingLeft={1.5}>
					<TenderSelect locationID={selectedLocationId} />
				</Box>
			</CustomModal2>
		</RootStack>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(LocationsList);
