import StarIcon from "@mui/icons-material/Star";
import { Avatar, Divider, Grid, Paper, Radio, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { connect } from "react-redux";

import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import EnvConfig from "../../utils/EnvConfig";
import { getCurrencyString, getManualOnline } from "../../utils/Utils";
import useCommonData from "../../utils/hooks/useCommonData";
import MenuListItem from "./MenuListItem";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const RadioOption = ({ location, pickupOrDelivery, onChange, value }) => {
	const { dietary_restrictions, name, nutrition_calories, price, point_bonus } = value;
	const restrictions = dietary_restrictions.slice(0, Constants.maxDietaryFlags).filter((r) => r.icon_url !== "");
	const isAvailable = getManualOnline(location, value, pickupOrDelivery);

	return (
		<Grid
			container
			direction="row"
			padding={2}
			alignItems="center"
			onClick={isAvailable && onChange}
			className={clsx(classes.menuItemOptionBorder, { [classes.hoverPointerWithBackground]: isAvailable })}
		>
			<Grid item xs="auto" pr={1}>
				<Radio checked={value.selected} value={value.valueid} disabled={!isAvailable} />
			</Grid>
			<Grid item xs>
				<Stack direction="column" className={clsx({ [classes.disabledGrey]: !isAvailable })}>
					<Typography variant="body1">{name}</Typography>
					{(nutrition_calories > 0 || restrictions.length > 0 || point_bonus > 0) && (
						<Stack direction="row" spacing={1}>
							{nutrition_calories > 0 && <Typography variant="caption">{`+${nutrition_calories} Cals`}</Typography>}

							{restrictions.length > 0 &&
								restrictions.map((r) => {
									return (
										<Avatar
											srcSet={EnvConfig.baseImageURL + r.icon_url}
											alt={r.label}
											sx={{ height: "1rem", width: "1rem" }}
											key={r.label + " Icon"}
											className={clsx({ [classes.grayScaleFilter]: !isAvailable })}
										/>
									);
								})}

							{point_bonus > 0 && (
								<Typography variant={"caption"}>
									{`+${point_bonus}`} <StarIcon className={classes.locationItemStarIcon} alt="Star" />
								</Typography>
							)}
						</Stack>
					)}
				</Stack>
			</Grid>
			{!isAvailable && (
				<Grid item xs="auto">
					<Typography variant="caption" className={classes.errorTextRed}>
						{ConstantsLabels.labelCurrentlyUnavailable}
					</Typography>
				</Grid>
			)}
			{isAvailable && price > 0 && (
				<Grid item xs="auto">
					<Typography variant="caption">{`+${getCurrencyString(price)}`}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

const MenuListItemComboOptions = forwardRef((props, ref) => {
	const { widgetSpacingSmall, widgetPaddingSmall } = useAppDefaults();
	const { handleUpdateSelectedRadioOption, location, option, modalFormRef, modalOnSetComboItemClick } = props;
	const { locationCart } = useCommonData();
	const { pickupOrDelivery } = locationCart;
	const { isError, name, maximum, minimum, values } = option;
	const required = minimum === 1 ? "Required" : `Select up to ${maximum}`;

	// Figure out if it has options & so opens a modal, or if it's a simple single radio button
	const hasOptions = values.some((v) => v.options?.length > 0);

	if (!option) return <></>;

	return (
		<Paper elevation={0} variant="outlined" square ref={ref}>
			<Stack direction="column" divider={<Divider role="presentation" flexItem />}>
				<div className={classes.groupHeader}>
					<Stack direction="row" justifyContent="space-between">
						<Typography
							padding={2}
							variant="h6"
							variantMapping={{ h6: "h2" }}
							className={clsx({ [classes.errorTextRedItalicsBold]: isError })}
						>
							{name.toUpperCase()}
						</Typography>
						{required && (
							<Typography
								padding={2}
								variant="subtitle1"
								variantMapping={{ subtitle1: "h3" }}
								className={clsx({ [classes.errorTextRedItalicsBold]: isError })}
							>
								{required}
							</Typography>
						)}
					</Stack>
				</div>
				<Grid container padding={widgetPaddingSmall} spacing={widgetSpacingSmall}>
					{!hasOptions &&
						values.map((value) => {
							return (
								<Grid item xs={12} sm={6} key={value.valueid}>
									<RadioOption
										checked={value.selected}
										value={value}
										onChange={(e) => handleUpdateSelectedRadioOption(e, option.optionid, value)}
										key={value.valueid}
										location={location}
										pickupOrDelivery={pickupOrDelivery}
									/>
								</Grid>
							);
						})}

					{hasOptions &&
						values.map((v) => {
							return (
								<Grid item xs={12} sm={6} key={v.valueid}>
									<MenuListItem
										menuItem={v}
										key={v.valueid}
										location={location}
										pickupOrDelivery={pickupOrDelivery}
										comboProps={{
											modalFormRef: modalFormRef,
											modalOnSetComboItemClick: modalOnSetComboItemClick,
											icon: v.selected ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon,
											iconProps: v.selected ? { color: "primary" } : null
										}}
									/>
								</Grid>
							);
						})}
				</Grid>
			</Stack>
		</Paper>
	);
});

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps, null, { forwardRef: true })(MenuListItemComboOptions);
