import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { connect } from "react-redux";

import { classes } from "../../theme/GlobalStyle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ApiEndpoints from "../../api/ApiEndpoints";
import Typography from "@mui/material/Typography";
import ConstantsLabels from "../../utils/ConstantsLabels";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import BarcodeScannerComponent from "react-qr-barcode-scanner";
import QRCode from "react-qr-code";
import CustomGridContainer from "../custom/CustomGridContainer";
import useAppDefaults from "../../utils/hooks/useAppDefaults";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import { getCheckInRequiredText } from "./LocationUtils";

const ScannerComponent = forwardRef((props, ref) => {
	const {
		order,
		location,
		setBarCode,
		children,
		className,
		doApiCall,
		doUpdateErrorAlert,
		doClearErrorAlert,
		doEnqueueSnackbar,
		handleCheckinCamera,
		styleClasses = [],
		...other
	} = props;

	const { widgetPaddingSmall, widgetSpacingSmall } = useAppDefaults();

	const [scan, setScan] = useState(true);
	const [scannedCode, setScannedCode] = useState();

	useImperativeHandle(ref, () => ({
		getScannedCode() {
			return scannedCode;
		},
		doCheckin() {
			const body = {
				orderid: "" + order.orderid,
				checkin_barcode: scannedCode?.text
			};

			doApiCall(
				ApiEndpoints.CheckinOrder,
				body,
				null,
				{
					logRequest: true,
					logResponse: true,
					hideErrorToast: false
				},
				(data) => {
					doClearErrorAlert();
					doEnqueueSnackbar({
						message: ConstantsLabels.labelConfirmCheckin,
						title: "Checked-In",
						excludeFromHistory: false,
						options: { variant: "success" }
					});
				},
				(errorMsg, errorReason) => {
					doUpdateErrorAlert(errorMsg, errorReason);
				}
			);
		}
	}));

	useEffect(() => {
		handleCheckinCamera();
	}, [scannedCode]);

	const barcodeScannerComponentHandleUpdate = (error, result) => {
		if (result) {
			setScannedCode(result);
			window.navigator.vibrate(100);
			setScan(false);
		}
	};

	return (
		<CustomGridContainer padding={widgetPaddingSmall} spacing={widgetSpacingSmall}>
			<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1} mb={5}>
				<CustomFormLabelItem
					dense
					label={getCheckInRequiredText(location)}
					Icon={WarningAmberIcon}
					IconProps={{ alt: "Checkin required" }}
					showLargeIcon
					isLabel={true}
					hideRightChevron={true}
				/>
			</Grid>

			<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1}>
				{scan && (
					<Grid item>
						<div className={classes.fullWidth}>
							<BarcodeScannerComponent onUpdate={barcodeScannerComponentHandleUpdate} />
						</div>
					</Grid>
				)}
			</Grid>
		</CustomGridContainer>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ScannerComponent);
