import { LicenseInfo } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import React, { useState, useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LogRocket from "logrocket";

import CampusRoutingPage from "./pages/CampusRoutingPage";
import UserRoutingPage from "./pages/UserRoutingPage";
import LegalRoutingPage from "./pages/LegalRoutingPage";

import { defaultMapStateToProps } from "./store/Actions";

import { CustomTheme } from "./theme/CustomTheme";
import "./theme/scrollbars/darkmodescroll.css";
import "./theme/scrollbars/lightmodescroll.css";

import EnvConfig from "./utils/EnvConfig";
import { isWebOrderLocal } from "./utils/EnvUtils";

import { LogDebug } from "./utils/Utils";
import ErrorBoundary from "./utils/components/ErrorBoundary";
import PlaceholderWhileLoadingLazy from "./utils/components/PlaceholderWhileLoadingLazy";
import SnackbarNotifier from "./utils/components/SnackbarNotifier";
import Contexts from "./utils/contexts/Contexts";
import { logRocketInitObj } from "./utils/LogRocketUtils";
import usePushNotification from "./utils/firebase/fb-pushNotification";
import useGoogleAnalytics from "./utils/firebase/fb-analytics";
import useCommonApiCalls from "./utils/hooks/useCommonApiCalls";

dayjs.extend(updateLocale);
LicenseInfo.setLicenseKey(EnvConfig.muiProLicenseKey);

const App = ({ settingsData, campusData, userData }) => {
	const { initPushMessaging } = usePushNotification();
	useGoogleAnalytics();

	const { getUser } = useCommonApiCalls();
	////THEME////
	const themeType = settingsData.themeType;
	const [theCurrentTheme, setTheCurrentTheme] = useState(() => CustomTheme(themeType, campusData));
	const { brand_hexcolor_primary, brand_hexcolor_navbar_background, brand_hexcolor_navbar_text } = campusData?.campus || {};
	//TODO user should be able to toggle between modes
	useEffect(() => {
		LogDebug("themeType=" + themeType);
		LogDebug("theCurrentTheme.palette.mode=" + theCurrentTheme?.palette?.mode);
		// if (theCurrentTheme && theCurrentTheme.palette.mode === themeType) return;
		//same type already being used. dont want this called initially if we set the theme in the default
		LogDebug("setTheCurrentTheme called");
		const newTheme = CustomTheme(themeType, campusData);
		//LogDebug(newTheme); //so we can view this object props in the console while developing
		setTheCurrentTheme(newTheme);
	}, [themeType, brand_hexcolor_primary, brand_hexcolor_navbar_background, brand_hexcolor_navbar_text]); //when the themetype changes, we rebuild the theme muitheme()

	useEffect(() => {
		if (!isWebOrderLocal()) {
			LogDebug("Log Rocket initialize");
			LogRocket.init(EnvConfig.logRocketID, {
				release: EnvConfig.version,
				...logRocketInitObj
			});
		}
		initPushMessaging();
		window.addEventListener("focus", focusEventHandler, false); //listen for when they back to the app

		return () => {
			window.removeEventListener("focus", focusEventHandler);
		};
	}, []);

	const focusEventHandler = () => {
		// if there is an order, call get user so we can update the status if there is one
		if (user?.last_order?.iscomplete === 0) {
			getUser();
		}
	};

	const [selectedLocationId, setLogRocketIdentified] = useState(false);
	const { user } = userData;

	useEffect(() => {
		if (user && !isWebOrderLocal() && !selectedLocationId) {
			LogDebug("Log Rocket identify called");
			LogRocket.identify("" + user.userid, {
				name: user.first_name + " " + user.last_name,
				email: user.email,
				campusid: "" + user.campusid,
				themeType: settingsData.themeType,
				rememberMe: "" + settingsData.rememberMe
			});
			setLogRocketIdentified(true);
		}
	}, [user]);

	LogDebug("app.js rendered");

	if (!campusData) return <></>;

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theCurrentTheme}>
				<SnackbarNotifier>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<CssBaseline />
						<ErrorBoundary>
							<Contexts>
								<React.Suspense fallback={<PlaceholderWhileLoadingLazy />}>
									<Routes>
										<Route path={`legal/*`} element={<LegalRoutingPage />} />
										<Route path={`user/*`} element={<UserRoutingPage />} />
										<Route path={`/*`} element={<CampusRoutingPage />} />
										<Route path={`/`} element={<div>default page no campus id</div>} />
										<Route path={`*`} element={<div>default page no campus id</div>} />
									</Routes>
								</React.Suspense>
							</Contexts>
						</ErrorBoundary>
					</LocalizationProvider>
				</SnackbarNotifier>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default connect(defaultMapStateToProps, {})(App);
//only passing the settings props here fixed this parent component being unnecessarily re-rendered
