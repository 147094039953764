import { Stack, styled } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import { connect } from "react-redux";

import MenuItemDetails from "./MenuItemDetails";
import MenuItemOptions from "./MenuItemOptions";

import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { calculateCartItemCost } from "../../store/CartUtils";
import { GlobalStyle } from "../../theme/GlobalStyle";
import { LogDebug } from "../../utils/Utils";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const RootStack = styled(Stack)(({ theme }) => GlobalStyle(theme));

const MenuComboModalForm = forwardRef(({ menuItem, location, setModalTitlePrice }, ref) => {
	const scrollToRef = useRef([]);
	const { widgetSpacing } = useAppDefaults();

	const [cartItem, setCartItem] = useState(menuItem);

	const validateOptions = () => {
		let errorIndex = -1;
		const updatedCartItem = cloneDeep(cartItem);
		updatedCartItem.options.forEach((option, i) => {
			const numberOfSelectedValues = option.values.filter((value) => value.selected).length;
			const error = numberOfSelectedValues > option.maximum || numberOfSelectedValues < option.minimum;
			option.isError = error;
			if (error) {
				errorIndex = errorIndex === -1 ? i : errorIndex;
			}
		});

		if (errorIndex !== -1) {
			if (scrollToRef.current) {
				setTimeout(() => {
					scrollToRef.current[errorIndex].scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
			setCartItem(updatedCartItem);
		}
		return errorIndex;
	};

	useImperativeHandle(ref, () => ({
		getData() {
			if (validateOptions() > -1) return;
			return cartItem;
		}
	}));

	useEffect(() => {
		LogDebug(`Opened Combo Item Modal - ${cartItem.name}`);
	}, []);

	useEffect(() => {
		if (cartItem?.combo_itemid) {
			setModalTitlePrice(calculateCartItemCost(cartItem));
		}
	}, [cartItem]);

	const handleUpdateSelectedCheckboxOption = (selected, valueid, optionid) => {
		const updatedItem = cloneDeep(cartItem);
		const option = updatedItem.options.find((o) => o.optionid === optionid);
		option.isError = false;
		const value = option.values.find((v) => v.valueid === valueid);
		value.selected = selected;
		setCartItem(updatedItem);
	};

	const handleUpdateSelectedRadioOption = (valueid, optionid) => {
		const updatedItem = cloneDeep(cartItem);
		const option = updatedItem.options.find((o) => o.optionid === optionid);
		if (option.optionid === optionid) {
			option.isError = false;
			option.values.forEach((v) => {
				v.selected = v.valueid === valueid;
			});
		}
		setCartItem(updatedItem);
	};

	return (
		<RootStack spacing={widgetSpacing}>
			<MenuItemDetails menuItem={cartItem} />
			{cartItem?.options &&
				cartItem?.options.map((option, i) => {
					return (
						<MenuItemOptions
							key={option.optionid}
							options={option}
							location={location}
							ref={(el) => (scrollToRef.current[i] = el)}
							handleUpdateSelectedCheckboxOption={handleUpdateSelectedCheckboxOption}
							handleUpdateSelectedRadioOption={handleUpdateSelectedRadioOption}
							isCombo={true}
						/>
					);
				})}
		</RootStack>
	);
});

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps, null, { forwardRef: true })(MenuComboModalForm);
