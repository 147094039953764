import { Collapse, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import { classes, GlobalStyle } from "../../theme/GlobalStyle";
import CustomExpandMoreIcon from "../../views/custom/CustomExpandMoreIcon";
import { useRef } from "react";
import { useEffect } from "react";

const RootPaper = styled(Paper)(({ theme }) => GlobalStyle(theme));

const CustomFormHeaderCollapse = ({ children, title, subtitle, isDeviceMobile, isSkipLink, scrollToRef, widgetPadding, widgetPaddingSmall }) => {
	const ref = useRef([]);

	const [open, setOpen] = useState(true);
	const [subtitleWidth, setSubtitleWidth] = useState(null);

	const py = isDeviceMobile ? 1.5 : 2;

	useEffect(() => {
		// Figure out how big the title and button are, so we can cut off the subtitle if needs be
		const handleResize = () => {
			setSubtitleWidth(ref.current[0].offsetWidth - ref.current[1].offsetWidth - ref.current[2].offsetWidth - widgetPadding - widgetPadding);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [ref]);

	useEffect(() => {
		setSubtitleWidth(ref.current[0].offsetWidth - ref.current[1].offsetWidth - ref.current[2].offsetWidth - widgetPadding - widgetPadding);
	}, []);

	return (
		<RootPaper elevation={0} variant={isDeviceMobile ? "elevation" : "outlined"} square ref={scrollToRef}>
			<Grid container direction="row" alignItems="center" className={classes.groupHeader} wrap="nowrap" ref={(el) => (ref.current[0] = el)}>
				<Grid item xs="auto" ref={(el) => (ref.current[1] = el)}>
					<Typography px={widgetPadding} py={py} variant="h4" variantMapping={{ h4: "h2" }} noWrap>
						{title}
					</Typography>
				</Grid>

				<Grid xs item>
					{subtitle && (
						<Typography
							py={py}
							variant="subtitle1"
							align="right"
							variantMapping={{ subtitle1: "h3" }}
							maxWidth={`${subtitleWidth}px`}
							noWrap
						>
							{subtitle}
						</Typography>
					)}
				</Grid>
				<Grid item xs="auto" pr={widgetPaddingSmall} ref={(el) => (ref.current[2] = el)}>
					<CustomExpandMoreIcon
						expand={open}
						onClick={() => setOpen(!open)}
						aria-expanded={open}
						aria-label={`${open ? "close" : "open"} ${title}`}
						tabIndex={isSkipLink ? 0 : undefined}
						id={isSkipLink ? "skip_link" : undefined}
					/>
				</Grid>
			</Grid>

			<Collapse in={open} timeout="auto" unmountOnExit>
				{children}
			</Collapse>
		</RootPaper>
	);
};

export default CustomFormHeaderCollapse;
