import clsx from "clsx";
import React, { useState } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { handleSendPhoneText } from "./signInUtils";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import useCommonData from "../../utils/hooks/useCommonData";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import Stack from "@mui/material/Stack";
import ApiEndpoints from "../../api/ApiEndpoints";

const PhoneReqdVerifyCodeModal = ({ doApiCall }) => {
	const { user } = useCommonData();
	const [value, setValue] = useState("");

	const handleChange = (e) => {
		e && e.preventDefault();
		setValue(e.target.value);
	};

	const handleVerificationCode = (code, doApiCall) => {
		if (!code) return;

		doApiCall(
			ApiEndpoints.RegisterEnterPhoneVerificationCode,
			{ code: code },
			undefined,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Phone Number Verified`,
				toastTitle: `Verified`
			},
			(data) => {},
			(error) => {}
		);
	};

	return (
		<CustomPaperContainer>
			<Stack direction="column" padding={1} alignItems="center" spacing={2}>
				<Typography variant="body1" mb={0.1}>
					{ConstantsLabels.labelVerificationCodeRequired}
				</Typography>
				<InputMask value={value} onChange={handleChange} placeholder="99999" mask={"999999"} maskChar={"9"}>
					{() => <TextField className={classes.textfieldInput} fullWidth margin={"normal"} />}
				</InputMask>
				<CustomFormButton
					buttonText={`Verify Code`}
					clsxClasses={clsx(classes.confirmButton)}
					onClick={() => handleVerificationCode(value, doApiCall)}
					marginTop={1}
				/>
				<CustomFormButton
					buttonText={`Didn't get it? Resend to ${user?.phone_number}`}
					clsxClasses={clsx(classes.confirmButton)}
					onClick={() => handleSendPhoneText(user.phone_number, doApiCall)}
					marginTop={1}
				/>
			</Stack>
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(PhoneReqdVerifyCodeModal);
