import React from "react";
import { Grid } from "@mui/material";

const CustomGridContainer = (props) => {
	const { children, columns = 100, ...other } = props;
	return (
		<Grid columns={columns} container {...other}>
			{children}
		</Grid>
	);
};

export default CustomGridContainer;
