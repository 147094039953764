import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Button, Popover, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import NotificationItem from "./NotificationItem";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes, GlobalStyle } from "../../theme/GlobalStyle";
import { getDateObjFromUTCString, getNowDate } from "../../utils/DateUtils";

const RootPopover = styled(Popover)(({ theme }) => GlobalStyle(theme));
// const CHECK_SERVER_INTERVAL = 10000; // value in milliseconds

const Notifications = ({ settingsData, doApiCall, doUpdateLastReadMessageTime }) => {
	const { lastReadMessageTime } = settingsData;
	const [anchorEl, setAnchorEl] = useState(null);
	const [notifications, setNotifications] = useState([]);
	const [numberOfNewMessages, setNumberOfNewMessages] = useState(0);
	// const [timerId, setTimerId] = useState(0);

	const open = Boolean(anchorEl);
	const id = open ? "notifications-popover" : undefined;

	const handleClick = (event) => {
		getPushNotifications();
		doUpdateLastReadMessageTime(notifications[0]?.push_datetime);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setNumberOfNewMessages(0);
	};

	useEffect(() => {
		// getPushNotifications();
		// getPushNotificationsTimer();
	}, []);

	// TODO - Leaving in for now. We should be using this once we get the new API we can call.
	// useEffect(() => {
	// 	// This might only happen in Dev, but just to be safe, adding a clearTimer to prevent the potential of lots of timers getting set & running in background.
	// 	return () => {
	// 		clearTimeout(timerId);
	// 	};
	// }, [timerId]);

	// const getPushNotificationsTimer = () => {
	// 	setTimerId(
	// 		setTimeout(() => {
	// 			getPushNotifications();
	// 			getPushNotificationsTimer();
	// 		}, CHECK_SERVER_INTERVAL)
	// 	);
	// };

	useEffect(() => {
		if (notifications.length > 0) {
			const latestPushDateTimeObj = getDateObjFromUTCString(notifications[0]?.push_datetime);
			const lastReadMessageDateTimeObj = getDateObjFromUTCString(lastReadMessageTime);
			const isNewMessages = !latestPushDateTimeObj?.isSame(lastReadMessageDateTimeObj);
			if (isNewMessages) {
				// Find new message - these should be less than an hour old and newer than the last time we opened the notifications.
				const newNotifications = notifications.filter((n) => {
					const nPushDateTime = getDateObjFromUTCString(n.push_datetime);
					return nPushDateTime.add(1, "hour").isSameOrAfter(getNowDate()) && nPushDateTime.isAfter(lastReadMessageDateTimeObj);
				});
				setNumberOfNewMessages(newNotifications.length);
			}
		}
	}, [notifications]);

	const getPushNotifications = () => {
		doApiCall(
			ApiEndpoints.getPushMessages,
			{},
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				setNotifications(data.push_messages);
			},
			(errorMsg, errorReason) => {}
		);
	};

	return (
		<div>
			<Tooltip aria-hidden="true" title={"Notifications"} disableFocusListener id={`tooltip-Notifications`}>
				<Button
					aria-hidden="false"
					role={"button"}
					className={clsx(classes.appBarIconButton, classes.appBarIconButtonRight, classes.appBarIconButtonRound)}
					size="large"
					aria-label="Notifications"
					aria-haspopup="true"
					aria-expanded={open}
					onClick={handleClick}
					aria-describedby={`tooltip-Notifications`}
				>
					<Badge badgeContent={numberOfNewMessages} color="secondary">
						<NotificationsIcon />
					</Badge>
				</Button>
			</Tooltip>
			<RootPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				disableRestoreFocus
				PaperProps={{ className: clsx(classes.paperBackgroundWidgetTheme, classes.appBarPopoverPlacement) }}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Stack spacing={1} padding={1}>
					{notifications.length === 0 ? (
						<NotificationItem notification={{ title: "Nothing to see here", message: "Check back later!" }} />
					) : (
						notifications.map((n, index) => {
							return (
								<NotificationItem
									notification={n}
									// TODO Fix this key if/when we ever get a proper unique pushid
									key={`${n.pushid}-${n.orderid}-${n.push_datetime}-${n.message}`}
									newMessage={index < numberOfNewMessages}
								/>
							);
						})
					)}
				</Stack>
			</RootPopover>
		</div>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(Notifications);
