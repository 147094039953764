import clsx from "clsx";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import CampusIcon from "@mui/icons-material/AccountBalanceOutlined";
import AddIcon from "@mui/icons-material/Add";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import DarkModeIcon from "@mui/icons-material/Brightness3";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import CameraIcon from "@mui/icons-material/Camera";
import ImageIcon from "@mui/icons-material/Image";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PolicyIcon from "@mui/icons-material/Policy";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import SendIcon from "@mui/icons-material/Send";
import StarIcon from "@mui/icons-material/Star";

import ProfileCameraImageUploader from "./ProfileCameraImageUploader";
import ProfileEditEmail from "./ProfileEditEmail";
import ProfileEditName from "./ProfileEditName";
import ProfileEditPassword from "./ProfileEditPassword";
import ProfileImageUploader from "./ProfileImageUploader";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsAndCartActionsToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { Log, LogDebug, isDeviceMobileFx, isDarkTheme } from "../../utils/Utils";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CustomFormSwitch from "../../utils/forms/CustomFormSwitch";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import useCommonData from "../../utils/hooks/useCommonData";

import CustomGridContainer from "../custom/CustomGridContainer";
import CustomPaperContainer from "../custom/CustomPaperContainer";

import CustomModal2 from "../modals/CustomModal2";
import Image from "mui-image";

const ProfileDetails = ({
	settingsData,
	doApiCall,
	doUpdateThemeType,
	doClearAllCarts,
	doClearHistorySnackbar,
	doClearErrorAlert,
	doUpdateHighContrast,
	doUpdateExtendNotificationDuration
}) => {
	const modalFormRef = useRef();
	const theme = useTheme();
	const { campus, user, carts, highContrast, extendNotificationDuration } = useCommonData();
	const { themeType } = settingsData;
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const navigate = useNavigate();

	const [busy, setBusy] = useState(false);
	const [dirtyBit, setDirtyBit] = useState(false);

	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [isEmailReceipts, setIsEmailReceipts] = useState(user?.email_receipts === 1 || false);
	const [isReceivePromos, setIsReceivePromos] = useState(user?.opt_in_marketing === 1 || false);
	const [isParticipateRewardsProgram, setIsParticipateRewardsProgram] = useState(user?.opt_in_loyalty === 1 || false);
	const [isHighContrast, setIsHighContrast] = useState(highContrast);
	const [isDarkMode, setIsDarkMode] = useState(isDarkTheme(themeType));
	const [isExtendNotificationDuration, setIsExtendNotificationDuration] = useState(extendNotificationDuration);

	const [isShowingUpdateProfile, setIsShowingUpdateProfile] = useState(false);
	const [isShowingUpdateProfileCamera, setIsShowingUpdateProfileCamera] = useState(false);
	const [isShowingConfirmDeleteProfileDialog, setIsShowingConfirmDeleteProfileDialog] = useState(false);

	const [deleteAccountText, setDeleteAccountText] = useState("");
	const [errorDeleteAccountText, setErrorDeleteAccountText] = useState("");

	const [anchorUpdateProfileImage, setAnchorUpdateProfileImage] = useState(null);
	const handleMenuUpdateProfileImage = (event) => {
		setAnchorUpdateProfileImage(event.currentTarget);
	};
	const handleMenuUpdateProfileClose = () => {
		setAnchorUpdateProfileImage(null);
	};

	const handleEmailReceipts = (e) => {
		e && e.preventDefault();
		if (!user) return;

		setBusy(true);

		const reqBody = {
			email_receipts: e.target.checked === true ? "1" : "0"
		};

		doApiCall(
			ApiEndpoints.ChangeEmailReceipts,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Email receipts updated.`,
				toastTitle: `User #${user.userid}`
			},
			(data) => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const handleReceivePromos = (e) => {
		e && e.preventDefault();

		if (!user) return;

		setBusy(true);

		const reqBody = {
			opt_in: e.target.checked === true ? "1" : "0"
		};

		doApiCall(
			ApiEndpoints.ChangeMarketingOptIn,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Receive promos updated.`,
				toastTitle: `User #${user.userid}`
			},
			(data) => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const handleParticpateRewardsProgram = (e) => {
		e && e.preventDefault();

		if (!user) return;

		setBusy(true);

		const reqBody = {
			opt_in: e.target.checked === true ? "1" : "0"
		};

		doApiCall(
			ApiEndpoints.ChangeLoyaltyOptIn,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Particpate in Rewards Program updated.`,
				toastTitle: `User #${user.userid}`
			},
			(data) => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const handleAddImage = (e) => {
		setIsShowingUpdateProfile(true);
	};

	const handleAddImageSuccess = (e) => {
		setIsShowingUpdateProfile(e?.status !== "OK");
	};

	const handleAddImageCamera = (e) => {
		setIsShowingUpdateProfileCamera(true);
	};

	const handleAddImageCameraSuccess = (e) => {
		setIsShowingUpdateProfileCamera(e?.status !== "OK");
	};

	const handleLegal = (e) => {
		navigate(`/legal`);
	};

	const handleThemeChange = () => {
		const newThemeType = isDarkTheme(themeType) ? Constants.themeLightString : Constants.themeDarkString;
		doUpdateThemeType(newThemeType);
	};

	const handleNameChange = (closeModalCallback) => {
		modalFormRef.current.handleNameUpdate(closeModalCallback);
	};

	const handleEmailChange = (closeModalCallback) => {
		modalFormRef.current.handleEmailUpdate(closeModalCallback);
	};

	const handlePasswordChange = (closeModalCallback) => {
		modalFormRef.current.handlePasswordUpdate(closeModalCallback);
	};

	const handleHighContrastChange = (e) => {
		doUpdateHighContrast(e.target.checked); // TODO: need to do something when this value changes
	};

	const handleExtendNotificationDurationChange = (e) => {
		doUpdateExtendNotificationDuration(e.target.checked);
	};

	const handleDeleteAccount = (e) => {
		if (!user) return;

		doApiCall(
			ApiEndpoints.LogoutAndDelete,
			{},
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `${user.full_name} logged out and deleted.`,
				toastTitle: `User #${user.userid}`
			},
			(data) => {
				doClearAllCarts();
				doClearHistorySnackbar();
				doClearErrorAlert();
			},
			(errorMsg) => {}
		);
	};

	useEffect(() => {
		if (!user) {
			setFullName(undefined);
			setEmail(undefined);
			setIsEmailReceipts(true);
			setIsReceivePromos(true);
			setIsHighContrast(false);
			setIsDarkMode(isDarkTheme(themeType));
			setIsExtendNotificationDuration(false);
			return;
		}
		setFullName(`${user.first_name} ${user.last_name}`);
		setEmail(user.email);
		setIsEmailReceipts(user.email_receipts === 1);
		setIsReceivePromos(user.opt_in_marketing === 1);
		setIsHighContrast(highContrast);
		setIsDarkMode(isDarkTheme(themeType));
		setIsExtendNotificationDuration(extendNotificationDuration);
	}, [user]);

	const customFormSwitchProps = {
		setDirtyBit: setDirtyBit,
		isDisabled: busy
		// marginBottom: isDeviceMobile ? 2 : 10,
	};

	const iconProps = {
		marginRight: theme.spacing(1)
	};

	const gridItemProps = {
		container: true,
		alignItems: "stretch"
	};

	if (!theme.isThemeLoaded || !user) return <></>;

	return (
		<CustomPaperContainer className={clsx(classes.contentRootDiv)}>
			<CustomGridContainer
				direction="column"
				padding={theme.spacing(1, isDeviceMobile ? 1 : 10)}
				alignItems="center"
				justifyContent="center"
				spacing={2}
				className={clsx(classes.fullHeight)}
			>
				<Grid item>
					<Grid item>
						<div
							id={"skip_link"}
							role={"button"}
							className={classes.userProfileImageDiv}
							onClick={(e) => handleMenuUpdateProfileImage(e)}
							onKeyUp={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									handleMenuUpdateProfileImage(e);
								}
							}}
							alt={"Update profile image"}
							title={"Update profile image"}
							aria-label={"Update profile picture"}
							tabIndex={0}
							aria-haspopup="menu"
							aria-expanded={Boolean(anchorUpdateProfileImage)}
						>
							<Image
								className={clsx(classes.userProfileImage)}
								src={user.picture_url_with_base}
								width={250}
								height={250}
								duration={350}
							/>
							<Fab className={classes.userProfileImageFab} tabIndex={-1}>
								<AddIcon />
							</Fab>
						</div>
						<div>
							<Menu
								id="menu-updateImage"
								anchorEl={anchorUpdateProfileImage}
								keepMounted
								anchorOrigin={{
									vertical: 225,
									horizontal: 45
								}}
								open={Boolean(anchorUpdateProfileImage)}
								onClose={handleMenuUpdateProfileClose}
							>
								<MenuItem
									onClick={(e) => {
										handleMenuUpdateProfileClose(e);
										handleAddImage(e);
									}}
								>
									<ListItemIcon>
										<ImageIcon />
									</ListItemIcon>
									<ListItemText primary={"From File"} />
								</MenuItem>
								<MenuItem
									onClick={(e) => {
										handleMenuUpdateProfileClose(e);
										handleAddImageCamera(e);
									}}
								>
									<ListItemIcon>
										<CameraIcon />
									</ListItemIcon>
									<ListItemText primary={"From Camera"} />
								</MenuItem>
							</Menu>
						</div>
					</Grid>
				</Grid>
				<Grid
					item
					style={{
						margin: "auto",
						display: "flex",
						justifyContent: "center"
					}}
				>
					<Stack justifyContent="space-evenly" alignItems="center" className={classes.heightMaxWebkitFitContent}>
						<div>
							<Typography align="justify" mt={0.75} mb={1.25}>
								<CampusIcon className={classes.iconVerticalAlignBottom} style={{ marginRight: "1rem" }} />
								{campus.name}
							</Typography>
							<Typography align="justify" mt={1} mb={1}>
								<MailOutlineIcon className={classes.iconVerticalAlignBottom} style={{ marginRight: "1rem" }} />
								{user.email}
							</Typography>
						</div>
					</Stack>
				</Grid>
				<Grid item container alignItems="stretch">
					<CustomFormLabelItem
						dense
						label={"Name"}
						subLabel={fullName}
						hideIcon={true}
						modalForm={ProfileEditName}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Name",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditName,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditName,
							subtitle: ConstantsLabels.labelEditAsterixRequired,
							onButtonClick: handleNameChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid>
				<Grid item {...gridItemProps}>
					<CustomFormLabelItem
						dense
						label={"Email"}
						subLabel={email}
						hideIcon={true}
						modalForm={ProfileEditEmail}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Email",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditEmail,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditEmail,
							subtitle: ConstantsLabels.labelEditAsterixRequired,
							onButtonClick: handleEmailChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid>
				<Grid item {...gridItemProps}>
					<CustomFormLabelItem
						dense
						label={"Current Password"}
						subLabel={"********"}
						hideIcon={true}
						modalForm={ProfileEditPassword}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Password",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditPassword,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditPassword,
							subtitle: ConstantsLabels.labelEditAsterixRequired,
							onButtonClick: handlePasswordChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid>
				<Grid item {...gridItemProps}>
					<Stack spacing={1} className={classes.fullWidth}>
						<CustomFormSwitch
							inputID={"isEmailReceipts"}
							inputLabel={"Email Receipts"}
							setValue={setIsEmailReceipts}
							isChecked={isEmailReceipts}
							{...customFormSwitchProps}
							// helperText={"Do you wish to receive emailed receipts"}
							icon={<SendIcon sx={{ ...iconProps }} />}
							onChange={handleEmailReceipts}
						/>

						<CustomFormSwitch
							inputID={"isReceivePromos"}
							inputLabel={"Receive Promos"}
							setValue={setIsReceivePromos}
							isChecked={isReceivePromos}
							{...customFormSwitchProps}
							// helperText={"Do you wish to receive emailed promotions?"}
							icon={<LocalOfferIcon sx={{ ...iconProps }} />}
							onChange={handleReceivePromos}
						/>

						<CustomFormSwitch
							inputID={"isParticipateRewardsProgram"}
							inputLabel={"Participate in Rewards Program"}
							setValue={setIsParticipateRewardsProgram}
							isChecked={isParticipateRewardsProgram}
							{...customFormSwitchProps}
							// helperText={"Do you wish to participate in the rewards program?"}
							icon={<StarIcon sx={{ ...iconProps }} />}
							onChange={handleParticpateRewardsProgram}
						/>

						<CustomFormSwitch
							inputID={"isHighContrast"}
							inputLabel={"High Contrast"}
							setValue={setIsHighContrast}
							isChecked={isHighContrast}
							{...customFormSwitchProps}
							// helperText={"Set high contrast"}
							icon={<BrightnessMediumIcon sx={{ ...iconProps }} />}
							onChange={handleHighContrastChange}
						/>

						<CustomFormSwitch
							inputID={"isDarkMode"}
							inputLabel={"Dark Mode"}
							setValue={setIsDarkMode}
							isChecked={isDarkMode}
							{...customFormSwitchProps}
							// helperText={"Set dark mode"}
							icon={<DarkModeIcon sx={{ ...iconProps }} />}
							onChange={handleThemeChange}
						/>

						<CustomFormSwitch
							inputID={"isExtendNotificationDuration"}
							inputLabel={"Extend Notification Duration"}
							setValue={setIsExtendNotificationDuration}
							isChecked={isExtendNotificationDuration}
							{...customFormSwitchProps}
							// helperText={"Extend Notification Duration"}
							icon={<AlarmAddIcon sx={{ ...iconProps }} />}
							onChange={handleExtendNotificationDurationChange}
						/>
					</Stack>
				</Grid>

				{hasFeatureFlag(campus, FeatureConstants.FEA_REGISTER_ALLOW_DELETE_ACCOUNT) && (
					<Grid item container alignItems="stretch" justifyContent="center">
						<CustomFormButton
							buttonText="Delete Account"
							color="error"
							onClick={() => {
								setIsShowingConfirmDeleteProfileDialog(true);
							}}
							startIcon={<PersonRemoveIcon />}
							disableFullWidth
							disableDefaultStyle
						/>
					</Grid>
				)}

				<Grid item>
					<CustomFormButton
						inputID={"legal"}
						startIcon={<PolicyIcon />}
						buttonText="Legal"
						variant="text"
						handleClick={handleLegal}
						className={clsx(classes.userProfileLegalButton, classes.marginAuto)}
					/>
				</Grid>
			</CustomGridContainer>

			<CustomModal2
				modalLabel={"Upload Profile Picture"}
				setIsShowing={setIsShowingUpdateProfile}
				isShowing={isShowingUpdateProfile}
				title={"UPLOAD PROFILE PICTURE"}
				maxWidth={650}
				maxHeight={700}
			>
				<ProfileImageUploader successCallback={handleAddImageSuccess} />
			</CustomModal2>
			<CustomModal2
				modalLabel={"Upload Camera Profile Picture"}
				setIsShowing={setIsShowingUpdateProfileCamera}
				isShowing={isShowingUpdateProfileCamera}
				title={"UPLOAD CAMERA PROFILE PICTURE"}
				maxWidth={650}
				maxHeight={700}
			>
				<ProfileCameraImageUploader successCallback={handleAddImageCameraSuccess} />
			</CustomModal2>

			<ConfirmAlertDialog
				isVisible={isShowingConfirmDeleteProfileDialog}
				setIsVisible={setIsShowingConfirmDeleteProfileDialog}
				title={"Delete Account"}
				centerTitle
				titlePrimaryBackground
				dialogContentComponent={
					<>
						<Typography variant="body1">{ConstantsLabels.labelActionCannotBeUndone}</Typography>

						<CustomFormTextInput
							inputID={"deleteAccountInput-label"}
							inputLabel={ConstantsLabels.labelTypeDeleteToConfirm}
							setValue={setDeleteAccountText}
							value={deleteAccountText}
							setError={setErrorDeleteAccountText}
							errorText={errorDeleteAccountText}
							marginTop={20}
							marginBottom={10}
						/>
					</>
				}
				confirmButtonText={"Delete Account"}
				isButtonDisabled={deleteAccountText?.toLowerCase() !== "delete"}
				cancelButtonText={"Cancel"}
				confirmCallback={handleDeleteAccount}
				preventCloseOnClickOutside
			/>
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(ProfileDetails);
