import { Log } from "../utils/Utils";
import { getNowDate } from "../utils/DateUtils";
import * as ApiCall from "./../api/ApiCall";

export const API = "api:call";

export const SNACKBAR_CLEAR_HISTORY = "snackbar:clearHistory";
export const SNACKBAR_ENQUEUE = "snackbar:enqueue";
export const SNACKBAR_CLOSE = "snackbar:close";
export const SNACKBAR_REMOVE = "snackbar:remove";
export const UPDATE_THEME_TYPE = "settings:updateThemeType";
export const UPDATE_REMEMBER_ME = "settings:updateRememberMe";
export const UPDATE_APP_BAR_TITLE = "settings:updateAppBarTitle";
export const UPDATE_LAST_READ_MESSAGE_TIME = "settings:updateLastReadMessageTime";
export const UPDATE_LOCATION_DISABLED_SHOWN = "settings:updateLocationDisabledShown";
export const UPDATE_HIGH_CONTRAST = "settings:updateHighContrast";
export const UPDATE_EXTEND_NOTIFICATION_DURATION = "settings:updateExtendNotificationDuration";
export const UPDATE_CURRENT_USER_EMAIL = "settings:updateCurrentUserEmail";

export const UPDATE_CART_FROM_SERVER = "cart:updateFromServer";
export const UPDATE_CART = "cart:update";
export const CLEAR_CART = "cart:clear";
export const CLEAR_ALL_CARTS = "cart:clearAllCarts";
export const CLEAR_CART_ALL_ITEMS = "cart:clearAllCartItems";
export const CLEAR_CART_SERVER = "cart:clearCartServer";
export const CREATE_CART = "cart:createCart";
export const UPDATE_CART_ADD_ITEM = "cart:additem";
export const UPDATE_CART_UPDATE_ITEM = "cart:updateitem";
export const UPDATE_CART_REMOVE_ITEM = "cart:removeitem";
export const UPDATE_CART_PICKUP_OR_DELIVERY = "cart:updatePickupOrDelivery";
export const UPDATE_CART_PICKUP_SPOT_ID = "cart:updatePickupSpotId";
export const UPDATE_CART_TENDER = "cart:updateTender";
export const UPDATE_CART_SCHEDULED_TIME = "cart:updateScheduledTime";

export const UPDATE_USER = "user:update";
export const LOGOUT_USER = "user:logout";
export const UPDATE_ORDERS = "user:updateOrders";
export const UPDATE_PENDING_ORDERS = "user:updatePendingOrders";
export const UPDATE_INBOX_MESSAGES = "user:updateInboxMessages";
export const UPDATE_LATITUDE_LONGITUDE = "user:updateLatitudeLongitude";
export const UPDATE_PUSH_TOKEN = "user:updatePushToken";

export const UPDATE_CAMPUS = "campus:update";
export const UPDATE_LOCATIONS_ARRAY = "location:updateArray";
export const UPDATE_LOCATION_INDIVIDUAL = "location:updateIndividual";
export const UPDATE_LOCATION_SLOTS = "menu:updateLocationSlots";
export const UPDATE_MENU_INDIVIDUAL = "menu:updateIndividual";

export const CLEAR_ERROR_ALERT = "menu:clearErrorAlert";
export const UPDATE_ERROR_ALERT = "menu:updateErrorAlert";

export const enqueueSnackbar = (notification) => {
	const key = notification.options && notification.options.key;
	Log((notification?.message || "") + " || " + (notification?.title || ""));
	return {
		type: SNACKBAR_ENQUEUE,
		notification: {
			...notification,
			key: key || new Date().getTime() + Math.random(),
			dateTimestamp: getNowDate()
		}
	};
};

export const closeSnackbar = (key) => ({
	type: SNACKBAR_CLOSE,
	dismissAll: !key, // dismiss all if no key has been defined
	key
});

export const removeSnackbar = (key) => ({
	type: SNACKBAR_REMOVE,
	key
});

export const clearHistorySnackbar = () => ({
	type: SNACKBAR_CLEAR_HISTORY
});

///////CAMPUS DATA/////////
export const updateCampus = (campus) => ({
	type: UPDATE_CAMPUS,
	payload: {
		campus: campus
	}
});

export const updateMenu = (menu) => ({
	type: UPDATE_MENU_INDIVIDUAL,
	payload: {
		menu: menu
	}
});

export const updateLocation = (location) => ({
	type: UPDATE_LOCATION_INDIVIDUAL,
	payload: {
		location: location
	}
});

export const updateLocationAndCafeteriaArrays = ({ locations, cafeterias, cafeteria_groups, user }) => ({
	type: UPDATE_LOCATIONS_ARRAY,
	payload: {
		locations: locations,
		cafeterias: cafeterias,
		cafeteria_groups: cafeteria_groups,
		user
	}
});

export const updateLocationSlots = (data) => {
	const { locationid, menu_date_slots, menu_time_slots, asap_min, asap_max, target_time, target_date, target_time_changed } = data;
	return {
		type: UPDATE_LOCATION_SLOTS,
		payload: {
			menuslot: {
				locationid,
				menu_date_slots,
				menu_time_slots,
				asap_min,
				asap_max,
				target_time,
				target_date,
				target_time_changed
			}
		}
	};
};

///////ERROR ALERT DATA/////////
export const clearErrorAlert = () => ({
	type: CLEAR_ERROR_ALERT,
	payload: {
		message: "",
		severity: "",
		title: "",
		type: "CLEAR"
	}
});
export const updateErrorAlert = (message, type, title, severity) => ({
	type: UPDATE_ERROR_ALERT,
	payload: {
		message,
		severity,
		title,
		type
	}
});

///////USER AUTH DATA/////////
export const updateUser = (user) => ({
	type: UPDATE_USER,
	payload: {
		user: user
	}
});

export const logoutUser = () => ({
	type: LOGOUT_USER,
	payload: {
		user: undefined
	}
});

export const updateOrders = (orders) => ({
	type: UPDATE_ORDERS,
	payload: {
		orders: orders
	}
});

export const updatePendingOrders = (pending_orders) => ({
	type: UPDATE_PENDING_ORDERS,
	payload: {
		pending_orders: pending_orders
	}
});

export const updateInboxMessages = (messages) => ({
	type: UPDATE_INBOX_MESSAGES,
	payload: {
		messages: messages
	}
});

///////CART/////////
export const updateEntireCart = (cart, locationid) => ({
	type: UPDATE_CART,
	payload: {
		cart: cart,
		locationid: locationid
	}
});

export const createCart = (locationid) => ({
	type: CREATE_CART,
	payload: {
		locationid: locationid
	}
});

export const clearCart = (locationid) => ({
	type: CLEAR_CART,
	payload: {
		locationid: locationid
	}
});

export const clearAllCarts = () => ({
	type: CLEAR_ALL_CARTS
});

export const clearAllCartItems = (locationid) => ({
	type: CLEAR_CART_ALL_ITEMS,
	payload: {
		locationid: locationid
	}
});

export const addItemToCart = (locationid, item) => ({
	type: UPDATE_CART_ADD_ITEM,
	payload: {
		item: item,
		locationid: locationid
	}
});

export const updateCartItem = (locationid, item) => ({
	type: UPDATE_CART_UPDATE_ITEM,
	payload: {
		item: item,
		locationid: locationid
	}
});

export const removeItemFromCart = (locationid, cartItemId) => ({
	type: UPDATE_CART_REMOVE_ITEM,
	payload: {
		cartItemId: cartItemId,
		locationid: locationid
	}
});

export const updateCartFromServer = (serverData) => ({
	type: UPDATE_CART_FROM_SERVER,
	payload: {
		serverData: serverData,
		locationid: serverData.locationid
	}
});

export const clearCartServer = (locationid) => ({
	type: CLEAR_CART_SERVER,
	payload: {
		locationid: locationid
	}
});

export const updateCartPickupDelivery = (locationid, newPickupOrDelivery) => ({
	type: UPDATE_CART_PICKUP_OR_DELIVERY,
	payload: {
		locationid: locationid,
		pickupOrDelivery: newPickupOrDelivery
	}
});

export const updateCartPickupSpotId = (locationid, newPickupSpotId) => ({
	type: UPDATE_CART_PICKUP_SPOT_ID,
	payload: {
		locationid: locationid,
		pickupSpotId: newPickupSpotId
	}
});

export const updateCartTender = (locationid, ct_id, ct_id2) => ({
	type: UPDATE_CART_TENDER,
	payload: {
		locationid: locationid,
		ct_id: ct_id,
		ct_id2: ct_id2
	}
});

export const updateScheduledTime = (locationid, date_display, date_local, end_time, start_time) => ({
	type: UPDATE_CART_SCHEDULED_TIME,
	payload: {
		locationid: locationid,
		date_display,
		date_local,
		end_time,
		start_time
	}
});

///////SETTINGS/////////
export const updateThemeType = (newType) => ({
	type: UPDATE_THEME_TYPE,
	payload: {
		themeType: newType
	}
});

export const updateRememberMe = (newFlag) => ({
	type: UPDATE_REMEMBER_ME,
	payload: {
		rememberMe: newFlag
	}
});

export const updateAppBarTitle = (newTitle) => ({
	type: UPDATE_APP_BAR_TITLE,
	payload: {
		title: newTitle
	}
});

export const updateLastReadMessageTime = (lastReadMessageTime) => ({
	type: UPDATE_LAST_READ_MESSAGE_TIME,
	payload: {
		lastReadMessageTime
	}
});

export const updateLocationDisabledShown = (newFlag) => ({
	type: UPDATE_LOCATION_DISABLED_SHOWN,
	payload: {
		locationDisabledShown: newFlag
	}
});

export const updateLatitudeLongitude = (latLong) => ({
	type: UPDATE_LATITUDE_LONGITUDE,
	payload: {
		latitudeLongitude: latLong
	}
});

export const updateHighContrast = (isHighContrast) => ({
	type: UPDATE_HIGH_CONTRAST,
	payload: {
		highContrast: isHighContrast
	}
});

export const updateExtendNotificationDuration = (extendNotificationDurationTime) => ({
	type: UPDATE_EXTEND_NOTIFICATION_DURATION,
	payload: {
		extendNotificationDuration: extendNotificationDurationTime
	}
});

export const updateCurrentUserEmail = (userEmail) => ({
	type: UPDATE_CURRENT_USER_EMAIL,
	payload: {
		currentUserEmail: userEmail
	}
});

export const updatePushToken = (token) => ({
	type: UPDATE_PUSH_TOKEN,
	payload: {
		pushToken: token
	}
});

///////API MIDDLEWARE HELPERS/////////
export const updateIndividualData = (obj, objKey, actionType) => {
	return {
		type: actionType,
		payload: {
			[objKey]: obj
		}
	};
};

export const updateArrayData = (array, arrayKey, actionType) => {
	return {
		type: actionType,
		payload: {
			[arrayKey]: array
		}
	};
};

export const defaultMapStateToProps = (state) => ({
	campusData: state.campusData,
	cartData: state.cartData,
	errorAlertData: state.errorAlertData,
	settingsData: state.settingsData,
	snackbarData: state.snackbarData,
	userData: state.userData
});

export const defaultActionsToProps = {
	doApiCall: ApiCall.apiCall,
	doClearHistorySnackbar: clearHistorySnackbar,
	doClearErrorAlert: clearErrorAlert,
	doCreateCart: createCart,
	doEnqueueSnackbar: enqueueSnackbar,
	doUpdateErrorAlert: updateErrorAlert,
	doUpdateAppBarTitle: updateAppBarTitle,
	doUpdateLastReadMessageTime: updateLastReadMessageTime,
	doUpdateLocationDisabledShown: updateLocationDisabledShown,
	doUpdateRememberMe: updateRememberMe,
	doUpdateThemeType: updateThemeType,
	doUpdatePendingOrders: updatePendingOrders,
	doUpdateUser: updateUser,
	doUpdateHighContrast: updateHighContrast,
	doUpdateExtendNotificationDuration: updateExtendNotificationDuration,
	doUpdateCurrentUserEmail: updateCurrentUserEmail,
	doUpdatePushToken: updatePushToken
};

export const defaultCartActionsToProps = {
	doApiCall: ApiCall.apiCall,
	doAddItemToCart: addItemToCart,
	doCreateCart: createCart,
	doClearCart: clearCart,
	doClearAllCarts: clearAllCarts,
	doClearAllCartItems: clearAllCartItems,
	doClearCartServer: clearCartServer,
	doRemoveItemFromCart: removeItemFromCart,
	doUpdateCartItem: updateCartItem,
	doUpdateCartPickupDelivery: updateCartPickupDelivery,
	doUpdateCartPickupSpotId: updateCartPickupSpotId,
	doUpdateCartTender: updateCartTender,
	doUpdateEntireCart: updateEntireCart,
	doUpdateScheduledTime: updateScheduledTime
};

export const defaultActionsAndCartActionsToProps = Object.assign({}, defaultActionsToProps, defaultCartActionsToProps);

export const settingsMapStateToProps = (state) => ({
	settingsData: state.settingsData
});
