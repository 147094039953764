import Constants from "../../utils/Constants";
import {
	getDateObjFromLocalString,
	getDateObjFromUTCString,
	getMinutesBetweenDates,
	getNowDate,
	getTimeOfDaySalutation
} from "../../utils/DateUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import { getCurrencyString } from "../../utils/Utils";
import ConstantsLabels from "../../utils/ConstantsLabels";

const isPointsEnabled = (campus, user) => {
	if (!campus) {
		return false;
	}

	if (user?.opt_in_loyalty === 0) {
		return false;
	}

	return campus.loyalty_enabled > 0 && campus.loyalty_points_enabled === 1;
};

const hasCurrentOrder = (lastOrder) => {
	if (!lastOrder || lastOrder.orderid === 0 || lastOrder.asap === 0) {
		return false;
	} else if (lastOrder.iscomplete === 0 || lastOrder.complete_datetime === "") {
		return true;
	} else {
		const completeDateTime = getDateObjFromLocalString(lastOrder.complete_datetime);
		const nowDateTime = getNowDate();
		const completeDateTimePlus = completeDateTime.add(1, "hour");
		return completeDateTimePlus.isAfter(nowDateTime);
	}
};

//int 0  received
// 1  printed to kitchen
// 2 complete not pickedup
// 3 pickedup
const getCurrentTakeoutOrderStatus = (lastOrder) => {
	if (lastOrder === null) {
		// Utilities.log("last order is null");
		return Constants.ORDER_TAKEOUT_STATUS_ENUM.RECEIVED;
	}

	if (lastOrder.pickup_datetime !== "" && lastOrder.iscomplete === 1) {
		return Constants.ORDER_TAKEOUT_STATUS_ENUM.PICKED_UP;
	} else if (lastOrder.iscomplete === 1) {
		return Constants.ORDER_TAKEOUT_STATUS_ENUM.COMPLETE;
	} else if (lastOrder.requires_checkin === 1 && lastOrder.isTakeout) {
		return Constants.ORDER_TAKEOUT_STATUS_ENUM.CHECK_IN; //requires check-in
	} else if (lastOrder.printed_datetime !== "") {
		return Constants.ORDER_TAKEOUT_STATUS_ENUM.PRINTED;
	} else return Constants.ORDER_TAKEOUT_STATUS_ENUM.RECEIVED;
};

const getCurrentDeliveryOrderStatus = (lastOrder) => {
	if (!lastOrder) {
		// Utilities.log("last order is null");
		return Constants.ORDER_DELIVERY_STATUS_ENUM.RECEIVED;
	}

	//3.  Delivery is complete. isDelivered === 1
	if (lastOrder.iscomplete === 1 && lastOrder.isdelivered === 1) {
		return Constants.ORDER_DELIVERY_STATUS_ENUM.DELIVERED;
	}
	//2.c Delivery in progress. complete === 1. startDeliveryDateTime != empty
	else if (lastOrder.iscomplete === 1 && lastOrder.delivery_start_datetime?.length > 0) {
		return Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_DELIVERY_IN_PROGRESS;
	}
	//2.b Order is marked complete === 1. Has not started delivery - Packaging - startDeliveryDateTime = empty
	else if (lastOrder.iscomplete === 1 && (lastOrder.delivery_start_datetime === null || lastOrder.delivery_start_datetime.length === 0)) {
		return Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_NOT_STARTED_DELIVERY;
	}
	//2.a Order has been printed
	else if (lastOrder.iscomplete === 0 && lastOrder.printed_datetime?.length > 0) {
		return Constants.ORDER_DELIVERY_STATUS_ENUM.PRINTED;
	} else {
		return Constants.ORDER_DELIVERY_STATUS_ENUM.RECEIVED;
	}
};

export const getHeaderText = (campus, user, lastOrder, location) => {
	let pickupInstructionsTitleText;
	let campusRatingTitleText; // TODO - Implement this
	let campusShowMapTitleText;
	let inviteFriendTitleText;
	let receiptTitleText;
	let statusRatingTitleText;
	let rate;
	let announcementTitleText;
	let orderStatus;
	let _progressState;
	let receivedText, preparingText, readyText, receivedIcon, preparingIcon, readyIcon, timeText;
	const _hasCurrentOrder = hasCurrentOrder(lastOrder);
	const { orderid_short_string, orderid, isTakeout, isDelivery } = lastOrder || {};

	if (user && _hasCurrentOrder) {
		//status
		if (isTakeout) {
			const {
				receivedTextTakeout,
				preparingTextTakeout,
				readyTextTakeout,
				timeTextTakeout,
				orderTakeoutStatus,
				receivedIconTakeout,
				preparingIconTakeout,
				readyIconTakeout,
				progressState
			} = setupTakeoutHeader(location, lastOrder);
			receivedText = receivedTextTakeout;
			receivedIcon = receivedIconTakeout;
			preparingText = preparingTextTakeout;
			preparingIcon = preparingIconTakeout;
			readyText = readyTextTakeout;
			readyIcon = readyIconTakeout;
			timeText = timeTextTakeout;
			_progressState = progressState;
			orderStatus = orderTakeoutStatus;
		} else if (isDelivery) {
			const {
				receivedTextDelivery,
				receivedIconDelivery,
				preparingTextDelivery,
				preparingIconDelivery,
				readyTextDelivery,
				readyIconDelivery,
				timeTextDelivery,
				orderDeliveryStatus,
				progressState
			} = setupDeliveryHeader(location, lastOrder);
			receivedText = receivedTextDelivery;
			receivedIcon = receivedIconDelivery;
			preparingText = preparingTextDelivery;
			preparingIcon = preparingIconDelivery;
			readyText = readyTextDelivery;
			readyIcon = readyIconDelivery;
			timeText = timeTextDelivery;
			orderStatus = orderDeliveryStatus;
			_progressState = progressState;
		}

		if (lastOrder.isVirtualQueue) {
			receiptTitleText = `Reservation # ${orderid_short_string > 0 ? orderid_short_string : orderid}`;
		} else {
			receiptTitleText = `Order # ${orderid_short_string > 0 ? orderid_short_string : orderid} Receipt`;
		}

		let pickupMessage = "Scan your receipt at the designated pick up area.";
		if (lastOrder) {
			if (lastOrder.requires_checkin === 1 && isTakeout) {
				pickupMessage = '"Check In" soon or your order will be cancelled.';
			} else if (isTakeout && location?.pickup_instructions.length > 0) {
				pickupMessage = location.pickup_instructions;
			} else if (isDelivery & (location?.delivery_instructions?.length > 0)) {
				pickupMessage = location.delivery_instructions;
			}
		}

		pickupInstructionsTitleText = pickupMessage;

		let wordPoints = " Points";
		if (user.points_rateorder_bonus === 1) {
			wordPoints = " Point";
		}

		//isComplete, and is not refunded; and has not rated
		if (
			lastOrder &&
			lastOrder.rating_datetime?.length === 0 &&
			lastOrder.iscomplete === 1 &&
			lastOrder.isrefunded === 0 &&
			lastOrder.asap === 1
		) {
			rate = "Rate Order";
			if (lastOrder.isVirtualQueue) rate = "Rate Reservation";

			if (!isPointsEnabled(campus, user) || user.points_rateorder_bonus === 0) {
				statusRatingTitleText = rate;
			} else {
				statusRatingTitleText = `${rate} & Earn ${user.points_rateorder_bonus}${wordPoints}`;
			}

			if (
				(location && hasFeatureFlag(location, FeatureConstants.FEA_LOC_DISABLE_RATE_ORDER)) ||
				hasFeatureFlag(location, FeatureConstants.FEA_DISABLE_RATE_ORDER)
			) {
				statusRatingTitleText = undefined;
			}
		}

		if (lastOrder.pickup_datetime !== "" && pickupInstructionsTitleText) {
			//already picked up
			pickupInstructionsTitleText = undefined;
		}
	} else {
		//campus
		receiptTitleText = `${getTimeOfDaySalutation()}, ${user?.first_name}!`;

		announcementTitleText = user?.announcement_message?.length > 0 ? user.announcement_message : null;

		if (hasFeatureFlag(campus, FeatureConstants.FEA_HOME_HIDEINVITEFRIEND)) {
			inviteFriendTitleText = null;
		} else {
			if (user?.announcement_message_referral?.length > 0) {
				inviteFriendTitleText = user.announcement_message_referral;
			} else {
				let inviteText = ConstantsLabels.labelInviteAFriend;
				if (campus) {
					let referralPoints = campus.referrer_points;
					if (campus.referrer_credit > 0 && campus.referrer_number_of_orders <= 1)
						inviteText = `Invite a Friend & Earn ${getCurrencyString(campus?.referrer_credit)}`;
					else if (referralPoints > 0 && campus.referrer_number_of_orders <= 1 && isPointsEnabled(campus, user))
						inviteText = "Invite a Friend & Earn " + referralPoints + " Points";
				}
				inviteFriendTitleText = inviteText;
			}
		}

		//isComplete, and is not refunded; and has not rated
		if (
			lastOrder &&
			lastOrder.rating_datetime?.length === 0 &&
			lastOrder.iscomplete === 1 &&
			lastOrder.isrefunded === 0 &&
			lastOrder.asap === 1
		) {
			let wordPoints = " Points";
			if (user?.points_rateorder_bonus === 1) wordPoints = " Point";

			let rate = "Rate Order";
			if (lastOrder.isVirtualQueue) rate = "Rate Reservation";

			if (!isPointsEnabled(campus, user) || user?.points_rateorder_bonus === 0) campusRatingTitleText = rate;
			else campusRatingTitleText = rate + " & Earn " + user.points_rateorder_bonus + wordPoints;

			if (
				(location && hasFeatureFlag(location, FeatureConstants.FEA_LOC_DISABLE_RATE_ORDER)) ||
				hasFeatureFlag(location, FeatureConstants.FEA_DISABLE_RATE_ORDER)
			) {
				campusRatingTitleText = undefined;
			}
		}
	}

	if (hasFeatureFlag(campus, FeatureConstants.FEA_HOME_SHOWMAPS)) {
		campusShowMapTitleText = "Find Restaurants on the Map";
	}

	if (timeText) {
		receiptTitleText = timeText;
	}
	return {
		announcementTitleText,
		receiptTitleText,
		pickupInstructionsTitleText,
		statusRatingTitleText,
		receivedText,
		preparingText,
		readyText,
		inviteFriendTitleText,
		campusShowMapTitleText,
		receivedIcon,
		preparingIcon,
		readyIcon,
		orderStatus,
		hasCurrentOrder: _hasCurrentOrder,
		progressState: _progressState
	};
};

const setupTakeoutHeader = (location, lastOrder) => {
	let receivedTextTakeout = "Received";
	let preparingTextTakeout = "Preparing";
	let readyTextTakeout = "Ready";
	let timeTextTakeout;
	let progressState;

	let receivedIconTakeout = "status_received";
	let preparingIconTakeout = "status_preparing";
	let readyIconTakeout = "status_ready";

	if (location) {
		// 	Utilities.log("status_takeout_text_1 " + status_takeout_text_1);
		//override status text from server
		if (location.status_takeout_text_1.length > 0) receivedTextTakeout = location.status_takeout_text_1;
		if (location.status_takeout_text_2.length > 0) preparingTextTakeout = location.status_takeout_text_2;
		if (location.status_takeout_text_3.length > 0) readyTextTakeout = location.status_takeout_text_3;

		//override status image from server
		if (location.status_takeout_icon_1) {
			receivedIconTakeout = location.status_takeout_icon_1;
		}
		if (location.status_takeout_icon_2) {
			preparingIconTakeout = location.status_takeout_icon_2;
		}
		if (location.status_takeout_icon_3) {
			readyIconTakeout = location.status_takeout_icon_3;
		}
	}

	const orderTakeoutStatus = getCurrentTakeoutOrderStatus(lastOrder);
	if (orderTakeoutStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.PICKED_UP) {
		// picked up
		// readyIcon highlighted
		timeTextTakeout = "ENJOY";

		// setProgressState(orderProgressStateEnum.state3);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_3;
	} else if (orderTakeoutStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.COMPLETE) {
		//ready
		// readyIcon highlighted
		if (location && (location.isVirtualQueue || hasFeatureFlag(location, FeatureConstants.FEA_LOC_STATUS_COMPLETE_USE_STAGETEXT))) {
			timeTextTakeout = readyTextTakeout.toUpperCase();
		} else {
			timeTextTakeout = "PICK UP NOW";
		}
		// setProgressState(orderProgressStateEnum.state3);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_3;
	} else if (orderTakeoutStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.CHECK_IN) {
		// receivedIcon highlighted
		timeTextTakeout = "CHECK IN REQUIRED";
		// setProgressState(orderProgressStateEnum.state1);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_1;
	} else if (orderTakeoutStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.PRINTED) {
		//preparing / printed
		// preparingIcon highlighted
		// setProgressState(orderProgressStateEnum.state2);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_2;
	} else if (orderTakeoutStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.RECEIVED) {
		//received
		// receivedIcon highlighted
		// setProgressState(orderProgressStateEnum.state1);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_1;
	}
	// setAccessibilityLabelsForStatus();
	return {
		receivedTextTakeout,
		receivedIconTakeout,
		preparingTextTakeout,
		preparingIconTakeout,
		readyTextTakeout,
		readyIconTakeout,
		timeTextTakeout,
		orderTakeoutStatus,
		progressState
	};
};

const setupDeliveryHeader = (location, lastOrder) => {
	let receivedTextDelivery = "Received";
	let preparingTextDelivery = "Preparing";
	let readyTextDelivery = "Delivered";
	let timeTextDelivery;

	let receivedIconDelivery = "status_received";
	let preparingIconDelivery = "status_preparing";
	let readyIconDelivery = "status_ready";

	let progressState;

	if (location) {
		// Utilities.log("status_takeout_text_1 " + status_text_1);

		//override status text from server
		if (location.status_delivery_text_1.length > 0) receivedTextDelivery = location.status_delivery_text_1;
		if (location.status_delivery_text_2a.length > 0) preparingTextDelivery = location.status_delivery_text_2a;
		if (location.status_delivery_text_3.length > 0) readyTextDelivery = location.status_delivery_text_3;

		//override status image from server
		if (location.status_delivery_icon_1?.length > 0) {
			receivedIconDelivery = location.status_delivery_icon_1;
		}

		if (location.status_delivery_icon_2a?.length > 0) {
			preparingIconDelivery = location.status_delivery_icon_2a;
		}
		if (location.status_delivery_icon_3?.length > 0) {
			readyIconDelivery = location.status_delivery_icon_3;
		}
	}

	//   Order order = user.last_order;

	//1.  Received
	//2.a Order has been printed
	//2.b Order is marked complete === 1. Has not started delivery - Packaging - startDeliveryDateTime = empty
	//2.c Delivery in progress. complete === 1. startDeliveryDateTime != empty
	//3.  Delivery is complete. isDelivered === 1

	const orderDeliveryStatus = getCurrentDeliveryOrderStatus(lastOrder);

	//3.  Delivery is complete. isDelivered === 1
	// if (orderStatus === Constants.orderDeliveryStatusState.os_3_delivered.ordinal()) //delivered
	if (orderDeliveryStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.DELIVERED) {
		//delivered
		// readyIconDelivery highlighted
		timeTextDelivery = "DELIVERED";

		if (location.status_delivery_text_3?.length > 0) {
			timeTextDelivery = location.status_delivery_text_3;
		}

		//ensure middle icon remains at 2 (it gets reset above)
		if (location.status_delivery_text_2c?.length > 0) {
			preparingTextDelivery = location.status_delivery_text_2c;
		} else {
			preparingTextDelivery = "Delivering";
		}

		preparingIconDelivery = location.status_delivery_icon_2c?.length > 0 ? location.status_delivery_icon_2c : "status_delivering";

		// setProgressState(orderProgressStateEnum.state3);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_3;
	} else if (orderDeliveryStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_DELIVERY_IN_PROGRESS) {
		// preparingIconDelivery highlighted

		preparingIconDelivery = location.status_delivery_icon_2c?.length > 0 ? location.status_delivery_icon_2c : "status_delivering";

		preparingTextDelivery = location.status_delivery_text_2c?.length > 0 ? location.status_delivery_text_2c : "Delivering";

		// setProgressState(orderProgressStateEnum.state2);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_2;
	}
	//2.b Order is marked complete === 1. Has not started delivery - Packaging - startDeliveryDateTime = empty
	else if (orderDeliveryStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_NOT_STARTED_DELIVERY) {
		// preparingIconDelivery highlighted

		preparingIconDelivery = location.status_delivery_icon_2b?.length > 0 ? location.status_delivery_icon_2b : "status_packaging";

		preparingTextDelivery = location.status_delivery_text_2b?.length > 0 ? location.status_delivery_text_2b : "Packaging";

		// setProgressState(orderProgressStateEnum.state2);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_2;
	}
	//2.a Order has been printed
	else if (orderDeliveryStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.PRINTED) {
		// preparingIconDelivery highlighted

		preparingIconDelivery = location.status_delivery_icon_2a?.length > 0 ? location.status_delivery_icon_2a : "status_preparing";

		preparingTextDelivery = location.status_delivery_text_2a?.length > 0 ? location.status_delivery_text_2a : "Preparing";

		// setProgressState(orderProgressStateEnum.state2);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_2;
	} else if (orderDeliveryStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.RECEIVED) {
		// preparingIconDelivery highlighted
		// setProgressState(orderProgressStateEnum.state1);
		progressState = Constants.ORDER_PROGRESS_STATE_ENUM.STATE_1;
	}
	return {
		receivedTextDelivery,
		preparingTextDelivery,
		readyTextDelivery,
		receivedIconDelivery,
		preparingIconDelivery,
		readyIconDelivery,
		timeTextDelivery,
		progressState
	};
};

export const setHeaderPhotos = (cafeteria, campus, location, lastOrder) => {
	let pictureUrl = "";
	let locationIconUrl = "";
	// Utilities.log("OrderFrag - setHeaderPhotos");

	if (hasCurrentOrder(lastOrder)) {
		//status
		if (!lastOrder || !location || !cafeteria) {
			return;
		}

		//source images are 1080x405
		// let width = Utilities.getScreenWidth(this.getActivity());
		// let ratio = (float) width / coverImageWidth; //find the ratio relative to 1080x405
		// let height = (int) ((float) ratio * coverImageHeight); //scale up the desired height relative to ratio

		// pictureUrl = cafeteria.cover_picture_url;
		pictureUrl = cafeteria.cover_picture_url_with_base;

		// if (!cafeteria.getCoverImageUrl().equalsIgnoreCase(""))
		//   Picasso.get().load(cafeteria.getCoverImageUrl())
		//          //.fit()
		//          .centerInside().resize(width, height).into(statusCoverImageView);

		locationIconUrl = location.icon_picture_url_with_base;
		// if (!location.getIconImageUrl().equalsIgnoreCase(""))
		//   Picasso.get().load(location.getIconImageUrl()).fit().transform(new CircleTransform(1, false)).into(
		//       statusIconImageView);
	} //campus
	else {
		// Utilities.log("OrderFrag - setHeaderPhotos - no currentorder");

		//source images are 1080x405
		// int width = Utilities.getScreenWidth(this.getActivity());
		// float ratio = (float) width / coverImageWidth; //find the ratio relative to 1080x405
		// int height = (int) ((float) ratio * coverImageHeight); //scale up the desired height relative to ratio

		pictureUrl = campus.cover_picture_url_with_base;
	}
	return { pictureUrl, locationIconUrl };
};

export const updateOrderTimer = (lastOrder, location) => {
	if (!lastOrder || lastOrder.orderid === 0) return;

	const now_datetime = getNowDate();
	let orderStatus = 0;
	let timeText;

	if (lastOrder.isTakeout) {
		orderStatus = getCurrentTakeoutOrderStatus(lastOrder);

		if (orderStatus > Constants.ORDER_TAKEOUT_STATUS_ENUM.PRINTED) {
			return;
		}

		let countdownTimerEnabled = true;
		if (location && hasFeatureFlag(location, FeatureConstants.FEA_LOC_STATUS_DISABLE_COUNTDOWN)) {
			countdownTimerEnabled = false;
		}

		//timeTextView
		if (lastOrder.preferred_datetime === "") {
			return;
		}

		let preferred_datetime = getDateObjFromUTCString(lastOrder.preferred_datetime);

		let kitchenAdjustMinutes = lastOrder.kitchen_adjustment_minutes;

		if (kitchenAdjustMinutes > 0) {
			preferred_datetime = preferred_datetime.add(kitchenAdjustMinutes, "minute");
		} else if (kitchenAdjustMinutes < 0) {
			preferred_datetime = preferred_datetime.add(Math.abs(kitchenAdjustMinutes, "minute"));
		}

		let difference = getMinutesBetweenDates(now_datetime, preferred_datetime);

		if (lastOrder.requires_checkin === 1) {
			timeText = "CHECK IN REQUIRED";
		} else if (difference >= 0 && countdownTimerEnabled) {
			if (lastOrder.isVirtualQueue) {
				let timeUpText =
					location?.status_takeout_almostready_text?.length > 0
						? location.status_takeout_almostready_text.toUpperCase()
						: "TIME IS UP";
				timeText = timeUpText;
			} else {
				let almostReadyText =
					location?.status_takeout_almostready_text?.length > 0
						? location.status_takeout_almostready_text.toUpperCase()
						: "ALMOST READY";
				timeText = almostReadyText;
			}
		} else if (countdownTimerEnabled) {
			timeText = difference * -1 + " mins";
		} else if (orderStatus === Constants.ORDER_TAKEOUT_STATUS_ENUM.RECEIVED) {
			timeText = location.status_takeout_text_1.toUpperCase();
		} else {
			timeText = location.status_takeout_text_2.toUpperCase();
		}
	} else if (lastOrder.isDelivery) {
		orderStatus = getCurrentDeliveryOrderStatus(lastOrder);

		if (orderStatus > Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_DELIVERY_IN_PROGRESS) {
			return;
		}

		let countdownTimerEnabled = true;
		if (location && hasFeatureFlag(location, FeatureConstants.FEA_LOC_STATUS_DISABLE_COUNTDOWN)) {
			countdownTimerEnabled = false;
		}

		//timeTextView
		if (lastOrder.preferred_datetime === "") {
			return;
		}

		let preferred_datetime = getDateObjFromUTCString(lastOrder.preferred_datetime);

		let kitchenAdjustMinutes = lastOrder.kitchen_adjustment_minutes;

		if (kitchenAdjustMinutes > 0) {
			preferred_datetime = preferred_datetime.plusMinutes(kitchenAdjustMinutes);
		} else if (kitchenAdjustMinutes < 0) {
			preferred_datetime = preferred_datetime.minusMinutes(Math.abs(kitchenAdjustMinutes));
		}

		// let difference = Minutes.minutesBetween(preferred_datetime, now_datetime).getMinutes();
		let difference = getMinutesBetweenDates(now_datetime, preferred_datetime);

		let almostReadyText =
			!location?.status_delivery_almostready_text || location.status_delivery_almostready_text.length === 0
				? "ALMOST READY"
				: location.status_delivery_almostready_text.toUpperCase();

		if (difference >= 0 && countdownTimerEnabled) {
			timeText = almostReadyText;
		} else if (countdownTimerEnabled) {
			timeText = difference * -1 + " mins";
		} else if (orderStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.RECEIVED) {
			timeText = location.status_delivery_text_1.toUpperCase();
		} else if (orderStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.PRINTED) {
			timeText = location.status_delivery_text_2a.toUpperCase();
		} else if (orderStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_NOT_STARTED_DELIVERY) {
			timeText = location.status_delivery_text_2b.toUpperCase();
		} else if (orderStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.COMPLETE_DELIVERY_IN_PROGRESS) {
			timeText = location.status_delivery_text_2c.toUpperCase();
		} else if (orderStatus === Constants.ORDER_DELIVERY_STATUS_ENUM.DELIVERED) {
			timeText = location.status_delivery_text_3.toUpperCase();
		} else {
			timeText = "";
		}
	}
	// setAccessibilityLabelsForStatus();
	return timeText;
};

export const checkOrderCheckinRequiredStatus = (lastOrder, location) => {
	let statusOrderCheckinTitleText;
	const hideReqButton = hasFeatureFlag(location, FeatureConstants.FEA_LOC_USER_CHECKIN_REQUIRED_HIDE_BUTTON);

	if (hasCurrentOrder(lastOrder) && lastOrder.requires_checkin === 1 && !hideReqButton) {
		statusOrderCheckinTitleText = "Check In To Start Order";
	}
	return statusOrderCheckinTitleText;
};

export const getCheckInRequiredText = (location) => {
	let name = "the Restaurant";
	if (location) {
		name = location.name;
		if (location.checkin_disclaimer_text && location.checkin_disclaimer_text?.length > 0) return location.checkin_disclaimer_text;
	}

	return `You must "Check In" when you arrive at ${name} before your order will be prepared. If you do not check in, your order will be cancelled & refunded.`;
};

export const isTestingWithLivePayments = (location) => {
	return location.test_mode === 1 && location.test_mode_payments === 1;
};

export const isTestingWithFreeOrders = (location) => {
	return location.test_mode === 1 && location.test_mode_payments === 0;
};

export const getIsLocationClosed = (location, user) => {
	if (location.test_mode === 1 && user?.isAdmin) {
		//closed for testing
		return true;
	} else if (
		location.is_currently_takeout_open === 1 ||
		location.is_currently_delivery_open === 1 ||
		location.scheduling_orders_days_ahead > 0 ||
		(isTestingWithFreeOrders(location) && user?.isAdmin)
	) {
		//open
		return false;
	} //closed
	else {
		return true;
	}
};

export const getLocationStatusToDisplay = ({
	app_takeout_status_display_text,
	app_delivery_status_display_text,
	delivery_descriptive_name_override
}) => {
	let pickupStatus = "";
	let deliveryStatus = "";

	if (app_takeout_status_display_text?.length > 0) pickupStatus = app_takeout_status_display_text;

	if (app_delivery_status_display_text?.length > 0) {
		if (pickupStatus.length > 0) {
			pickupStatus = "Pickup: " + pickupStatus;
			deliveryStatus = delivery_descriptive_name_override + ": ";
		}

		deliveryStatus += app_delivery_status_display_text;
	}

	return { pickupStatus, deliveryStatus };
};

export const getOpenPriorityType = (location, user) => {
	if (location.test_mode === 1 && user?.isAdmin) {
		// test mode and admin
		return Constants.OPEN_FILTER_PRIORITY_TYPE_ENUM.OFP_TEST_MODE;
	} else if (location.test_mode === 1 && (!user || !user.isAdmin)) {
		// test mode and not admin
		return Constants.OPEN_FILTER_PRIORITY_TYPE_ENUM.OFP_CLOSED_FOR_TRAINING;
	} else if (location.is_currently_takeout_open === 1) {
		// take out open
		return Constants.OPEN_FILTER_PRIORITY_TYPE_ENUM.OFP_OPEN;
	} else if (location.is_currently_takeout_open === 0) {
		// take out closed
		return Constants.OPEN_FILTER_PRIORITY_TYPE_ENUM.OFP_CLOSED;
	}
	return -1;
};
