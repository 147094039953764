import { Masonry } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { defaultActionsToProps, defaultMapStateToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import ConstantsLabels from "../utils/ConstantsLabels";
import useAppDefaults from "../utils/hooks/useAppDefaults";
import useCommonApiCalls from "../utils/hooks/useCommonApiCalls";
import { setAppPageTitle } from "../utils/Utils";
import CustomDivContainer from "../views/custom/CustomDivContainer";
import PendingOrder from "../views/pendingOrders/PendingOrder";

const PendingOrdersPage = ({ campusData, userData, doApiCall, doEnqueueSnackbar, doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const { getPendingOrders } = useCommonApiCalls();
	const { widgetSpacingSmall } = useAppDefaults();
	const { cafeteria_groups, campus, locations } = campusData;
	const { orders, pending_orders: pendingOrders, user } = userData;
	const theme = useTheme();

	const [busy, setBusy] = useState(false);

	useEffect(() => {
		getPendingOrders();
		doUpdateAppBarTitle(ConstantsLabels.labelAppBarTitlePendingOrders);
	}, []);

	useEffect(() => {
		setAppPageTitle("Invite a Friend");
	}, [navigate]);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer
			styleClasses={[classes.menuContentDiv, classes.contentRootDiv]}
			showLoading={!user || !orders}
			showLoadingOnTop={busy}
			hideAutoScrollPadding
		>
			<Masonry columns={1} spacing={widgetSpacingSmall}>
				{pendingOrders?.length > 0 &&
					pendingOrders.map((po) => {
						return (
							<PendingOrder
								cafeteriaGroups={cafeteria_groups}
								campus={campus}
								locations={locations}
								pendingOrder={po}
								doEnqueueSnackbar={doEnqueueSnackbar}
								doApiCall={doApiCall}
								setBusy={setBusy}
								key={po.orderid}
							/>
						);
					})}
			</Masonry>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(PendingOrdersPage);
