import crypto from "crypto";
import LogRocket from "logrocket";
import EnvConfig from "./EnvConfig";
import { isProduction, isWebOrderLocal } from "./EnvUtils";
import { detect } from "detect-browser";
import Constants from "./Constants";
import { regExValidator } from "./ConstantsValidators";
import { guessBrowserTimezoneAbbreviation } from "./DateUtils";
import { cloneDeep } from "lodash";
import { hasFeatureFlag } from "./PermissionUtils";
import FeatureConstants from "./FeatureConstants";
import { readCookie } from "./CookieUtils";

const disableDevLogs = false; //TODO dev console logs disabled for pentest. after pen test change to false;
const allowConsoleLogs = (!isProduction() && !disableDevLogs) || isWebOrderLocal(); //always allow for local

//Log to console
export const Log = (msg) => {
	if (allowConsoleLogs) console.log(msg);
	else LogRocket.log(msg);
};

//includes stack of where the log was called from
export const LogTrace = (msg) => {
	if (allowConsoleLogs) console.trace(msg);
	else LogRocket.log(msg);
};

export const LogArray = (msg) => {
	if (allowConsoleLogs) console.table(msg);
	else LogRocket.debug(msg);
};

//less important, more of a trace. need to change console to verbose to see these color coded blue
export const LogDebug = (msg) => {
	if (allowConsoleLogs) console.debug(msg);
	else LogRocket.debug(msg);
};

export const LogError = (msg) => {
	if (allowConsoleLogs) console.error(msg);
	else LogRocket.error(msg);
};

//separate fx for api logging so that we can handle it differently based on env
export const LogAPIBody = (msg) => {
	if (allowConsoleLogs) console.info(msg);
};

export const LogAPI = (msg) => {
	if (allowConsoleLogs) console.info(msg);
	else LogRocket.info(msg);
};

//eventName must be unique so include Date.now() in name
export const LogTime = (eventName, isEnd) => {
	if (disableDevLogs && !isWebOrderLocal()) return;
	//allowing on production so we can see function times in prod
	if (!isEnd) console.time(eventName);
	else console.timeEnd(eventName);
};

export const getHashedPassword = (pass, salt) => {
	let hash = crypto.createHmac("sha512", salt);
	hash.update(pass);
	const value = hash.digest("hex");
	return value;
};

export const getHashedChecksumForApiCall = function (content, salt) {
	let hash = crypto.createHmac("sha256", salt);
	hash.update(content);
	const value = hash.digest("hex");
	return value;
};

export const setAppPageTitle = (title) => {
	let theTitle = title.length > 0 ? title + " | " + EnvConfig.websiteName : EnvConfig.websiteName;
	if (document.title !== theTitle) document.title = theTitle;
};

export const getBrowserInfoForInitialization = () => {
	let browser = undefined,
		language = "",
		timezone = "";
	try {
		language = navigator.language || navigator.userLanguage;
		browser = detect();
		timezone = guessBrowserTimezoneAbbreviation();
	} catch {}

	return { appVersion: EnvConfig.version, deviceLanguage: language, timezone: timezone, browser: browser };
};

export const addDeviceInfoToBody = (body, brand_ios_bundle_name, pushToken) => {
	const device = getBrowserInfoForInitialization();
	const onLaunchCookie = readCookie(Constants.onLaunchCookieName);

	const deviceInfoBody = {
		on_launch: onLaunchCookie ? "0" : "1", // 0 = don't update device info in database. 1 = do.
		push_token: pushToken || "",
		os_type: "2",
		os_version: device?.browser?.version || "",
		app_version: device?.appVersion || "",
		timezone: device?.timezone || "",
		device_model: device?.browser?.type || "",
		device_name: device?.browser?.name || "",
		os_language: device?.deviceLanguage || "",
		carrier_name: device?.browser?.os || "",
		app_bundle_name: brand_ios_bundle_name || ""
	};
	Object.assign(body, deviceInfoBody);
};

//intentionally allows sapces
export const scrubNonAlphaNumeric = (input) => {
	if (!input) return "";
	return input.replace(/[^a-z0-9 ]/gi, "");
};

export const scrubNonAlphaNumericUnderscore = (input) => {
	if (!input) return "";
	return input.replace(" ", "_").replace(/[^a-z0-9_]/gi, "");
};

export const scrubSpaces = (input) => {
	if (!input) return "";
	return input.replace(/\s/g, "");
};

export const scrubForFileName = (input) => {
	return scrubSpaces(scrubNonAlphaNumeric(input));
};

export const scrubUnsafeCharacters = (input) => {
	if (!input) return "";
	return input.replace(regExValidator.unsafeChars, "");
};

export const scrub = (input) => {
	if (!input) return "";
	else return input;
};

export const scrubNonZero = (input) => {
	return input === 0 ? 1 : input;
};

export const scrubNullTo0 = (input) => {
	if (input === undefined) return 0;
	return input;
};

export const doesContainNumber = (str) => {
	return /\d/.test(str);
};

export const doesContainLowerCase = (str) => {
	return /[a-z]/.test(str);
};

export const doesContainUpperCase = (str) => {
	return /[A-Z]/.test(str);
};

export const isValidHexColor = (str) => {
	return /[0-9A-Fa-f]{6}/g.test(str) && str.length === 6;
};

export const isValidEmail = (str) => {
	return str.match(regExValidator.email);
};

export const isDarkTheme = (themeType) => {
	return themeType === Constants.themeDarkString;
};

export const isDarkThemeWithObj = (theme) => {
	return theme.palette.mode === Constants.themeDarkString;
};

export const getWidgetIDFromTitle = (title) => {
	return scrubNonAlphaNumeric(title).toLowerCase();
};

export const isDeviceLargeFx = (theme) => theme.breakpoints.up("lg"); //lg=1600, so everything larger than that
export const isDeviceCompactFx = (theme) => theme.breakpoints.down("md"); //from 0 upto and md (1280)
export const isDeviceMobileFx = (theme) => theme.breakpoints.down("sm"); //from 0 upto and sm (800)

export const getScrollClassName = (themeType) => (themeType === "dark" ? "darkmodeScroll" : "lightmodeScroll");
export const getScrollClassNameFromTheme = (theme) => (theme.palette.mode === "dark" ? "darkmodeScroll" : "lightmodeScroll");

export const removeSpacesAndLowerCase = (inputString) => {
	return inputString.replace(/\s/g, "_").toLowerCase();
};

export const getFontColorFromBackground = (theColor, defaultColor) => {
	if (!theColor || theColor.length === 0) return defaultColor;

	let hex = theColor.toString().replace("#", "");

	if (hex.length !== 6) return defaultColor;

	let r = parseInt(hex.substr(0, 2), 16);
	let g = parseInt(hex.substr(2, 2), 16);
	let b = parseInt(hex.substr(4, 2), 16);

	let lum = (r * 299.0 + g * 587.0 + b * 114.0) / 1000.0;

	if (lum > 255.0 / 2) return "#000000";
	// bright colors - black font
	else return "#FFFFFF"; // dark colors - white font
};

export const getRandomString = (length, chars) => {
	let result = "";
	for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
	return result;
};

export const capitalizeFirstLetter = (str) => {
	if (!str || str.length === 0) return "";
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toTitleCase = (str) => {
	str = str.toLowerCase().split(" ");
	for (let i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(" ");
};

export const toTitleCaseFiltered = (str) => {
	const articles = ["a", "an", "the"];
	const conjunctions = ["for", "and", "nor", "but", "or", "yet", "so"];
	const prepositions = [
		"with",
		"at",
		"from",
		"into",
		"upon",
		"of",
		"to",
		"in",
		"for",
		"on",
		"by",
		"like",
		"over",
		"plus",
		"but",
		"up",
		"down",
		"off",
		"near"
	];

	// The list of spacial characters can be tweaked here
	const replaceCharsWithSpace = (str) => str.replace(/[^0-9a-z&/\\]/gi, " ").replace(/(\s\s+)/gi, " ");
	const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.substr(1);
	const normalizeStr = (str) => str.toLowerCase().trim();
	const shouldCapitalize = (word, fullWordList, posWithinStr) => {
		if (posWithinStr === 0 || posWithinStr === fullWordList.length - 1) {
			return true;
		}

		return !(articles.includes(word) || conjunctions.includes(word) || prepositions.includes(word));
	};

	str = replaceCharsWithSpace(str);
	str = normalizeStr(str);

	let words = str.split(" ");
	if (words.length <= 2) {
		// Strings less than 3 words long should always have first words capitalized
		words = words.map((w) => capitalizeFirstLetter(w));
	} else {
		for (let i = 0; i < words.length; i++) {
			words[i] = shouldCapitalize(words[i], words, i) ? capitalizeFirstLetter(words[i], words, i) : words[i];
		}
	}

	return words.join(" ");
};

export const convertCommaStringIDsToArray = (inputString) => {
	let finalArray = [];
	if (!inputString) return [];

	let stringIds = inputString.split(",");
	if (stringIds && stringIds.length > 0)
		stringIds.forEach((strID) => {
			let intID = parseInt(strID) || 0;
			if (intID > 0 || intID < 0) {
				finalArray.push(intID);
			}
		});

	return finalArray;
};

export const convertCommaStringValsToArray = (inputString) => {
	let finalArray = [];
	let stringIds = inputString?.split(",");
	if (stringIds && stringIds.length > 0)
		stringIds.forEach((strVal) => {
			if (strVal && strVal.length > 0) {
				finalArray.push(strVal);
			}
		});

	return finalArray;
};

//from select
export const convertIDarrayToCommaString = (array, negativeNumber) => {
	if (!array || array.length === 0) return "";

	const multiplier = negativeNumber ? -1 : 1;

	let idsString = "";
	array.forEach((r) => {
		if (r.value && r.value !== 0) idsString = idsString + ((multiplier * parseInt(r.value) || "") + ",");
	});
	return idsString;
};

//from select
export const convertSelectObjectArrayToCommaString = (array) => {
	if (!array || array.length === 0) return "";

	let idsString = "";
	array.forEach((r) => {
		if (r.value && r.value !== 0 && r.value.trim().length > 0) idsString = idsString + r.value + ",";
	});
	return idsString;
};

export const maxStringLength = (thestring, thelength, includeElipse) => {
	const elipse = includeElipse ? "..." : "";
	if (thestring === undefined) return "";
	const trimmedString = thestring.length > thelength ? thestring.substring(0, thelength) + elipse : thestring;
	return trimmedString;
};

export const isPointsEnabled = (campus, user) => {
	if (!campus) return false;

	if (!user || user.opt_in_loyalty === 0) return false;

	return campus.loyalty_enabled > 0 && campus.loyalty_points_enabled === 1;
};

export const getCurrencyString = (value) => {
	if (value === undefined) return "";
	return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value / 100.0);
};

export const setRadioButtonSelected = (array, keyString, matchValue) => {
	if (array?.length === 0 || matchValue === undefined) return { selectedArray: [], selectedObject: {} };
	const clonedRadioButtonArray = cloneDeep(array);
	let selectedObject = {};
	clonedRadioButtonArray.forEach((radioButton) => {
		if (radioButton[keyString].toString() === matchValue.toString()) {
			radioButton.selected = true;
			selectedObject = radioButton;
		} else {
			radioButton.selected = false;
		}
	});
	return { selectedArray: clonedRadioButtonArray, selectedObject };
};

export const getCreditCardType = (cardFirstTwoNumbers) => {
	if (cardFirstTwoNumbers === "" || cardFirstTwoNumbers.length < 2) return "Credit Card";

	const first = cardFirstTwoNumbers.substring(0, 1);
	const two = cardFirstTwoNumbers.substring(0, 2);

	if (first === "4") return "VISA";
	else if (
		two === "50" ||
		two === "51" ||
		two === "52" ||
		two === "53" ||
		two === "54" ||
		two === "55" ||
		two === "22" ||
		two === "23" ||
		two === "24" ||
		two === "25" ||
		two === "26" ||
		two === "67"
	)
		return "MC";
	else if (two === "34" || two === "37") return "AMEX";
	else if (two === "60" || two === "64" || two === "65") return "DS";
	else return "";
};

export const getManualOnline = (location, menuItem, pickupOrDelivery) => {
	let online = false;
	if (!hasFeatureFlag(location, FeatureConstants.FEA_LOC_DELIVERY_SEPARATE_AVAILABILITY_VISIBILITY)) return menuItem?.manual_online === 1;

	if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 && menuItem.manual_online === 1) {
		online = true;
	} else if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 && menuItem.manual_online_delivery === 1) {
		online = true;
	}
	return online;
};

export const getIsHidden = (location, menuItem, pickupOrDelivery) => {
	let hidden = false;
	if (!hasFeatureFlag(location, FeatureConstants.FEA_LOC_DELIVERY_SEPARATE_AVAILABILITY_VISIBILITY)) return menuItem.is_hidden === 1;

	if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 && menuItem.is_hidden === 1) {
		hidden = true;
	} else if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 && menuItem.is_hidden_delivery === 1) {
		hidden = true;
	}
	return hidden;
};

export const openLink = (url) => {
	if (url?.length < 1) return;
	window.open(url, "_blank", "noopener,noreferrer");
};

export const getPaymentTypesArray = (user, mealplan_name, creditCardPaymentsAllowed, mealPlanPaymentsAllowed) => {
	const {
		creditcard_1_type,
		creditcard_1_last4,
		creditcard_2_type,
		creditcard_2_last4,
		has_creditcard_1,
		has_creditcard_2,
		has_mealplancard,
		payment_method_default
	} = user;

	const paymentTypesArray = [];
	if (has_mealplancard === 1 && mealPlanPaymentsAllowed) {
		paymentTypesArray.push({
			ccTypeFirst2Digits: "",
			ccLast4Digits: "",
			ccType: mealplan_name,
			name: mealplan_name,
			paymentTypeId: 0,
			selected: payment_method_default === 0,
			isCampusCard: true,
			ct_id: 0
		});
	}
	if (has_creditcard_1 === 1 && creditCardPaymentsAllowed) {
		const ccType = getCreditCardType(creditcard_1_type);
		paymentTypesArray.push({
			ccTypeFirst2Digits: creditcard_1_type,
			ccLast4Digits: creditcard_1_last4,
			ccType,
			name: `${ccType} ****${creditcard_1_last4}`,
			paymentTypeId: 1,
			selected: payment_method_default === 1,
			isCampusCard: false,
			ct_id: -1
		});
	}
	if (has_creditcard_2 === 1 && creditCardPaymentsAllowed) {
		const ccType = getCreditCardType(creditcard_2_type);
		paymentTypesArray.push({
			ccTypeFirst2Digits: creditcard_2_type,
			ccLast4Digits: creditcard_2_last4,
			ccType,
			name: `${ccType} ****${creditcard_2_last4}`,
			paymentTypeId: 2,
			selected: payment_method_default === 2,
			isCampusCard: false,
			ct_id: -2
		});
	}

	// If nothing selected, default to whatever is first in the array
	if (!paymentTypesArray.find((t) => t.selected) && paymentTypesArray.length > 0) paymentTypesArray[0].selected = true;

	return paymentTypesArray;
};

export const getDistanceFromCurrent = (lat1, long1, lat2, long2) => {
	const R = 6371; //Radius of the earth in Km
	const dLat = ((lat2 - lat1) * Math.PI) / 180; //delta (difference between) latitude in radians
	const dLon = ((long2 - long1) * Math.PI) / 180; //delta (difference between) longitude in radians

	const a = 0.5 - Math.cos(dLat) / 2 + (Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * (1 - Math.cos(dLon))) / 2;

	return R * 2 * Math.asin(Math.sqrt(a));
};
