import { Typography, Rating } from "@mui/material";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { GlobalStyle, classes } from "../../theme/GlobalStyle";
import { isDeviceMobileFx } from "../../utils/Utils";
import CustomFormTextInput from "../../utils/forms/CustomFormTextInput";
import CustomFormSelect from "../../utils/forms/CustomFormSelect";

import CustomGridContainer from "../custom/CustomGridContainer";

const RootDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const commentChoices = {
	isVirtualQueue: {
		serviceCommentsArray: [
			{ label: "I had to wait when I arrived.", value: `vs-I had to wait when I arrived.` },
			{ label: "Didn't receive notifications.", value: `vs-Didn't receive notifications.` },
			{ label: "Took too long to book reservation.", value: `vs-Took too long to book reservation.` },
			{ label: "I couldn't use my campus card.", value: `vs-I couldn't use my campus card.` },
			{ label: "Reservation process was confusing.", value: `vs-Reservation process was confusing.` },
			{ label: "Other.", value: `vs-Other` }
		],
		foodCommentsArray: [
			{ label: "", value: `vf-Quality was poor.` },
			{ label: "Limited options.", value: `vf-Limited options.` },
			{ label: "The atmosphere was unenjoyable.", value: `vf-The atmosphere was unenjoyable.` },
			{ label: "Other.", value: `vf-Other.` }
		]
	},
	notVirtualQueue: {
		serviceCommentsArray: [
			{ label: "Order wasn't ready on time", value: `nvs-Order wasn't ready on time` },
			{ label: "Didn't receive notifications", value: `nvs-Didn't receive notifications` },
			{ label: "Took too long to order order", value: `nvs-Took too long to order order` },
			{ label: "Couldn't use my meal plan", value: `nvs-Couldn't use my meal plan` },
			{ label: "Other", value: `nvs-Other` }
		],
		foodCommentsArray: [
			{ label: "Meal wasn't hot", value: `nvf-Meal wasn't hot` },
			{ label: "Meal wasn't cold", value: `nvf-Meal wasn't cold` },
			{ label: "Ingredients weren't fresh", value: `nvf-Ingredients weren't fresh` },
			{ label: "Didn't like the taste", value: `nvf-Didn't like the taste` },
			{ label: "It was missing ingredients", value: `nvf-It was missing ingredients"` },
			{ label: "Portion was too small", value: `nvf-Portion was too small` },
			{ label: "Other", value: `nvf-Other` }
		]
	}
};

const OrderFeedbackItem = (props) => {
	const {
		inputID,
		inputLabel,
		ratingName,
		ratingValue,
		ratingString,
		selectedFeedbackChoice,
		setSelectedFeedbackChoice,
		feedback,
		setFeedback,
		feedbackError,
		setFeedbackError,
		onChange,
		addTopMargin,
		feedbackOptionArray
	} = props;

	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);

	return (
		<>
			<Grid item marginTop={addTopMargin ? 2 : 0}>
				<Typography component="legend">{ratingString}</Typography>
			</Grid>
			<Grid item>
				<Rating
					name={ratingName}
					value={ratingValue}
					size="large"
					max={5}
					onChange={(event, newValue) => {
						onChange(newValue);
					}}
					className={classes.orderFeedbackRating}
				/>
			</Grid>

			{!!ratingValue && ratingValue !== 0 && ratingValue <= 3 && (
				<>
					<Grid item>
						<CustomFormSelect
							inputID={"feedbackSelect"}
							inputLabel={inputLabel ? inputLabel : "Feedback"}
							optionsArray={feedbackOptionArray}
							selectedOption={selectedFeedbackChoice}
							setSelectedOption={(selectedObj) => setSelectedFeedbackChoice(selectedObj)}
							marginBottom={10}
						/>
					</Grid>
					{selectedFeedbackChoice?.label === "Other" && (
						<Grid item>
							<CustomFormTextInput
								inputID={inputID}
								inputLabel={inputLabel}
								setValue={setFeedback}
								value={feedback}
								setError={setFeedbackError}
								errorText={feedbackError}
								marginBottom={isDeviceMobile ? 20 : 40}
								allowAutoComplete
								maxLength={100}
								multiline
								rows={3}
								isOptional
							/>
						</Grid>
					)}
				</>
			)}
		</>
	);
};

const TransactionFeedbackModalForm = forwardRef((props, ref) => {
	const theme = useTheme();

	const { order, doApiCall, doClearErrorAlert, setIsButtonDisabled } = props;

	const [orderingRating, setOrderingRating] = useState(0);
	const [orderingFeedbackChoice, setOrderingFeedbackChoice] = useState(null);
	const [orderingFeedbackText, setOrderingFeedbackText] = useState("");
	const [errorOrderingFeedback, setErrorOrderingFeedback] = useState("");

	const [mealRating, setMealRating] = useState(0);
	const [mealFeedbackChoice, setMealFeedbackChoice] = useState(null);
	const [mealFeedbackText, setMealFeedbackText] = useState("");
	const [errorMealFeedback, setErrorMealFeedback] = useState("");

	const getIsRatingNotValid = () => {
		return (
			!orderingRating ||
			orderingRating === 0 ||
			(orderingRating <= 3 && (!orderingFeedbackChoice || (orderingFeedbackChoice?.label === "Other" && orderingFeedbackText === ""))) ||
			!mealRating ||
			mealRating === 0 ||
			(mealRating <= 3 && (!mealFeedbackChoice || (mealFeedbackChoice?.label === "Other" && mealFeedbackText === "")))
		);
	};

	useImperativeHandle(ref, () => ({
		saveOrderFeedback() {
			doClearErrorAlert();

			const body = {
				orderid: "" + order.orderid,
				hangry_rating: "" + orderingRating,
				food_rating: "" + mealRating,
				hangry_comment:
					orderingRating > 3 ? "" : orderingFeedbackChoice?.label === "Other" ? orderingFeedbackText : orderingFeedbackChoice?.label,
				food_comment: mealRating > 3 ? "" : mealFeedbackChoice?.label === "Other" ? mealFeedbackText : mealFeedbackChoice?.label
			};

			doApiCall(
				ApiEndpoints.RateOrder,
				body,
				null,
				{ logRequest: true, logResponse: true, hideErrorToast: false },
				(data) => {},
				(errorMsg, errorReason) => {}
			);
		}
	}));

	useEffect(() => {
		setIsButtonDisabled(getIsRatingNotValid());
	}, [orderingRating, mealRating, orderingFeedbackChoice, orderingFeedbackText, mealFeedbackChoice, mealFeedbackText]);

	if (!theme.isThemeLoaded || !order) return <></>;

	return (
		<RootDiv>
			<CustomGridContainer direction="column" spacing={1}>
				<OrderFeedbackItem
					isVirtualQueue={order?.isVirtualQueue}
					inputID={"orderingFeedbackText"}
					inputLabel={"Ordering Feedback"}
					ratingName="rating-order-experience"
					ratingString={"Ordering Experience"}
					ratingValue={orderingRating}
					feedbackOptionArray={
						order?.isVirtualQueue
							? commentChoices.isVirtualQueue.serviceCommentsArray
							: commentChoices.notVirtualQueue.serviceCommentsArray
					}
					selectedFeedbackChoice={orderingFeedbackChoice}
					setSelectedFeedbackChoice={setOrderingFeedbackChoice}
					feedback={orderingFeedbackText}
					setFeedback={setOrderingFeedbackText}
					feedbackError={errorOrderingFeedback}
					setFeedbackError={setErrorOrderingFeedback}
					onChange={setOrderingRating}
				/>
				<OrderFeedbackItem
					isVirtualQueue={order?.isVirtualQueue}
					inputID={"mealFeedbackText"}
					inputLabel={"Meal Feedback"}
					ratingName="rating-meal-satisfaction"
					ratingValue={mealRating}
					ratingString={"Meal Satisfaction"}
					selectedFeedbackChoice={mealFeedbackChoice}
					setSelectedFeedbackChoice={setMealFeedbackChoice}
					feedback={mealFeedbackText}
					setFeedback={setMealFeedbackText}
					feedbackError={errorMealFeedback}
					setFeedbackError={setErrorMealFeedback}
					onChange={setMealRating}
					feedbackOptionArray={
						order?.isVirtualQueue
							? commentChoices.isVirtualQueue.foodCommentsArray
							: commentChoices.notVirtualQueue.foodCommentsArray
					}
					addTopMargin
				/>
			</CustomGridContainer>
		</RootDiv>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(TransactionFeedbackModalForm);
