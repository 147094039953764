import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle } from '@mui/material';
import { connect } from 'react-redux';
import { defaultActionsToProps, defaultMapStateToProps } from '../../store/Actions';
import _ from 'lodash';

const CustomFormErrorAlertRedux = ({ doClearErrorAlert, errorAlertData, errorKey, noCollapse }) => {
	const { error } = errorAlertData || {error:{}};
  const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("error");
	const [title, setTitle] = useState("");

	useEffect(() => {
		if ((!errorKey && !_.isEmpty(error)) || error.type === errorKey || errorKey.includes(error.type)) {
			setMessage(error.message);
			error.title && setTitle(error.title);
			error.severity && setSeverity(error.severity);
			setOpen(true);
		} else if (_.isEmpty(error)){
			setOpen(false);
		}
	}, [error])
	
	const onHandleClose = () => {
		doClearErrorAlert()
		setOpen(false);
	}

	return (
    <Box sx={{ width: '100%', border: 0 }} >
      <Collapse in={noCollapse ? true : open}>
        <Alert
          action={
            <IconButton
              role={"button"}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onHandleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
					severity={severity}
          sx={{ mb: 0 }}
        >
  				{title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
}

export default connect(defaultMapStateToProps, defaultActionsToProps)(CustomFormErrorAlertRedux);
