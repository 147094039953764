export const DataGridScrollDark = {
	"*::-webkit-scrollbar": {
		width: "1rem",
		height: "1rem"
	},

	/* background of bar*/
	"*::-webkit-scrollbar-track-piece": {
		backgroundColor: "#202020"
	},

	/* background is the actual scroll thumb*/
	"*::-webkit-scrollbar-thumb": {
		backgroundColor: "#333",
		borderTop: "2px solid #666",
		borderBottom: "2px solid #666",
		borderRadius: "0px",
		borderLeft: "none",
		borderRight: "none"
	},

	"*::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#444"
	},

	"*::-webkit-scrollbar-thumb:active": {
		backgroundColor: "#555"
	},

	/* Buttons */
	"*::-webkit-scrollbar-button": {
		backgroundSize: "1rem"
	},

	"*::-webkit-scrollbar-button:single-button": {
		backgroundColor: "#333",
		display: "block",
		backgroundRepeat: "no-repeat"
	},

	/* Up */
	"*::-webkit-scrollbar-button:single-button:vertical:decrement": {
		height: "22px",
		width: "22px",
		backgroundPosition: "center 6px",
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(210, 210, 210)'><polygon points='50,00 0,50 100,50'/></svg>\")"
	},

	"*::-webkit-scrollbar-button:single-button:vertical:decrement:hover": {
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='50,00 0,50 100,50'/></svg>\")"
	},

	"*::-webkit-scrollbar-button:single-button:vertical:decrement:active": {
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='50,00 0,50 100,50'/></svg>\")"
	},

	/* Down */
	"*::-webkit-scrollbar-button:single-button:vertical:increment": {
		height: "22px",
		width: "22px",
		backgroundPosition: "center 7px",
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(210, 210, 210)'><polygon points='0,0 100,0 50,50'/></svg>\")"
	},

	"*::-webkit-scrollbar-button:single-button:vertical:increment:hover": {
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(3, 88, 151)'><polygon points='0,0 100,0 50,50'/></svg>\")"
	},

	"*::-webkit-scrollbar-button:single-button:vertical:increment:active": {
		backgroundImage:
			"url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(7, 127, 216)'><polygon points='0,0 100,0 50,50'/></svg>\")"
	}
};
