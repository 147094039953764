import { Log } from "../utils/Utils";
import { cloneDeep } from "lodash";

export const reduceToArray = (obj, state, stateArrayKey, objKey, processIndvidualFx) => {
	let newArray = [];
	let array = state[stateArrayKey];
	array = !array ? [] : array;
	newArray = cloneDeep(array);
	processIndvidualFx(obj, state);
	const index = array.findIndex((o) => o[objKey] === obj[objKey]);
	if (index === -1) newArray.push(obj);
	else newArray[index] = obj;
	return newArray;
};

export const reduceLocationData = (locations) => {
	return locations.map((l) => {
		const _reducedLocation = {
			locationid: l.locationid,
			cafeteriaid: l.cafeteriaid,
			app_delivery_status_display_text: l.app_delivery_status_display_text,
			app_takeout_status_display_text: l.app_takeout_status_display_text,
			delivery_descriptive_name_override: l.delivery_descriptive_name_override,
			delivery_instructions: l.delivery_instructions,
			estimated_wait_time: l.estimated_wait_time,
			features: l.features,
			icon_picture_url_with_base: l.icon_picture_url_with_base,
			isVirtualQueue: l.isVirtualQueue,
			is_currently_delivery_open: l.is_currently_delivery_open,
			is_currently_takeout_open: l.is_currently_takeout_open,
			name: l.name,
			order_queue_count: l.order_queue_count,
			openPriorityType: l.openPriorityType,
			pickup_instructions: l.pickup_instructions,
			point_bonus: l.point_bonus,
			scheduling_orders_days_ahead: l.scheduling_orders_days_ahead,
			status_delivery_almostready_text: l.status_delivery_almostready_text,
			status_delivery_icon_1: l.status_delivery_icon_1,
			status_delivery_icon_2a: l.status_delivery_icon_2a,
			status_delivery_icon_2b: l.status_delivery_icon_2b,
			status_delivery_icon_2c: l.status_delivery_icon_2c,
			status_delivery_icon_3: l.status_delivery_icon_3,
			status_delivery_text_1: l.status_delivery_text_1,
			status_delivery_text_2a: l.status_delivery_text_2a,
			status_delivery_text_2c: l.status_delivery_text_2c,
			status_delivery_text_3: l.status_delivery_text_3,
			status_takeout_almostready_text: l.status_takeout_almostready_text,
			status_takeout_icon_1: l.status_takeout_icon_1,
			status_takeout_icon_2: l.status_takeout_icon_2,
			status_takeout_icon_3: l.status_takeout_icon_3,
			status_takeout_text_1: l.status_takeout_text_1,
			status_takeout_text_2: l.status_takeout_text_2,
			status_takeout_text_3: l.status_takeout_text_3,
			test_mode: l.test_mode,
			test_mode_payments: l.test_mode_payments,
			virtualqueue_max_capacity: l.virtualqueue_max_capacity
		};
		return _reducedLocation;
	});
};
