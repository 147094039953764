import clsx from "clsx";
import React, { useState } from "react";
import AppleSignin from "react-apple-signin-auth";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import { ReactComponent as GoogleIcon } from "../../utils/icons/googleIcon.svg";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import AppleIcon from "@mui/icons-material/Apple";
import EmailIcon from "@mui/icons-material/Email";
import GavelIcon from "@mui/icons-material/Gavel";
import LoginIcon from "@mui/icons-material/Login";
import PolicyIcon from "@mui/icons-material/Policy";
import SchoolIcon from "@mui/icons-material/School";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import EnvConfig from "../../utils/EnvConfig";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import { getHashedChecksumForApiCall, openLink } from "../../utils/Utils";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import Loading from "../../utils/components/Loading";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import LoginPage from "./LoginPage";
import RegistrationPage from "./RegistrationPage";
import useCommonData from "../../utils/hooks/useCommonData";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const SignInPage = ({ setIsShowingModal, doApiCall, isMainModalPage, setIsMainModalPage, isForgotPassword, setIsForgotPassword }) => {
	const { campus, campusID } = useCommonData();
	const theme = useTheme();
	const [busy, setBusy] = useState(false);
	const { widgetSpacing } = useAppDefaults();

	const [loginType, setLoginType] = useState(false);

	const handleClickLogin = (_loginType) => {
		setLoginType(_loginType);
		switch (_loginType) {
			case "student":
				setBusy(true);
				// TODO: will create env entry for api_key later
				window.location.href = `${EnvConfig.apiBaseURL}samllogin?campusid=${campusID}&redirect_url=${campusID}&is_local=0`;
				// TODO: need to call the logout when loging out of app example - https://samltest.id/idp/profile/Logout?execution=e1s1
				break;
			case "google":
			case "apple":
				break;
			case "registration":
			case "guest":
				setIsMainModalPage(false);
				break;
			default:
				setLoginType(false);
				setIsMainModalPage(true);
				break;
		}
	};

	if (!theme.isThemeLoaded) return <></>;

	const responseGoogleFailed = (response) => {
		console.log(`google failed: ${JSON.stringify(response)}`);
	};

	const responseGoogleSuccess = async (googleData) => {
		console.log(`google response: ${JSON.stringify(googleData)}`);
		// googleData: {
		//   accessToken,
		//   Ba,
		//   googleId,
		//   Lu:Nw {TW: '107948049446502174634', tf: 'Ernest Leon', iY: 'Ernest', wW: 'Leon', rN: 'https://lh3.googleusercontent.com/a/AATXAJxWc0vudgt76Bh2qe0MrStmrYYkBjV8jXpnjtwv=s96-c', ...},
		//   profileObj: {googleId: '107948049446502174634', imageUrl: '', email: '', name: '', givenName: '', …},
		//   tokenId:''
		//   tokenObj: {token_type: 'Bearer', access_token: '', scope: 'email profile openid https://www.googleapis.c…ps://www.googleapis.com/auth/userinfo.profile', login_hint: '', expires_in: 3599, …}
		//   xc: {token_type: 'Bearer', access_token: '', scope: 'email profile openid https://www.googleapis.c…ps://www.googleapis.com/auth/userinfo.profile', login_hint: '', expires_in: 3599, …}

		const hashChecksum = getHashedChecksumForApiCall(`${googleData.profileObj.email}${googleData.tokenId}`, Constants.apiHashSalt);
		// API_REGISTERWITHGOOGLE,
		// [ "hash", "language", "email", "google_auth_token", "google_userid", "full_name", "os_type", "campusid", "app_bundle_name" ]
		// [ hash, "EN", theemail, theauthtoken, theuserid, thefullname, Constants.OSTYPE, campusid + "", BundleIdentifier.getAppBundleName() ]
		const reqBody = {
			hash: hashChecksum ? hashChecksum : "",
			email: googleData.profileObj.email,
			google_auth_token: googleData.tokenId,
			google_userid: googleData.googleId,
			full_name: googleData.profileObj.name,
			campusid: "" + (campus.campusid ? campus.campusid : "")
		};

		//passing setBusy to the api call function will call setBusy in the 'finally' of api middleware
		//this resulted in React state update on an unmounted component error. so by doing it outside and then in the callback,
		//we prevent the component being onloaded because of routerHistory and then the callback happening
		setBusy(true);

		doApiCall(
			ApiEndpoints.RegisterWithGoogle,
			reqBody,
			undefined,
			{ logRequest: false, logResponse: true, hideErrorToast: true, addDeviceInfoToBody: true },
			() => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
			}
		);
	};

	const responseAppleFailed = (response) => {
		console.log(`apple failed: ${JSON.stringify(response)}`);
	};

	const responseAppleSuccess = async (response) => {
		console.log(`apple response: ${JSON.stringify(response)}`);

		// const hashChecksum = getHashedChecksumForApiCall(`${response.profileObj.email}${googleData.accessToken}`, Constants.apiHashSalt);
		// API_REGISTERWITHGOOGLE,
		// [ "hash", "language", "email", "google_auth_token", "google_userid", "full_name", "os_type", "campusid", "app_bundle_name" ]
		// [ hash, "EN", theemail, theauthtoken, theuserid, thefullname, Constants.OSTYPE, campusid + "", BundleIdentifier.getAppBundleName() ]
		const reqBody = {
			// hash: hashChecksum ? hashChecksum : "",
			// language: "EN",
			// email: googleData.profileObj.email,
			// google_auth_token: googleData.tokenId,
			// google_userid: googleData.googleId,
			// full_name: googleData.profileObj.name,
			// os_type: "2",
			// campusid: "" + (campus.campusid ? campus.campusid : ""),
			// app_bundle_name: campus.brand_ios_bundle_name
		};

		// //passing setBusy to the api call function will call setBusy in the 'finally' of api middleware
		// //this resulted in React state update on an unmounted component error. so by doing it outside and then in the callback,
		// //we prevent the component being onloaded because of routerHistory and then the callback happening
		// setBusy(true);

		// doApiCall(
		// 	ApiEndpoints.RegisterWithApple,
		// 	reqBody,
		// 	undefined,
		// 	{ logRequest: false, logResponse: true, hideErrorToast: true },
		// 	() => {
		// 		setBusy(false);
		// 	},
		// 	(errorMsg) => {
		// 		setBusy(false);
		// 	}
		// );
	};

	return (
		<CustomPaperContainer
			rounded
			className={clsx(classes.fullHeight, classes.paperBackgroundWidgetTheme)}
			role="region"
			aria-live="polite"
			aria-label={`Signin ${loginType ? `${loginType} ` : ""}page`}
		>
			<Loading open={busy} />
			{isMainModalPage ? (
				<Stack padding={4} direction="column" justifyContent="space-between" className={classes.fullHeight}>
					{hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_CAMPUSSIGNIN) && (
						<CustomFormLabelItem
							label={
								campus !== null && campus?.login_sso_btn_title !== null && campus?.login_sso_btn_title?.length > 0
									? campus.login_sso_btn_title
									: "Log In With Campus ID"
							}
							Icon={SchoolIcon}
							IconProps={{ alt: "Log in with campus ID" }}
							onClick={() => {
								handleClickLogin("student");
							}}
						/>
					)}

					{/* LEAVING THIS HERE IN CASE WE NEED TO COME BACK TO USE IT/MY UPDATES ARE GOOD*/}
					{/* {hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_GOOGLESIGNIN) && (
								<ListItemButton divider disableGutters dense>
									<GoogleLogin
										clientId={EnvConfig.googleClientId}
										label="Log In With Google"
										onSuccess={responseGoogleSuccess}
										onFailure={responseGoogleFailed}
										cookiePolicy={"single_host_origin"}
										// className={clsx(classes.customLabelItemListButton, classes.signInButton)}
										// uxMode="popup"
										// isSignedIn="false"
										// redirectUri={"http://localhost:3002/21"} //google?token=____ -- need to scrub (history.replace to remove from history)
										render={(renderProps) => (
											<Stack
												direction="row"
												spacing={1}
												paddingRight={1}
												alignItems="center"
												justifyContent="space-between"
												className={classes.fullWidth}
											>
												<GoogleButton
													onClick={renderProps.onClick}
													className={clsx(classes.signInButton, classes.fullWidthImportant)}
													theme={isDarkTheme ? "dark" : "light"}
												>
													Login with Google
												</GoogleButton>
												<ChevronRightIcon />
											</Stack>
										)}
									/>
								</ListItemButton>
							)}

							{hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_APPLESIGNIN) && (
								<ListItemButton divider disableGutters dense>
									<Stack
										direction="row"
										spacing={1}
										paddingRight={1}
										alignItems="center"
										justifyContent="space-between"
										className={classes.fullWidth}
									>
										<AppleSignin
											// Auth options passed to AppleID.auth.init() 
											authOptions={{
												clientId: "dev.bevaleo",
												scope: "email name",
												redirectURI: "https://bevaleo.dev/",
												state: "",
												nonce: "nonce",
												usePopup: true
											}}
											// General props 
											uiType={isDarkTheme ? "dark" : "light"}
											// className 
											className={clsx(classes.signInButton)}
											// Allows to change the button's children, eg: for changing the button text 
											buttonExtraChildren="Sign In With Apple"
											//onSuccess response object will contain the user object on the first time attempt only
											onSuccess={responseAppleSuccess}
											onError={responseAppleFailed}
											// Apple image props
											iconProps={{
												className: classes.signInButtonAppleIcon
											}}
											/ Spread rest props if needed 
											// {...rest}
										/>
										{/* <AppleSignin
												authOptions={{
													// Client ID - eg: 'com.example.com'
													clientId: "com.example.web",
													// Requested scopes, seperated by spaces - eg: 'email name'
													scope: "email name",
													// Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails
													redirectURI: "https://example.com",
													// State string that is returned with the apple response
													state: "state",
													// Nonce
													nonce: "nonce",
													// Uses popup auth instead of redirection
													usePopup: false
												}} // REQUIRED
												// General props
												uiType={isDarkTheme ? "dark" : "light"}
												// className
												className={clsx(classes.customLabelItemListButton, classes.signInButton)}
												// Removes default style tag
												noDefaultStyle={false}
												// Allows to change the button's children, eg: for changing the button text
												buttonExtraChildren="Sign In With Apple"
												// Extra controlling props
												// Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side
												onSuccess={(response) => console.log(`apple login ${response}`)} // default = undefined
												// Called upon signin error
												// onError={(error) => console.error(error)} // default = undefined
												// Skips loading the apple script if true
												// skipScript={false} // default = undefined
												// Apple image props
												iconProps={{ style: { width: "48px", height: "48px" } }}
												// render function - called with all props - can be used to fully customize the UI by rendering your own component 
												// render={(props) => <button {...props}>My Custom Button</button>}
											/> 
										<ChevronRightIcon />
									</Stack>
								</ListItemButton>
							)} */}

					{/* {hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_GOOGLESIGNIN) && (
							<GoogleLogin
								clientId={EnvConfig.googleClientId}
								label="Login with Google"
								onSuccess={responseGoogleSuccess}
								onFailure={responseGoogleFailed}
								cookiePolicy={"single_host_origin"}
								// className={clsx(classes.customLabelItemListButton, classes.signInButton)}
								// uxMode="popup"
								// isSignedIn="false"
								// redirectUri={"http://localhost:3002/21"} //google?token=____ -- need to scrub (history.replace to remove from history)
								render={(renderProps) => (
									<CustomFormLabelItem
										label={"Sign in with Google"}
										{...renderProps}
										Icon={SvgIcon}
										IconProps={{ alt: "Sign in with Google", component: GoogleIcon, inheritViewBox: true }}
									/>
								)}
							/>
						)} */}

					{
						/* {hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_APPLESIGNIN) && (
							<AppleSignin
								/** Auth options passed to AppleID.auth.init() *"/
								authOptions={{
									clientId: "dev.bevaleo",
									scope: "email name",
									redirectURI: "https://bevaleo.dev/",
									state: "",
									nonce: "nonce",
									usePopup: true
								}}
								/** General props *"/
								uiType={isDarkTheme ? "dark" : "light"}
								/** className */
						// className={clsx(classes.signInButton)}
						/** Allows to change the button's children, eg: for changing the button text *"/
								buttonExtraChildren="Sign In With Apple"
								//onSuccess response object will contain the user object on the first time attempt only
								onSuccess={responseAppleSuccess}
								onError={responseAppleFailed}
								// Apple image props
								// iconProps={{
								// 	className: classes.signInButtonAppleIcon
								// }}
								/** Spread rest props if needed *"/
								// {...rest}
								render={(props) => (
									<CustomFormLabelItem
										{...props}
										label="Sign In With Apple"
										Icon={AppleIcon}
										IconProps={{ alt: "Sign in with Apple" }}
									/>
								)}
							/>
						)} */
					}

					{hasFeatureFlag(campus, FeatureConstants.FEA_WELCOME_ENABLE_EMAILSIGNIN) && (
						<>
							<CustomFormLabelItem
								label={
									campus !== null && campus?.login_email_btn_title !== null && campus?.login_email_btn_title.length > 0
										? campus.login_email_btn_title
										: "Register With Email"
								}
								Icon={EmailIcon}
								IconProps={{ alt: "Register with email" }}
								onClick={() => {
									handleClickLogin("registration");
								}}
							/>

							<CustomFormLabelItem
								label={
									campus !== null &&
									campus?.login_returning_btn_title !== null &&
									campus?.login_returning_btn_title.length > 0
										? campus.login_returning_btn_title
										: "I Already Have An Account"
								}
								Icon={LoginIcon}
								IconProps={{ alt: "Login with existing account" }}
								onClick={() => {
									handleClickLogin("guest");
								}}
							/>
						</>
					)}
					<Stack direction="row" justifyContent="space-between" mt={widgetSpacing}>
						<CustomFormButton
							inputID={"terms"}
							startIcon={<GavelIcon />}
							buttonText="Terms"
							variant="text"
							handleClick={() => {
								openLink(campus.terms_url);
							}}
							className={classes.userProfileLegalButton}
						/>
						<CustomFormButton
							inputID={"policy"}
							startIcon={<PolicyIcon />}
							buttonText="Policy"
							variant="text"
							handleClick={() => {
								openLink(campus.privacy_url);
							}}
							className={classes.userProfileLegalButton}
						/>
					</Stack>
				</Stack>
			) : (
				<>
					{loginType === "registration" && (
						<RegistrationPage setIsShowingModal={setIsShowingModal} setIsMainModalPage={setIsMainModalPage} />
					)}
					{loginType === "guest" && (
						<LoginPage
							setIsShowingModal={setIsShowingModal}
							setIsMainModalPage={setIsMainModalPage}
							isForgotPassword={isForgotPassword}
							setIsForgotPassword={setIsForgotPassword}
						/>
					)}
				</>
			)}
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(SignInPage);
