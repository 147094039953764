import { isDarkThemeWithObj } from "../../utils/Utils";

const PREFIX = "LoginPage";

export const classes = {
	root: `${PREFIX}-root`,
	backdrop: `${PREFIX}-backdrop`,
	formControl: `${PREFIX}-formControl`,
	formLoginButton: `${PREFIX}-formLoginButton`,
	paperBackgroundWidgetTheme: `${PREFIX}-paperBackgroundWidgetTheme`
};

export const SignInPageStyle = (theme) => {
	const p = theme.palette;

	const isDarkTheme = isDarkThemeWithObj(theme);

	return {
		[`&.${classes.root}`]: {
			height: "100%"
		},

		[`& .${classes.backdrop}`]: {
			zIndex: 100
		},

		[`& .${classes.formControl}`]: {
			width: "100%",
			border: "1px solid transparent",
			borderRadius: 5,
			padding: "8px 2px",
			"&:hover": {
				backgroundColor: p.background.backgroundInput,
				border: "1px solid " + p.border.inputBoxBorder
			},
			"&:focus": {
				backgroundColor: p.background.selectBackgroundHover,
				border: "2px solid " + p.text.primary,
				boxShadow: "inset 0 0 0 1px " + p.text.primary
			},
			[theme.breakpoints.down("sm")]: {
				marginBottom: theme.spacing(0)
			}
		},
		[`& .${classes.formLoginButton}`]: {
			padding: theme.spacing(2, 3)
		},
		[`& .${classes.paperBackgroundWidgetTheme}`]: {
			background: p.background.backgroundWidget
		}
	};
};
