import { isDarkThemeWithObj } from "../Utils";

const PREFIX = "CustomForm";

export const classes = {
	alertActionButton: `${PREFIX}-alertActionButton`,
	alertError: `${PREFIX}-alertError`,
	cancelButton: `${PREFIX}-cancelButton`,
	formButton: `${PREFIX}-formButton`,
	pickupDeliveryButton: `${PREFIX}-pickupDeliveryButton`,
	radioButtonGroup: `${PREFIX}-radioButtonGroup`,
	switchFormControlLabelPlacement: `${PREFIX}-switchFormControlLabelPlacement`
};

export const CustomFormStyled = (theme) => {
	const p = theme.palette;
	const isDarkTheme = isDarkThemeWithObj(theme);

	return {
		[`&.${classes.alertActionButton}`]: {
			color: "#FFF !important",
			paddingTop: 2,
			"& .MuiSvgIcon-root": { color: "#FFF" },
			"& .MuiButtonBase-root.MuiIconButton-root": {
				"&:hover": {
					background: "#00000057",
					border: "1px solid #FFF",
					color: "#FFF !important"
				},
				"&:focus": {
					background: "#00000057",
					border: "2px solid #FFF",
					color: "#FFF !important"
				}
			}
		},
		[`&.${classes.alertError}`]: {
			marginTop: 0,
			marginBottom: 0,
			color: "#FFF",
			background: p.error.main,
			"& .MuiAlert-icon": {
				fontSize: "1.2rem",
				alignItems: "center"
			},
			"& .MuiAlert-message": {
				fontWeight: 400
			}
		},
		[`&.${classes.formButton}`]: {
			padding: theme.spacing(1.5, 0.5),
			marginBottom: 0,
			lineHeight: "normal"
		},

		[`&.${classes.cancelButton}`]: {
			color: p.text.secondary,
			border: "1px solid " + p.divider,
			"&:hover": {
				color: p.text.primary,
				background: p.background.selectBackgroundHover
			},
			"&.Mui-focusVisible": {
				color: p.text.primary,
				background: p.background.selectBackgroundHover
			}
		},
		[`&.${classes.pickupDeliveryButton}`]: {
			width: 110,
			height: 80
		},
		[`&.${classes.radioButtonGroup}`]: {
			flexWrap: "nowrap",
			overflowY: "auto"
		},
		[`& .${classes.switchFormControlLabelPlacement}`]: {
			width: "100%",
			float: "left",
			marginLeft: 0,
			marginRight: 0
		}
	};
};

const CustomFormStyles = (theme) => {
	return {
		textfieldInput: { width: "100%", marginBottom: 0, marginTop: 0 },
		textfieldInputSave: { width: "50%", marginBottom: 0, marginTop: 0 },

		switchFormControlLabelPlacement: {
			width: "100%",
			float: "left",
			marginLeft: 0,
			marginRight: 0
		}
	};
};

export default CustomFormStyles;
