import FastfoodIcon from "@mui/icons-material/Fastfood";
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";

import { getItemFromItemId } from "../../store/CartUtils";
import { classes } from "../../theme/GlobalStyle";
import ConstantsLabels from "../../utils/ConstantsLabels";
import EnvConfig from "../../utils/EnvConfig";
import useCommonData from "../../utils/hooks/useCommonData";

import CustomGridContainer from "../custom/CustomGridContainer";

const CheckoutSectionBreakdownUpSell = ({ server }) => {
	const navigate = useNavigate();
	const { campusID, locationID, menu } = useCommonData();
	const { upsell_itemid, upsell_message, upsell_variantid, upsell_upsellid } = server.cart;

	if ((!upsell_itemid && !upsell_variantid && !upsell_upsellid) || !menu) {
		return null;
	}

	const upSellMenuItem = getItemFromItemId(upsell_itemid, menu);
	const { name, icon_picture_url } = upSellMenuItem;

	const handleAddUpSellItem = () => {
		const { itemid, sectionid } = upSellMenuItem;
		navigate(`/${campusID}/${locationID}/${sectionid}/${itemid}`, { state: { upsell_variantid, upsell_upsellid } });
	};

	const iconUrl = icon_picture_url ? EnvConfig.baseImageURL + icon_picture_url : "";

	return (
		<>
			<Divider role="presentation" variant="middle" />
			<CustomGridContainer
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={clsx(classes.fullHeight, classes.checkoutUpSellGrid)}
				spacing={1}
			>
				<Grid item xs="auto">
					{iconUrl ? (
						<Avatar color="primary" srcSet={iconUrl} alt={name} className={classes.iconAvatar} />
					) : (
						<Box className={classes.iconSpacingLarge}>
							<FastfoodIcon className={classes.iconVerticalAlign} />
						</Box>
					)}
				</Grid>
				<Grid item xs className={classes.customLabelItemListButtonDivider}>
					<Stack direction={"column"}>
						<Typography id="id_upsell_message" variant={"subtitle2"} variantMapping={{ subtitle2: "span" }}>
							{upsell_message}
						</Typography>
						<Typography id="id_upsell_name" variant={"body1"} variantMapping={{ body1: "span" }}>
							{name}
						</Typography>
					</Stack>
				</Grid>

				<Grid item xs="auto">
					<Button
						aria-labelledby="id_upsell_message id_upsell_button_add id_upsell_name"
						variant={"outlined"}
						onClick={handleAddUpSellItem}
					>
						<Typography id="id_upsell_button_add">{ConstantsLabels.labelAdd}</Typography>
					</Button>
				</Grid>
			</CustomGridContainer>
		</>
	);
};

export default CheckoutSectionBreakdownUpSell;
