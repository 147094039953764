import React from "react";
import { styled } from "@mui/material/styles";
import { classes, CustomFormStyled } from "./CustomFormStyles";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const RootAlert = styled(Alert)(({ theme }) => CustomFormStyled(theme));

const CustomFormErrorAlert = ({ setFormErrorMessage, formErrorMessage, theID, marginTop, marginBottom, width, margin }) => {
	const theStyle = { marginTop, marginBottom, width: width, margin: margin };

	if (!formErrorMessage || formErrorMessage.length === 0) return <></>;

	return (
		<RootAlert
			classes={{ root: classes.alertError, action: classes.alertActionButton }}
			style={theStyle}
			id={theID}
			severity="error"
			variant="filled"
			onClose={() => {}}
			onClick={(e) => {
				e && e.preventDefault();
				setFormErrorMessage("");
			}}
		>
			<Typography variant="body2" display="block">
				{formErrorMessage}
			</Typography>
		</RootAlert>
	);
};

export default CustomFormErrorAlert;
