import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import ReportWebVitals from "./utils/ReportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store, Persistor } from "./store/Store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./utils/components/Loading";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={Store}>
		<PersistGate loading={<Loading />} persistor={Persistor}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

// TODO - added this when building the push notifications stuff.  May not be needed - after thigns have been deployed and tested, if things are still working, we can remove this and serviceWorkerRegistration.js
// serviceWorkerRegistration.register();
ReportWebVitals();
