import CreditCardIcon from "@mui/icons-material/CreditCard";
import LinkIcon from "@mui/icons-material/Link";
import List from "@mui/material/List";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import { classes } from "../../theme/GlobalStyle";
import { getCurrencyString, getCreditCardType, getPaymentTypesArray, setRadioButtonSelected } from "../../utils/Utils";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import ApiEndpoints from "../../api/ApiEndpoints";
import CheckoutSectionTendersModalForm from "./CheckoutSectionTendersModalForm";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CustomFormModalRadioButtonGroup from "../../utils/forms/CustomFormModalRadioButtonGroup";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import FeatureConstants from "../../utils/FeatureConstants";

const CASH_EQ_SWIPES_REF = 3;
const SECONDARY_TENDER_REF = 3;

const CheckoutSectionTenders = ({
	campus,
	location,
	locationid,
	menu,
	user,
	ctId,
	ctId2,
	doCalculateCart,
	doApiCall,
	serverCart,
	openTenderModal,
	doUpdateCartTender
}) => {
	const refs = useRef([]);
	const { mealplan_name, creditcard_payments_allowed, creditcard_processor, mealplan_payments_allowed } = campus;
	const { cash_eq_swipes, cash_eq_total, meal_ex_total, tender2_subtotal, subtotal } = serverCart;

	const [paymentTypes, setPaymentTypes] = useState([]);
	const [selectedPaymentType, setSelectedPaymentType] = useState({});
	const [campusTenders, setCampusTenders] = useState([]);
	const [selectedCampusTender, setSelectedCampusTender] = useState({});
	const [campusTenderName, setCampusTenderName] = useState("");

	const [cashEqDataArray, setCashEqDataArray] = useState([]);

	const [secondaryCampusTenders, setSecondaryCampusTenders] = useState([]);
	const [secondarySelectedCampusTender, setSecondarySelectedCampusTender] = useState({});

	const [showSecondaryTender, setShowSecondaryTender] = useState(false);

	const hideTenderSelectIfOnlyOne = hasFeatureFlag(campus, FeatureConstants.FEA_CHECKOUT_HIDETENDERSELECT_IFONLYONE);

	const hidePaymentMethod =
		hasFeatureFlag(campus, FeatureConstants.FEA_CHECKOUT_HIDEPAYMENTMETHOD) &&
		user?.has_mealplancard === 1 &&
		user?.payment_method_default === 0;

	const hideSecondaryTenderCreditCard =
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_DISABLE_SECONDARY_TENDER_CREDITCARD) ||
		hasFeatureFlag(campus, FeatureConstants.FEA_DISABLE_SECONDARY_TENDER_CREDITCARD);

	const creditCardPaymentsAllowed =
		creditcard_payments_allowed === 1 && (creditcard_processor === Constants.paymentExpress || creditcard_processor === Constants.freedomPay);
	const mealPlanPaymentsAllowed = mealplan_payments_allowed === 1;

	const handleOnChangePaymentType = (paymentMethodDefault) => {
		const body = {
			card_slot: paymentMethodDefault.toString()
		};

		doApiCall(
			ApiEndpoints.ChangeDefaultPaymentMethod,
			body,
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {},
			(errorMsg, errorReason) => {}
		);
	};

	useEffect(() => {
		const paymentTypesArray = getPaymentTypesArray(user, mealplan_name, creditCardPaymentsAllowed, mealPlanPaymentsAllowed);
		setSelectedPaymentType(paymentTypesArray.find((t) => t.selected));
		setPaymentTypes(paymentTypesArray);

		let _campusTenders = menu.tenders.filter(
			(t) => (t.locationid === 0 || t.locationid === locationid) && t.is_hidden === 0 && t.currently_available === 1
		);
		const _ctId = ctId && ctId !== "0" ? ctId : _campusTenders[0]?.ct_id || "0";
		const { selectedArray, selectedObject } = setRadioButtonSelected(_campusTenders, "ct_id", _ctId);

		setCampusTenders(selectedArray);
		setSelectedCampusTender(selectedObject);
		setCampusTenderName(selectedObject.name);

		setShowSecondaryTender((selectedObject?.casheq === 1 || selectedObject?.mealexchange === 1) && tender2_subtotal > 0);

		let _secondaryCampusTenders = _campusTenders.filter((t) => t.swipe === 0);
		if (!hideSecondaryTenderCreditCard) {
			_secondaryCampusTenders.push(...paymentTypesArray.filter((pt) => pt.ct_id !== 0));
		}
		const _ctId2 = ctId2 !== "0" ? ctId2 : _secondaryCampusTenders[0]?.ct_id || "0";
		const { selectedArray: secondaryArray, selectedObject: secondaryObject } = setRadioButtonSelected(_secondaryCampusTenders, "ct_id", _ctId2);
		setSecondaryCampusTenders(secondaryArray);
		setSecondarySelectedCampusTender(secondaryObject);

		if (ctId === "0" && selectedArray.length > 0) {
			doUpdateCartTender(locationid, selectedArray[0].ct_id, _ctId2);
		}
	}, [user]);

	useEffect(() => {
		if (selectedPaymentType?.paymentTypeId && selectedPaymentType.paymentTypeId !== user.payment_method_default) {
			handleOnChangePaymentType(selectedPaymentType.paymentTypeId);
		}
		const _cashEqDataArray = [];
		for (let i = 1; i <= selectedCampusTender.cash_eq_max_swipes; i++) {
			if (selectedCampusTender.cash_eq_value * (i - 1) <= subtotal) {
				_cashEqDataArray.push({ label: i, id: i, selected: i === 1 });
			}
		}
		setCashEqDataArray(_cashEqDataArray);
	}, [selectedCampusTender]);

	const updateCartSelectedTender = (callbackOnSave) => {
		const { selectedPaymentTypeModal, selectedCampusTenderModal } = refs.current[6].returnSelectedData();
		setCampusTenders(setRadioButtonSelected(campusTenders, "ct_id", selectedCampusTenderModal.ct_id).selectedArray);
		setSelectedPaymentType(selectedPaymentTypeModal);
		setSelectedCampusTender(selectedCampusTenderModal);
		setCampusTenderName(selectedCampusTenderModal.name);
		doCalculateCart({ tender: selectedCampusTenderModal, ct_id2: secondarySelectedCampusTender.ct_id });
		callbackOnSave && callbackOnSave();
	};

	const updateCartCashEq = (callbackOnSave) => {
		const cashEqSwipes = refs.current[CASH_EQ_SWIPES_REF].returnSelectedData();
		doCalculateCart({ tender: selectedCampusTender, cash_eq_swipes: cashEqSwipes.id, ct_id2: secondarySelectedCampusTender.ct_id });
		setCashEqDataArray(setRadioButtonSelected(cashEqDataArray, "id", cashEqSwipes.id).selectedArray);
		callbackOnSave();
	};

	const updateSecondaryTender = (callbackOnSave) => {
		const secondaryTender = refs.current[SECONDARY_TENDER_REF].returnSelectedData();
		doCalculateCart({ tender: selectedCampusTender, cash_eq_swipes, ct_id2: secondaryTender.ct_id });
		setSecondarySelectedCampusTender(secondaryTender);
		callbackOnSave();
	};

	let rightText = undefined;
	if (selectedCampusTender.casheq === 1) {
		rightText = cash_eq_total === 0 ? undefined : getCurrencyString(cash_eq_total);
	} else if (selectedCampusTender.mealexchange === 1) {
		rightText =
			meal_ex_total === 0 ? undefined : (
				<>
					<LinkIcon fontSize="small" className={classes.iconVerticalAlign} /> {getCurrencyString(meal_ex_total)}
				</>
			);
	}

	return (
		<CustomPaperContainer>
			<List className={classes.listTransparentNoBorder} disablePadding>
				<CustomFormLabelItem
					dense
					Icon={CreditCardIcon}
					IconProps={{ alt: selectedPaymentType?.ccType || "Add a payment method" }}
					label={
						`${
							!_.isEmpty(selectedPaymentType) && getCreditCardType(selectedPaymentType?.ccTypeFirst2Digits) !== ""
								? `${selectedPaymentType?.name}`
								: ""
						}` || "Please add a payment method before making a purchase."
					}
					subLabel={selectedPaymentType?.isCampusCard && campusTenderName !== "" && campusTenderName}
					rightDisplayText={rightText && rightText}
					showModal={openTenderModal}
					modalForm={CheckoutSectionTendersModalForm}
					useCustomModal2
					modalFormProps={{
						modalFormRef: refs,
						doApiCall: doApiCall,
						paymentTypes: paymentTypes,
						selectedPaymentType: selectedPaymentType,
						campusTenders: campusTenders,
						selectedCampusTender: selectedCampusTender,
						creditCardPaymentsAllowed: creditCardPaymentsAllowed,
						mealPlanPaymentsAllowed: mealPlanPaymentsAllowed,
						hidePaymentMethod: hidePaymentMethod,
						hideTenderSelectIfOnlyOne: hideTenderSelectIfOnlyOne
					}}
					modalProps={{
						buttonText: "OK",
						closeModalOnButtonClick: true,
						modalLabel: hidePaymentMethod ? ConstantsLabels.labelCheckoutSelectTender : ConstantsLabels.labelPaymentMethods,
						onButtonClick: updateCartSelectedTender,
						onClickCloseButton: updateCartSelectedTender,
						preventOutsideClick: true,
						showButton: true,
						title: hidePaymentMethod ? ConstantsLabels.labelCheckoutSelectTender : ConstantsLabels.labelPaymentMethods,
						titlePaddingBottom: 0
					}}
				/>
				{selectedPaymentType?.isCampusCard &&
					selectedCampusTender?.casheq === 1 &&
					selectedCampusTender?.cash_eq_max_swipes > 1 &&
					cashEqDataArray.length !== 1 && (
						<CustomFormLabelItem
							dense
							Icon={CreditCardIcon}
							IconProps={{ alt: "Cash Eq" }}
							label={`Number of ${campusTenderName}`}
							rightDisplayText={cash_eq_swipes !== 0 && cash_eq_swipes}
							hideDivider={!showSecondaryTender}
							modalForm={CustomFormModalRadioButtonGroup}
							useCustomModal2
							modalFormProps={{
								modalFormRef: refs,
								dataArray: cashEqDataArray,
								refIdNumber: SECONDARY_TENDER_REF,
								ariaLabel: "cash_eq",
								radioLabel: "label",
								radioId: "id"
							}}
							modalProps={{
								buttonText: "Save",
								closeModalOnButtonClick: true,
								modalLabel: `Number of ${campusTenderName}`,
								onButtonClick: updateCartCashEq,
								preventOutsideClick: true,
								showButton: true,
								title: `Number of ${campusTenderName}`
							}}
						/>
					)}
				{showSecondaryTender && (
					<CustomFormLabelItem
						dense
						Icon={CreditCardIcon}
						IconProps={{ alt: "Secondary Tender" }}
						label={secondarySelectedCampusTender.name}
						rightDisplayText={getCurrencyString(tender2_subtotal)}
						modalForm={CustomFormModalRadioButtonGroup}
						hideDivider
						useCustomModal2
						modalFormProps={{
							modalFormRef: refs,
							dataArray: secondaryCampusTenders,
							refIdNumber: SECONDARY_TENDER_REF,
							ariaLabel: "secondary-tender",
							radioLabel: "name",
							radioId: "ct_id"
						}}
						modalProps={{
							buttonText: "Save",
							closeModalOnButtonClick: true,
							modalLabel: "Select Secondary Tender",
							onButtonClick: updateSecondaryTender,
							preventOutsideClick: true,
							showButton: true,
							title: "Select Secondary Tender"
						}}
					/>
				)}
			</List>
		</CustomPaperContainer>
	);
};

export default CheckoutSectionTenders;
