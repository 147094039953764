import _ from "lodash";
import { Divider, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useEffect, useState } from "react";

import { classes } from "../../theme/GlobalStyle";
import { enqueueSnackbar } from "../../store/Actions";
import { getPaymentTypesArray } from "../../utils/Utils";
import { isWebOrderLocal } from "../../utils/EnvUtils";
import ApiEndpoints from "../../api/ApiEndpoints";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomGridContainer from "../custom/CustomGridContainer";
import CustomModal2 from "../modals/CustomModal2";
import Loading from "../../utils/components/Loading";
import useCommonData from "../../utils/hooks/useCommonData";

const PaymentMethodModalForm = ({ doApiCall, isShowing, setIsShowing }) => {
	const routerLocation = useLocation();
	const { campus, user } = useCommonData();
	const dispatch = useDispatch();

	const { mealplan_name, creditcard_payments_allowed, creditcard_processor, mealplan_payments_allowed } = campus || {};

	const creditCardPaymentsAllowed =
		creditcard_payments_allowed === 1 && (creditcard_processor === Constants.paymentExpress || creditcard_processor === Constants.freedomPay);

	const mealPlanPaymentsAllowed = mealplan_payments_allowed === 1;

	const [paymentTypes, setPaymentTypes] = useState(() =>
		user ? getPaymentTypesArray(user, mealplan_name, creditCardPaymentsAllowed, mealPlanPaymentsAllowed) : [] || []
	);

	const [deleteCardPaymentType, setDeleteCardPaymentType] = useState({});
	const [isConfirmDeleteCardVisible, setIsConfirmDeleteCardVisible] = useState(false);
	const [busy, setBusy] = useState(false);

	const creditCardCount = paymentTypes.filter((pt) => pt.paymentTypeId !== 0).length;

	useEffect(() => {
		if (user) {
			setPaymentTypes(getPaymentTypesArray(user, mealplan_name, creditCardPaymentsAllowed, mealPlanPaymentsAllowed));
		}
	}, [user]);

	const displayErrorMessage = (errorMsg, errorReason) => {
		dispatch(
			enqueueSnackbar({
				message: errorMsg,
				title: errorReason,
				excludeFromHistory: true,
				options: { variant: "error" }
			})
		);
	};

	const handleOnChangePaymentType = (e) => {
		const body = {
			card_slot: e.target.value
		};

		doApiCall(
			ApiEndpoints.ChangeDefaultPaymentMethod,
			body,
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	const handleAddNewCard = () => {
		doApiCall(
			ApiEndpoints.AddCreditCardGetUrl,
			{
				redirect_url: `${routerLocation.pathname}/addcard`,
				is_local: isWebOrderLocal() ? 1 : 0
			},
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				if (data.payment_url !== "") {
					window.location.href = data.payment_url;
				} else {
					displayErrorMessage("Error adding new card. Please try again later.", "ERROR_ADDING_NEW_CARD");
				}
			},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	const handleClickDeleteCard = (paymentType) => {
		setDeleteCardPaymentType(paymentType);
		setIsConfirmDeleteCardVisible(true);
	};

	const handleCancelDeleteCard = () => {
		setIsConfirmDeleteCardVisible(false);
	};

	const deleteCard = () => {
		const body = {
			card_slot: deleteCardPaymentType.paymentTypeId.toString()
		};

		doApiCall(
			ApiEndpoints.RemovePaymentMethod,
			body,
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				setIsConfirmDeleteCardVisible(false);
			},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	return (
		<CustomModal2
			buttonText={"OK"}
			setIsShowing={setIsShowing}
			isShowing={isShowing}
			closeModalOnButtonClick={true}
			modalLabel={ConstantsLabels.labelPaymentMethods}
			onButtonClick={() => {
				setIsShowing(false);
			}}
			preventOutsideClick={true}
			showButton={true}
			title={ConstantsLabels.labelPaymentMethods}
		>
			<CustomGridContainer
				direction="column"
				justifyContent="space-evenly"
				alignItems="stretch"
				spacing={2}
				className={clsx(classes.checkoutSectionTenderModalDiv)}
			>
				<Grid item>
					<RadioGroup aria-labelledby={`tender-radio-buttons-group`} name={`tender-radio-buttons-group`}>
						{paymentTypes?.map((paymentType) => {
							return (
								<Stack
									direction={"row"}
									alignItems="center"
									paddingLeft={1.5}
									justifyContent={"space-between"}
									key={paymentType.paymentTypeId}
								>
									<FormControlLabel
										control={
											<Radio
												value={paymentType.paymentTypeId}
												checked={paymentType.selected}
												onChange={(e) => handleOnChangePaymentType(e)}
											/>
										}
										label={paymentType.name}
									/>
									{paymentType.paymentTypeId !== 0 && (
										<Tooltip
											aria-hidden="true"
											title={ConstantsLabels.labelButtonDeleteCreditCard}
											disableFocusListener
											id={`tooltip-Close${ConstantsLabels.labelButtonDeleteCreditCard}`}
										>
											<IconButton
												aria-hidden="false"
												role={"button"}
												aria-label={`Delete ${paymentType.name} `}
												onClick={() => handleClickDeleteCard(paymentType)}
												// aria-describedby={`tooltip-${ConstantsLabels.labelButtonDeleteCreditCard}`}
											>
												<DeleteForeverIcon fontSize="small" />
											</IconButton>
										</Tooltip>
									)}
								</Stack>
							);
						})}
					</RadioGroup>
				</Grid>

				{paymentTypes.length === 0 && (
					<Grid item xs="auto">
						<Typography variant="body1" align="center" pb={2}>
							You currently don't have any Payment Cards on file.
						</Typography>
					</Grid>
				)}

				{creditCardCount < 2 &&
					creditCardPaymentsAllowed && [
						<Grid item key="gi-add-credit-card">
							<CustomFormButton
								variant="text"
								onClick={handleAddNewCard}
								color="secondary"
								size="medium"
								startIcon={<AddIcon />}
								buttonText={"Add Credit Card"}
								disableDefaultStyle
								key={"add-credit-card-button"}
								className={clsx(classes.widthAuto)}
							/>
						</Grid>
					]}
				<Loading open={busy} />
				<ConfirmAlertDialog
					isVisible={isConfirmDeleteCardVisible}
					setIsVisible={setIsConfirmDeleteCardVisible}
					title={"Remove Card"}
					description={`Are you sure you want to unlink your ${deleteCardPaymentType.name} from the App?`}
					confirmButtonText={"Remove"}
					cancelCallback={handleCancelDeleteCard}
					confirmCallback={deleteCard}
					confirmDanger
				/>
			</CustomGridContainer>
		</CustomModal2>
	);
};

export default PaymentMethodModalForm;
