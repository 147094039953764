import Axios from "axios";
import * as Actions from "../store/Actions";
import EnvConfig from "../utils/EnvConfig";
import { addDeviceInfoToBody, getHashedChecksumForApiCall, LogAPI, LogAPIBody, LogTime, toTitleCaseFiltered } from "../utils/Utils";
import ApiEndpoints from "./ApiEndpoints";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Constants from "../utils/Constants";
import { readCookie, removeCookie } from "../utils/CookieUtils";
import { deletePushToken } from "../utils/firebase/fb-pushNotification";

// Registers dayJs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const statusNotFound = "Api call did not return a status.";

//this is for our calls to our backend. customapi is their calls to thier own custom endpoints from a widget / page / global
const ApiMiddleware = (store) => (next) => (action) => {
	next(action);
	if (action.type !== Actions.API) return;

	const storeState = store.getState();
	const dispatch = store.dispatch;
	const { pushToken, user } = storeState.userData;
	const { campus } = storeState.campusData;
	const { rememberMe } = storeState.settingsData;

	const { apiEndpoint, body, onSuccess, onFailure, setBusy, extraProps } = action.payload;

	const finalURL = EnvConfig.apiBaseURL + apiEndpoint;

	const campusID = user?.campusid ? user.campusid : campus?.campusid;

	let theHeaders = getHeaders(apiEndpoint, user?.login_token, user?.userid, campusID, rememberMe, body);
	let theBody = body;

	if (extraProps.addDeviceInfoToBody) {
		addDeviceInfoToBody(theBody, campus?.brand_ios_bundle_name, pushToken);
	}

	extraProps && extraProps.logRequest && LogAPI("*** ApiCall Url: " + finalURL);
	extraProps && extraProps.logRequest && LogAPIBody(theBody);
	setBusy && setBusy(true);

	const showErrorToast = extraProps && !extraProps.hideErrorToast; //error toast shown by default, pass hideErrorToast prop (use this when using formError alert instead)
	const showSuccessToast = extraProps && extraProps.successMessage; //if pass a success message than show it
	const toastTitle = extraProps && extraProps.toastTitle ? toTitleCaseFiltered(extraProps.toastTitle) : "";
	const setFormErrorMessage = extraProps && extraProps.setFormErrorMessage ? extraProps.setFormErrorMessage : () => {};
	const isDelete = extraProps && extraProps.isDelete; //used when the api calling view ends up getting unmounted & setBusy is no longer accessible

	const timeLogText = "ApiCall Time: " + apiEndpoint + " | " + Date.now();
	LogTime(timeLogText);

	Axios.post(finalURL, theBody, {
		headers: theHeaders
	})
		.then(({ data }) => {
			LogTime(timeLogText, true);
			extraProps && extraProps.logResponse && LogAPIBody(data);

			if (!data || !data.status) {
				showErrorToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: statusNotFound,
							title: toastTitle,
							excludeFromHistory: true,
							options: { variant: "error" }
						})
					);
				onFailure && onFailure(statusNotFound, "SERVER");
				isDelete && setBusy && setBusy(false);
				setFormErrorMessage(statusNotFound);
				LogAPI(statusNotFound);
			} else if (data.status === "OK") {
				if (apiEndpoint === ApiEndpoints.Logout || apiEndpoint === ApiEndpoints.LogoutAndDelete) {
					dispatch(Actions.logoutUser());
					dispatch(Actions.clearAllCarts());
					removeCookie(Constants.onLaunchCookieName);
					deletePushToken();
					onSuccess && onSuccess(data);
					return;
				}

				if (apiEndpoint === ApiEndpoints.CalculateCart) {
					dispatch(Actions.updateCartFromServer(data));
				}

				data.user && dispatch(Actions.updateUser(data.user));
				data.orders && dispatch(Actions.updateOrders(data.orders));
				data.pending_orders && dispatch(Actions.updatePendingOrders(data.pending_orders));
				data.messages && dispatch(Actions.updateInboxMessages(data.messages));
				data.campus && dispatch(Actions.updateCampus(data.campus));
				data.locations && data.cafeterias && data.cafeteria_groups && dispatch(Actions.updateLocationAndCafeteriaArrays(data));
				data.location && dispatch(Actions.updateLocation(data.location));
				data.menu && dispatch(Actions.updateMenu(data.menu));

				data.menu_date_slots && data.menu_time_slots && dispatch(Actions.updateLocationSlots(data));

				showSuccessToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: extraProps.successMessage,
							title: toastTitle,
							options: { variant: "success" }
						})
					);
				onSuccess && onSuccess(data); //callback function
			} else if (data.status === "ERROR") {
				if (data.reason && data.reason.includes("TOKEN")) dispatch(Actions.logoutUser());
				showErrorToast &&
					dispatch(
						Actions.enqueueSnackbar({
							message: data.message,
							title: toastTitle,
							excludeFromHistory: true,
							options: { variant: "error" }
						})
					);
				onFailure && onFailure(data.message, data.reason, data.additional_details);
				isDelete && setBusy && setBusy(false);
				setFormErrorMessage(data.message);
				LogAPI(`REASON: ${data?.reason}   -   MESSAGE: ${data.message}`);
			}
		})
		.catch((error) => {
			const errorMessage = error && error.message ? error.message : "API Server Error.";
			const errorReason = error && error.reason ? error.reason : "";
			LogAPI(error);
			LogTime(timeLogText, true);
			showErrorToast &&
				dispatch(
					Actions.enqueueSnackbar({
						message: errorMessage,
						title: toastTitle,
						excludeFromHistory: true,
						options: { variant: "error" }
					})
				);
			onFailure && onFailure(errorMessage, errorReason); //callback function
			isDelete && setBusy && setBusy(false);
			setFormErrorMessage(errorMessage);
		})
		.finally(() => {
			if (!isDelete && setBusy) setBusy(false);
		});
};

const getHeaders = (endpointName, login_token_web, input_userid, input_campusid, rememberMe, body) => {
	//use the one from request body if we pass it
	const campusID = body?.campusid ?? input_campusid;
	const locationID = body?.locationid ?? 0;

	const theUUIDCookie = readCookie(Constants.uuidCookieName) || "";
	//const theUUIDLocal = localStorage.getItem(Constants.uuidCookieName) || "";
	//const theUUID = theUUIDCookie.length > 0 ? theUUIDCookie : theUUIDLocal;

	const hashChecksum = getHashedChecksumForApiCall(
		endpointName + theUUIDCookie + (campusID || 0) + (locationID || 0) + (input_userid || 0) + (login_token_web || ""),
		Constants.loginHashKey
	);

	return {
		"Content-Type": "application/json",
		login_token_web: "" + (login_token_web ?? ""),
		userid: "" + (input_userid ?? 0),
		campusid: "" + (campusID ?? 0),
		locationid: "" + (locationID ?? 0),
		uuid: theUUIDCookie,
		hash: hashChecksum,
		rememberme: rememberMe ? "ON" : "OFF"
	};
};

export default ApiMiddleware;
