import React from "react";

import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Typography } from "@mui/material";

export default function CustomFormPagination({
	ariaLabelId,
	currentIndex,
	handleClickFirst,
	handleClickLast,
	handleClickNext,
	handleClickPrevious,
	hideCount,
	paginationArrayLength = 0
}) {
	return (
		<ButtonGroup variant="contained">
			<IconButton
				role={"button"}
				aria-label={`goto-first-${ariaLabelId}`}
				size="small"
				disabled={currentIndex === 0}
				onClick={handleClickFirst}
			>
				<FirstPageIcon />
			</IconButton>
			<IconButton
				role={"button"}
				aria-label={`goto-previous-${ariaLabelId}`}
				size="small"
				disabled={currentIndex === 0}
				onClick={handleClickPrevious}
			>
				<ChevronLeftIcon />
			</IconButton>

			{!hideCount && (
				<Box sx={{ p: 1 }}>
					<Typography variant="body2"> {`${currentIndex + 1}/${paginationArrayLength}`}</Typography>
				</Box>
			)}

			<IconButton
				role={"button"}
				aria-label={`goto-next-${ariaLabelId}`}
				size="small"
				disabled={currentIndex === paginationArrayLength - 1}
				onClick={handleClickNext}
			>
				<ChevronRightIcon />
			</IconButton>
			<IconButton
				role={"button"}
				aria-label={`goto-last-${ariaLabelId}`}
				size="small"
				disabled={currentIndex === paginationArrayLength - 1}
				onClick={handleClickLast}
			>
				<LastPageIcon />
			</IconButton>
		</ButtonGroup>
	);
}
