import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import clsx from "clsx";
import React, { forwardRef } from "react";

import { classes, GlobalStyle } from "../../theme/GlobalStyle";

const RootPaper = styled(Paper)(({ theme }) => GlobalStyle(theme));

const CustomPaperContainer = forwardRef((props, ref) => {
	const { children, elevation = 0, variant = "outlined", rounded = false, className, styleClasses = [], ...other } = props;
	return (
		<RootPaper elevation={elevation} variant={variant} square={!rounded} className={clsx(className, [...styleClasses])} {...other} ref={ref}>
			{children}
		</RootPaper>
	);
});

export default CustomPaperContainer;
