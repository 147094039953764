import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {isEmpty} from "lodash";

import { defaultMapStateToProps, defaultActionsAndCartActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import Constants from "../utils/Constants";
import usePushNotification from "../utils/firebase/fb-pushNotification";
import useCommonApiCalls from "../utils/hooks/useCommonApiCalls";
import useCommonData from "../utils/hooks/useCommonData";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import CustomPaperContainer from "../views/custom/CustomPaperContainer";
import TransactionItem from "../views/transactions/TransactionItem";
import CustomModal2 from "../views/modals/CustomModal2";
import { Divider, Stack, Typography } from "@mui/material";

const ConfirmationPage = ({ doClearAllCartItems }) => {
	const navigate = useNavigate();
	const { getPastOrders, getUser } = useCommonApiCalls();
	const { campus, campusID, locationID, locations, user } = useCommonData();
	const { requestNotificationPermission } = usePushNotification();

	const [searchParams] = useSearchParams();
	const orderid = searchParams.get("orderid");
	const [order, setOrder] = useState({});

	const [isShowingNotificationDialog, setIsShowingNotificationDialog] = useState(false);

	useEffect(() => {
		doClearAllCartItems(locationID);
		getUser(); //Update user object to reflect new last_order
		getPastOrders();
		// default means it hasn't been granted or denied yet
		const NotificationIsSupported = !!(
			(
				window.Notification /* W3C Specification */ ||
				window.webkitNotifications /* old WebKit Browsers */ ||
				navigator.mozNotification
			) /* Firefox for Android and Firefox OS */
		);
		if (NotificationIsSupported && Notification?.permission === "default") {
			setIsShowingNotificationDialog(true);
		}
	}, []);

	useEffect(() => {
		if (!user?.last_order || String(user.last_order.orderid) !== orderid )  {
			navigate(`/${campusID}`, { replace: true });
		}
		setOrder(user.last_order);
	}, [user]);

	const onClickEnable = () => {
		setIsShowingNotificationDialog(false);
		requestNotificationPermission(orderid);
	};

	if (isEmpty(order)) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!campus || !locations}>
			<CustomPaperContainer styleClasses={[classes.contentRootDiv]}>
				<TransactionItem appLocation={Constants.APP_LOCATION.CONFIRMATION_PAGE} orderid={orderid} currentOrder={order} confirmation />
			</CustomPaperContainer>
			<CustomModal2
				isShowing={isShowingNotificationDialog}
				setIsShowing={setIsShowingNotificationDialog}
				title={"Enable push notifications"}
				buttonText={"ENABLE"}
				showButton
				onButtonClick={onClickEnable}
				preventOutsideClick
				titlePaddingBottom={1}
			>
				<Stack spacing={3} pb={2}>
					<Divider role="presentation" orientation="horizontal" variant="fullWidth" flexItem />
					<Typography pl={3} pr={3}>
						We send push messages to let you know about important updates, such as when your order is ready.
					</Typography>
					<Divider role="presentation" orientation="horizontal" variant="fullWidth" flexItem />
				</Stack>
			</CustomModal2>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(ConfirmationPage);
