
export const regExValidator = {
	alpha: /[a-zA-Z]/gi,
	alphaNumeric: /^[a-zA-Z0-9.-]+$/gi,
	decimal: /^[0-9]+\.?[0-9]*$/gi,
	phone: /^1?[\(\- ]*\d{3}[\)-\. ]*\d{3}[-\. ]*\d{4}$/gi, // \(?(\d{3})\)?[-\.\s]?(\d{3})[-\.\s]?(\d{4}) 
	email: /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi, // (\w\.?)+@[\w\.-]+\.\w{2,} or /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
	zipCode: /^\d{5}(-\d{4})?$/gi,
	postalCode: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/gi,
	hex: /^#?([a-f0-9]{6}|[a-f0-9]{3})$/gi,
	wholeNumbers: /^\d+$/gi,
	decimalNumbers: /^\d*\.\d+$/gi,
	wholeDecimalNumbers: /^\d*(\.\d+)?$/gi,
	negativePositiveWholeDecimalNumbers: /^-?\d*(\.\d+)?$/gi,
	wholeDecimalFractions: /[-]?[0-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/gi,
	alphanumericWithoutSpace: /^[a-zA-Z0-9]*$/gi,
	alphanumericWithSpace: /^[a-zA-Z0-9 ]*$/gi,
	commonEmail: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/gi,
	uncommonEmail: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/gi,
	passwordComplex: `/(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~\`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/`, // Complex: Should have 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be at least 8 characters long
	passwordModerate: /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/gi, // Should have 1 lowercase letter, 1 uppercase letter, 1 number, and be at least 8 characters long
	username: /^[a-z0-9_-]{3,16}$/gi, // Alphanumeric string that may include _ and – having a length of 3 to 16 characters
	// userName: example /^[a-zA-Z0-9_-]{3,16}$/' alphanumierc with under or dash 3-16 chars
	// password: example /^[a-z0-9_-]{6,18}$/' lowercase alphanumeric with under or dash 6-18chars
	// url: example /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/'
	time12hr: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/gi, // Time Format HH:MM 12-hour, optional leading 0
	time12hrAMPM: /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/gi, // Time Format HH:MM 12-hour, optional leading 0, Meridiems (AM/PM)
	time24hrLeading0: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gi, // Time Format HH:MM 24-hour with leading 0
	time24hrOptLeading0: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gi, // Time Format HH:MM 24-hour, optional leading 0
	time24hrSeconds: /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/gi, // Time Format HH:MM:SS 24-hour
    unsafeChars: /[^a-z0-9 ._+:!@#$%^&*\[\]()\|`~?,'\"\\\/-]/gi, 
}