import React, { useState } from "react";
import { Log, getScrollClassName } from "../Utils";
import Constants from "../Constants";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import CustomFormStyles from "./CustomFormStyles";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => {
	return CustomFormStyles(theme);
});

const OptionCell = ({ props, option, selected, optionsArray, classes, smallFont, tightCellPadding }) => {
	const firstObj = optionsArray && optionsArray.length > 0 ? optionsArray[0] : undefined;
	const isFirst = firstObj && firstObj.value === option.value;

	return (
		<li {...props}>
			<div
				className={clsx(classes.optionCellDiv, {
					[classes.optionCellDivTightPadding]: tightCellPadding,
					[classes.optionCellDivSelected]: selected,
					[classes.optionCellDivFirst]: isFirst
				})}
			>
				<Typography noWrap color={"inherit"} className={clsx(classes.optionCellText, { [classes.optionCellSmallFont]: smallFont })}>
					{option ? option.label : ""}
				</Typography>
			</div>
		</li>
	);
};

const OptionImageCell = ({ props, option, selected, optionsArray, imageRectanglePlaceholder, imageSelectWhiteBackground, classes }) => {
	const firstObj = optionsArray && optionsArray.length > 0 ? optionsArray[0] : undefined;
	const isFirst = firstObj && firstObj.value === option.value;

	const placeholderImage = imageRectanglePlaceholder ? Constants.placeholderFoodRectangle : Constants.placeholderFood;
	const imageUrl = option.value > 0 && option.obj ? option.obj.filename_with_base : placeholderImage;

	return (
		<li {...props}>
			<div
				className={clsx(classes.optionImageCellDiv, {
					[classes.optionCellDivSelected]: selected,
					[classes.optionCellDivFirst]: isFirst
				})}
			>
				<div className={classes.optionImageImgDiv}>
					<img
						src={imageUrl}
						className={clsx(classes.optionImageImg, { [classes.optionImageImgWhiteBackground]: imageSelectWhiteBackground })}
						alt={option.label}
					/>
				</div>
				<Typography noWrap color={"inherit"} className={clsx(classes.optionImageCellText)}>
					{option ? option.label : ""}
				</Typography>
			</div>
		</li>
	);
};

const CustomFormSelect = ({
	optionsArray,
	inputID,
	inputLabel,
	setSelectedOption,
	selectedOption,
	isMultiSelect,
	setDirtyBit,
	isDisabled,
	helperText,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	outerMarginTop,
	outerMarginBottom,
	outerMarginLeft,
	outerMarginRight,
	smallFont,
	tightCellPadding,
	isImageSelect,
	imageSelectWhiteBackground,
	imageRectanglePlaceholder,
	isOptional,
	multiCloseAfterSelection,
	canBeCleared,
	placeholder,
	defaultOption,
	fullWidth,
	labelAlwaysAtTop = true
}) => {
	const classes = useStyles();
	const theStyle = { marginTop, marginBottom, marginLeft, marginRight };
	const theOuterStyle = { 
		marginTop: outerMarginTop,
		marginBottom: outerMarginBottom,
		marginLeft: outerMarginLeft,
		marginRight: outerMarginRight
	};

	const theme = useTheme();
	const scrollClassName = getScrollClassName(theme.palette.mode);
	const [inputText, setInputText] = useState("");

	const InputLabelProps = labelAlwaysAtTop || (placeholder && placeholder.length > 0) ? { shrink: true } : {};

	const getPlaceholder = () => {
		if (!placeholder) return undefined;

		if (isMultiSelect) {
			if (!selectedOption || selectedOption.length === 0) return placeholder;
			else return undefined;
		} else {
			if (!selectedOption) return placeholder;
			else return undefined;
		}
	};

	const longListFilterOptions =
		optionsArray && optionsArray.length > 100
			? createFilterOptions({
					matchFrom: "any",
					limit: 100
			  })
			: undefined;

	return (
		<Autocomplete
			style={theOuterStyle}
			multiple={isMultiSelect}
			filterOptions={longListFilterOptions}
			filterSelectedOptions={isMultiSelect}
			disabled={isDisabled}
			defaultValue={defaultOption}
			classes={{
				root: classes.optionAutoCompleteRoot,
				popper: classes.optionAutoCompletePopper,
				paper: classes.optionAutoCompletePaper,
				listbox: clsx(classes.optionAutoCompleteListbox, scrollClassName),
				option: classes.optionAutoCompleteOptionItem,
				noOptions: classes.optionsAutoCompleteNoOptions,
				tag: classes.optionsAutoCompleteMultiTag
			}}
			fullWidth={fullWidth}
			disableCloseOnSelect={isMultiSelect && !multiCloseAfterSelection}
			options={optionsArray}
			value={selectedOption}
			onChange={(e, selectedObj) => {
				e && e.preventDefault();
				setDirtyBit && setDirtyBit(true);
				!isDisabled && setSelectedOption(selectedObj);
			}}
			inputValue={inputText}
			onInputChange={(e, newInputValue) => {
				e && e.preventDefault();
				setInputText(newInputValue);
			}}
			isOptionEqualToValue={(option, selected) => {
				if (!selected || !option) return false;
				return selected?.value === option?.value;
			}}
			getOptionLabel={(option) => {
				return option?.label || "";
			}}
			renderOption={(props, option, { selected }) => {
				return isImageSelect ? (
					<OptionImageCell
						props={props}
						key={`optioninputcell-${option.value}`}
						option={option}
						selected={selected}
						classes={classes}
						optionsArray={optionsArray}
						imageRectanglePlaceholder={imageRectanglePlaceholder}
						imageSelectWhiteBackground={imageSelectWhiteBackground}
					/>
				) : (
					<OptionCell
						props={props}
						key={`optioncell-${option.value}`}
						option={option}
						selected={selected}
						classes={classes}
						optionsArray={optionsArray}
						smallFont={smallFont}
						tightCellPadding={tightCellPadding}
					/>
				);
			}}
			noOptionsText={"No Matches"}
			disableClearable={!canBeCleared}
			//open={true}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						className={classes.textfieldInput}
						style={theStyle}
						label={inputLabel}
						InputLabelProps={InputLabelProps}
						variant="outlined"
						margin="normal"
						fullWidth
						disabled={isDisabled}
						placeholder={getPlaceholder()}
						helperText={helperText}
						inputProps={{
							...params.inputProps,
							"data-lpignore": "true"
						}}
						required={isOptional ? false : true}
					/>
				);
			}}
		/>
	);
};

export default CustomFormSelect;
