import { Collapse, Divider, Grid, IconButton, List, ListItem, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";

import { defaultCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { calculateServerCartTotalCostString } from "../../store/CartUtils";
import { classes } from "../../theme/GlobalStyle";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { getMenuOptionsFromMenuItem } from "../../utils/ObjectUtils";
import useCommonData from "../../utils/hooks/useCommonData";
import { getCurrencyString } from "../../utils/Utils";

import CustomExpandMoreIcon from "../custom/CustomExpandMoreIcon";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomValuesList from "../custom/CustomValuesList";
import Constants from "../../utils/Constants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import CheckoutSectionBreakdownUpSell from "./CheckoutSectionBreakdownUpSell";

const CheckoutItem = ({ item, handleEditItem, handleRemoveItem, mealPlanLinkedItem }) => {
	const [expanded, setExpanded] = useState(false);
	const { location, locationCart } = useCommonData();
	const { pickupOrDelivery } = locationCart;
	const options = getMenuOptionsFromMenuItem(location, item, pickupOrDelivery);
	const selectedValues = options.flatMap((o) => {
		return o.values.filter((v) => v.selected);
	});
	const listValuesLeftPadding = 3;

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Grid container direction="row" spacing={1} justifyItems="center" alignItems="center">
			{/* Horizontal Grid */}

			<Grid item xs="auto" style={{ height: "100%" }}>
				{/* Column 1 */}
				<CustomExpandMoreIcon expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label={`${expanded ? "Hide" : "Show"} more ${item.name}`} />
			</Grid>

			<Grid item xs>
				{/* Column 2 */}
				<Grid container justifyItems="center" alignItems="center" spacing={1}>
					{" "}
					{/* Vertical Grid */}
					{/* ROW 1 */}
					<Grid item xs>
						<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1}>
							<Grid item xs="auto">
								<Typography variant="h6" variantMapping={{ h6: "span" }}>{`${item.name}`}</Typography>
							</Grid>

							<Grid item xs>
								<Tooltip
									aria-hidden="true"
									title={ConstantsLabels.labelButtonEditItemInCart}
									disableFocusListener
									id={`tooltip-Edit ${item.name}`}
								>
									<IconButton
										aria-hidden="false"
										role={"button"}
										aria-label={`Edit ${item.name}`}
										onClick={() => handleEditItem(item)}
										aria-describedby={`tooltip-Edit ${item.name}`}
									>
										<EditIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</Grid>

							<Grid item xs="auto">
								<Typography variant="caption">
									{expanded ? (
										getCurrencyString(item.price_base)
									) : (
										<>
											{mealPlanLinkedItem && (
												<LinkIcon fontSize="small" className={classes.iconVerticalAlignMiddle} />
											)}
											{` ${getCurrencyString(item.price_total)}`}
										</>
									)}
								</Typography>
							</Grid>

							{!expanded && (
								<Grid item xs="auto">
									<Tooltip
										aria-hidden="true"
										title={ConstantsLabels.labelButtonDeleteItemFromCart}
										disableFocusListener
										id={`tooltip-Delete ${item.name}`}
									>
										<IconButton
											aria-hidden="false"
											role={"button"}
											aria-label={`Delete ${item.name}`}
											onClick={() => handleRemoveItem(item)}
											aria-describedby={`tooltip-Delete ${item.name}`}
										>
											<DeleteForeverIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>

				{/* ROW 2 */}
				<Grid item xs>
					<Collapse in={!expanded} timeout="auto" unmountOnExit>
						<CustomValuesList valueArray={selectedValues} keyId={"valueid"} paddingLeft={listValuesLeftPadding} />
						{item.nutrition_calories > 0 && (
							<Typography
								variant={"caption"}
								paddingLeft={listValuesLeftPadding}
							>{`${item.nutrition_calories} Cals`}</Typography>
						)}
					</Collapse>
				</Grid>

				{/* ROW 3 */}
				<Grid item xs="auto">
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<Grid container direction="column" spacing={1}>
							{selectedValues.map((v) => {
								const comboValues = [];

								if (v.combo_itemid > 0 && v.options?.length > 0) {
									v.options.forEach((o) => {
										o.values.forEach((value) => {
											if (value.selected) {
												comboValues.push({ name: value.name, valueid: value.valueid, price: value.price });
											}
										});
									});
								}

								return [
									<Grid item key={`${v.optionid}${v.valueid}`}>
										<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1}>
											<Grid item xs>
												<Typography variant="caption" paddingLeft={listValuesLeftPadding}>
													{v.name}
												</Typography>
											</Grid>

											<Grid item xs="auto">
												<Typography variant="caption">{`+ ${getCurrencyString(v.price)}`}</Typography>
											</Grid>
										</Grid>
									</Grid>,
									comboValues.length > 0 &&
										comboValues.map((cv) => {
											return (
												<Grid item key={`${v.optionid}${v.valueid}-combo-values-list`}>
													<Grid
														container
														direction="row"
														justifyItems="center"
														alignItems="center"
														spacing={1}
													>
														<Grid item xs>
															<Typography variant="caption" paddingLeft={5}>
																{cv.name}
															</Typography>
														</Grid>

														<Grid item xs="auto">
															<Typography variant="caption">
																{cv.price > 0 ? `+ ${getCurrencyString(cv.price)}` : "-"}
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											);
										})
								];
							})}

							<Grid item>
								<Divider role="presentation" />
							</Grid>
							<Grid item>
								<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1}>
									<Grid item xs>
										<Typography variant="subtitle1" variantMapping={{ subtitle1: "h3" }}>
											Item Total:
										</Typography>
									</Grid>

									<Grid item xs="auto">
										<Typography variant="subtitle1" variantMapping={{ subtitle1: "h3" }}>
											<>
												{mealPlanLinkedItem && (
													<LinkIcon fontSize="small" className={classes.iconVerticalAlignMiddle} />
												)}
												{` ${getCurrencyString(item.price_total)}`}
											</>
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item pt={2} textAlign={"center"}>
							<CustomFormButton
								buttonText="Delete"
								color="error"
								onClick={() => handleRemoveItem(item)}
								startIcon={<DeleteForeverIcon />}
								size="medium"
								disableFullWidth
								disableDefaultStyle
								aria-label={`Delete ${item.name}`}
							/>
						</Grid>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
};

const CheckoutCostLineItem = ({ title, value, divider }) => {
	return (
		<Grid item ml={2} mr={2}>
			<Grid container direction="row">
				<Grid item xs>
					<Typography variant="subtitle1" variantMapping={{ subtitle1: "span" }}>
						{title}
					</Typography>
				</Grid>
				<Grid item pb={divider ? 1 : 0}>
					<Typography variant="subtitle1" variantMapping={{ subtitle1: "span" }} align="right">
						{value}
					</Typography>
				</Grid>
			</Grid>
			{divider && <Divider />}
		</Grid>
	);
};

const CheckoutSectionBreakdown = ({ doRemoveItemFromCart, server, setPayTotal, hidePaymentDetails }) => {
	const navigate = useNavigate();
	const { cartData, campus, campusID, locationCart, locationID } = useCommonData();
	const { pickupOrDelivery } = locationCart;
	const {
		subtotal,
		subtotal_tax,
		servicefee,
		servicefee_tax,
		campaign_credit_total,
		promo_credit_total,
		credit_total,
		punchcard_credit_total,
		meal_ex_itemidarray,
		mealplan_discount
	} = server.cart;

	const combinedCredit = credit_total + promo_credit_total + campaign_credit_total + punchcard_credit_total;
	const promoTotal = promo_credit_total + campaign_credit_total + punchcard_credit_total; //only one of these three will apply

	const [totalCostString, setTotalCostString] = useState(calculateServerCartTotalCostString(cartData.carts, locationID));

	const serviceFeeString = pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 ? "Service Fee" : "Delivery Fee";

	let mealplanDiscountString = "Mealplan Discount";
	if (hasFeatureFlag(campus, FeatureConstants.FEA_MPDISCOUNT_TXT_TO_DISCAPPLIED)) {
		mealplanDiscountString = "Discount Applied";
	}

	const upSellAdded = locationCart?.items.find((i) => i.upsell_upsellid > 0 && i.upsell_variantid > 0);

	useEffect(() => {
		setTotalCostString(calculateServerCartTotalCostString(cartData.carts, locationID));
		setPayTotal(calculateServerCartTotalCostString(cartData.carts, locationID));
	}, [locationCart, locationID]);

	if (!server) return <></>;

	// If MealExchange is selected, figure out which items have been covered by the plan so we can add a LinkIcon
	const mealExCartItemIdArray = [];
	if (meal_ex_itemidarray?.length > 0) {
		meal_ex_itemidarray.forEach((itemId) => {
			const items = locationCart?.items.filter((item) => item.itemid === itemId);
			if (items.length > 1) {
				let mostExpensiveItem = items[0];
				items.forEach((i) => {
					if (i.price_total > mostExpensiveItem.price_total) {
						mostExpensiveItem = i;
					}
				});
				mealExCartItemIdArray.push(mostExpensiveItem.cartItemId);
			} else {
				mealExCartItemIdArray.push(items[0].cartItemId);
			}
		});
	}

	const handleRemoveItem = (item) => {
		doRemoveItemFromCart(locationID, item.cartItemId);
	};

	const handleEditItem = (item) => {
		const { cartItemId, itemid, sectionid } = item;
		navigate(`/${campusID}/${locationID}/${sectionid}/${itemid}/${cartItemId}`);
	};

	return (
		<CustomPaperContainer>
			<Grid container spacing={1} direction="column" alignItems="stretch">
				<Grid item xs>
					<List className={classes.listTransparentNoBorder} dense disablePadding>
						{locationCart?.items?.map((item, i) => {
							return (
								<div key={item.cartItemId}>
									<ListItem className={classes.checkoutListItem}>
										<CheckoutItem
											id={`checkout-item-${item.id}`}
											item={item}
											handleRemoveItem={handleRemoveItem}
											handleEditItem={handleEditItem}
											mealPlanLinkedItem={mealExCartItemIdArray && mealExCartItemIdArray.includes(item.cartItemId)}
										/>
									</ListItem>
									{i + 1 < locationCart.items.length && <Divider variant="middle" />}
								</div>
							);
						})}
					</List>
				</Grid>
				{!upSellAdded && (
					<Grid item>
						<CheckoutSectionBreakdownUpSell server={server} />
					</Grid>
				)}
				{(promo_credit_total > 0 || !hidePaymentDetails) && (
					<Grid item>
						<Divider />
					</Grid>
				)}
				{!hidePaymentDetails && <CheckoutCostLineItem title={"Subtotal:"} value={`${subtotal ? getCurrencyString(subtotal) : "-"}`} />}
				{promoTotal > 0 && credit_total > 0 && (
					<CheckoutCostLineItem title={"Credit & Promo Applied:"} value={`(${getCurrencyString(combinedCredit)})`} />
				)}
				{promoTotal > 0 && credit_total === 0 && (
					<CheckoutCostLineItem title={"Promo Applied:"} value={`(${getCurrencyString(combinedCredit)})`} />
				)}
				{promoTotal === 0 && credit_total > 0 && (
					<CheckoutCostLineItem title={"Account Credit Applied:"} value={`(${getCurrencyString(combinedCredit)})`} />
				)}

				{!hidePaymentDetails && (
					<>
						{mealplan_discount > 0 && (
							<CheckoutCostLineItem title={mealplanDiscountString} value={`(${getCurrencyString(mealplan_discount)})`} />
						)}
						<CheckoutCostLineItem
							title={"Tax:"}
							value={`${subtotal_tax ? getCurrencyString(subtotal_tax) : "-"}`}
							divider={servicefee === 0}
						/>
						{servicefee > 0 && (
							<>
								<CheckoutCostLineItem title={serviceFeeString} value={`${getCurrencyString(servicefee)}`} />
								<CheckoutCostLineItem
									title={`${serviceFeeString} Tax:`}
									value={`${servicefee_tax ? getCurrencyString(servicefee_tax) : "-"}`}
									divider
								/>
							</>
						)}
						<Grid item margin={2} mt={1}>
							<Typography
								variant="h5"
								variantMapping={{ h5: "span" }}
								align={"right"}
								aria-live="polite"
							>{`Total: ${totalCostString}`}</Typography>
						</Grid>
					</>
				)}
			</Grid>
		</CustomPaperContainer>
	);
};
export default connect(defaultMapStateToProps, defaultCartActionsToProps)(CheckoutSectionBreakdown);
