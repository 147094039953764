import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { getDateFormats, getDateObjFromUTCString, isDateSameAsToday } from "../../utils/DateUtils";

const NotificationItem = ({ notification, newMessage }) => {
	const { message, orderid, push_datetime, title } = notification;

	const notificationDateObj = getDateObjFromUTCString(push_datetime);
	const notificationDateString = `${
		isDateSameAsToday(notificationDateObj) ? "Today" : getDateFormats(notificationDateObj)?.localDateMonthDay
	} at ${getDateFormats(notificationDateObj)?.localTimeHourMin}`;

	return (
		<Card sx={{ minWidth: 275, maxWidth: 400 }} variant="outlined" square>
			<CardContent sx={{ opacity: newMessage ? 1 : 0.7 }}>
				<Grid container spacing={0.5} alignContent="space-between" justifyContent="right">
					<Grid item xs>
						<Typography variant="body2">{!!orderid ? `#${orderid}` : ""}</Typography>
					</Grid>
					<Grid item xs="auto">
						<Typography variant="body2">{push_datetime !== undefined && notificationDateString}</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body1" variantMapping={{ body1: "h2" }}>
							{title}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body2">{message}</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default NotificationItem;
