import { CircularProgress, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import React from "react";

import { classes } from "../theme/GlobalStyle";

import CustomPaperContainer from "../views/custom/CustomPaperContainer";

const ProcessingOrderModalForm = ({ isVirtualQueue, statusText }) => {
	return (
		<CustomPaperContainer className={classes.processingOrderContainer}>
			<Stack justifyContent="space-evenly" alignItems="center" className={classes.heightWebkitFillAvailable}>
				<div>
					<Typography variant="h1" variantMapping={{ h1: "h2" }} align="center">
						{isVirtualQueue ? "Processing Reservation" : "Processing Order"}
					</Typography>
					<Typography variant="h4" variantMapping={{ h4: "h3" }} align="center">
						Please wait...
					</Typography>
				</div>
				<CircularProgress disableShrink size={100} />
				<Typography variant="h3" align="center">
					{statusText}
				</Typography>
			</Stack>
		</CustomPaperContainer>
	);
};

export default ProcessingOrderModalForm;
