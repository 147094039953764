const StyleConstants = {
	closedSidebarWidth: 68,
	dropdownbarHeight: 90,
	maxWidthMediumBreakpointDown: "800px",
	maxWidthMediumBreakpointUp: "1000px",
	modalPadding: 30,
	modalPaddingTopBottom: 20,
	openSidebarWidth: 170,
	sessionEventsNameDivHeight: 150,
	shiftFromTop: 50,
	standardBorderHeight: 8,
	standardBorderHeightMobile: 4,
	tabbarHeight: 56,
	tableToolbarHeight: 65,
	topnavbarHeight: 66,
	viewFormBottomButton: 80,
	viewFormBottomMargin: 20,
	viewTabbarHeight: 60,
	widgetContainerPadding: 20
};

export default StyleConstants;
