import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCart } from "../../store/CartUtils";
import { getObjectFromArray } from "../ObjectUtils";
import { hasFeatureFlag } from "../PermissionUtils";
import FeatureConstants from "../FeatureConstants";
import { getDistanceFromCurrent } from "../Utils";
import { enqueueSnackbar, updateLatitudeLongitude, updateLocationDisabledShown } from "../../store/Actions";

const useCommonData = () => {
	const dispatch = useDispatch();
	const campusData = useSelector((store) => store.campusData);
	const cartData = useSelector((store) => store.cartData);
	const userData = useSelector((store) => store.userData);
	const settingsData = useSelector((store) => store.settingsData);
	const routerLocation = useLocation();

	const path = routerLocation.pathname.split("/");
	const [locationID, setLocationID] = useState(parseInt(path[2]) || path[2]);
	const inputcampusid = path[1];
	const [campusID, setCampusID] = useState(inputcampusid);

	const locations = campusData.locations || [];
	const location = getObjectFromArray(locationID, locations, "locationid");
	const disabled_locations = campusData.disabled_locations;
	const locationsWithDisabled = [...locations, ...disabled_locations];

	const { carts } = cartData;

	const locationCart = getCart(carts, locationID);

	const menus = campusData.menus || [];
	const menu = getObjectFromArray(locationID, menus, "locationid");
	const menuSlots = campusData.menuslots || [];
	const menuSlot = getObjectFromArray(locationID, menuSlots, "locationid");

	const cafeterias = campusData.cafeteria_groups.flatMap((cafeteriaGroup) => {
		return cafeteriaGroup.cafeterias;
	});
	const [cafeteriaGroups, setCafeteriaGroups] = useState(campusData.cafeteria_groups);
	const campus = campusData.campus;

	const user = userData.user;
	const pushToken = userData.pushToken;
	const orders = userData.orders;

	const latitudeLongitude = userData.latitudeLongitude;
	const highContrast = user ? settingsData.highContrast : false;
	const extendNotificationDuration = user ? settingsData.extendNotificationDuration : false;

	const currentUserEmail = settingsData.currentUserEmail;

	useEffect(() => {
		const path = routerLocation.pathname.split("/");
		const locationId = parseInt(path[2]) || path[2];
		setLocationID(locationId);
		setCafeteriaGroups(campusData.cafeteria_groups);
	}, [path]);

	useEffect(() => {
		setCampusID(getCampusId());
	}, [user]);

	const cafeteriaGroupsSortByLocation = () => {
		if (
			!hasFeatureFlag(campus, FeatureConstants.FEA_HOME_SORTBYPRIORITY) &&
			!hasFeatureFlag(campus, FeatureConstants.FEA_HOME_SORTBYOPENTYPE)
		) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const lat = position.coords.latitude;
					const long = position.coords.longitude;
					const _cafeteriaGroups = cafeteriaGroups.map((cGroup) => {
						cGroup.gpsDistanceFromCurrent = getDistanceFromCurrent(lat, long, cGroup.latitude, cGroup.longitude);
						return cGroup;
					});
					_cafeteriaGroups.sort((a, b) => a.gpsDistanceFromCurrent - b.gpsDistanceFromCurrent);
					setCafeteriaGroups(_cafeteriaGroups);
					dispatch(updateLatitudeLongitude({ latitude: lat.toString(), longitude: long.toString() }));
				},
				() => {
					if (settingsData.locationDisabledShown === false) {
						dispatch(
							enqueueSnackbar({
								message: "Unable to retrieve your location",
								title: "Location Error",
								excludeFromHistory: false,
								options: { variant: "error" }
							})
						);
						dispatch(updateLocationDisabledShown(true));
					}
				},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0
				}
			);
		}
	};

	function getCampusId() {
		const path = routerLocation.pathname.split("/");
		const inputcampusid = path[1];

		return user ? user.campusid : inputcampusid || 0;
	}

	function getLocationFromId(locationId) {
		return getObjectFromArray(locationId, locations, "locationid");
	}

	function getLocationWithDisabledFromId(locationId) {
		return getObjectFromArray(locationId, locationsWithDisabled, "locationid");
	}

	function getMenuFromLocationId(locationId) {
		return getObjectFromArray(locationId, menus, "locationid");
	}

	function getCartFromLocationId(locationId) {
		return getCart(carts, locationId);
	}

	return {
		cafeterias,
		cafeteriaGroups,
		campus,
		campusID,
		cartData,
		carts,
		disabled_locations,
		latitudeLongitude,
		locationCart,
		getCartFromLocationId,
		locationID,
		location,
		locations,
		locationsWithDisabled,
		getLocationFromId,
		getLocationWithDisabledFromId,
		menu,
		menuSlot,
		menuSlots,
		pushToken,
		getMenuFromLocationId,
		orders,
		user,
		cafeteriaGroupsSortByLocation,
		highContrast,
		extendNotificationDuration,
		currentUserEmail
	};
};

export default useCommonData;
