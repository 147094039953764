import React from "react";
import { useTheme } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack, Typography } from "@mui/material";

const Loading = ({ open = true, loadingText }) => {
	const theme = useTheme();

	if (!theme.isThemeLoaded) return <></>;

	return (
		<Backdrop style={{ zIndex: 100 }} open={open} onClick={() => {}}>
			<Stack direction="column" alignItems={"center"}>
				<CircularProgress id="loading" alt="Loading. Please wait..." color="secondary" size={100} />
				{loadingText && (
					<Typography variant="h6" variantMapping={{ h6: "h2" }} align="center" pt={3}>
						{loadingText}
					</Typography>
				)}
			</Stack>
		</Backdrop>
	);
};

export default Loading;
