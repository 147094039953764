import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";
import FeatureConstants from "../utils/FeatureConstants";
import useCommonData from "../utils/hooks/useCommonData";
import { hasFeatureFlag } from "../utils/PermissionUtils";

import { LogDebug, setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import LocationHeader from "../views/location/LocationHeader";
import LocationsList from "../views/location/LocationsList";

const LocationPage = ({ doApiCall, doEnqueueSnackbar }) => {
	const { campus, campusID, cafeterias, locations, locationsWithDisabled, user } = useCommonData();
	const navigate = useNavigate();
	const [busy, setBusy] = useState(false);

	LogDebug("Location Page rendered");

	useEffect(() => {
		setAppPageTitle("Locations");
	}, [navigate]);

	return (
		<CustomDivContainer
			tabStyleClasses={[classes.contentRootDiv]}
			styleClasses={[classes.contentDivWithHeader, classes.contentRootDiv]}
			showLoading={!campus || !locationsWithDisabled}
			showLoadingOnTop={busy}
			childrenHeaderComponent={
				<LocationHeader
					campus={campus}
					campusID={campusID}
					user={user}
					locations={locationsWithDisabled}
					cafeterias={cafeterias}
					setBusy={setBusy}
					doApiCall={doApiCall}
					doEnqueueSnackbar={doEnqueueSnackbar}
				/>
			}
		>
			{locations.length === 0 || !hasFeatureFlag(campus, FeatureConstants.FEA_WEBORDER_ENABLED) ? (
				<Typography align="center" variant="h4" variantMapping={{ h4: "h2" }} pt={2}>
					No available locations
				</Typography>
			) : (
				<LocationsList setBusy={setBusy} />
			)}
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LocationPage);
