import clsx from "clsx";
import React, { useEffect } from "react";

import ApiEndpoints from "../../api/ApiEndpoints";
import { classes } from "../../theme/GlobalStyle";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";

const MessageItem = ({ campus, currentMessage, doApiCall }) => {
	useEffect(() => {
		// do we neeed to check if is_opened === 0? or always call this api when viewed to update last_opened_date_time?
		const body = {
			messageid: "" + currentMessage.inboxid
		};

		doApiCall(
			ApiEndpoints.OpenedMessage,
			body,
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {},
			(errorMsg, errorReason) => {}
		);
	}, [currentMessage]);

	return (
		<iframe
			id={"iframe-message" + currentMessage.inboxid}
			className={clsx(classes.iframe)}
			title={currentMessage.title}
			srcDoc={currentMessage.body}
			frameBorder="0"
			sandbox={hasFeatureFlag(campus, FeatureConstants.FEA_MESSAGES_ALLOW_JAVASCRIPT) ? null : "allow-scripts"}
		/>
	);
};

export default MessageItem;
