import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import React from "react";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ConstantsLabels from "../utils/ConstantsLabels";
import CustomPaperContainer from "../views/custom/CustomPaperContainer";
import { CircularProgress } from "@mui/material";

const EmptyCart = ({ popout }) => {
	return (
		<CustomPaperContainer>
			<Stack direction="column" padding={popout ? 1 : 2} alignItems="center" spacing={2}>
				<Tooltip
					aria-hidden="true"
					title={ConstantsLabels.labelButtonEditItemInCart}
					disableFocusListener
					id={`tooltip-${ConstantsLabels.labelButtonEditItemInCart}`}
				>
					<ProductionQuantityLimitsIcon
						aria-hidden="false"
						fontSize={popout ? "medium" : "large"}
						aria-describedby={`tooltip-${ConstantsLabels.labelButtonEditItemInCart}`}
					/>
				</Tooltip>
				<Typography variant={popout ? "h6" : "h4"} align="center">
					{popout ? ConstantsLabels.labelEmptyCart : ConstantsLabels.labelEmptyCartRedirecting}
				</Typography>
				{!popout && <CircularProgress disableShrink />}
			</Stack>
		</CustomPaperContainer>
	);
};

export default EmptyCart;
