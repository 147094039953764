import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import LegalList from "../views/legal/LegalList";
import ConstantsLabels from "../utils/ConstantsLabels";

const LegalPage = ({ campusData, doUpdateAppBarTitle }) => {
	const { campus } = campusData;

	const theme = useTheme();

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.legal);
	}, []);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!campus}>
			<LegalList />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LegalPage);
