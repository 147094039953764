const PREFIX = "ConfirmAlertDialog";

export const classes = {
	buttonBorder: `${PREFIX}-buttonBorder`,
	cancelButton: `${PREFIX}-cancelButton`,
	confirmButton: `${PREFIX}-confirmButton`,
	defaultButton: `${PREFIX}-defaultButton`,
	descriptionText: `${PREFIX}-descriptionText`,
	errorButton: `${PREFIX}-errorButton`,
	errorButtonOutlineOnly: `${PREFIX}-errorButtonOutlineOnly`,
	listTransparentNoBorder: `${PREFIX}-listTransparentNoBorder`,
	root: `${PREFIX}-root`,
	successButton: `${PREFIX}-successButton`,
	titleText: `${PREFIX}-titleText`,
	justifySpaceEvenly: `${PREFIX}-justifySpaceEvenly`,
	textAlignCenter: `${PREFIX}-textAlignCenter`,
	primaryBackground: `${PREFIX}-primaryBackground`
};

export const ConfirmAlertDialogStyle = (theme) => {
	const p = theme.palette;

	return {
		[`&.${classes.root}`]: {
			"& .MuiPaper-root": {
				background: p.background.backgroundPopUpMenu,
				padding: 10,
				border: theme.border.menuBorder,
				maxWidth: 500,
				width: "100%"
			},
			"& .MuiDialogActions-root": {
				marginTop: 10
			},
			"& .MuiDialogContentText-root": {
				marginBottom: 12,
				marginTop: 12
			}
		},
		[`& .${classes.primaryBackground}`]: {
			background: p.primary.main + "D9",
			color: "white !important",
		},

		[`& .${classes.titleText}`]: { color: p.text.secondary },

		[`& .${classes.descriptionText}`]: { color: p.text.secondary },

		[`& .${classes.cancelButton}`]: {
			color: p.text.secondary,
			width: "50%",
			maxWidth: 300,
			padding: "10px 20px",
			border: "1px solid " + p.divider,
			marginRight: 15,
			"&:hover": {
				color: p.text.primary,
				background: p.background.selectBackgroundHover
			},
			"&.Mui-focusVisible": {
				color: p.text.primary,
				background: p.background.selectBackgroundHover
			}
		},

		[`& .${classes.confirmButton}`]: {
			width: "50%",
			maxWidth: 300,
			padding: "10px 20px",
			border: "1px solid " + p.divider
		},

		[`& .${classes.errorButton}`]: {
			color: "white",
			background: p.error.main,
			"&:hover ": { background: p.error.main + "D9", color: "white" },
			"&.Mui-focusVisible": {
				backgroundColor: p.error.main + "D9",
				color: "white"
			}
		},
		[`& .${classes.listTransparentNoBorder}`]: {
			background: "transparent",
			border: 0
		},

		[`& .${classes.successButton}`]: {
			color: "white",
			background: p.secondary.main + "D9",
			"&:hover ": { background: p.secondary.main }
		},

		[`& .${classes.defaultButton}`]: {
			color: "white",
			background: p.primary.main + "D9",
			"&:hover ": { background: p.primary.main }
		},

		[`& .${classes.buttonBorder}`]: {
			border: "1px solid " + p.border.inputBoxBorder
		},

		[`& .${classes.errorButtonOutlineOnly}`]: {
			border: "1px solid " + p.border.inputBoxBorder,
			backgroundColor: p.background.backgroundInput,
			color: p.text.primary,
			background: "transparent"
		},
		[`& .${classes.justifySpaceEvenly}`]: {
			justifyContent: "space-evenly"
		},
		[`& .${classes.textAlignCenter}`]: {
			textAlign: "center"
		},
	};
};
