const FeatureConstants = {
	//Home
	FEA_HOME_SHOWMAPS: "FEA_HOME_SHOWMAPS",
	FEA_HOME_HIDEINVITEFRIEND: "FEA_HOME_HIDEINVITEFRIEND",
	// FEA_HOME_RENAMEREORDERTABTOSCHEDULE: "FEA_HOME_RENAMEREORDERTABTOSCHEDULE",
	// FEA_HOME_SHOWREVIEWINSTEADOFRATE: "FEA_HOME_SHOWREVIEWINSTEADOFRATE",
	//TODO implement this (we only did this on iOS)
	// FEA_HOME_DONOTROUNDIMAGES: "FEA_HOME_DONOTROUNDIMAGES",
	//TODO implement this (we only did this on iOS)
	FEA_HOME_SORTBYPRIORITY: "FEA_HOME_SORTBYPRIORITY",
	FEA_HOME_SORTBYOPENTYPE: "FEA_HOME_SORTBYOPENTYPE",
	FEA_HOME_HIDEWAITESTIMATE: "FEA_HOME_HIDEWAITESTIMATE",
	FEA_DISABLE_RATE_ORDER: "FEA_DISABLE_RATE_ORDER",
	FEA_WEBORDER_ENABLED: "FEA_WEBORDER_ENABLED",

	//Rewards
	// FEA_REWARDS_DISABLELEVELSELECTCELL: "FEA_REWARDS_DISABLELEVELSELECTCELL",

	//Inbox
	//   FEA_INBOX_RECEIPTSDISABLED: "FEA_INBOX_RECEIPTSDISABLED",
	FEA_MESSAGES_INBOX_DISABLED: "FEA_MESSAGES_INBOX_DISABLED",
	FEA_MESSAGES_ALLOW_JAVASCRIPT: "FEA_MESSAGES_ALLOW_JAVASCRIPT",

	//Checkout
	FEA_CHECKOUT_HIDEPAYMENTMETHOD: "FEA_CHECKOUT_HIDEPAYMENTMETHOD",
	//deprecated "FEA_CHECKOUT_HIDEPAYMENTCREDITCARD",
	FEA_CHECKOUT_HIDETENDERSELECT_IFONLYONE: "FEA_CHECKOUT_HIDETENDERSELECT_IFONLYONE",
	FEA_CHECKOUT_HIDEAPPLYPROMO: "FEA_CHECKOUT_HIDEAPPLYPROMO",
	// FEA_CBORD_FILTERTENDERS_BYPLAN: "FEA_CBORD_FILTERTENDERS_BYPLAN",
	FEA_DISABLE_SECONDARY_TENDER_CREDITCARD: "FEA_DISABLE_SECONDARY_TENDER_CREDITCARD",
	// FEA_DO_NOT_LOAD_TENDERS_FROM_DISK: "FEA_DO_NOT_LOAD_TENDERS_FROM_DISK",

	//Welcome

	//USE THESE ONES to control individual cells
	FEA_WELCOME_ENABLE_GOOGLESIGNIN: "FEA_WELCOME_ENABLE_GOOGLESIGNIN",
	// FEA_WELCOME_ENABLE_APPLESIGNIN: "FEA_WELCOME_ENABLE_APPLESIGNIN",
	FEA_WELCOME_ENABLE_EMAILSIGNIN: "FEA_WELCOME_ENABLE_EMAILSIGNIN",
	FEA_WELCOME_ENABLE_CAMPUSSIGNIN: "FEA_WELCOME_ENABLE_CAMPUSSIGNIN",
	// FEA_BBMIGRATION_KILLSWITCH: "FEA_BBMIGRATION_KILLSWITCH",

	//Menu - Order
	FEA_ORDER_SHOWNUTRITIONDISCLAIMER: "FEA_ORDER_SHOWNUTRITIONDISCLAIMER",
	FEA_WEBORDER_HIDE_MENU_THUMBNAILS: "FEA_WEBORDER_HIDE_MENU_THUMBNAILS",
	//campus-wide. overridden by location specific text (if set)

	//Options

	//Processing
	// FEA_PROCESSORDER_LEGACY_APICALL: "FEA_PROCESSORDER_LEGACY_APICALL",
	// FEA_LOC_PROCESSORDER_LEGACY_APICALL: "FEA_LOC_PROCESSORDER_LEGACY_APICALL",

	//Registration
	FEA_REGISTER_EMAIL_PHONE_VERIFY_REQD: "FEA_REGISTER_EMAIL_PHONE_VERIFY_REQD", //phone verify
	FEA_REGISTER_EMAIL_EMAIL_VERIFY_REQD: "FEA_REGISTER_EMAIL_EMAIL_VERIFY_REQD", //email verify
	// FEA_REGISTER_SHOWENTERPROMO: "FEA_REGISTER_SHOWENTERPROMO",

	//Profile
	FEA_REGISTER_ALLOW_DELETE_ACCOUNT: "FEA_REGISTER_ALLOW_DELETE_ACCOUNT",

	//More
	// FEA_MORE_CAMPUS_QUEENS_SHOWBALANCECELL: "FEA_MORE_CAMPUS_QUEENS_SHOWBALANCECELL",
	FEA_MORE_HIDEPAYMENTMETHODSCELL: "FEA_MORE_HIDEPAYMENTMETHODSCELL",
	FEA_MORE_HIDEINVITEFRIENDCELL: "FEA_MORE_HIDEINVITEFRIENDCELL",
	FEA_MORE_HIDEAPPLYPROMOCELL: "FEA_MORE_HIDEAPPLYPROMOCELL",
	FEA_MORE_HIDESUPPORTCELL: "FEA_MORE_HIDESUPPORTCELL",
	// FEA_MORE_SHOWMANAGEINBOXSUBSCRIPTION: "FEA_MORE_SHOWMANAGEINBOXSUBSCRIPTION",

	// FEA_PROFILE_OPTIN_SHOW_MARKETING_TOGGLE: "FEA_PROFILE_OPTIN_SHOW_MARKETING_TOGGLE",
	// FEA_PROFILE_OPTIN_SHOW_LOYALTY_TOGGLE: "FEA_PROFILE_OPTIN_SHOW_LOYALTY_TOGGLE",

	//MISC
	// FEA_RECEIPT_TAXLABEL_HST: "FEA_RECEIPT_TAXLABEL_HST",
	// FEA_SSO_LEGACY_APICALL: "FEA_SSO_LEGACY_APICALL",
	FEA_MPDISCOUNT_TXT_TO_DISCAPPLIED: "FEA_MPDISCOUNT_TXT_TO_DISCAPPLIED",

	//Location Feature Flags //LOC
	FEA_LOC_USER_CHECKIN_REQUIRED: "FEA_LOC_USER_CHECKIN_REQUIRED", //chick fil a
	FEA_LOC_USER_CHECKIN_REQUIRED_HIDE_BUTTON: "FEA_LOC_USER_CHECKIN_REQUIRED_HIDE_BUTTON",
	//hide the button, user will tablet barcode scan the receipt
	FEA_LOC_USER_CHECKIN_REQUIRED_CAMERASCAN: "FEA_LOC_USER_CHECKIN_REQUIRED_CAMERASCAN",
	//show button, but when clicing it pops up the users camera
	FEA_LOC_MARK_COMPLETE_RIGHTAWAY: "FEA_LOC_MARK_COMPLETE_RIGHTAWAY",
	FEA_LOC_DISABLE_RATE_ORDER: "FEA_LOC_DISABLE_RATE_ORDER",
	FEA_LOC_HIDE_PICKUP_TIME_ESTIMATE: "FEA_LOC_HIDE_PICKUP_TIME_ESTIMATE",
	FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY: "FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY",
	FEA_LOC_HIDE_WAIT_ESTIMATE: "FEA_LOC_HIDE_WAIT_ESTIMATE",
	FEA_LOC_ALWAYS_SHOW_CAMPUS_TENDER_SELECT: "FEA_LOC_ALWAYS_SHOW_CAMPUS_TENDER_SELECT",
	FEA_LOC_HIDE_RECEIPT_BARCODE: "FEA_LOC_HIDE_RECEIPT_BARCODE",
	FEA_LOC_HIDE_RECEIPT_BARCODE_WHEN_COMPLETE: "FEA_LOC_HIDE_RECEIPT_BARCODE_WHEN_COMPLETE",
	FEA_LOC_RECEIPT_HIDE_TARGETTIME: "FEA_LOC_RECEIPT_HIDE_TARGETTIME",
	FEA_LOC_CHECKOUT_SHOWLASTORDERPROMPT_ONDEV: "FEA_LOC_CHECKOUT_SHOWLASTORDERPROMPT_ONDEV",
	FEA_LOC_ALL_PREMADE_NO_PREPTIME: "FEA_LOC_ALL_PREMADE_NO_PREPTIME",
	FEA_LOC_STATUS_DISABLE_COUNTDOWN: "FEA_LOC_STATUS_DISABLE_COUNTDOWN",
	FEA_LOC_STATUS_COMPLETE_USE_STAGETEXT: "FEA_LOC_STATUS_COMPLETE_USE_STAGETEXT",
	FEA_LOC_SINGLE_ITEM_DIRECT_TO_CHECKOUT: "FEA_LOC_SINGLE_ITEM_DIRECT_TO_CHECKOUT",
	FEA_LOC_ADDING_ITEM_GOES_TO_CHECKOUT: "FEA_LOC_ADDING_ITEM_GOES_TO_CHECKOUT",
	FEA_LOC_MENU_LIST_SHOW_STOCK_REMAINING: "FEA_LOC_MENU_LIST_SHOW_STOCK_REMAINING",
	FEA_LOC_MENU_ITEM_SHOW_STOCK_REMAINING: "FEA_LOC_MENU_ITEM_SHOW_STOCK_REMAINING",
	FEA_LOC_MENU_STOCK_SHOW_TOTAL: "FEA_LOC_MENU_STOCK_SHOW_TOTAL",
	FEA_LOC_CHECKOUT_HIDEAPPLYPROMO: "FEA_LOC_CHECKOUT_HIDEAPPLYPROMO",
	FEA_LOC_CHECKOUT_FREE_ITEMS_NOPAYMENTDETAILS: "FEA_LOC_CHECKOUT_FREE_ITEMS_NOPAYMENTDETAILS", // TODO needs further implementation
	FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE: "FEA_LOC_MENU_STOCK_DISPLAY_UNAVAILABLE",
	FEA_LOC_DELIVERY_SEPARATE_AVAILABILITY_VISIBILITY: "FEA_LOC_DELIVERY_SEPARATE_AVAILABILITY_VISIBILITY",
	FEA_LOC_HIDE_TIME_IF_ONE_SLOT: "FEA_LOC_HIDE_TIME_IF_ONE_SLOT",
	FEA_LOC_DISABLE_CANCEL_ORDER: "FEA_LOC_DISABLE_CANCEL_ORDER",
	FEA_LOC_MENU_SHOW_TENDER_SELECT: "FEA_LOC_MENU_SHOW_TENDER_SELECT",
	FEA_LOC_DISABLE_SECONDARY_TENDER_CREDITCARD: "FEA_LOC_DISABLE_SECONDARY_TENDER_CREDITCARD",
	FEA_LOC_HIDE_TENDER_NAME_IN_PAYMENT_CONFIRM: "FEA_LOC_HIDE_TENDER_NAME_IN_PAYMENT_CONFIRM",
	FEA_LOC_PICKUPSPOTS_SORTBYPOSITION: "FEA_LOC_PICKUPSPOTS_SORTBYPOSITION",
	// FEA_LOC_CHECKOUT_MODIFIERS_SEPARATE_LINES: "FEA_LOC_CHECKOUT_MODIFIERS_SEPARATE_LINES",
	FEA_LOC_MENU_CUSTOMIZE_SHOW_TOTAL_CALS_COUNT: "FEA_LOC_MENU_CUSTOMIZE_SHOW_TOTAL_CALS_COUNT"
};

export default FeatureConstants;
