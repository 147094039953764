import clsx from "clsx";
import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ConfirmAlertDialogStyle, classes } from "./ConfirmAlertDialogStyle";

import CustomFormButton from "../forms/CustomFormButton";
import Loading from "../components/Loading";

const RootDialog = styled(Dialog)(({ theme }) => ConfirmAlertDialogStyle(theme));

const DialogList = ({ listItems, listHeaderText }) => {
	return (
		<Stack>
			<Typography variant="subtitle2" variantMapping={{ subtitle2: "h3" }}>
				{listHeaderText}
			</Typography>
			<List disablePadding className={classes.listTransparentNoBorder}>
				{listItems.map((item) => {
					return (
						<ListItem disablePadding key={item.key}>
							<ListItemText inset primary={item.value} className={classes.descriptionText} />
						</ListItem>
					);
				})}
			</List>
		</Stack>
	);
};

const ConfirmAlertDialog = React.forwardRef(
	(
		{
			cancelButtonText = "BACK",
			cancelCallback,
			confirmCallback,
			confirmDanger,
			confirmSuccess,
			confirmButtonText = "OK",
			description,
			dialogContentComponent,
			hideBackButton,
			isButtonDisabled,
			isVisible,
			listHeaderText,
			listItems,
			preventCloseOnClickOk = false,
			preventCloseOnClickOutside,
			setIsVisible,
			showLoading,
			title,
			titlePrimaryBackground = false,
			centerTitle = false,
			centerButtonConfirm = false,
			centerButtonCancel = false,
			showDividers = true
		},
		ref
	) => {
		return (
			<RootDialog
				className={classes.root}
				open={isVisible}
				onClose={(event, reason) => {
					if (preventCloseOnClickOutside && reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return;
					setIsVisible(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{ tabIndex: -1 }}
				role={"dialog"}
			>
				<Loading open={!!showLoading} />
				<DialogTitle id="alert-dialog-title">
					<div
						className={clsx(classes.titleText, {
							[classes.textAlignCenter]: centerTitle,
							[classes.primaryBackground]: titlePrimaryBackground
						})}
					>
						{title?.toUpperCase()}
					</div>
				</DialogTitle>
				<DialogContent dividers={showDividers}>
					{description && (
						<DialogContentText id="alert-dialog-description" className={classes.descriptionText}>
							{description}
						</DialogContentText>
					)}
					{listItems && <DialogList listItems={listItems} listHeaderText={listHeaderText} />}
					{dialogContentComponent && dialogContentComponent}
				</DialogContent>
				<DialogActions className={clsx({ [classes.justifySpaceEvenly]: centerButtonConfirm || centerButtonCancel })}>
					{!hideBackButton && (
						<CustomFormButton
							buttonText={cancelButtonText}
							clsxClasses={clsx(classes.cancelButton)}
							onClick={(e) => {
								e && e.preventDefault();
								cancelCallback ? cancelCallback() : setIsVisible && setIsVisible(false);
							}}
							variant="outlined"
						/>
					)}
					<CustomFormButton
						buttonText={confirmButtonText?.toUpperCase()}
						clsxClasses={clsx(classes.confirmButton, {
							[classes.errorButton]: confirmDanger,
							[classes.successButton]: confirmSuccess,
							[classes.defaultButton]: !confirmSuccess && !confirmDanger
						})}
						onClick={(e) => {
							e && e.preventDefault();
							!preventCloseOnClickOk && setIsVisible(false);
							confirmCallback();
						}}
						// autoFocus
						disabled={isButtonDisabled}
					/>
				</DialogActions>
			</RootDialog>
		);
	}
);

export default ConfirmAlertDialog;
