import { useMediaQuery } from "@mui/material";
import { isDeviceMobileFx } from "../Utils";

const useAppDefaults = () => {
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);

	return {
		isDeviceMobile,
		widgetPaddingLarge: isDeviceMobile ? 1 : 2,
		widgetPaddingSmall: isDeviceMobile ? 0 : 1,
		widgetSpacing: isDeviceMobile ? 0 : 2,
		widgetSpacingSmall: isDeviceMobile ? 0 : 1
	};
};

export default useAppDefaults;
