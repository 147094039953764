import { Avatar, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";

import { getMenuItemDetailsStockText } from "./menuUtils";

import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import EnvConfig from "../../utils/EnvConfig";
import Image from "mui-image";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const Restrictions = ({ restriction }) => {
	const { icon_url, label } = restriction;
	return (
		<Stack justifyContent="flex-start" alignItems="center">
			<Avatar srcSet={EnvConfig.baseImageURL + icon_url} alt="" className={clsx(classes.menuItemRestrictionImage)} />
			<Typography variant="caption" align="center" className={clsx(classes.menuItemRestrictionFontSize)}>
				{label}
			</Typography>
		</Stack>
	);
};

const NutritionItem = ({ label, value, suffix, thickDivider }) => {
	return (
		<Stack direction="row" justifyContent="space-between" className={clsx({ [classes.thickDivider]: thickDivider })}>
			<Typography variant="caption" align="center">
				{label}
			</Typography>
			<Typography variant="caption" align="center">
				{`${value}${suffix}`}
			</Typography>
		</Stack>
	);
};

const Nutrition = ({ menuItem }) => {
	const {
		nutrition_calories,
		nutrition_carbohydrates,
		nutrition_cholesterol,
		nutrition_fat,
		nutrition_fiber,
		nutrition_protein,
		nutrition_sodium,
		nutrition_sugar
	} = menuItem;
	let showNutrition = false;
	if (
		nutrition_carbohydrates + nutrition_cholesterol + nutrition_fat + nutrition_fiber + nutrition_protein + nutrition_sodium + nutrition_sugar >
		0
	) {
		showNutrition = true;
	}

	return nutrition_calories > 0 || showNutrition ? (
		<Stack padding={2} spacing={1} divider={<Divider role="presentation" flexItem />}>
			<Typography variant="h4" variantMapping={{ h4: "h2" }} className={classes.thickDivider}>
				NUTRITION FACTS
			</Typography>
			{nutrition_calories > 0 && <NutritionItem label={"Calories"} value={nutrition_calories} suffix={" Cals"} />}
			{showNutrition && [
				<NutritionItem label={"Fat"} value={nutrition_fat} suffix={"g"} key={"nutrition-item-Fat"} />,
				<NutritionItem label={"Cholesterol"} value={nutrition_cholesterol} suffix={"mg"} key={"nutrition-item-Cholesterol"} />,
				<NutritionItem label={"Sodium"} value={nutrition_sodium} suffix={"mg"} key={"nutrition-item-Sodium"} />,
				<NutritionItem label={"Carbohydrates"} value={nutrition_carbohydrates} suffix={"g"} key={"nutrition-item-Carbohydrates"} />,
				<NutritionItem label={"Fiber"} value={nutrition_fiber} suffix={"g"} key={"nutrition-item-Fiber"} />,
				<NutritionItem label={"Sugar"} value={nutrition_sugar} suffix={"g"} key={"nutrition-item-Sugar"} />,
				<NutritionItem label={"Protein"} value={nutrition_protein} suffix={"g"} key={"nutrition-item-Protein"} thickDivider />
			]}
		</Stack>
	) : null;
};

const MenuItemDetails = ({ menuItem, isCombo, location }) => {
	const { isDeviceMobile } = useAppDefaults();
	if (!menuItem) return <></>;
	const { cover_picture_url, description, dietary_restrictions, name } = menuItem;

	const restrictions = dietary_restrictions?.slice(0, Constants.maxDietaryFlags).filter((r) => r.icon_url !== "") || [];

	const { stockText, lowStock } = getMenuItemDetailsStockText(menuItem, location);

	const nutrition = Nutrition({ menuItem: menuItem });
	const hasNoDetails =
		cover_picture_url === "" &&
		!nutrition &&
		menuItem.options.length === 0 &&
		description === "" &&
		!isCombo &&
		!stockText &&
		restrictions.length === 0;

	return (
		<Paper elevation={0} variant="outlined" square>
			{/* TODO: added the conversion to block or flex to resolve over lapping image into next section, this needs to be reviewed, as this was a quick fix*/}
			<Grid container direction={isDeviceMobile ? "column" : "row"} display={isDeviceMobile ? "block" : "flex"}>
				{hasNoDetails ? (
					<Grid item>
						<Typography align="center" p={1}>
							No image available
						</Typography>
					</Grid>
				) : (
					<>
						<Grid item xs={6} className={clsx(classes.menuItemGridItem)}>
							<Stack direction="column" alignItems={"center"}>
								{cover_picture_url && cover_picture_url !== "" && (
									<Image
										src={EnvConfig.baseImageURL + cover_picture_url}
										alt={name}
										duration={350}
										className={classes.menuItemDetailsImage}
									/>
								)}
								{description && (
									<Typography padding={2} variant="body1" align="center">
										{description}
									</Typography>
								)}
								{!isCombo && (
									<>
										{stockText && (
											<>
												<Typography
													padding={2}
													variant="body1"
													align="center"
													className={clsx({ [classes.errorTextRedItalicsBold]: lowStock })}
												>
													{stockText}
												</Typography>
												<Divider role="presentation" />
											</>
										)}

										{restrictions.length > 0 && (
											<Grid container direction="row" padding={2} spacing={1} justifyContent="space-evenly">
												{restrictions.map((restriction) => {
													return (
														<Grid item key={restriction.label}>
															<Restrictions restriction={restriction} />
														</Grid>
													);
												})}
											</Grid>
										)}
									</>
								)}
							</Stack>
						</Grid>
						<Grid item xs={6}>
							{!isCombo && nutrition}
						</Grid>
					</>
				)}
			</Grid>
		</Paper>
	);
};

export default MenuItemDetails;
