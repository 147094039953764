import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { enqueueSnackbar } from "../../../store/Actions";
import ConstantsLabels from "../../../utils/ConstantsLabels";
import Loading from "../../../utils/components/Loading";

import ApiEndpoints from "../../../api/ApiEndpoints";
import CustomFormTextInput from "../../../utils/forms/CustomFormTextInput";
import CustomModal2 from "../CustomModal2";

const ApplyPromoModalForm = ({ doApiCall, setIsShowing, isShowing }) => {
	const dispatch = useDispatch();

	const [promoCode, setPromoCode] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [busy, setBusy] = useState(false);

	const displaySuccessMessage = (msg, reason) => {
		dispatch(
			enqueueSnackbar({
				message: msg,
				title: reason,
				excludeFromHistory: false,
				options: { variant: "success" }
			})
		);
	};

	const validatePromoCode = () => {
		setErrorMessage("");
		let validated = false;
		if (promoCode === "") {
			setErrorMessage("Enter a promo code or referral code.");
		} else if (promoCode !== "" && promoCode.length < 4) {
			setErrorMessage("Promo code must be at least 4 characters.");
		} else if (promoCode.length > 15) {
			setErrorMessage("Promo code must no more than 15 characters.");
		} else {
			validated = true;
		}
		return validated;
	};

	const handleSubmitPromoCode = () => {
		if (!validatePromoCode()) {
			return;
		}

		const body = {
			promo_code: promoCode
		};

		doApiCall(
			ApiEndpoints.ApplyPromoRegistration,
			body,
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				displaySuccessMessage(data.promo_message, data.message);
				setIsShowing(false);
			},
			() => {}
		);
	};

	return (
		<CustomModal2
			buttonText={"OK"}
			setIsShowing={setIsShowing}
			isShowing={isShowing}
			closeModalOnButtonClick={true}
			modalLabel={ConstantsLabels.labelApplyPromo}
			onButtonClick={() => {
				handleSubmitPromoCode();
			}}
			onClose={() => {
				setErrorMessage("");
				setPromoCode("");
			}}
			preventOutsideClick={true}
			showButton={true}
			title={ConstantsLabels.labelApplyPromo}
		>
			<CustomFormTextInput value={promoCode} setValue={setPromoCode} errorText={errorMessage} maxLength={15} />
			<Loading open={busy} />
		</CustomModal2>
	);
};

export default ApplyPromoModalForm;
