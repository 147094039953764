import React from "react";
import { Log, LogError } from "./../Utils";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		LogError(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		Log(error);
		Log(errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div>App Error. Please reload the page.</div>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

//not sure how do this with a functional component
