import { Toolbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import LegalPage from "./LegalPage";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { GlobalStyle, classes } from "../theme/GlobalStyle";

import { LogDebug } from "../utils/Utils";
import Loading from "../utils/components/Loading";

import CustomAppBar from "../views/custom/CustomAppBar";


const ContainerDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const LegalRoutingPage = ({ campusData, userData }) => {
	const theme = useTheme();

	const [busy, setBusy] = useState(false);
	const { campus } = campusData;

	if (!theme.isThemeLoaded) return <></>;

	if (busy) return <Loading />;
	if (!campus) return <Loading />;

	return (
		<>
			<CustomAppBar />
			{/* Second Toolbar used for spacing */}
			<Toolbar />
			<ContainerDiv className={classes.defaultPageContainer} role="main">
				<Routes>
					<Route path={`/`} element={<LegalPage />} />
				</Routes>
			</ContainerDiv>
		</>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(LegalRoutingPage);
