import React from "react";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";
import { useState } from "react";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "../../store/Actions";

export const InputReqDialog = ({
	isVisible,
	setIsVisible,
	title,
	cancelCallback,
	dialogContentComponent: DialogContentComponent,
	isContinueDisabled,
	callGetUserOnClickConfirm
}) => {
	const { getUser } = useCommonApiCalls();
	const dispatch = useDispatch();
	const [isFirstAttempt, setIsFirstAttempt] = useState(true);
	const [busy, setBusy] = useState(false);

	return (
		<ConfirmAlertDialog
			isVisible={isVisible}
			setIsVisible={setIsVisible}
			title={title}
			centerTitle
			titlePrimaryBackground
			dialogContentComponent={<DialogContentComponent isFirstAttempt={isFirstAttempt} />}
			confirmButtonText={isFirstAttempt ? "Continue" : "Retry"}
			isButtonDisabled={isContinueDisabled}
			cancelButtonText={ConstantsLabels.labelMenuLogOut}
			confirmCallback={() => {
				callGetUserOnClickConfirm &&
					getUser({
						setBusy,
						onSuccess: (data) => {
							setIsFirstAttempt(false);
							if (data.user?.phone_verified === 1 && data.user?.email_verified === 1) {
								dispatch(
									enqueueSnackbar({
										message: "Account Verified",
										title: "Account Verified",
										excludeFromHistory: false,
										options: { variant: "success" }
									})
								);
							}
						},
						onFailure: () => {
							setIsFirstAttempt(false);
						}
					});
			}}
			showLoading={busy}
			preventCloseOnClickOk
			preventCloseOnClickOutside
			cancelCallback={cancelCallback}
		/>
	);
};
