import { Divider, Grid, List, ListItem, Typography, Stack } from "@mui/material";

import React, { useState, useRef } from "react";

import { classes } from "../../theme/GlobalStyle";
import { getCurrencyString } from "../../utils/Utils";

import CustomPaperContainer from "../custom/CustomPaperContainer";

import TransactionFeedbackModalForm from "./TransactionFeedbackModalForm";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomValuesList from "../custom/CustomValuesList";
import CustomModal2 from "../modals/CustomModal2";
import useAppDefaults from "../../utils/hooks/useAppDefaults";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import TransactionItemDetailsFeedbackSection from "./TransactionItemDetailsFeedbackSection";
import useCommonData from "../../utils/hooks/useCommonData";
import { buildTransactionDetailsTotalsArray, getTransactionDetailsTenderValues } from "./TransactionUtils";
import Constants from "../../utils/Constants";

const TransactionPaymentDetailsRow = ({ label, labelVariant = "h6", value, valueVariant = "body2", ...other }) => {
	return (
		<ListItem className={classes.fullWidth} {...other}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" className={classes.fullWidth}>
				<Typography variant={labelVariant} variantMapping={{ labelVariant: "h2" }}>
					{label}
				</Typography>
				<Typography variant={valueVariant} variantMapping={{ valueVariant: "h2" }}>
					{value}
				</Typography>
			</Stack>
		</ListItem>
	);
};

const TransactionOrderItem = ({ item, bulletNumber }) => {
	const options = item.options;
	const selectedValues = options.flatMap((o) => {
		return o.values.map((v) => v);
	});

	return (
		<Grid container direction="row" spacing={1}>
			<Grid item xs>
				<Grid container spacing={1}>
					<Grid item xs>
						<Grid container direction="row" spacing={1}>
							<Grid item xs>
								<Typography variant="h6" variantMapping={{ h6: "h2" }}>{`${bulletNumber}. ${item.name}`}</Typography>
							</Grid>

							<Grid item xs="auto">
								<Typography variant="caption">{item.price_base > 0 ? getCurrencyString(item.price_base) : ""}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs>
					<Grid container direction="column" spacing={1} paddingLeft={3}>
						{selectedValues.map((v) => {
							const comboValues = [];

							if (v.combo_itemid > 0) {
								v.options?.forEach((o) => {
									o.values.forEach((value) => {
										const name = `${value.name}${value.price > 0 ? ` (+${getCurrencyString(value.price)})` : ""}`;
										comboValues.push({ name, valueid: value.valueid, price: value.price });
									});
								});
							}

							return [
								<Grid item key={`${v.optionid}${v.valueid}`}>
									<Grid container direction="row" spacing={1}>
										<Grid item xs>
											<Typography variant="caption">{v.name}</Typography>
										</Grid>

										<Grid item xs="auto">
											<Typography variant="caption">{`${
												!v.price || v.price === 0 ? "-" : `+ ${getCurrencyString(v.price)}`
											}`}</Typography>
										</Grid>
									</Grid>
								</Grid>,
								comboValues.length > 0 && (
									<Grid item key={`${v.optionid}${v.valueid}-values-list`}>
										<Grid container direction="row" justifyItems="center" alignItems="center" spacing={1}>
											<Grid item xs>
												<CustomValuesList valueArray={comboValues} keyId={"valueid"} paddingLeft={2} />
											</Grid>

											<Grid item xs="auto">
												<Typography variant="caption">{`+ ${getCurrencyString(
													comboValues.reduce((partial, current) => partial + current["price"] || 0, 0)
												)}`}</Typography>
											</Grid>
										</Grid>
									</Grid>
								)
							];
						})}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const TransactionItemDetails = ({ orderData: order, locationData: location }) => {
	const modalFormRef = useRef();
	const { widgetSpacing } = useAppDefaults();
	const { campus, getMenuFromLocationId } = useCommonData();
	const { mealplan_name } = campus;
	const { creditcard_total, special_comment, special_comment_choices, retrieval_type } = order; // retrieval_type - 0 pickup or 1 delivery

	const [isShowingRateOrder, setIsShowingRateOrder] = useState(false);
	const [isShowingRatingButton, setIsShowingRatingButton] = useState(false);
	const [isShowingRatedMessage, setIsShowingRatedMessage] = useState(false);
	const [ratedMessage, setRatedMessage] = useState("");

	const [isRateButtonDisabled, setIsRateButtonDisabled] = useState(true);

	if (!order || !location) return <></>;

	const hidePriceIfZero =
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_FREE_ITEMS_NOPAYMENTDETAILS) ||
		(order.virtualqueue === 1 && order.location_subtotal === 0);

	const transactionDetailsTotalsList = buildTransactionDetailsTotalsArray(campus, order);

	const menu = getMenuFromLocationId(location.locationid);
	const { tenderType, tenderDisplayValue, secondaryTenderType, secondaryTenderDisplayValue } = getTransactionDetailsTenderValues(menu, order);

	const filteredItems = order?.items?.filter((i) => !i.comboHide);

	const handleShowFeedback = (e) => {
		setIsShowingRateOrder(true);
	};

	const handleSaveOrderFeedback = (closeModalCallback) => {
		modalFormRef.current.saveOrderFeedback();
		closeModalCallback();
	};

	return (
		<Stack spacing={widgetSpacing}>
			<CustomPaperContainer>
				<Grid container spacing={1} direction="column" alignItems="stretch">
					{filteredItems.length > 0 && (
						<>
							<Grid item xs>
								<List className={classes.listTransparentNoBorder}>
									{filteredItems.map((item, i) => {
										return (
											<div key={item.itemid}>
												<ListItem>
													<TransactionOrderItem item={item} bulletNumber={i + 1} />
												</ListItem>
												{i + 1 < filteredItems.length && <Divider role="presentation" variant="middle" />}
											</div>
										);
									})}
								</List>
							</Grid>
							{!hidePriceIfZero && (
								<Grid item>
									<Divider role="presentation" />
								</Grid>
							)}
						</>
					)}

					{!hidePriceIfZero && (
						<>	
							<Grid item mt={filteredItems.length === 0 ? 1 : 0}>
								<List className={classes.listTransparentNoBorder} dense>
									{transactionDetailsTotalsList.map((item) => {
										return (
											<TransactionPaymentDetailsRow
												label={item.label}
												labelVariant="subtitle1"
												value={item.value}
												valueVariant="subtitle1"
												sx={{ pb: 0, pt: 0 }}
												key={`total row item ${item.label}`}
											/>
										);
									})}
								</List>

								<Divider role="presentation" variant="middle" />
							</Grid>
							<Grid item margin={2} mt={1}>
								<Typography variant="h5" variantMapping={{ h5: "h2" }} align={"right"}>{`Total: ${getCurrencyString(
									order?.total_net_payout
								)}`}</Typography>
							</Grid>
						</>
					)}
				</Grid>
			</CustomPaperContainer>

			{order?.total_net_payout > 0 && (
				<CustomPaperContainer>
					<List className={classes.listTransparentNoBorder} dense>
						{mealplan_name && creditcard_total === 0 && <TransactionPaymentDetailsRow label={mealplan_name} />}
						<TransactionPaymentDetailsRow label={tenderType} value={tenderDisplayValue} />
						{secondaryTenderType !== "" && (
							<TransactionPaymentDetailsRow
								label={secondaryTenderType}
								value={secondaryTenderDisplayValue}
								valueVariant="subtitle1"
							/>
						)}
					</List>
				</CustomPaperContainer>
			)}

			{special_comment_choices?.length > 0 && (
				<CustomPaperContainer>
					<List className={classes.listTransparentNoBorder} dense>
						{special_comment_choices.split("||").map((choice) => {
							return <TransactionPaymentDetailsRow label={choice} key={choice} />;
						})}
					</List>
				</CustomPaperContainer>
			)}
			{special_comment?.length > 0 && (
				<CustomPaperContainer>
					<List className={classes.listTransparentNoBorder} dense>
						<TransactionPaymentDetailsRow label={"Comment:"} value={special_comment} />
					</List>
				</CustomPaperContainer>
			)}

			<CustomPaperContainer>
				<List className={classes.listTransparentNoBorder} dense>
					{!hidePriceIfZero && location?.tax_number?.length > 0 && (
						<TransactionPaymentDetailsRow label={"Tax #"} value={location.tax_number} />
					)}
					<TransactionPaymentDetailsRow label={"Transaction #"} value={order.orderid} />
				</List>
			</CustomPaperContainer>

			<TransactionItemDetailsFeedbackSection
				order={order}
				location={location}
				campus={campus}
				setRatedMessage={setRatedMessage}
				setIsShowingRatingButton={setIsShowingRatingButton}
				setIsShowingRatedMessage={setIsShowingRatedMessage}
				isShowingRatingButton={isShowingRatingButton}
				isShowingRatedMessage={isShowingRatedMessage}
				handleShowFeedback={handleShowFeedback}
				ratedMessage={ratedMessage}
			/>

			<CustomModal2
				modalLabel={ConstantsLabels.labelRateOrder}
				setIsShowing={setIsShowingRateOrder}
				isShowing={isShowingRateOrder}
				title={ConstantsLabels.labelRateOrder}
				maxWidth={650}
				minWidth={250}
				showButton
				onButtonClick={handleSaveOrderFeedback}
				buttonText="Rate Order"
				closeModalOnButtonClick
				isButtonDisabled={isRateButtonDisabled}
			>
				<TransactionFeedbackModalForm order={order} ref={modalFormRef} setIsButtonDisabled={setIsRateButtonDisabled} />
			</CustomModal2>
		</Stack>
	);
};
export default TransactionItemDetails;
