import clsx from "clsx";
import React, { useState } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { handleSendPhoneText } from "./signInUtils";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import Stack from "@mui/material/Stack";

const PhoneNumberReqModal = ({ doApiCall }) => {
	const [value, setValue] = useState("");

	const handleChange = (e) => {
		e && e.preventDefault();
		setValue(e.target.value);
	};

	return (
		<CustomPaperContainer>
			<Stack direction="column" padding={1} alignItems="center" spacing={2}>
				<Typography variant="body1" mb={0.1}>
					{ConstantsLabels.labelPhoneRequired.join("\n")}
				</Typography>
				<InputMask value={value} onChange={handleChange} placeholder={"999-999-9999"} mask={"999-999-9999"} maskChar={"9"}>
					{() => <TextField className={classes.textfieldInput} fullWidth margin={"normal"} type={"tel"} />}
				</InputMask>
				<CustomFormButton
					buttonText={`Send Text`}
					clsxClasses={clsx(classes.confirmButton)}
					onClick={() => handleSendPhoneText(value, doApiCall)}
					marginTop={1}
				/>
			</Stack>
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(PhoneNumberReqModal);
