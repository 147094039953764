import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../../store/CartUtils";
import * as ApiCall from "../../api/ApiCall";
import ApiEndpoints from "../../api/ApiEndpoints";
import { enqueueSnackbar, updateScheduledTime } from "../../store/Actions";
import Constants from "../Constants";

const useCommonApiCalls = () => {
	const dispatch = useDispatch();
	const campusid = useSelector((store) => store.campusData?.campus?.campusid);
	const carts = useSelector((store) => store.cartData?.carts);
	const user = useSelector((store) => store.userData);

	function getPastOrders(setBusy = () => {}) {
		dispatch(
			ApiCall.apiCall(
				ApiEndpoints.GetPastOrders,
				{},
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: true, title: "Common API - getPastOrders" },
				(data) => {},
				(errorMsg, errorReason) => {}
			)
		);
	}

	function getPendingOrders(setBusy = () => {}) {
		dispatch(
			ApiCall.apiCall(
				ApiEndpoints.GetPendingOrders,
				{},
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: true },
				(data) => {},
				(errorMsg, errorReason) => {}
			)
		);
	}

	function getMenu(locationid, options = {}) {
		const { setBusy = () => {}, target_date = null, date_display = "", newPickupOrDeliveryValue, onSuccess = () => {} } = options;
		const cart = getCart(carts, parseInt(locationid)) || {};
		const { ct_id, date_local, end_time, pickupOrDelivery } = cart;
		const payment_method_default = user?.payment_method_default?.toString() || "1";

		dispatch(
			ApiCall.apiCall(
				ApiEndpoints.GetMenu,
				{
					campusid: campusid.toString(),
					locationid: locationid.toString(),
					retrieval_type: (newPickupOrDeliveryValue || pickupOrDelivery || Constants.pickupDeliveryTypeEnum.PICKUP_0)?.toString(),
					payment_method: payment_method_default,
					ct_id: ct_id?.toString() || "0",
					target_date: target_date || date_local || "",
					target_time: end_time || ""
				},
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: true, title: "Common API - Menu" },
				(data) => {
					if (data.target_time_changed === "1") {
						dispatch(updateScheduledTime(locationid, date_display, data.target_date, data.target_time, ""));
						const title = `${
							pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 ? "Delivery" : "Pickup"
						} time changed`;
						const message = `${title} to ${date_display} ${data.target_time === "" ? "ASAP" : `at ${data.target_time}`}`;
						dispatch(
							enqueueSnackbar({
								message,
								title,
								excludeFromHistory: true,
								options: { variant: "success" }
							})
						);
					}
					onSuccess(data);
				},
				(errorMsg, errorReason) => {}
			)
		);
	}

	const getUser = (options = {}) => {
		const { setBusy = () => {}, onSuccess = () => {}, onFailure = () => {} } = options;
		dispatch(
			ApiCall.apiCall(
				ApiEndpoints.GetUser,
				{},
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: true },
				(data) => {
					onSuccess(data);
				},
				(errorMsg, errorReason) => {
					onFailure(errorMsg, errorReason);
				}
			)
		);
	};

	return { getMenu, getPastOrders, getPendingOrders, getUser };
};

export default useCommonApiCalls;
