import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import _ from "lodash";
import React from "react";

import ScannerComponent from "./ScannerComponent";

import ApiEndpoints from "../../api/ApiEndpoints";
import ConstantsLabels from "../../utils/ConstantsLabels";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";

const LocationCheckinComponent = ({
	location,
	statusOrderCheckinTitleText,
	lastOrder,
	modalFormRef,
	doApiCall,
	doUpdateErrorAlert,
	doClearErrorAlert,
	doEnqueueSnackbar,
	setBusy
}) => {
	let component = undefined;

	const checkinReq = hasFeatureFlag(location, FeatureConstants.FEA_LOC_USER_CHECKIN_REQUIRED);
	const checkinReqCamera = hasFeatureFlag(location, FeatureConstants.FEA_LOC_USER_CHECKIN_REQUIRED_CAMERASCAN);
	const { orderid } = lastOrder || {};

	const handleCheckinCamera = (closeModalCallback) => {
		if (modalFormRef.current.getScannedCode()) {
			modalFormRef.current.doCheckin(closeModalCallback);
		}
	};

	const handleCheckin = () => {
		doApiCall(
			ApiEndpoints.CheckinOrder,
			{ orderid: orderid.toString(), checkin_barcode: "" },
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				doEnqueueSnackbar({
					message: ConstantsLabels.labelConfirmCheckin,
					title: "Checked-In",
					excludeFromHistory: false,
					options: { variant: "success" }
				});
			},
			(errorMsg, errorReason) => {}
		);
	};

	if (statusOrderCheckinTitleText) {
		if (checkinReqCamera) {
			component = (
				<CustomFormLabelItem
					colorRed
					dense
					label={statusOrderCheckinTitleText}
					Icon={WarningAmberIcon}
					IconProps={{ alt: "Scan check in required" }}
					modalForm={ScannerComponent}
					useCustomModal2
					modalFormProps={{
						order: lastOrder,
						location: location,
						ref: modalFormRef,
						doApiCall: doApiCall,
						doUpdateErrorAlert: doUpdateErrorAlert,
						doClearErrorAlert: doClearErrorAlert,
						doEnqueueSnackbar: doEnqueueSnackbar,
						handleCheckinCamera: handleCheckinCamera
					}}
					modalProps={{
						closeModalOnButtonClick: true,
						modalLabel: "Scan Barcode / QR Code to Check in",
						preventOutsideClick: true,
						showButton: false,
						title: "Scan Barcode / QR Code to Check in"
					}}
				/>
			);
		} else if (checkinReq && !checkinReqCamera) {
			component = (
				<CustomFormLabelItem
					colorRed
					dense
					label={statusOrderCheckinTitleText}
					onClick={handleCheckin}
					Icon={WarningAmberIcon}
					IconProps={{ alt: "Check In Required" }}
				/>
			);
		}
	}
	return component;
};

export default LocationCheckinComponent;
