import { deleteToken, getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { apiCall } from "../../api/ApiCall";
import ApiEndpoints from "../../api/ApiEndpoints";
import { enqueueSnackbar, updatePushToken } from "../../store/Actions";
import useCommonApiCalls from "../hooks/useCommonApiCalls";
import { LogDebug, LogError } from "../Utils";
import { fb_app } from "./fb-config";

// https://firebase.google.com/docs/cloud-messaging/js/client

let messaging = null;

// Delete token - ensures we have a fresh one incase a new user logs in
export const deletePushToken = async () => {
	try {
		if (messaging) {
			const deleteTokenSuccess = await deleteToken(messaging);
			LogDebug("Trying to delete current pushToken. Result: ", deleteTokenSuccess);
		} else {
			LogError("An error occurred while deleting Push Message token: no `messaging` object available. ");
		}
	} catch (error) {
		LogError("An error occurred while deleting Push Message token. ", error);
	}
};

const usePushNotification = () => {
	const dispatch = useDispatch();
	const { getUser } = useCommonApiCalls();

	const initPushMessaging = async () => {
		// Initialize pushMessaging if we're running locally/in dev environment
		// `isSupported() = false` when app is used exposed via http and not https - The following check allows us to get around that when developing/running locally
		if (window.location.hostname === "localhost") {
			LogDebug("localhost firebase messaging set up");
			initMessageListener();
			return;
		} else {
			try {
				// Only initialize if we're in a supported browser - things will crash otherwise
				const isSupportedBrowser = await isSupported();
				if (isSupportedBrowser) {
					initMessageListener();
				}
				LogDebug("Firebase not supported this browser");
				return null;
			} catch (error) {
				LogError(error);
				return null;
			}
		}
	};

	const initMessageListener = () => {
		messaging = getMessaging(fb_app);
		onMessage(messaging, (payload) => {
			if (payload.notification?.title) {
				getUser();
				dispatch(
					enqueueSnackbar({
						message: `${payload.notification?.title} ${payload.notification?.body}`,
						title: payload.notification?.title,
						excludeFromHistory: false,
						options: { variant: "information" }
					})
				);
			}
		});
	};

	const callUpdateUserPushToken = (orderid, currentToken) => {
		const reqBody = {
			orderid: orderid,
			push_token: currentToken
		};

		dispatch(
			apiCall(
				ApiEndpoints.UpdateUserPushToken,
				reqBody,
				null,
				{ logRequest: true, logResponse: true, hideErrorToast: false },
				(data) => {},
				(errorMsg, errorReason) => {}
			)
		);
	};

	const requestNotificationPermission = async (orderid) => {
		LogDebug("Requesting permission to send notifications");
		try {
			const isSupportedBrowser = await isSupported();
			if (!isSupportedBrowser) return;
			const permission = await Notification.requestPermission();
			if (permission === "granted") {
				LogDebug("Notification permission granted.");
				const currentToken = await requestPushToken();
				callUpdateUserPushToken(orderid, currentToken);
			} else {
				LogDebug("Notification permission denied.");
			}
		} catch (error) {
			LogError(error);
		}
	};

	const requestPushToken = async () => {
		try {
			const isSupportedBrowser = await isSupported();
			if (Notification.permission === "granted" && isSupportedBrowser) {
				const currentToken = await getToken(messaging, {
					vapidKey: process.env.REACT_APP_FB_WPC_KEY
				});
				if (currentToken) {
					LogDebug("Current Push Message token for client: ", currentToken);
					dispatch(updatePushToken(currentToken));
					return currentToken;
				} else {
					// Show permission request UI
					LogDebug("No Push Message registration token available. Request permission to generate one.");
				}
			}
		} catch (error) {
			LogError("An error occurred while retrieving Push Message token. ", error);
		}
		return;
	};

	return { initPushMessaging, requestNotificationPermission, requestPushToken };
};

export default usePushNotification;
