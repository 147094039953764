import React, { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { isDeviceMobileFx, openLink } from "../../utils/Utils";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Stack from "@mui/material/Stack";

import { Avatar, Divider, Typography, useMediaQuery } from "@mui/material";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import { getObjectFromArray } from "../../utils/ObjectUtils";
import clsx from "clsx";
import { checkOrderCheckinRequiredStatus, getHeaderText, setHeaderPhotos, updateOrderTimer } from "./LocationUtils";
import _ from "lodash";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import useAppDefaults from "../../utils/hooks/useAppDefaults";
import TransactionFeedbackModalForm from "../transactions/TransactionFeedbackModalForm";
import LocationCheckinComponent from "./LocationCheckinComponent";
import Image from "mui-image";

const HeaderStatus = ({ currentlyActive, icon, text }) => (
	<Stack direction="column" paddingTop={1} alignItems={"center"}>
		<Avatar
			src={`../../../images/statusicons/${icon}.png`}
			alt={""}
			className={clsx({ [classes.locationHeaderIconActive]: currentlyActive, [classes.locationHeaderIconNotActive]: !currentlyActive })}
			variant="rounded"
		/>
		<Typography
			variant="subtitle2"
			variantMapping={{ subtitle2: "span" }}
			className={clsx({ [classes.locationHeaderTextActive]: currentlyActive, [classes.locationHeaderTextNotActive]: !currentlyActive })}
		>
			{text}
		</Typography>
	</Stack>
);

const LocationHeader = ({
	cafeterias,
	campus,
	campusID,
	locations,
	setBusy,
	user,
	doApiCall,
	doUpdateErrorAlert,
	doClearErrorAlert,
	doEnqueueSnackbar
}) => {
	const modalFormRef = useRef();
	const navigate = useNavigate();
	const { getPastOrders } = useCommonApiCalls();

	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const { widgetSpacing } = useAppDefaults();
	const { last_order: lastOrder, pending_orders_count: pendingOrdersCount } = user || {};
	const location = getObjectFromArray(lastOrder?.locationid, locations, "locationid");
	const { orderid, orderid_short_string } = lastOrder || {};
	const { links } = campus;

	const cafeteria = cafeterias?.find((c) => c.cafeteriaid === location?.cafeteriaid);

	const {
		announcementTitleText,
		receiptTitleText,
		pickupInstructionsTitleText,
		statusRatingTitleText,
		receivedText,
		preparingText,
		readyText,
		inviteFriendTitleText,
		campusShowMapTitleText,
		receivedIcon,
		preparingIcon,
		readyIcon,
		progressState,
		hasCurrentOrder
	} = getHeaderText(campus, user, lastOrder, location) || {};
	const { pictureUrl, locationIconUrl } = setHeaderPhotos(cafeteria, campus, location, lastOrder) || {};
	const timeText = updateOrderTimer(lastOrder, location);
	const statusOrderCheckinTitleText = checkOrderCheckinRequiredStatus(lastOrder, location);

	let headerLinks = links.filter((l) => l.home_visible === 1)?.sort((a, b) => a.home_position - b.home_position);
	if (hasCurrentOrder && headerLinks.length > 0) {
		headerLinks = headerLinks.filter((l) => l.home_show_on_status === 1);
	}

	const handleViewReceipt = () => {
		getPastOrders();
		navigate(`/user/transaction?orderid=${orderid}`);
	};

	const handleInviteAFriend = (e) => {
		navigate("/user/inviteafriend");
	};

	const handleViewMap = (e) => {
		navigate(`/${campusID}/map`);
	};

	const handleViewOrderQueue = () => {
		navigate("/user/pending_orders");
	};

	const handleSaveOrderFeedback = (closeModalCallback) => {
		modalFormRef.current.saveOrderFeedback();
		closeModalCallback();
	};

	const _blankImgSrc = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="; // http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever

	return (
		<Stack direction="column" mb={widgetSpacing}>
			{user ? (
				<>
					<div className={classes.locationHeaderImageDiv}>
						<Image
							src={pictureUrl || _blankImgSrc}
							alt="campus cover"
							className={classes.locationHeaderImage}
							height="200px"
							duration={350}
						/>
						{locationIconUrl && (
							<Image
								errorIcon={null}
								src={locationIconUrl}
								alt="location"
								wrapperClassName={classes.locationHeaderImageLocationIcon}
								duration={350}
								height="48px"
								width="48px"
							/>
						)}
					</div>
					<CustomPaperContainer>
						<Typography variant="h4" variantMapping={{ h4: "h1" }} align="center" paddingTop={0.5}>
							{timeText || receiptTitleText}
						</Typography>
						{(announcementTitleText || pickupInstructionsTitleText) && (
							<Typography variant="subtitle1" variantMapping={{ subtitle1: "h2" }} align="center" paddingBottom={1}>
								{announcementTitleText || pickupInstructionsTitleText}
							</Typography>
						)}
						{inviteFriendTitleText && (
							<>
								<Divider role="presentation" />
								<CustomFormLabelItem
									dense
									label={inviteFriendTitleText}
									labelVariantMapping={"span"}
									onClick={handleInviteAFriend}
									Icon={PersonAddAlt1OutlinedIcon}
									IconProps={{ alt: "" }}
									hideDivider
								/>
							</>
						)}
						{campusShowMapTitleText && (
							<>
								<Divider role="presentation" />
								<CustomFormLabelItem
									dense
									label={campusShowMapTitleText}
									labelVariantMapping={"span"}
									onClick={handleViewMap}
									Icon={LocationOnOutlinedIcon}
									IconProps={{ alt: "" }}
									hideDivider
								/>
							</>
						)}
						{hasCurrentOrder ? (
							<>
								<Divider role="presentation" />
								<Stack
									padding={0.5}
									spacing={2}
									direction="row"
									justifyContent={"space-evenly"}
									divider={<Divider role="presentation" orientation="vertical" flexItem />}
								>
									<HeaderStatus
										icon={receivedIcon}
										text={receivedText}
										currentlyActive={progressState === Constants.ORDER_PROGRESS_STATE_ENUM.STATE_1}
									/>
									<HeaderStatus
										icon={preparingIcon}
										text={preparingText}
										currentlyActive={progressState === Constants.ORDER_PROGRESS_STATE_ENUM.STATE_2}
									/>
									<HeaderStatus
										icon={readyIcon}
										text={readyText}
										currentlyActive={progressState === Constants.ORDER_PROGRESS_STATE_ENUM.STATE_3}
									/>
								</Stack>
								<Divider role="presentation" />
								<LocationCheckinComponent
									location={location}
									statusOrderCheckinTitleText={statusOrderCheckinTitleText}
									lastOrder={lastOrder}
									modalFormRef={modalFormRef}
									doApiCall={doApiCall}
									doUpdateErrorAlert={doUpdateErrorAlert}
									doClearErrorAlert={doClearErrorAlert}
									doEnqueueSnackbar={doEnqueueSnackbar}
									setBusy={setBusy}
								/>
								<Stack direction={isDeviceMobile ? "column" : "row"} alignItems={"center"} justifyContent="space-between">
									<CustomFormLabelItem
										dense
										label={`Order ${orderid_short_string} Receipt`}
										labelVariantMapping={"span"}
										onClick={handleViewReceipt}
										Icon={QrCode2Icon}
										IconProps={{ alt: "" }}
										hideDivider
									/>

									{pendingOrdersCount >= 1 && (
										<>
											<Divider
												role="presentation"
												orientation={isDeviceMobile ? "horizontal" : "vertical"}
												flexItem
											/>
											<CustomFormLabelItem
												dense
												label={`Order Queue (${pendingOrdersCount})`}
												labelVariantMapping={"span"}
												onClick={handleViewOrderQueue}
												Icon={ReceiptLongIcon}
												IconProps={{ alt: "" }}
												hideDivider
											/>
										</>
									)}
								</Stack>
								{statusRatingTitleText?.length > 1 && (
									<>
										<Divider role="presentation" flexItem />
										<CustomFormLabelItem
											dense
											label={statusRatingTitleText}
											labelVariantMapping={"span"}
											Icon={StarBorderIcon}
											IconProps={{ alt: "" }}
											hideDivider
											modalForm={TransactionFeedbackModalForm}
											modalFormProps={{
												order: lastOrder,
												doApiCall,
												doClearErrorAlert: () => {},
												setIsButtonDisabled: () => {},
												ref: modalFormRef
											}}
											modalProps={{
												buttonText: ConstantsLabels.labelRateOrder,
												closeModalOnButtonClick: true,
												preventOutsideClick: true,
												showButton: true,
												onButtonClick: handleSaveOrderFeedback,
												title: ConstantsLabels.labelRateOrder,
												modalLabel: ConstantsLabels.labelRateOrder
											}}
										/>
									</>
								)}
							</>
						) : (
							pendingOrdersCount >= 1 && (
								<>
									<Divider role="presentation" />
									<CustomFormLabelItem
										dense
										label={`Order Queue (${pendingOrdersCount})`}
										labelVariantMapping={"span"}
										onClick={handleViewOrderQueue}
										Icon={ReceiptLongIcon}
										IconProps={{ alt: "" }}
										hideDivider
									/>
								</>
							)
						)}
						{headerLinks.length > 0 &&
							headerLinks.map((l) => {
								return (
									<Fragment key={l.ccl_id}>
										<Divider role="presentation" flexItem />
										<CustomFormLabelItem
											dense
											label={l.label}
											labelVariantMapping={"span"}
											icon_url={l.icon_url_with_base}
											hideDivider
											onClick={() => openLink(l.url)}
											isAltPresentation
										/>
									</Fragment>
								);
							})}
					</CustomPaperContainer>
				</>
			) : (
				<Image src={pictureUrl || _blankImgSrc} alt="" className={classes.locationHeaderImage} height="200px" duration={350} />
			)}
		</Stack>
	);
};

export default LocationHeader;
