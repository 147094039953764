import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { cloneDeep } from "lodash";
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { classes, GlobalStyle } from "../../theme/GlobalStyle";

const RootStack = styled(Stack)(({ theme }) => GlobalStyle(theme));

const CheckoutOptionItemModalForm = forwardRef(
	(
		{
			title,
			optionType,
			minimum,
			maximum,
			selectChoices,
			boxid,
			showHelperMessageError,
			helperMessage,
			initialTextValue = "",
			hideClearButton = false,
			textFieldMaxLength,
			placeholder
		},
		ref
	) => {
		const [optionItemValues, setOptionItemValues] = useState([]);
		const [textFieldValue, setTextFieldValue] = useState(initialTextValue);

		useEffect(() => {
			setOptionItemValues(cloneDeep(selectChoices));
		}, [selectChoices]);

		useImperativeHandle(ref, () => ({
			getPromoCodeValue() {
				return textFieldValue || "";
			},
			getSaveValues() {
				if (optionType === "text") {
					return { optionType, updateValue: textFieldValue };
				} else {
					return { optionType, updateValue: optionItemValues, boxid };
				}
			}
		}));

		const handleOnChange = (e, optionType, choice) => {
			if (optionType === "text") {
				setTextFieldValue(e.target.value);
			} else {
				const clonedChoices = cloneDeep(optionItemValues);
				clonedChoices.forEach((csc) => {
					if (optionType === 0) {
						if (csc.choiceid.toString() === e.target.value) {
							csc.selected = true;
						} else {
							csc.selected = false;
						}
					} else if (optionType === 1)
						if (csc.choiceid === choice.choiceid) {
							csc.selected = e.target.checked;
						}
				});
				setOptionItemValues(clonedChoices);
			}
		};

		const error =
			optionItemValues?.filter((v) => v.selected).length < minimum || optionItemValues?.filter((v) => v.selected).length > maximum || false;

		return (
			<RootStack spacing={2}>
				{optionType === 0 && optionItemValues && (
					<RadioGroup aria-labelledby={`${title}-radio-buttons-group`} name={`${title}-radio-buttons-group`}>
						{optionItemValues.map((choice) => {
							return (
								<FormControlLabel
									control={
										<Radio
											value={choice.choiceid}
											key={choice.choiceid}
											checked={choice.selected}
											onChange={(e) => handleOnChange(e, optionType, choice)}
										/>
									}
									label={choice.choice_label}
									key={choice.choiceid}
								/>
							);
						})}
					</RadioGroup>
				)}

				{optionType === 1 && optionItemValues && (
					<FormControl>
						<FormGroup>
							{optionItemValues.map((choice) => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={choice.selected}
												onChange={(e) => handleOnChange(e, optionType, choice)}
												name={choice.choiceid.toString()}
											/>
										}
										label={choice.choice_label}
										key={choice.choiceid}
									/>
								);
							})}
							{helperMessage && (
								<FormHelperText
									error={showHelperMessageError && error}
									className={clsx(classes.helperTextAlignRight, { [classes.errorTextRed]: error })}
								>
									{`${optionItemValues.filter((v) => v.selected).length} of ${minimum}-${maximum} selected`}
								</FormHelperText>
							)}
						</FormGroup>
					</FormControl>
				)}

				{optionType === "text" && (
					<TextField
						id={`${title}-textfield`}
						label={title}
						value={textFieldValue}
						onChange={(e) => handleOnChange(e, optionType)}
						autoFocus
						fullWidth
						variant="outlined"
						inputProps={{ maxLength: textFieldMaxLength }}
						InputProps={
							hideClearButton
								? undefined
								: {
										endAdornment: (
											<InputAdornment position="end">
												<IconButton role={"button"} size="small" onClick={() => setTextFieldValue("")}>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)
								  }
						}
						placeholder={placeholder}
					/>
				)}
			</RootStack>
		);
	}
);

export default CheckoutOptionItemModalForm;
