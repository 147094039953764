import { API } from "../store/Actions";

const apiAction = ({ apiEndpoint = "", body = {}, setBusy = () => {}, onSuccess = () => {}, onFailure = () => {}, extraProps = undefined }) => {
	return {
		type: API,
		payload: {
			apiEndpoint,
			body,
			setBusy,
			extraProps,
			onSuccess,
			onFailure
		} //onSuccess(data). onFailure(error), setBusy(boolean)
	};
};

export const apiCall = (theEndpoint, theBody, setBusyCallBack, theExtraProps, successCallback, failureCallback) => {
	return apiAction({
		apiEndpoint: theEndpoint,
		body: theBody,
		setBusy: setBusyCallBack,
		extraProps: theExtraProps,
		onSuccess: successCallback,
		onFailure: failureCallback
	});
};
