import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import ProfileDetails from "../views/profile/ProfileDetails";
import { useEffect } from "react";
import ConstantsLabels from "../utils/ConstantsLabels";
import useCommonData from "../utils/hooks/useCommonData";
import { setAppPageTitle } from "../utils/Utils";

const ProfilePage = ({ doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const { campus, user } = useCommonData();

	const theme = useTheme();

	useEffect(() => {
		setAppPageTitle("Support");
	}, [navigate]);

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.profile);
	}, []);
	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoading={!campus || !user}>
			<ProfileDetails />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(ProfilePage);
