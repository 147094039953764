import { Log, isDarkThemeWithObj } from "../utils/Utils";
import { primaryTextColor } from "./CustomTheme";
import StyleConstants from "./StyleConstants";

const PREFIX = "Global";

export const classes = {
	root: `${PREFIX}-root`,

	appBar: `${PREFIX}-appBar`,
	appBarBrandLogoButton: `${PREFIX}-appBarBrandLogoButton`,
	appBarBrandLogoImg: `${PREFIX}-appBarBrandLogoImg`,
	appBarCheckoutItemName: `${PREFIX}-appBarCheckoutItemName`,
	appBarCheckoutPopover: `${PREFIX}-appBarCheckoutPopover`,
	appBarCheckoutPopoverHeader: `${PREFIX}-appBarCheckoutPopoverHeader`,
	appBarIconButton: `${PREFIX}-appBarIconButton`,
	appBarIconButtonLeft: `${PREFIX}-appBarIconButtonLeft`,
	appBarIconButtonRight: `${PREFIX}-appBarIconButtonRight`,
	appBarIconButtonRound: `${PREFIX}-appBarIconButtonRound`,
	appBarPopoverPlacement: `${PREFIX}-appBarPopoverPlacement`,
	appBarTextColor: `${PREFIX}-appBarTextColor`,
	bold: `$${PREFIX}-bold`,
	buttonAsLabel: `${PREFIX}-buttonAsLabel`,
	contentDivContainer: `${PREFIX}-contentDivContainer`,
	contentDivWithHeader: `${PREFIX}-contentDivWithHeader`,
	contentDivMargin: `${PREFIX}-contentDivMargin`,
	contentRootDiv: `${PREFIX}-contentRootDiv`,

	checkoutListItem: `${PREFIX}-checkoutListItem`,
	checkoutSectionTenderModalDiv: `${PREFIX}-checkoutSectionTenderModalDiv`,
	checkoutUpSellGrid: `${PREFIX}-checkoutUpSell`,
	customizationMenuAddToCartFAB: `${PREFIX}-customizationMenuAddToCartFAB`,
	customLabelItemListButton: `${PREFIX}-customLabelItemListButton`,
	customLabelItemListButtonBorder: `${PREFIX}-customLabelItemListButtonBorder`,
	customLabelItemListButtonDivider: `${PREFIX}-customLabelItemListButtonDivider`,
	customLabelItemGridDense: `${PREFIX}-customLabelItemGridDense`,

	defaultPageContainer: `${PREFIX}-defaultPageContainer`,
	displayFlex: `${PREFIX}-displayFlex`,
	disabledGrey: `${PREFIX}-disabledGrey`,

	errorTextRed: `${PREFIX}-errorTextRed`,
	errorTextRedItalicsBold: `${PREFIX}-errorTextRedItalicsBold`,

	fullHeight: `${PREFIX}-fullHeight`,
	fullWidth: `${PREFIX}-fullWidth`,
	fullWidthImportant: `${PREFIX}-fullWidthImportant`,

	grayScaleFilter: `${PREFIX}-grayScaleFilter`,
	groupHeader: `${PREFIX}-groupHeader`,

	heightInherit: `${PREFIX}-heightInherit`,
	heightMaxWebkitFitContent: `${PREFIX}-heightMaxWebkitFitContent`,
	heightWebkitFillAvailable: `${PREFIX}-heightWebkitFillAvailable`,
	helperTextAlignRight: `${PREFIX}-test`,
	hoverPointerWithBackground: `${PREFIX}-menuCustomizationOptionHover`,

	iconAvatar: `${PREFIX}-iconAvatar`,
	iconAvatarLarge: `${PREFIX}-iconAvatarLarge`,
	iconSpacing: `${PREFIX}-iconSpacing`,
	iconSpacingLarge: `${PREFIX}-iconLargeSpacing`,
	iconVerticalAlign: `${PREFIX}-iconVerticalAlign`,
	iconVerticalAlignMiddle: `${PREFIX}-iconVerticalAlignMiddle`,
	iconVerticalAlignBottom: `${PREFIX}-iconVerticalAlignBottom`,
	iframe: `${PREFIX}-iframe`,

	justifyCenter: `${PREFIX}-justifyCenter`,
	justifyLeft: `${PREFIX}-justifyLeft`,

	largeCenterImage: `${PREFIX}-largeCenterImage`,
	listTransparentNoBorder: `${PREFIX}-listTransparentNoBorder`,
	locationItem: `${PREFIX}-locationItem`,
	locationItemChevron: `${PREFIX}-locationItemChevron`,
	locationItemStarIcon: `${PREFIX}-locationItemStarIcon`,
	locationHeaderIconActive: `${PREFIX}-locationHeaderIcon`,
	locationHeaderIconNotActive: `${PREFIX}-locationHeaderIconNotActive`,
	locationHeaderImage: `${PREFIX}-locationHeaderImage`,
	locationHeaderImageDiv: `${PREFIX}-locationHeaderImageDiv`,
	locationHeaderImageLocationIcon: `${PREFIX}-locationHeaderImageLocationIcon`,
	locationHeaderTextActive: `${PREFIX}-locationHeaderTextActive`,
	locationHeaderTextNotActive: `${PREFIX}-locationHeaderTextNotActive`,
	locationHeaderStackActive: `${PREFIX}-locationHeaderStackActive`,

	marginAuto: `${PREFIX}-marginAuto`,
	menuContentDiv: `${PREFIX}-menuContentDiv`,
	menuContentDivWithSchedulePicker: `${PREFIX}-menuContentDivWithSchedulePicker`,
	menuItemDetailsImage: `${PREFIX}-menuItemDetailsImage`,
	menuItemGridItem: `${PREFIX}-menuItemGridItem`,
	menuItemOptionBorder: `${PREFIX}-menuItemOptionBorder`,
	menuItemRestrictionFontSize: `${PREFIX}-menuItemRestrictionFontSize`,
	menuItemRestrictionImage: `${PREFIX}-menuItemRestrictionImage`,

	nameDiv: `${PREFIX}-nameDiv`,
	noBorder: `${PREFIX}-noBorder`,
	noBoxShadow: `${PREFIX}-noBoxShadow`,
	noPaddingOnSmallScreen: `${PREFIX}-noPaddingOnSmallScreen`,

	orderFeedbackRating: `${PREFIX}-orderFeedbackRating`,

	paperBackgroundWidgetTheme: `${PREFIX}-paperBackgroundWidgetTheme`,
	pendingOrdersCardActions: `${PREFIX}-pendingOrdersCardActions`,
	pendingOrdersCardHeader: `${PREFIX}-pendingOrdersCardHeader`,
	processingOrderContainer: `${PREFIX}-processingOrderContainer`,

	QRCodeContainer: `${PREFIX}-QRCodeContainer`,

	signInButton: `${PREFIX}-signInButton`,
	signInButtonAppleIcon: `${PREFIX}-signInButtonAppleIcon`,
	scrollbarPadding: `${PREFIX}-modal2ScrollbarPadding`,
	skipLink: `${PREFIX}-skipLink`,

	tabMenuBar: `${PREFIX}-tabMenuBar`,
	thickDivider: `${PREFIX}-thickDivider`,

	userProfileImage: `${PREFIX}-userProfileImage`,
	userProfileImageAppBar: `${PREFIX}-userProfileImageAppBar`,
	userProfileImageDiv: `${PREFIX}-userProfileImageDiv`,
	userProfileImageFab: `${PREFIX}-userProfileImageFab`,
	userProfileLegalButton: `${PREFIX}-userProfileLegalButton`,

	widthAuto: `${PREFIX}-widthAuto`,
	widthInherit: `${PREFIX}-widthInherit`
};

export const GlobalStyle = (theme) => {
	const isDarkTheme = isDarkThemeWithObj(theme);
	const p = theme.palette;
	const iconPadding = 6;

	return {
		[`&.${classes.root}`]: {},
		[`& .${classes.appBar}`]: {
			backgroundColor: p.navbar.backgroundColor,
			color: p.navbar.color
		},
		[`& .${classes.appBarBrandLogoButton}`]: {
			minWidth: theme.spacing(15),
			background: "transparent",
			"&:focus": {
				boxShadow: "inset 0 0 0 2px " + p.text.primary
			},
			"&:hover": {
				opacity: "0.65"
			}
		},
		[`& .${classes.appBarBrandLogoImg}`]: {
			maxWidth: theme.spacing(20),
			padding: theme.spacing(1),
			height: theme.spacing(5),
			background: p.navbar.brand_hexcolor_navbar_background,
			objectFit: "contain !important",
			backgroundSize: "cover"
		},
		[`& .${classes.appBarCheckoutItemName}`]: {
			flexGrow: 1,
			paddingLeft: theme.spacing(1)
		},
		[`&.${classes.appBarCheckoutPopover}`]: {
			"& .MuiPaper-root": {
				borderRadius: 0,
				border: 0
			},
			"& .MuiPopover-paper": {
				background: p.background.backgroundPopUpMenu,
				border: theme.border.menuBorder,
				borderRadius: 0,
				minWidth: 300
			}
		},
		[`& .${classes.appBarCheckoutPopoverHeader}`]: {
			...theme.typography.h4
		},
		[`& .${classes.appBarIconButton}`]: {
			float: "right",
			padding: iconPadding,
			border: "1px solid " + p.navbar.color,
			color: p.navbar.color,
			"&:hover, &:focus": {
				backgroundColor: "transparent",
				boxShadow: "inset 0 0 0 2px " + p.text.primary
			}
		},
		[`& .${classes.appBarIconButtonLeft}`]: {
			margin: theme.spacing(1, 0.5, 1, 2),
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(1, 0.5, 1, 1)
			}
		},
		[`& .${classes.appBarIconButtonRight}`]: {
			margin: theme.spacing(1, 2, 1, 0.5),
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(1, 1, 1, 0.5)
			}
		},
		[`& .${classes.appBarIconButtonRound}`]: {
			minWidth: "unset",
			borderRadius: "50%"
		},
		[`& .${classes.appBarPopoverPlacement}`]: {
			top: "52px !important",
			maxHeight: "calc(100% - 64px)",
			[theme.breakpoints.down("sm")]: {
				top: "48px !important"
			}
		},
		[`& .${classes.appBarTextColor}`]: {
			color: p.navbar.color
		},
		[`& .${classes.bold}`]: {
			fontWeight: "bold"
		},
		[`& .${classes.buttonAsLabel}`]: {
			cursor: "unset",
			"&:hover": {
				background: "unset"
			}
		},
		[`& .${classes.contentDivContainer}`]: {
			overflowY: "auto",
			overflowX: "hidden",
			margin: theme.spacing(2),
			marginRight: theme.spacing(0),
			paddingRight: theme.spacing(2),
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(0),
				paddingRight: theme.spacing(0)
			}
		},
		[`& .${classes.contentDivWithHeader}`]: {
			overflowY: "auto",
			overflowX: "hidden",
			margin: theme.spacing(1, "auto"), // '8px auto'
			// paddingRight: theme.spacing(2),
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(0),
				paddingRight: theme.spacing(0)
			}
		},
		[`& .${classes.contentDivMargin}`]: {
			margin: theme.spacing(1, "auto"), // '8px auto'
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(0)
			}
		},
		[`& .${classes.contentRootDiv}`]: {
			margin: "0 auto",
			[theme.breakpoints.down("md")]: {
				maxWidth: StyleConstants.maxWidthMediumBreakpointDown
			},
			[theme.breakpoints.up("md")]: {
				maxWidth: StyleConstants.maxWidthMediumBreakpointUp
			}
		},
		[`& .${classes.checkoutListItem}`]: {
			paddingLeft: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				paddingLeft: theme.spacing(0)
			}
		},
		[`& .${classes.checkoutSectionTenderModalDiv}`]: {
			width: "auto",
			border: theme.border.menuBorder,
			margin: "auto",
			paddingBottom: "16px",
			paddingRight: "16px",
			borderRadius: "5px"
		},
		[`& .${classes.checkoutUpSellGrid}`]: {
			padding: theme.spacing(1, 2, 0),
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(1, 2, 0, 1)
			}
		},
		[`& .${classes.customizationMenuAddToCartFAB}`]: {
			position: "sticky",
			float: "right",
			right: theme.spacing(2),
			bottom: theme.spacing(2),
			opacity: 0.5,
			"&:hover": {
				opacity: 1
			},
			[theme.breakpoints.down("sm")]: {
				right: theme.spacing(1)
			}
		},
		[`& .${classes.customLabelItemListButton}`]: {
			padding: theme.spacing(1),
			minHeight: "75px"
		},
		[`& .${classes.customLabelItemListButtonBorder}`]: {
			border: `solid 1px ${p.divider} `
		},
		[`& .${classes.customLabelItemListButtonDivider}`]: {
			paddingLeft: `${theme.spacing(1.5)} !important`
		},
		[`& .${classes.customLabelItemGridDense}`]: {
			minHeight: "unset"
		},
		[`&.${classes.defaultPageContainer}`]: {
			background: p.background.backgroundFullPage,
			color: p.text.primary,
			overflowY: "hidden",
			// padding: theme.spacing(2),
			// paddingTop: theme.spacing(2),
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(0)
			}
		},
		[`& .${classes.displayFlex}`]: {
			display: "flex"
		},
		[`& .${classes.disabledGrey}`]: {
			color: p.text.tertiary
		},
		[`& .${classes.errorTextRed}`]: {
			color: p.error.main
		},
		[`& .${classes.errorTextRedItalicsBold}`]: {
			color: p.error.main,
			fontStyle: "italic"
		},
		[`& .${classes.fullHeight}`]: {
			height: "100%"
		},
		[`& .${classes.fullWidth}`]: {
			width: "100%"
		},
		[`& .${classes.fullWidthImportant}`]: {
			width: "100% !important"
		},
		[`& .${classes.grayScaleFilter}`]: {
			filter: "grayscale(100%)"
		},
		[`& .${classes.groupHeader}`]: {
			background: p.background.tableHeaderBackground
		},
		[`& .${classes.heightInherit}`]: {
			height: "inherit"
		},
		[`& .${classes.heightMaxWebkitFitContent}`]: {
			maxHeight: "-webkit-fit-content"
		},
		[`& .${classes.heightWebkitFillAvailable}`]: {
			height: "-webkit-fill-available"
		},
		[`& .${classes.helperTextAlignRight}`]: {
			"&.MuiFormHelperText-root": {
				textAlign: "right"
			},
			"&.MuiFormHelperText-root.Mui-error": {
				fontWeight: "bold"
			}
		},
		[`& .${classes.hoverPointerWithBackground}`]: {
			cursor: "pointer",
			"&:hover": {
				background: p.background.tabBackgroundHover
			}
		},
		[`& .${classes.iconAvatar}`]: {
			height: theme.spacing(3),
			width: theme.spacing(3)
			// filter: isDarkTheme ? "invert(1)" : ""
		},
		[`& .${classes.iconAvatarLarge}`]: {
			height: theme.spacing(7),
			width: theme.spacing(7)
			// margin: theme.spacing(0, -1)
			// filter: isDarkTheme ? "invert(1)" : ""
		},
		[`& .${classes.iconSpacing}`]: {
			width: theme.spacing(3),
			textAlign: "center"
		},
		[`& .${classes.iconSpacingLarge}`]: {
			width: theme.spacing(5),
			textAlign: "center"
		},
		[`& .${classes.iconVerticalAlign}`]: {
			verticalAlign: "top"
		},
		[`& .${classes.iconVerticalAlignMiddle}`]: {
			verticalAlign: "middle"
		},
		[`& .${classes.iconVerticalAlignBottom}`]: {
			verticalAlign: "bottom"
		},
		[`& .${classes.iframe}`]: {
			background: "#FFF",
			height: "100%"
			// margin: "0 auto",
			// width: "50%",
			// minWidth: "350px",
			// maxWidth: "400px",
			// [theme.breakpoints.down("md")]: {
			// 	width: "70%"
			// },
			// [theme.breakpoints.up("md")]: {
			// 	width: "50%"
			// },
			// [theme.breakpoints.up("lg")]: {
			// 	width: "75%"
			// },
			// [theme.breakpoints.up("xl")]: {
			// 	width: "40%"
			// }
		},
		[`& .${classes.justifyCenter}`]: {
			justifyContent: "center"
		},
		[`& .${classes.justifyLeft}`]: {
			justifyContent: "left !important"
		},
		[`& .${classes.largeCenterImage}`]: {
			width: theme.spacing(30),
			height: theme.spacing(30),
			borderRadius: "50%",
			border: "4px solid " + p.divider,
			margin: "auto"
		},
		[`& .${classes.listTransparentNoBorder}`]: {
			background: "transparent",
			border: 0
		},
		[`& .${classes.locationItem}`]: {
			cursor: "pointer",
			width: "100%",
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(0),
				borderRadius: 0
			}
		},
		[`& .${classes.locationItemChevron}`]: {
			color: p.text.tertiary,
			paddingLeft: 0
		},
		[`& .${classes.locationItemStarIcon}`]: {
			// fontSize: theme.typography.caption.fontSize, //"0.75rem"
			fontSize: "0.9rem",
			verticalAlign: "top"
		},
		[`& .${classes.locationHeaderIconActive}`]: {
			filter: isDarkTheme ? "invert(1)" : "",
			backgroundColor: p.primary.main
		},
		[`& .${classes.locationHeaderIconNotActive}`]: {
			filter: isDarkTheme ? "" : "invert(1)",
			opacity: 0.5
		},
		[`& .${classes.locationHeaderImage}`]: {
			width: "100%",
			position: "relative",
			left: 0,
			top: 0
		},
		[`& .${classes.locationHeaderImageDiv}`]: {
			position: "relative",
			left: 0,
			top: 0,
			height: "200px"
		},
		[`& .${classes.locationHeaderImageLocationIcon}`]: {
			position: "absolute",
			left: 10,
			bottom: 15
		},
		[`& .${classes.locationHeaderTextActive}`]: {
			color: p.primary.main,
			fontWeight: "bold"
		},
		[`& .${classes.locationHeaderTextNotActive}`]: {
			opacity: 0.75
		},
		[`& .${classes.locationHeaderStackActive}`]: {
			border: `2px solid ${p.primary.main}`,
			borderRadius: "10px",
			width: "5rem",
			backgroundColor: p.primary.main
			// TODO ANDY change dimensions if mobile? Delete this?
		},
		[`& .${classes.marginAuto}`]: {
			margin: "auto"
		},
		[`& .${classes.menuContentDiv}`]: {
			overflowY: "auto",
			overflowX: "hidden",
			margin: theme.spacing(1, "auto"), // '8px auto'
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(0)
			}
		},
		[`& .${classes.menuContentDivWithSchedulePicker}`]: {
			overflowY: "auto",
			overflowX: "hidden",
			margin: theme.spacing(1, "auto"), // '8px auto'
			[theme.breakpoints.down("sm")]: {
				margin: theme.spacing(0)
			}
		},
		[`& .${classes.menuItemDetailsImage}`]: {
			maxWidth: 400
		},
		[`& .${classes.menuItemGridItem}`]: {
			maxWidth: "50%",
			[theme.breakpoints.down("sm")]: {
				maxWidth: "100% !important"
			}
		},
		[`& .${classes.menuItemOptionBorder}`]: {
			height: "100%",
			[theme.breakpoints.down("sm")]: {
				borderBottom: `solid 1px ${p.divider}`
			},
			[theme.breakpoints.up("sm")]: {
				border: `solid 1px ${p.divider}`
			}
		},
		[`& .${classes.menuItemRestrictionFontSize}`]: {
			[theme.breakpoints.down("xs")]: {
				fontSize: "0.5rem"
			}
		},
		[`& .${classes.menuItemRestrictionImage}`]: {
			height: "2rem",
			width: "2rem",
			[theme.breakpoints.down("sm")]: {
				height: "1.25rem",
				width: "1.25rem"
			}
		},

		[`& .${classes.nameDiv}`]: {
			padding: theme.spacing(0.5, 0, 2.5),
			textAlign: "center"
		},
		[`& .${classes.noBorder}`]: {
			border: "0"
		},
		[`& .${classes.noBoxShadow}`]: {
			boxShadow: "none",
			background: "transparent"
		},
		[`& .${classes.noPaddingOnSmallScreen}`]: {
			[theme.breakpoints.down("sm")]: {
				padding: 0
			}
		},
		[`& .${classes.orderFeedbackRating}`]: {
			fontSize: theme.spacing(8),
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.spacing(5)
			}
		},
		[`& .${classes.paperBackgroundWidgetTheme}`]: {
			background: p.background.backgroundWidget
		},
		[`& .${classes.pendingOrdersCardActions}`]: {
			padding: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(0.75)
			}
		},
		[`& .${classes.pendingOrdersCardHeader}`]: {
			paddingBottom: 0
		},
		[`& .${classes.processingOrderContainer}`]: {
			height: "550px",
			maxHeight: "calc(80vh - 60px)"
		},
		[`& .${classes.QRCodeContainer}`]: {
			background: "white",
			padding: theme.spacing(1, 1, 0),
			display: "inline-block"
		},
		[`& .${classes.signInButton}`]: {
			border: "0 !important",
			boxShadow: "none !important",
			background: "transparent !important",
			color: `${primaryTextColor(p.mode)} !important`,
			textAlign: "left !important",
			fontSize: "1rem !important",
			width: "100% !important",

			"> div": {
				//google button
				background: "transparent !important",
				marginRight: `${theme.spacing(1)} !important`,
				[theme.breakpoints.down("sm")]: {
					marginLeft: "-6px !important"
				}
			}
		},
		[`& .${classes.signInButtonAppleIcon}`]: {
			//apple button
			width: "4rem",
			height: "4rem",
			// margin: theme.spacing(-1, 0, -1, -2),
			margin: `-0.5em -0.5em -0.5em -1em`,
			[theme.breakpoints.down("sm")]: {
				marginLeft: `${theme.spacing(-3)} !important`
				// marginLeft: `-1em !important`
			},
			"> g > rect": {
				fill: "transparent !important"
			}
		},
		[`& .${classes.scrollbarPadding}`]: {
			paddingRight: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				paddingRight: theme.spacing(0)
			}
		},
		[`& .${classes.skipLink}`]: {
			background: "transparent",
			height: "30px",
			left: "50%",
			padding: "8px",
			position: "absolute",
			transform: "translateY(-150%)",
			transition: "transform 0.3s",
			"&:focus": {
				transform: "translateY(0)"
			}
		},
		[`& .${classes.tabMenuBar}`]: {
			position: "sticky",
			zIndex: 10,
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				margin: theme.spacing(0)
			}
		},
		[`& .${classes.thickDivider}`]: {
			"+ hr": {
				borderTop: `5px solid ${p.dividerDark}`
			}
		},
		[`& .${classes.userProfileImage}`]: {
			borderRadius: "50%",
			border: "4px solid " + p.text.tertiary,
			margin: "auto",
			cursor: "pointer"
		},
		[`& .${classes.userProfileImageAppBar}`]: {
			width: "2.375rem",
			height: "2.375rem",
			border: "0px",
			margin: "auto",
			cursor: "pointer",
			"&:hover": {
				filter: "brightness(80%)"
			},
			"&:focus": {
				border: "1px solid " + p.text.primary,
				boxShadow: "inset 0 0 0 1px " + theme.primary
			}
		},
		[`& .${classes.userProfileImageDiv}`]: {
			position: "relative",
			left: 0,
			top: 0,
			borderRadius: "50%",
			
			"&:hover, &:focus-within": {
				boxShadow: `0 0 0 4px ${p.text.primary}`,
				outline: `${p.navbar.backgroundColor} auto 4px`,
				"& img": {
					border: "4px solid " + p.primary.main,
					filter: "brightness(80%)"
				}
			}
		},
		[`& .${classes.userProfileImageFab}`]: {
			position: "absolute",
			bottom: theme.spacing(2),
			right: theme.spacing(2),
			border: "3px solid " + p.text.tertiary,
			width: theme.spacing(4),
			height: theme.spacing(4),
			minHeight: 0,
			minWidth: 0,
			cursor: "pointer"
		},
		[`& .${classes.userProfileLegalButton}`]: {
			color: p.text.primary,
			width: "max-content",
			margin: "auto",
			backgroundColor: "transparent",
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "transparent"
			}
		},
		[`& .${classes.widthAuto}`]: {
			width: "auto"
		},
		[`& .${classes.widthInherit}`]: {
			width: "inherit"
		}
	};
};
