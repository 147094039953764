import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ApiEndpoints from "../api/ApiEndpoints";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";

import Loading from "../utils/components/Loading";
import ConstantsLabels from "../utils/ConstantsLabels";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import MessagesList from "../views/messages/MessagesList";

const MessageListPage = ({ campusData, doApiCall, userData, doUpdateAppBarTitle }) => {
	const { messages, user } = userData;
	const { campus } = campusData;

	const [busy, setBusy] = useState(false);

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.messages);
		doApiCall(
			ApiEndpoints.GetMessages,
			{},
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: true },
			(data) => {},
			(errorMsg, errorReason) => {}
		);
	}, []);

	const theme = useTheme();

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	//show loading while we are initializing
	if (busy) return <Loading />;

	if (!campus || !user || !messages) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]}>
			<MessagesList messages={messages} />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(MessageListPage);
