import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import { enqueueSnackbar } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import EnvConfig from "../../utils/EnvConfig";
import { Log, LogDebug, isDeviceMobileFx } from "../../utils/Utils";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import { getCurrencyString, isPointsEnabled } from "../../utils/Utils";

import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import useCommonData from "../../utils/hooks/useCommonData";
import CustomGridContainer from "../custom/CustomGridContainer";
import CustomPaperContainer from "../custom/CustomPaperContainer";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ConstantsLabels from "../../utils/ConstantsLabels";

import { EmailShareButton, EmailIcon } from "react-share";

const getTitleDescriptionAmount = (campus, user) => {
	let inviteDescriptionLabel, inviteTitleLabel, inviteAmount;

	const challengeReferralPoints = 0; //TODO: Revist this value when we get it from loyalty api - Challenge.getInviteFriendChallengePoints();
	const referralPoints = challengeReferralPoints + campus.referrer_points;

	if (campus.referrer_credit > 0) {
		// Credit
		inviteAmount = getCurrencyString(campus.referrer_credit);

		if (campus.referrer_number_of_orders === 0) {
			inviteDescriptionLabel = `Invite a friend & you'll get a credit of ${inviteAmount} when they register with your code.`;
			inviteTitleLabel = `EARN ${getCurrencyString(campus.referrer_credit)}`;
		} else if (campus.referrer_number_of_orders === 1) {
			inviteDescriptionLabel = `Invite a friend & you'll get a kickback of ${inviteAmount} when they place their first order.`;
			inviteTitleLabel = `EARN ${getCurrencyString(campus.referrer_number_of_orders * campus.referrer_credit)}`;
		} //not used currently
		else {
			inviteDescriptionLabel = `Invite a friend & you'll get a kickback of ${inviteAmount} every time they order. Max ${campus.referrer_number_of_orders} orders.`;
			inviteTitleLabel = `EARN UP TO ${getCurrencyString(campus.referrer_number_of_orders * campus.referrer_credit)}`;
		}
	} else if (referralPoints > 0 && isPointsEnabled(campus, user)) {
		// Points
		if (campus.referrer_number_of_orders === 0) {
			if (challengeReferralPoints > 0 && campus.referrer_points > 0)
				inviteDescriptionLabel = `Invite a friend. You'll earn ${campus.referrer_points} Points plus an extra ${challengeReferralPoints} for completing a challenge.`;
			else inviteDescriptionLabel = `Invite a friend & you'll earn ${referralPoints} Points!`;
			inviteTitleLabel = `EARN ${referralPoints} POINTS`;
		} else if (campus.referrer_number_of_orders === 1) {
			inviteDescriptionLabel = `Invite a friend & you'll get a kickback of ${referralPoints} Points when they place their first order.`;
			inviteTitleLabel = `EARN ${referralPoints} POINTS`;
		} else {
			//not used currently
			inviteDescriptionLabel = `Invite a friend & you'll get a kickback of ${campus.referrer_points} Points every time they order. Max ${campus.referrer_number_of_orders} orders.`;
			inviteTitleLabel = `EARN UP TO ${campus.referrer_number_of_orders * campus.referrer_points} POINTS`;
		}
	}

	return { inviteDescriptionLabel, inviteTitleLabel, inviteAmount };
};

const DialogContentComponent = ({ userReferralCode, inviteTitleLabel, inviteAmount }) => {
	const dispatch = useDispatch();

	const emailBody = `Visit ${EnvConfig.webOrderURL} and use promo code ${userReferralCode} to earn ${inviteAmount} off your first order!`;

	return (
		<CustomPaperContainer styleClasses={[classes.contentRootDiv]}>
			<Grid container spacing={1} direction="column" alignItems="stretch">
				<Grid item justifyContent="center" alignItems="stretch">
					<Grid item>
						<Typography variant="body1">
							{`Visit ${EnvConfig.webOrderURL} and use promo code ${userReferralCode} to earn ${inviteAmount} off your first order!`}
						</Typography>
						{/* TODO: not needing this url right now, will need to figure out where to pull url from as android pulls from file */}
						{/* <Typography variant="body1">{`https://appurl.io/vyadfasdf`}</Typography> */}
					</Grid>
					<Grid item>
						<CustomFormLabelItem
							dense
							label={`Copy code: `}
							rightDisplayText={`${userReferralCode}`}
							subLabel={""}
							Icon={ContentCopyIcon}
							IconProps={{ alt: "Copy code" }}
							hideModal
							hideDivider
							hideRightChevron
							useCustomModal2
							onClick={() => {
								navigator.clipboard.writeText("" + userReferralCode);
								dispatch(
									enqueueSnackbar({
										message: "Copied code to clipboard",
										title: "Copied code to clipboard"
									})
								);
							}}
						/>
						<Divider role="presentation" />
					</Grid>
				</Grid>

				<Grid item textAlign={"center"} alignItems="stretch">
					<Grid item alignItems="stretch">
						<EmailShareButton url={EnvConfig.webOrderURL} subject={inviteTitleLabel} body={emailBody}>
							<EmailIcon size={32} round />
						</EmailShareButton>
					</Grid>
				</Grid>
			</Grid>
		</CustomPaperContainer>
	);
};

const InviteAFriend = () => {
	const theme = useTheme();
	const { campus, user } = useCommonData();

	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);

	const [isShowingInviteFried, setIsShowingInviteFried] = useState(false);

	const { inviteDescriptionLabel, inviteTitleLabel, inviteAmount } = getTitleDescriptionAmount(campus, user);
	const userReferralCode = user?.referral_code;

	return (
		<CustomPaperContainer className={clsx(classes.contentRootDiv)} style={{ minHeight: "-webkit-fill-available" }}>
			<CustomGridContainer
				direction="row"
				padding={theme.spacing(1, isDeviceMobile ? 1 : 10)}
				alignItems="center"
				justifyContent="center"
				spacing={2}
				className={clsx(classes.fullHeight)}
				style={{ minHeight: "-webkit-fill-available" }}
			>
				<Grid item>
					<PersonAddAlt1Icon className={clsx(classes.largeCenterImage)} alt={ConstantsLabels.labelInviteAFriend} />
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<Stack spacing={1} className={classes.fullWidth} pt={theme.spacing(2)} pb={theme.spacing(2)}>
						<Typography variant="h4" variantMapping={{ h4: "h1" }} align="center" paddingTop={theme.spacing(2)}>
							{`${inviteTitleLabel}`}
						</Typography>
						<Typography variant="h5" variantMapping={{ h5: "span" }} align="center" paddingTop={theme.spacing(1)}>
							{`${inviteDescriptionLabel}`}
						</Typography>
					</Stack>
				</Grid>
				<Grid item display="flex" justifyContent="center" container={true}>
					<Stack spacing={1} className={classes.fullWidth} pt={theme.spacing(2)} pb={theme.spacing(2)}>
						<Typography variant="h4" variantMapping={{ h4: "span" }} align="center" paddingTop={theme.spacing(2)}>
							Share your Promo Code
						</Typography>
						<Typography variant="h5" variantMapping={{ h5: "span" }} align="center" paddingTop={theme.spacing(1)}>
							{userReferralCode}
						</Typography>
					</Stack>
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<CustomFormButton
						inputID={"invite"}
						buttonText={ConstantsLabels.labelInvite}
						handleClick={() => setIsShowingInviteFried(true)}
						marginTop={theme.spacing(4)}
					/>
				</Grid>
			</CustomGridContainer>

			<ConfirmAlertDialog
				isVisible={isShowingInviteFried}
				setIsVisible={setIsShowingInviteFried}
				title={ConstantsLabels.labelPageTitles.inviteafriend}
				centerTitle
				titlePrimaryBackground
				dialogContentComponent={
					<DialogContentComponent
						userReferralCode={userReferralCode}
						inviteTitleLabel={inviteTitleLabel}
						inviteAmount={inviteAmount}
					/>
				}
				confirmButtonText={"Done"}
				centerButtonConfirm
				hideBackButton
				confirmCallback={() => setIsShowingInviteFried(false)}
				showDividers={false}
			/>
		</CustomPaperContainer>
	);
};

export default InviteAFriend;
