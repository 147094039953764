import React, { useState, useEffect, useRef, useContext } from "react";
import CustomDivContainer from "../views/custom/CustomDivContainer";
import MenusList from "../views/menu/MenusList";
import { LogDebug, isPointsEnabled, setAppPageTitle } from "../utils/Utils";
import { classes } from "../theme/GlobalStyle";
import { connect } from "react-redux";
import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";
import { getMenuSectionsFromMenu, getObjectFromArray } from "../utils/ObjectUtils";
import { useNavigate, useParams } from "react-router-dom";
import Constants from "../utils/Constants";
import useCommonApiCalls from "../utils/hooks/useCommonApiCalls";
import { SearchContext } from "../utils/contexts/Contexts";
import useCommonData from "../utils/hooks/useCommonData";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import FeatureConstants from "../utils/FeatureConstants";

const MenuPage = ({ doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const { getMenu } = useCommonApiCalls();
	const { campusID, campus, user, location, locationID, locationCart, menu } = useCommonData();
	const { compareSearchValue, setSearchValue } = useContext(SearchContext);

	const scrollToRef = useRef([]);
	const pickupOrDelivery = locationCart?.pickupOrDelivery;
	const _isPointsEnabled = isPointsEnabled(campus, user);

	const [busy, setBusy] = useState(false);

	const [sections, setSections] = useState(() => getMenuSectionsFromMenu(location, menu, pickupOrDelivery));

	const [tabValue, setTabValue] = useState(0);

	const hideMenuThumbnails = hasFeatureFlag(campus, FeatureConstants.FEA_WEBORDER_HIDE_MENU_THUMBNAILS);

	useEffect(() => {
		setAppPageTitle("Menu");
	}, [navigate]);

	const handleChangeTab = (_event, newTabValue) => {
		// Need to use setTimeout as if you select a tab item that overflows, it takes time to move that scroll over, and this prevents the scrollIntoView from firing
		if (scrollToRef.current) {
			setTimeout(() => {
				scrollToRef.current[newTabValue].scrollIntoView({ behavior: "smooth" });
			}, 500);
		}

		setTabValue(newTabValue);
	};

	useEffect(() => {
		setSections(getMenuSectionsFromMenu(location, menu, pickupOrDelivery));
	}, [menu]);

	// Filtering sections down via the search
	const filteredSections = sections.filter((s) => {
		const searchFilteredItems = s.items.filter((item) => compareSearchValue(item.name) || compareSearchValue(item.description));
		return searchFilteredItems.filter((item) => item.is_deleted === 0 && item.is_hidden === 0).length > 0;
	});

	useEffect(() => {
		if (campusID === 0) {
			LogDebug("campusID = 0, should go to a blank / error page");
			navigate(`/`, { replace: true });
		} else if (locationID === 0) {
			LogDebug("locationID = 0, should go to a blank / error page");
			navigate(`/${campusID}`, { replace: true });
		} else if (menu) {
			getMenu(locationID);
		} else {
			getMenu(locationID, { setBusy });
		}

		return () => {
			setSearchValue("");
		};
	}, []);

	useEffect(() => {
		LogDebug(`useEffect menu page`);
		location && doUpdateAppBarTitle(location.name);
	}, [location]);

	useEffect(() => {
		LogDebug(`useEffect campusID=${campusID} locationID=${locationID}`);
		if (campusID === 0 || locationID === 0) {
			//redirect here since campusID not entered
			navigate(`/`, { replace: true });
		}
	}, [locationID, campusID]);

	//if campusid or locationid is not supplied, then invalid url
	if (campusID === 0 || locationID === 0) return <></>;

	return (
		<CustomDivContainer
			campus={campus}
			location={location}
			tabs={filteredSections}
			tabValue={tabValue}
			tabLabel="name"
			tabId="sectionid"
			ariaLabel="Menu section"
			tabStyleClasses={[classes.contentRootDiv]}
			styleClasses={[classes.menuContentDivWithSchedulePicker, classes.contentRootDiv]}
			hideAutoScrollPadding
			handleChangeTab={handleChangeTab}
			locationid={locationID}
			scheduleProps={{ page: Constants.APP_LOCATION.MENU_PAGE, scheduleCallBack: () => getMenu(locationID, setBusy) }}
			showLoadingOnTop={busy}
			showLoading={!campus || !location || !menu}
			showSearch
			footerBarProps={{
				footerBarLocation: Constants.APP_LOCATION.MENU_PAGE
			}}
		>
			<MenusList
				location={location}
				pickupOrDelivery={pickupOrDelivery}
				sections={filteredSections}
				scrollToRef={scrollToRef}
				isPointsEnabled={_isPointsEnabled}
				hideMenuThumbnails={hideMenuThumbnails}
			/>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(MenuPage);
