import { Grid, Typography } from "@mui/material";

import React, { useEffect } from "react";
import { classes } from "../../theme/GlobalStyle";
import LoyaltyIcon from "@mui/icons-material/StarOutline";

import CustomGridContainer from "../custom/CustomGridContainer";
import CustomFormButton from "../../utils/forms/CustomFormButton";

import ConstantsLabels from "../../utils/ConstantsLabels";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";

const TransactionItemDetailsFeedbackSection = ({
	campus,
	location,
	order,
	setRatedMessage,
	setIsShowingRatingButton,
	setIsShowingRatedMessage,
	isShowingRatingButton,
	isShowingRatedMessage,
	handleShowFeedback,
	ratedMessage
}) => {
	const totalPoints = order.points_base + order.points_bonus;
	const disableRateOrder =
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_DISABLE_RATE_ORDER) || hasFeatureFlag(campus, FeatureConstants.FEA_DISABLE_RATE_ORDER);

	useEffect(() => {
		// order completed and not rated yet
		if (
			!!order &&
			(!order.rating_datetime || order.rating_datetime.length === 0) &&
			order.iscomplete === 1 &&
			order.isrefunded === 0 &&
			order.asap === 1
		) {
			setRatedMessage("");
			setIsShowingRatingButton(true);
			setIsShowingRatedMessage(false);
		}
		// order not completed
		else if (order?.iscomplete === 0) {
			setRatedMessage("");
			setIsShowingRatingButton(false);
			setIsShowingRatedMessage(false);
		}
		//Great Transact Exp
		else if (order?.rating_service === 5 && order.rating_food >= 4) {
			setRatedMessage("Thank you for your support");
			setIsShowingRatingButton(false);
			setIsShowingRatedMessage(true);
		}
		//Okay Transact Exp
		else if (order?.rating_service >= 4 && order.rating_food >= 3) {
			setRatedMessage("Your feedback is important to us and helps us improve.\n\nThank you for your review.");
			setIsShowingRatingButton(false);
			setIsShowingRatedMessage(true);
		}
		//bad experience == service 3 or less || food less than 3
		else {
			setRatedMessage("Sorry that your experience was not positive. Your feedback will be reviewed.\n\nThank you for your patience.");
			setIsShowingRatingButton(false);
			setIsShowingRatedMessage(true);
		}
	}, [order]);

	return (
		<CustomGridContainer direction="column" alignItems="stretch" justifyContent="space-between">
			<Grid item xs>
				<Typography width="inherit" variant="h6" variantMapping={{ h6: "h2" }} className={classes.bold} textAlign="center">
					{`You earned ${totalPoints} points`}
				</Typography>
			</Grid>
			{(isShowingRatingButton || isShowingRatedMessage) && !disableRateOrder && (
				<Grid item xs p={2} pb={0}>
					<>
						{isShowingRatingButton && (
							<Grid container>
								<Grid item xs>
									<CustomFormButton
										inputID={"orderFeedback"}
										startIcon={<LoyaltyIcon />}
										buttonText={ConstantsLabels.labelRateOrder}
										handleClick={handleShowFeedback}
									/>
								</Grid>
							</Grid>
						)}
						{isShowingRatedMessage && (
							<Grid container>
								<Grid item xs>
									<Typography padding={2} variant="h6" variantMapping={{ h6: "h2" }}>
										{ratedMessage}
									</Typography>
								</Grid>
							</Grid>
						)}
					</>
				</Grid>
			)}
		</CustomGridContainer>
	);
};

export default TransactionItemDetailsFeedbackSection;
