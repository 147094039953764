import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

export default function CustomValuesList({ keyId, labelKey = "name", valueArray, paddingLeft = 0, marginBottom = 0 }) {
	return (
		<Grid
			container
			direction="row"
			justifyItems="center"
			alignItems="center"
			spacing={0.5}
			paddingLeft={paddingLeft}
			marginBottom={marginBottom}
		>
			{valueArray.map((value, i) => {
				return (
					<Fragment key={value[keyId]}>
						<Grid item>
							<Typography variant="caption">{value[labelKey]}</Typography>
						</Grid>
						{i + 1 !== valueArray.length && (
							<Grid item>
								<Typography variant="caption">{"·"}</Typography>
							</Grid>
						)}
					</Fragment>
				);
			})}
		</Grid>
	);
}
