import React from "react";
import { classes } from "../../theme/GlobalStyle";
import Button from "@mui/material/Button";

const scrollToId = (id) => {
	document.getElementById(id)?.focus();
};

const SkipLink = () => {
	return (
		<Button
			onClick={() => {
				scrollToId("skip_link");
			}}
			onKeyUp={(event) => {
				if (event.key === "Enter") scrollToId("skip_link");
			}}
			variant="contained"
			color="inherit"
			disableElevation
			className={classes.skipLink}
		>
			Skip to content
		</Button>
	);
};

export default SkipLink;
