import { useEffect } from "react";
import { centerCrop, makeAspectCrop } from "react-image-crop";

export const getCroppedBase64ImgDataUrl = (canvas, isPNG = false, convertToJpeg = false) => {
	if (convertToJpeg) {
		return canvasToImage(canvas.current);
	} else {
		const fileType = isPNG ? "image/png" : "image/jpeg";
		const base64ImageCropped = canvas.current.toDataURL(fileType, 1);
		return base64ImageCropped;
	}
};

function canvasToImage(canvas, backgroundColor = "white") {
	// Need this to when uploading a png and converting to jpg. This changes transparent colours to white, vs the standard black.
	// https://stackoverflow.com/questions/32160098/change-html-canvas-black-background-to-white-background-when-creating-jpg-image
	var context = canvas.getContext("2d");

	//cache height and width
	var w = canvas.width;
	var h = canvas.height;

	var data;

	//get the current ImageData for the canvas.
	data = context.getImageData(0, 0, w + 1000, h + 1000);
	// Adding 1000 to the background canvas - need this as if there's weirdos (yeah, I'm talking about you Ernest!) out there that have their chrome window zoomed in, it causes a black square to show up on the picture.

	//store the current globalCompositeOperation
	var compositeOperation = context.globalCompositeOperation;

	//set to draw behind current content
	context.globalCompositeOperation = "destination-over";

	//set background color
	context.fillStyle = backgroundColor;

	//draw background / rect on entire canvas
	context.fillRect(0, 0, w + 1000, h + 1000);

	//get the image data from the canvas
	var imageData = canvas.toDataURL("image/jpeg");

	//clear the canvas
	context.clearRect(0, 0, w, h);

	//restore it with original / cached ImageData
	context.putImageData(data, 0, 0);

	//reset the globalCompositeOperation to what it was
	context.globalCompositeOperation = compositeOperation;

	//return the Base64 encoded data url string
	return imageData;
}

//the below isnt working properly, but it could be in the component displaying it
export const getCroppedImgBlobForPreview = (canvas, isPNG) => {
	const fileType = isPNG ? "image/png" : "image/jpeg";

	return new Promise((resolve, reject) => {
		canvas.toBlob(
			(blob) => {
				if (!blob) {
					resolve(undefined);
					return;
				}
				blob.name = "preview";
				resolve(blob);
			},
			fileType,
			1
		);
	});
};

export const getImageObj = (imagesArray, imageid) => {
	return imagesArray.find((image) => image.value === imageid);
};

export const getImageFileName = (imagesArray, imageId, defaultReturn) => {
	const theDefault = defaultReturn ? defaultReturn : "";
	if (!imageId) return theDefault;
	const imgObj = getImageObj(imagesArray, imageId);
	return imgObj?.obj ? imgObj.obj.filename_bare : theDefault;
};

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "px",
				width: mediaWidth
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

export function useDebounceEffect(fn, waitTime, deps) {
	useEffect(() => {
		const t = setTimeout(() => {
			fn.apply(undefined, deps);
		}, waitTime);

		return () => {
			clearTimeout(t);
		};
	}, deps);
}

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
	const ctx = canvas.getContext("2d");

	if (!ctx) {
		throw new Error("No 2d context");
	}

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	// devicePixelRatio slightly increases sharpness on retina devices
	// at the expense of slightly slower render times and needing to
	// size the image back down if you want to download/upload and be
	// true to the images natural size.
	const pixelRatio = window.devicePixelRatio;
	// const pixelRatio = 1

	canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
	canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

	ctx.scale(pixelRatio, pixelRatio);
	ctx.imageSmoothingQuality = "high";

	const cropX = crop.x * scaleX;
	const cropY = crop.y * scaleY;

	const rotateRads = rotate * TO_RADIANS;
	const centerX = image.naturalWidth / 2;
	const centerY = image.naturalHeight / 2;

	ctx.save();

	// 5) Move the crop origin to the canvas origin (0,0)
	ctx.translate(-cropX, -cropY);
	// 4) Move the origin to the center of the original position
	ctx.translate(centerX, centerY);
	// 3) Rotate around the origin
	ctx.rotate(rotateRads);
	// 2) Scale the image
	ctx.scale(scale, scale);
	// 1) Move the center of the image to the origin (0,0)
	ctx.translate(-centerX, -centerY);
	ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

	ctx.restore();
}
