import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import ApiEndpoints from "../api/ApiEndpoints";

import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../store/Actions";
import { getCartJson } from "../store/CartUtils";

import { classes } from "../theme/GlobalStyle";

import ConstantsLabels from "../utils/ConstantsLabels";
import { LogDebug, setAppPageTitle } from "../utils/Utils";
import { isProduction } from "../utils/EnvUtils";

import CheckoutSectionHeader from "../views/checkout/CheckoutSectionHeader.jsx";
import CheckoutSectionBreakdown from "../views/checkout/CheckoutSectionBreakdown";
import CheckoutSectionOptions from "../views/checkout/CheckoutSectionOptions";
import CustomDivContainer from "../views/custom/CustomDivContainer";
import EmptyCart from "./CheckoutEmptyCart";
import CheckoutSectionTenders from "../views/checkout/CheckoutSectionTenders";
import Constants from "../utils/Constants";
import {isEmpty} from "lodash";
import CustomModal2 from "../views/modals/CustomModal2";
import SignInPage from "../views/signin/SignInPage";
import useAppDefaults from "../utils/hooks/useAppDefaults";
import ConfirmAlertDialog from "../utils/alertdialog/ConfirmAlertDialog";
import { Typography } from "@mui/material";
import { getConfirmDialogDetails } from "../utils/CheckoutUtils";
import useCommonApiCalls from "../utils/hooks/useCommonApiCalls";
import ProcessingOrderModalForm from "./ProcessingOrderModalForm";
import useCommonData from "../utils/hooks/useCommonData";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import FeatureConstants from "../utils/FeatureConstants";
import CustomValuesList from "../views/custom/CustomValuesList";

import { getDateFormats, getDateObjFromUTCString } from "../utils/DateUtils";

const kStatusFailed = "failed";
const kStatusSuccess = "success";

const CheckoutPage = ({
	doApiCall,
	doClearErrorAlert,
	doEnqueueSnackbar,
	doUpdateAppBarTitle,
	doUpdateCartPickupSpotId,
	doUpdateCartTender,
	doUpdateErrorAlert
}) => {
	const checkoutPageRef = useRef();
	const navigate = useNavigate();
	const theme = useTheme();
	const { getUser } = useCommonApiCalls();
	const { campusID, campus, cafeteriaGroups, menu, menuSlots, latitudeLongitude, location, locationID, locationCart, pushToken, user } =
		useCommonData();
	const routerLocation = useLocation();
	const { widgetSpacingSmall } = useAppDefaults();
	const [busy, setBusy] = useState(true);
	const [showProcessingModal, setShowProcessingModal] = useState(false);

	const lastOrder = user?.last_order;

	const [specialComment, setSpecialComment] = useState("");
	const [showSpecialCommentModal, setShowSpecialCommentModal] = useState(false);
	const [showPickupSpotModal, setShowPickupSpotModal] = useState(false);
	const [checkoutSelectChoiceIds, setCheckoutSelectChoiceIds] = useState([]);
	const [isShowingLogin, setIsShowingLogin] = useState(false);
	const [promoCode, setPromoCode] = useState([]);
	const [isShowingConfirmPayDialog, setIsShowingConfirmPayDialog] = useState(false);
	const [isShowingInProcessDialog, setIsShowingInProcessDialog] = useState(false);
	const [processNumberString, setProcessNumberString] = useState("");

	const [isMainModalPage, setIsMainModalPage] = useState(true);
	const [isForgotPassword, setIsForgotPassword] = useState(false);

	const { ct_id, ct_id2, server, pickupSpotId, pickupOrDelivery } = locationCart || {};
	const isCartEmpty = !locationCart || locationCart?.items?.length === 0;

	const [selectedTender, setSelectedTender] = useState({});
	const [cashEqSwipes, setCashEqSwipes] = useState("0");

	const { order, card_added_checkout, card_added_checkout_failed } = routerLocation?.state || {};

	const cafeteriaGroup = cafeteriaGroups?.find((g) => g.locations.some((l) => l.locationid === locationID));

	const [statusText, setStatusText] = useState("");
	const [payTotal, setPayTotal] = useState("");

	const [confirmationDialogDetails, setConfirmationDialogDetails] = useState({});

	const { checkout_pay_button_title } = location;

	const hidePaymentDetails = hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_FREE_ITEMS_NOPAYMENTDETAILS);

	const showLastOrderPrompt = hasFeatureFlag(location, FeatureConstants.FEA_LOC_CHECKOUT_SHOWLASTORDERPROMPT_ONDEV);
	const [isShowingConfirmExistingOrderDialog, setIsShowingConfirmExistingOrderDialog] = useState(false);

	const alwaysShowTenderSelect =
		user?.has_mealplancard === 1 &&
		user?.payment_method_default === 0 &&
		hasFeatureFlag(location, FeatureConstants.FEA_LOC_ALWAYS_SHOW_CAMPUS_TENDER_SELECT);

	useEffect(() => {
		setAppPageTitle("Checkout");
	}, [navigate]);

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.checkout);
		//or cart is empty
		if (!locationCart || locationCart.items.length === 0) {
			LogDebug("no cart object or empty cart, go back to menu");
			navigate(`/${campusID}/${locationID}`);
		}

		if (!isEmpty(order)) {
			doUpdateCartPickupSpotId(locationID, order.pickupspotid);
			setSpecialComment(order.special_comment);
			setCashEqSwipes(order.mealplan_casheq_count);
			doUpdateCartTender(locationID, order.campus_tenderid.toString(), order.secondary_tenderid.toString());
		}

		if (card_added_checkout) {
			getUser();
		} else if (card_added_checkout_failed) {
			doEnqueueSnackbar({
				message: "Failed to add Credit Card",
				title: "Card Error",
				excludeFromHistory: false,
				options: { variant: "error" }
			});
		}
		setBusy(false);
	}, []);

	useEffect(() => {
		if (isCartEmpty || (user && user.campusid.toString() !== campusID.toString())) {
			navigate(`/${campusID}/${locationID}`);
		} else {
			doCalculateCart();
		}
	}, [locationCart?.items, pickupSpotId, user?.userid]);

	const doCalculateCart = (calcObject = {}) => {
		if (!locationCart || isCartEmpty || !user) return;
		const { cash_eq_swipes, promo, tender, ct_id2: _ctId2 } = calcObject;
		let _ctId;
		if (tender) {
			_ctId = tender.ct_id?.toString();
			setSelectedTender(tender);
			setCashEqSwipes(cash_eq_swipes);
			doUpdateCartTender(locationID, _ctId, _ctId2?.toString());
		}

		const body = getCartJson(
			locationCart,
			location,
			user,
			specialComment,
			pickupSpotId,
			checkoutSelectChoiceIds,
			promo ? promo : promoCode,
			_ctId ? _ctId : ct_id,
			tender ? tender : selectedTender,
			_ctId2 ? _ctId2 : ct_id2,
			cash_eq_swipes ? cash_eq_swipes : cashEqSwipes || "",
			order,
			latitudeLongitude,
			pushToken
		);

		doApiCall(
			ApiEndpoints.CalculateCart,
			body,
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: true },
			(data) => {
				doClearErrorAlert();
				if (data.error_message && data.error_message !== "") {
					doUpdateErrorAlert(data.error_message, "CART_ERROR");
					doEnqueueSnackbar({
						message: data.error_message,
						title: "Cart Error",
						excludeFromHistory: false,
						options: { variant: "error" }
					});
				}
			},
			(errorMsg, errorReason, order_number_string) => {
				if (errorReason === "INPROGRESS") {
					setIsShowingInProcessDialog(true);
					setProcessNumberString(order_number_string);
				} else {
					doUpdateErrorAlert(errorMsg, errorReason);
				}
			}
		);
	};

	const handleConfirmDialog = () => {
		setIsShowingInProcessDialog(false);
		setBusy(true);
		processOrderStatusCheck(processNumberString, 1);
	};

	const validateOrder = () => {
		if (!user) {
			setIsShowingLogin(true);
			return;
		}
		if (!locationCart || checkoutPageRef.current.validateOptions()) {
			return;
		} else if (user.phone_verified !== 1 && user.email_verified !== 1) {
			doEnqueueSnackbar({
				message: "Please verify your account prior to placing your order",
				title: "account-unverified",
				excludeFromHistory: false,
				options: { variant: "false" }
			});
			return;
		} else if (
			(isProduction() || (!isProduction() && showLastOrderPrompt)) &&
			lastOrder.iscomplete === 0 &&
			lastOrder.orderid > 0 &&
			lastOrder.complete_datetime?.length === 0
		) {
			//only prompt on prod, dont prompt duplicate orders on dev to facilitate testing (bulk ordering)
			setIsShowingConfirmExistingOrderDialog(true);
			return;
		} else if (
			pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 &&
			location.special_comment_required === 1 &&
			specialComment.length === 0
		) {
			setShowSpecialCommentModal(true);
			return;
		} else if (
			pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 &&
			location.special_comment_required_delivery &&
			specialComment.length === 0
		) {
			setShowSpecialCommentModal(true);
			return;
		} else if (pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 && pickupSpotId === -1) {
			setShowPickupSpotModal(true);
			return;
		}

		setConfirmationDialogDetails(
			getConfirmDialogDetails(cafeteriaGroup?.group_display_name, location, menu, menuSlots, locationCart, pickupOrDelivery, user)
		);
		setIsShowingConfirmPayDialog(true);
	};

	const processOrder = () => {
		const body = getCartJson(
			locationCart,
			location,
			user,
			specialComment,
			pickupSpotId,
			checkoutSelectChoiceIds,
			promoCode,
			ct_id,
			selectedTender,
			ct_id2,
			cashEqSwipes,
			order,
			latitudeLongitude,
			pushToken
		);

		setShowProcessingModal(true);
		doApiCall(
			ApiEndpoints.ProcessOrderStage1,
			body,
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false, addDeviceInfoToBody: true },
			(data) => {
				doClearErrorAlert();
				setStatusText(data.order_status_message);
				processOrderStatusCheck(data.order_status_orderid, 1);
			},
			(errorMsg, errorReason) => {
				doUpdateErrorAlert(errorMsg, errorReason);
				setShowProcessingModal(false);
			}
		);
	};

	const processOrderStatusCheck = (order_status_orderid, index) => {
		doApiCall(
			ApiEndpoints.ProcessOrderStage2,
			{ orderid: order_status_orderid.toString() },
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				const stage = data.order_status_stage;

				if (stage === kStatusSuccess) {
					setShowProcessingModal(false);
					window.history.pushState({}, undefined, `/${campusID}`);
					navigate(`/${campusID}/${locationID}/confirmation?orderid=${data.orderid}`);
				} else if (stage === kStatusFailed) {
					doUpdateErrorAlert(data.order_status_message, "FAILED");
					setShowProcessingModal(false);
				} else if (index > 10) {
					doUpdateErrorAlert("Sorry we were unable to process your order", "PROCESS_ORDER_FAILED");
					setShowProcessingModal(false);
				} else {
					setStatusText(data.order_status_message);
					setTimeout(() => {
						processOrderStatusCheck(order_status_orderid, index + 1);
					}, getCheckOrderStatusIntervalTiming(index));
				}
			},
			(errorMsg, errorReason) => {
				doUpdateErrorAlert(errorMsg, errorReason);
				setShowProcessingModal(false);
			}
		);
	};

	const getCheckOrderStatusIntervalTiming = (index) => {
		let updatedTime = 2000 * index;
		// Increase interval until every 15 seconds
		if (updatedTime > 15000) {
			updatedTime = 15000;
		}
		return updatedTime;
	};

	const savePromoCode = (_promoCode, closeModalCallback) => {
		if (_promoCode !== "") {
			const body = {
				promo_code: _promoCode,
				subtotal: `${server.cart.subtotal}`,
				locationid: `${location.locationid}`,
				ct_id: ct_id.toString(),
				ct_id2: ct_id2.toString()
			};
			doApiCall(
				ApiEndpoints.ValidatePromoCodeOneTime,
				body,
				setBusy,
				{ logRequest: true, logResponse: true, hideErrorToast: false },
				(data) => {
					doClearErrorAlert();
					setPromoCode([_promoCode, data.description]);
					doCalculateCart({ promo: [_promoCode, data.description] });
					doEnqueueSnackbar({
						message: data.description,
						title: data.message,
						excludeFromHistory: false,
						options: { variant: "success" }
					});
					closeModalCallback();
				},
				(errorMsg, errorReason) => {
					doUpdateErrorAlert(errorMsg, errorReason);
				}
			);
		} else {
			setPromoCode([]);
			doCalculateCart({ promo: [] });
			closeModalCallback();
		}
	};

	//wait for the theme to load to avoid strange color mismatch bug
	// locationCart can not exist here if we come directly to checkout
	if (!theme.isThemeLoaded || !locationCart) return <></>;

	return (
		<CustomDivContainer
			tabIndex={0}
			id={"skip_link"}
			tabStyleClasses={[classes.contentRootDiv]}
			styleClasses={[classes.contentDivWithHeader, classes.contentRootDiv]}
			locationid={locationID}
			scheduleProps={{ hideSchedule: isCartEmpty, page: Constants.APP_LOCATION.CHECKOUT_PAGE, scheduleCallBack: () => doCalculateCart() }}
			headerComponent={
				<CheckoutSectionHeader
					location={location}
					groupName={cafeteriaGroup?.group_display_name}
					cart={locationCart}
					pickupSpotId={pickupSpotId}
					doUpdateCartPickupSpotId={doUpdateCartPickupSpotId}
					isCartEmpty={isCartEmpty}
					showPickupSpotModal={showPickupSpotModal}
					setShowPickupSpotModal={setShowPickupSpotModal}
				/>
			}
			footerBarProps={{
				hideFooterBar: isCartEmpty,
				footerBarLocation: Constants.APP_LOCATION.CHECKOUT_PAGE,
				footerBarButtonText: user ? checkout_pay_button_title || "Pay" : "Please Login to Order",
				footerBarButtonTextRight: payTotal,
				footerBarOnClickButton: validateOrder
			}}
			showLoadingOnTop={busy}
		>
			<Stack spacing={widgetSpacingSmall}>
				{isCartEmpty ? (
					<EmptyCart />
				) : (
					<>
						<CheckoutSectionBreakdown server={server} setPayTotal={setPayTotal} hidePaymentDetails={hidePaymentDetails} />
						{user && !hidePaymentDetails && menu && (
							<CheckoutSectionTenders
								user={user}
								campus={campus}
								location={location}
								locationid={locationID}
								ctId={ct_id}
								ctId2={ct_id2}
								doCalculateCart={doCalculateCart}
								doApiCall={doApiCall}
								menu={menu}
								doUpdateErrorAlert={doUpdateErrorAlert}
								serverCart={server.cart}
								openTenderModal={card_added_checkout || alwaysShowTenderSelect}
								doUpdateCartTender={doUpdateCartTender}
							/>
						)}
						<CheckoutSectionOptions
							campus={campus}
							location={location}
							specialComment={specialComment}
							setSpecialComment={setSpecialComment}
							showSpecialCommentModal={showSpecialCommentModal}
							setShowSpecialCommentModal={setShowSpecialCommentModal}
							setCheckoutSelectChoiceIds={setCheckoutSelectChoiceIds}
							server={server}
							ref={checkoutPageRef}
							savePromoCode={savePromoCode}
							promoCode={promoCode}
							pickupOrDelivery={pickupOrDelivery}
							doEnqueueSnackbar={doEnqueueSnackbar}
						/>
					</>
				)}
			</Stack>

			<CustomModal2
				modalLabel={ConstantsLabels.labelMenuLogin}
				setIsShowing={setIsShowingLogin}
				isShowing={isShowingLogin}
				title={`${campus?.name} ${ConstantsLabels.labelMenuLogin}`}
				leftCornerButtonIcon={isMainModalPage ? undefined : <ArrowBackIcon></ArrowBackIcon>}
				leftCornerButtonTooltip={"Back"}
				leftCornerButtonCallback={() => {
					if (isForgotPassword) {
						setIsForgotPassword(false);
					} else {
						setIsMainModalPage(true);
					}
				}}
				onClose={() => {
					setIsMainModalPage(true);
				}}
				onClickCloseButton={() => {
					setIsMainModalPage(true);
				}}
			>
				<SignInPage
					setIsShowingModal={setIsShowingLogin}
					isMainModalPage={isMainModalPage}
					setIsMainModalPage={setIsMainModalPage}
					isForgotPassword={isForgotPassword}
					setIsForgotPassword={setIsForgotPassword}
				/>
			</CustomModal2>

			<ConfirmAlertDialog
				isVisible={isShowingConfirmPayDialog}
				setIsVisible={setIsShowingConfirmPayDialog}
				title={ConstantsLabels.labelConfirm}
				dialogContentComponent={
					<>
						<Typography variant="body1">{confirmationDialogDetails.confirmationMessage}</Typography>
						{confirmationDialogDetails.confirmationTenderString && (
							<Typography variant="body1">{confirmationDialogDetails.confirmationTenderString}</Typography>
						)}
					</>
				}
				confirmButtonText={confirmationDialogDetails.confirmationPositiveButton}
				cancelButtonText={"CANCEL"}
				confirmCallback={processOrder}
				preventCloseOnClickOutside
			/>

			<ConfirmAlertDialog
				isVisible={isShowingInProcessDialog}
				setIsVisible={setIsShowingInProcessDialog}
				title={"Processing Previous Order"}
				description={
					<>
						<Typography>We have not finished processing the payment for your previous order.</Typography>
						<Typography>Tap OK to view the status.</Typography>
					</>
				}
				confirmButtonText={"OK"}
				confirmCallback={handleConfirmDialog}
				hideBackButton
				preventCloseOnClickOutside
			/>

			{lastOrder && (
				<ConfirmAlertDialog
					isVisible={isShowingConfirmExistingOrderDialog}
					setIsVisible={setIsShowingConfirmExistingOrderDialog}
					title={`Pending Order #${lastOrder.orderid}`}
					centerTitle
					titlePrimaryBackground
					dialogContentComponent={
						<>
							<Typography variant="body1" pb={2}>{`Pickup: ${
								getDateFormats(getDateObjFromUTCString(lastOrder.preferred_datetime))?.localDateTimeMonthDayYearHourMin
							}`}</Typography>

							<CustomValuesList
								valueArray={lastOrder?.items.filter((item) => !item.comboHide)}
								keyId={"itemid"}
								marginBottom={"1rem"}
								paddingLeft={3}
							/>

							<Divider role="presentation" />

							<Typography variant="body1" component="div">
								<pre style={{ fontFamily: "inherit" }}>{ConstantsLabels.labelExistingPendingOrder.join("\n")}</pre>
							</Typography>
						</>
					}
					confirmButtonText={"Continue"}
					cancelButtonText={"Back"}
					confirmCallback={processOrder}
					preventCloseOnClickOutside
				/>
			)}

			<CustomModal2 isShowing={showProcessingModal} setIsShowing={setShowProcessingModal} hideXButton noTitleBox>
				<ProcessingOrderModalForm statusText={statusText} isVirtualQueue={location.virtualqueue === 1} />
			</CustomModal2>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(CheckoutPage);
