import { Log } from "../../utils/Utils";
import StyleConstants from "../../theme/StyleConstants";
import { defaultFocusBoxCss } from "../../theme/CustomTheme";

const { topnavbarHeight, shiftFromTop, modalPadding, modalPaddingTopBottom } = StyleConstants;

const PREFIX = "Modal";

export const classes = {
	bottomButtonDiv: `${PREFIX}-bottomButtonDiv`,
	closeButtonDiv: `${PREFIX}-closeButtonDiv`,
	closeIconButton: `${PREFIX}-closeIconButton`,
	leftCornerButtonDiv: `${PREFIX}-leftCornerButtonDiv`,
	leftCornerIconButton: `${PREFIX}-leftCornerIconButton`,
	modal2Base: `${PREFIX}-modal2Base`,
	modal2GridItemContent: `${PREFIX}-modal2GridItemContent`,
	modal2GridItemContentButton: `${PREFIX}-modal2GridItemContentButton`,
	modal2ScrollbarPadding: `${PREFIX}-modal2ScrollbarPadding`,
	modalBase: `${PREFIX}-modalBase`,
	modalCenter: `${PREFIX}-modalCenter`,
	modalContentChildrenDiv: `${PREFIX}-modalContentChildrenDiv`,
	modalContentChildrenDivImageUploader: `${PREFIX}-modalContentChildrenDivImageUploader`,
	modalContentChildrenDivNoPaddingNoMaxWidth: `${PREFIX}-modalContentChildrenDivNoPaddingNoMaxWidth`,
	modalContentChildrenDivNoTitle: `${PREFIX}-modalContentChildrenDivNoTitle`,
	modalContentChildrenDivVideoPlayer: `${PREFIX}-modalContentChildrenDivVideoPlayer`,
	modalContentContainer: `${PREFIX}-modalContentContainer`,
	modalContentContainerNoTitle: `${PREFIX}-modalContentContainerNoTitle`,
	modalInnerDiv: `${PREFIX}-modalInnerDiv`,
	modalMobileCenter: `${PREFIX}-modalMobileCenter`,
	modalRight: `${PREFIX}-modalRight`,
	modalTitleDiv: `${PREFIX}-modalTitleDiv`,
	modalTitleInnerDiv: `${PREFIX}-modalTitleInnerDiv`,
	subtitleSpan: `${PREFIX}-subtitleSpan`,
	titleSpan: `${PREFIX}-titleSpan`
};

export const CustomModalStyle = (theme) => {
	const p = theme.palette;

	const titleHeight = 90;
	const bottomModalBasePadding = 15; //scroll bar (if shown) cant go right to the edge since window is rounded

	return {
		[`& .${classes.modal2Base}`]: {
			position: "absolute",
			top: "50%",
			left: "50%",
			display: "block",
			minHeight: "200px",
			transform: "translate(-50%, -50%)",
			width: "96%",
			background: p.background.backgroundWidget,
			border: theme.border.menuBorder,
			borderRadius: 10,
			"&:focus": {
				outline: "none"
			}
		},
		[`& .${classes.modal2GridItemContent}`]: {
			overflow: "auto",
			maxWidth: "-webkit-fill-available !important",
			paddingTop: theme.spacing(1),
			maxHeight: "75vh",
			padding: theme.spacing(0.5)
		},
		[`& .${classes.modal2GridItemContentButton}`]: {
			maxHeight: "calc(75vh - 64px)"
		},
		[`& .${classes.modal2ScrollbarPadding}`]: {
			paddingRight: theme.spacing(1),
			[theme.breakpoints.down("sm")]: {
				paddingRight: theme.spacing(0)
			}
		},
		[`& .${classes.modalBase}`]: {
			position: "relative",
			height: `calc(100vh - ${topnavbarHeight + shiftFromTop}px)`,
			width: "96%",
			background: p.background.backgroundWidget,
			border: theme.border.menuBorder,
			borderRadius: 10,
			paddingTop: 0,
			paddingBottom: bottomModalBasePadding,
			"&:focus": {
				outline: "none"
			},
			minWidth: 300,
			maxWidth: 600 //default. override by passing maxWidth prop to component
		},
		// modalBaseOutter: {
		// 	height: "100%"
		// },
		[`& .${classes.modalInnerDiv}`]: {
			width: "100%",
			height: "100%"
		},
		[`& .${classes.modalRight}`]: {
			position: "fixed",
			top: topnavbarHeight,
			right: "2%"
		},
		[`& .${classes.modalCenter}`]: {
			margin: `${topnavbarHeight}px auto 0px`
		},
		[`& .${classes.modalMobileCenter}`]: {
			margin: "1rem auto",
			height: "-webkit-fill-available"
		},
		[`& .${classes.modalTitleDiv}`]: {
			width: "100%",
			height: titleHeight,
			paddingTop: 15,
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		[`& .${classes.modalTitleInnerDiv}`]: {
			display: "block",
			padding: "2px 10px",
			...defaultFocusBoxCss(p.mode)
		},
		[`& .${classes.titleSpan}`]: {
			lineHeight: 1.0
		},
		[`& .${classes.subtitleSpan}`]: {
			lineHeight: 1.0,
			marginTop: 10
		},
		[`& .${classes.modalContentContainer}`]: {
			margin: "auto",
			width: "100%",
			height: `calc(100vh - ${topnavbarHeight + shiftFromTop + 55}px)`,
			overflow: "auto"
		},
		[`& .${classes.modalContentContainerNoTitle}`]: {
			marginTop: 55,
			height: `calc(100vh - ${topnavbarHeight + shiftFromTop + bottomModalBasePadding + 55}px)`
		},
		[`& .${classes.modalContentChildrenDiv}`]: {
			maxWidth: 500,
			paddingTop: modalPaddingTopBottom + "px",
			paddingBottom: modalPaddingTopBottom + "px",
			margin: "auto",
			height: "inherit",
			width: "inherit",
			[theme.breakpoints.down("sm")]: {
				padding: theme.spacing(2)
			}
		},
		[`& .${classes.modalContentChildrenDivNoPaddingNoMaxWidth}`]: {
			maxWidth: 1000,
			paddingTop: "0px",
			paddingBottom: "0px"
		},
		[`& .${classes.modalContentChildrenDivVideoPlayer}`]: {
			maxWidth: 800,
			paddingTop: modalPaddingTopBottom + "px",
			paddingBottom: modalPaddingTopBottom + "px",
			margin: "auto"
		},
		[`& .${classes.modalContentChildrenDivImageUploader}`]: {
			padding: `${modalPadding}px ${modalPadding}px 15px ${modalPadding}px`,
			height: "100%",
			maxWidth: 1500
		},
		[`& .${classes.modalContentChildrenDivNoTitle}`]: {
			padding: `0px ${modalPadding / 2}px 15px ${modalPadding / 2}px`
		},
		[`& .${classes.closeButtonDiv}`]: {
			position: "absolute",
			top: theme.spacing(1),
			right: theme.spacing(3)
		},
		[`& .${classes.closeIconButton}`]: {
			padding: 7,
			color: p.text.tertiary,
			border: "1px solid transparent",
			"&:hover": {
				border: "1px solid " + p.divider,
				backgroundColor: p.error.main,
				color: "#FFF"
			},
			"&:focus": {
				border: "1px solid " + p.divider,
				backgroundColor: p.error.main,
				color: "#FFF",
				boxShadow: "none"
			}
		},
		[`& .${classes.leftCornerButtonDiv}`]: {
			position: "absolute",
			top: theme.spacing(1.5),
			left: theme.spacing(2)
		},
		[`& .${classes.bottomButtonDiv}`]: {
			bottom: theme.spacing(2),
			padding: theme.spacing(2, 2, 0),
			position: "absolute",
			width: "100%"
		},
		[`& .${classes.leftCornerIconButton}`]: {
			color: p.text.primary,
			border: "1px solid transparent",
			padding: "5px",
			"&:hover": {
				border: "1px solid " + p.divider,
				backgroundColor: p.primary.main,
				color: "#FFF"
			},
			"&:focus": {
				border: "1px solid " + p.divider,
				backgroundColor: p.primary.main,
				color: "#FFF",
				boxShadow: "none"
			}
		}
	};
};
