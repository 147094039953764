import { Avatar, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import StarIcon from "@mui/icons-material/Star";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import EnvConfig from "../../utils/EnvConfig";
import { getCurrencyString, getIsHidden, getManualOnline, LogDebug } from "../../utils/Utils";

import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import MenuComboModalForm from "./MenuComboModalForm";
import { getMenuItemOrValueStockStatusText } from "./menuUtils";
import useAppDefaults from "../../utils/hooks/useAppDefaults";

const MenuListItem = ({ hideMenuThumbnails, isPointsEnabled, location, menuItem, pickupOrDelivery, comboProps }) => {
	const navigate = useNavigate();
	const { isDeviceMobile } = useAppDefaults();
	const {
		dietary_restrictions,
		cover_picture_url_with_base,
		is_deleted,
		itemid,
		point_bonus,
		nutrition_calories,
		mobile_stock_available,
		sectionid
	} = menuItem;

	const { icon, iconProps, modalOnSetComboItemClick, ...other } = comboProps || {};

	const manualOnline = getManualOnline(location, menuItem, pickupOrDelivery);
	const isHidden = getIsHidden(location, menuItem, pickupOrDelivery);

	const bonusPoints =
		isPointsEnabled && point_bonus > 0 && is_deleted === 0 && !isHidden && manualOnline && mobile_stock_available === 1
			? `+${point_bonus}`
			: null;

	const isDisabled = !manualOnline || isHidden || menuItem.is_deleted === 1 || menuItem.mobile_stock_available === 0;

	const isComboItem = menuItem.combo_itemid > 0;

	const { stockText, lowStock } = getMenuItemOrValueStockStatusText(menuItem, location);

	const restrictions = dietary_restrictions.slice(0, Constants.maxDietaryFlags).filter((r) => r.icon_url !== "");

	const { campusid, locationid } = location;

	const [modalTitlePrice, setModalTitlePrice] = useState(0);

	const goToSelectedItem = () => {
		LogDebug(`goToSelectedItem ${itemid} in section ${sectionid}`);
		navigate(`/${campusid}/${locationid}/${sectionid}/${itemid}`);
	};

	const AdditionalDetails = () => {
		return (
			<Stack>
				<Stack direction="row" spacing={1} className={classes.fullWidth} alignItems="center">
					{nutrition_calories > 0 && <Typography variant={"caption"}>{`${nutrition_calories} Cals`}</Typography>}
					{restrictions.length > 0 &&
						restrictions.map((r) => {
							return (
								<Avatar
									srcSet={EnvConfig.baseImageURL + r.icon_url}
									alt=""
									sx={{ height: "1.2rem", width: "1.2rem" }}
									key={r.label + " Icon"}
								/>
							);
						})}
					{bonusPoints > 0 && (
						<Typography variant={"caption"}>
							{bonusPoints} <StarIcon className={classes.locationItemStarIcon} alt="Star" />
						</Typography>
					)}
				</Stack>
				{stockText && (
					<Typography variant={"caption"} className={clsx({ [classes.errorTextRed]: lowStock })}>
						{`${stockText}`}
					</Typography>
				)}
			</Stack>
		);
	};

	return (
		<CustomFormLabelItem
			label={menuItem.name}
			onClick={!isComboItem ? goToSelectedItem : undefined}
			isDisabled={isDisabled}
			hideRightChevron={isDisabled}
			rightDisplayText={getCurrencyString(menuItem.price_display || menuItem.price_total)}
			rightDisplayTextVariant="caption"
			subLabelElement={<AdditionalDetails />}
			fullBorder={!isDeviceMobile}
			fullHeight
			hideIcon={hideMenuThumbnails}
			Icon={icon || (!cover_picture_url_with_base && ImageNotSupportedIcon)}
			IconProps={{ alt: menuItem.name, ...iconProps }}
			icon_url={cover_picture_url_with_base}
			iconVariant="rounded"
			showLargeIcon
			modalForm={MenuComboModalForm}
			useCustomModal2
			modalFormProps={{ menuItem, location, setModalTitlePrice, ...other }}
			modalProps={{
				buttonText: `Save  (${getCurrencyString(modalTitlePrice)})`,
				closeModalOnButtonClick: true,
				onButtonClick: modalOnSetComboItemClick,
				onClickCloseButton: () => setModalTitlePrice(0),
				preventOutsideClick: true,
				showButton: true,
				title: menuItem.name,
				modalLabel: menuItem.name
			}}
		/>
	);
};

export default MenuListItem;
