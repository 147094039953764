import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import Tooltip from "@mui/material/Tooltip";

const CustomFormPassword = ({
	inputID,
	inputLabel,
	setValue,
	value,
	setError,
	errorText,
	placeholderText,
	labelAlwaysAtTop = true ,
	setDirtyBit,
	isDisabled,
	helperText,
	marginTop,
	marginBottom,
	maxLength,
	maxWidth,
	display,
	allowAutoComplete,
	isAutoFocus,
	isOptional,
	hiddenUserNameValue // Add this to get rid of accessibility warning if we don't have the username or email filed
}) => {
	const theStyle = { marginTop, marginBottom, maxWidth, display };

	const theMaxLength = maxLength && maxLength > 0 ? maxLength : 1000;

	// When using as a password field:
	// 	<Textfield
	// 		type="password"
	// 		inputProps={{
	// 			autoComplete: "new-password",
	// 		}}
	// 	/>
	// When using as a text field:
	// 	<Textfield
	// 		type="text"
	// 		inputProps={{
	// 			autoComplete: "off",
	// 		}}
	// />
	const autoCompleteProps = allowAutoComplete ? { autoComplete: "password" } : { autoComplete: "new-password", "data-lpignore": "true" };
	const subText = errorText ? errorText : helperText;

	const handleMouseDownPassword = (e) => {
		e && e.preventDefault();
	};

	const handleOnBlur = (e) => {
		e && e.preventDefault();
	};

	const handleOnChange = (e) => {
		e && e.preventDefault();
		setValue(e.target.value);
		setError && setError("");
		setDirtyBit && setDirtyBit(true);
	};

	const [passwordVisible, setPasswordVisible] = useState(false);
	const passwordVisibilityClicked = (e) => {
		e && e.preventDefault();
		setPasswordVisible(!passwordVisible);
	};

	const InputLabelProps = labelAlwaysAtTop ? { shrink: true } : {};

	return (
		<>
			{/* {hiddenUserNameValue && <TextField type="text" name="username" autoComplete="username email" value={hiddenUserNameValue} readOnly />} // added for accessibity? removed for now as it is causing and error*/}
			<TextField
				data-private
				style={theStyle}
				id={inputID}
				label={inputLabel}
				value={value}
				aria-label={`Enter ${inputLabel}`}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				type={passwordVisible ? "text" : "password"}
				error={errorText !== undefined && errorText.length > 0}
				placeholder={placeholderText}
				helperText={subText}
				variant="outlined"
				margin="normal"
				fullWidth
				disabled={isDisabled}
				required={isOptional ? false : true}
				autoFocus={isAutoFocus ? true : false}
				InputLabelProps={{ ...InputLabelProps, shrink: true }}
				inputProps={{ maxLength: theMaxLength, ...autoCompleteProps }}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip
								aria-hidden="true"
								title={`${passwordVisible ? "Hide" : "Show"} ${inputLabel}.`}
								disableFocusListener
								id={`tooltip-${inputID}`}
							>
								<IconButton
									aria-hidden="false"
									aria-label={`${passwordVisible ? "Hide" : "Show"} ${inputLabel}.`}
									onClick={passwordVisibilityClicked}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									size="large"
									style={{ marginRight: "0.25rem" }}
								>
									{passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</Tooltip>
						</InputAdornment>
					)
				}}
				inputRef={(input) => errorText?.length > 0 && input?.focus()}
			/>
		</>
	);
};

export default CustomFormPassword;
