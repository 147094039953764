import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import ApiEndpoints from "../api/ApiEndpoints";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { classes } from "../theme/GlobalStyle";
import ConstantsLabels from "../utils/ConstantsLabels";
import useCommonData from "../utils/hooks/useCommonData";
import { LogDebug, setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import TransactionsList from "../views/transactions/TransactionsList";

const TransactionsListPage = ({ doApiCall, successCallback, doUpdateAppBarTitle }) => {
	const navigate = useNavigate();
	const [busy, setBusy] = useState(false);
	const { campus, locationsWithDisabled, orders, user } = useCommonData();

	const theme = useTheme();

	useEffect(() => {
		setAppPageTitle("Transactions");
	}, [navigate]);

	const doGetPastOrders = () => {
		doApiCall(
			ApiEndpoints.GetPastOrders,
			{},
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: true, title: "Transaction History" },
			(data) => {
				successCallback && successCallback(data);
			},
			(errorMsg, errorReason) => {}
		);
	};

	useEffect(() => {
		doUpdateAppBarTitle(ConstantsLabels.labelPageTitles.transactions);
		if (!campus) {
			LogDebug("campusID = 0, should go to a blank / error page");
			navigate(`/`, { replace: true });
		} else if (!user) {
			LogDebug("user not set should go to a blank / error page");
			navigate(`/${campus.campusID}`, { replace: true });
		} else {
			doGetPastOrders();
		}
	}, []);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	if (!campus || !user) return <></>;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]} showLoadingOnTop={busy}>
			<TransactionsList campus={campus} locations={locationsWithDisabled} orders={orders} />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(TransactionsListPage);
