import clsx from "clsx";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { GlobalStyle, classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { Log, LogDebug, isDeviceMobileFx, isDarkTheme } from "../../utils/Utils";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import CustomFormSwitch from "../../utils/forms/CustomFormSwitch";

import CustomGridContainer from "../custom/CustomGridContainer";
import CustomPaperContainer from "../custom/CustomPaperContainer";

import CustomModal2 from "../modals/CustomModal2";
import { StarBorder } from "@mui/icons-material";

const CircularProgressWithLabel = (props) => {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
};

const RewardsDetails = ({ userData, campusData, settingsData, doApiCall, doUpdateThemeType }) => {
	const modalFormRef = useRef();
	const theme = useTheme();
	const { campus } = campusData;
	const { user } = userData;
	const { themeType } = settingsData;
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const navigate = useNavigate();

	const [busy, setBusy] = useState(false);
	const [dirtyBit, setDirtyBit] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [progress, setProgress] = useState(10);
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [isEmailReceipts, setIsEmailReceipts] = useState(user?.email_receipts === 1 || false);
	const [isReceivePromos, setIsReceivePromos] = useState(user?.opt_in_marketing === 1 || false);
	const [isHighContrast, setIsHighContrast] = useState(false);
	const [isDarkMode, setIsDarkMode] = useState(isDarkTheme(themeType));
	const [isExtendNotificationDuration, setIsExtendNotificationDuration] = useState(false);

	const [isShowingUpdateProfile, setIsShowingUpdateProfile] = useState(false);

	const handleEmailReceipts = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!user) return;

		setFormErrorMessage("");
		setBusy(true);

		const reqBody = {
			email_receipts: e.target.checked === true ? "1" : "0"
		};

		doApiCall(
			ApiEndpoints.ChangeEmailReceipts,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Email receipts updated.`,
				toastTitle: `User #${user.userid}`,
				setFormErrorMessage: setFormErrorMessage
			},
			(data) => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
				setFormErrorMessage(errorMsg);
			}
		);
	};

	const handleReceivePromos = (e) => {
		e && e.preventDefault();
		setFormErrorMessage("");
		if (!user) return;

		setFormErrorMessage("");
		setBusy(true);

		const reqBody = {
			opt_in: e.target.checked === true ? "1" : "0"
		};

		doApiCall(
			ApiEndpoints.ChangeMarketingOptIn,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				hideErrorToast: false,
				successMessage: `Receive promos updated.`,
				toastTitle: `User #${user.userid}`,
				setFormErrorMessage: setFormErrorMessage
			},
			(data) => {
				setBusy(false);
			},
			(errorMsg) => {
				setBusy(false);
				setFormErrorMessage(errorMsg);
			}
		);
	};

	const handleAddImage = (e) => {
		setIsShowingUpdateProfile(true);
	};

	const handleAddImageSuccess = (e) => {
		setIsShowingUpdateProfile(e?.status !== "OK");
	};

	const handleLegal = (e) => {
		navigate(`/legal`);
	};

	const handleThemeChange = () => {
		const newThemeType = isDarkTheme(themeType) ? Constants.themeLightString : Constants.themeDarkString;
		doUpdateThemeType(newThemeType);
	};

	const handleNameChange = (closeModalCallback) => {
		modalFormRef.current.handleNameUpdate();
		closeModalCallback();
	};

	const handleEmailChange = (closeModalCallback) => {
		modalFormRef.current.handleEmailUpdate();
		closeModalCallback();
	};

	const handlePasswordChange = (closeModalCallback) => {
		modalFormRef.current.handlePasswordUpdate();
		closeModalCallback();
	};

	useEffect(() => {
		if (!user) {
			setFullName(undefined);
			setEmail(undefined);
			setIsEmailReceipts(true);
			setIsReceivePromos(true);
			setIsHighContrast(false);
			setIsDarkMode(isDarkTheme(themeType));
			setIsExtendNotificationDuration(false);
			return;
		}
		setFullName(`${user.first_name} ${user.last_name}`);
		setEmail(user.email);
		setIsEmailReceipts(user.email_receipts === 1);
		setIsReceivePromos(user.opt_in_marketing === 1);
		setIsHighContrast(false);
		setIsDarkMode(isDarkTheme(themeType));
		setIsExtendNotificationDuration(false);
	}, [userData]);

	// React.useEffect(() => {
	// 	const timer = setInterval(() => {
	// 		setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
	// 	}, 800);
	// 	return () => {
	// 		clearInterval(timer);
	// 	};
	// }, []);

	const customFormSwitchProps = {
		setDirtyBit: setDirtyBit,
		isDisabled: busy
		// marginBottom: isDeviceMobile ? 2 : 10,
	};

	const iconProps = {
		marginRight: theme.spacing(1)
	};

	const gridItemProps = {
		container: true,
		alignItems: "stretch"
	};

	if (!theme.isThemeLoaded || !user) return <></>;

	return (
		<CustomPaperContainer
			className={clsx(classes.contentRootDiv, classes.fullHeight, classes.heightWebkitFillAvailable)}
			style={{ marginBottom: "16px" }}
		>
			<div className={classes.nameDiv}>
				<Typography variant="h6" variantMapping={{ h6: "h2" }} tabIndex={0}>
					{"REWARDS"}
				</Typography>
			</div>
			<CustomGridContainer
				direction="column"
				padding={theme.spacing(1, isDeviceMobile ? 1 : 10)}
				alignItems="center"
				justifyContent="center"
				spacing={2}
				className={clsx(classes.heightInherit)}
			>
				<Grid item className={clsx(classes.widthInherit)}>
					<CustomFormLabelItem
						dense
						Icon={StarIcon}
						IconProps={{ alt: "Rewards" }}
						label={`${user.points}`}
						onClick={(e) => {
							// goToRewards(e, message);
						}}
						hideDivider={false}
					/>
				</Grid>

				<Grid item onClick={() => handleAddImage(true)}>
					<CircularProgressWithLabel variant={"determinate"} size={"10rem"} value={progress} />
				</Grid>
				{/* <Grid
					item
					style={{
						margin: "auto",
						display: "flex",
						justifyContent: "center"
					}}
				>
					<List
						style={{
							padding: "0px",
							border: "0px",
							background: "none"
						}}
					>
						<ListItem disablePadding>
							<ListItemIcon>
								<CampusIcon />
							</ListItemIcon>
							<ListItemText primary={`${campus.name}`} />
						</ListItem>
						<ListItem disablePadding>
							<ListItemIcon>
								<MailOutlineIcon />
							</ListItemIcon>
							<ListItemText primary={user.email} />
						</ListItem>
					</List>
				</Grid> */}
				{/* <Grid item container={true} alignItems="stretch">
					<CustomFormLabelItem
						dense
						label={"Name"}
						subLabel={fullName}
						hideIcon={true}
						modalForm={ProfileEditName}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Name",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditName,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditName,
							onButtonClick: handleNameChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid>
				<Grid item {...gridItemProps}>
					<CustomFormLabelItem
						dense
						label={"Email"}
						subLabel={email}
						hideIcon={true}
						modalForm={ProfileEditEmail}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Email",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditEmail,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditEmail,
							onButtonClick: handleEmailChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid>
				<Grid item {...gridItemProps}>
					<CustomFormLabelItem
						dense
						label={"Current Password"}
						subLabel={"********"}
						hideIcon={true}
						modalForm={ProfileEditPassword}
						useCustomModal2
						modalFormProps={{
							modalFormRef: modalFormRef
						}}
						modalProps={{
							buttonText: "Update Password",
							closeModalOnButtonClick: true,
							modalLabel: ConstantsLabels.labelEditPassword,
							preventOutsideClick: true,
							showButton: true,
							title: ConstantsLabels.labelEditPassword,
							onButtonClick: handlePasswordChange,
							startIcon: <SaveIcon />
						}}
					/>
				</Grid> */}
			</CustomGridContainer>
		</CustomPaperContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(RewardsDetails);
