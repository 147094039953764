import { Toolbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, useNavigate, Routes } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import RewardsPage from "./RewardsPage";
import ProfilePage from "./ProfilePage";
import MessagePage from "./MessagePage";
import MessageListPage from "./MessageListPage";
import TransactionsListPage from "./TransactionsListPage";
import TransactionPage from "./TransactionPage";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { GlobalStyle, classes } from "../theme/GlobalStyle";

import { LogDebug } from "../utils/Utils";
import Loading from "../utils/components/Loading";
import useCommonData from "../utils/hooks/useCommonData";
import CustomAppBar from "../views/custom/CustomAppBar";
import PendingOrdersPage from "./PendingOrdersPage";
import InviteAFriendPage from "./InviteAFriendPage";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import FeatureConstants from "../utils/FeatureConstants";

const ContainerDiv = styled("div")(({ theme }) => GlobalStyle(theme));

const UserRoutingPage = ({ campusData, userData }) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const { campus, campusID, user } = useCommonData();

	const [busy, setBusy] = useState(false);

	useEffect(() => {
		LogDebug(`useEffect UserRoutingPage campusID=${campusID} userid=${user?.userid}`);
		if (!user && campusID === 0) {
			LogDebug("campusID = 0, should go to a blank / error page");
			navigate(`/`, { replace: true });
		} else if (!user) {
			//or user is empty
			LogDebug("user object does not exist, back to campus");
			navigate(`/${campus?.campusid}`, { replace: true });
		}
	}, [user]);

	if (!theme.isThemeLoaded) return <></>;

	//if campusid is not supplied, then invalid url
	if (campusID === 0) return <></>;

	if (busy) return <Loading />;
	if (!campus || !user) return <Loading />;

	return (
		<>
			<CustomAppBar />
			{/* Second Toolbar used for spacing */}
			<Toolbar />
			<ContainerDiv className={classes.defaultPageContainer} role="main">
				<Routes>
					<Route path={`rewards`} element={<RewardsPage />} />
					{(!hasFeatureFlag(campus, FeatureConstants.FEA_HOME_HIDEINVITEFRIEND) ||
						!hasFeatureFlag(campus, FeatureConstants.FEA_MORE_HIDEINVITEFRIENDCELL)) && (
						<Route path={"inviteafriend"} element={<InviteAFriendPage />} />
					)}
					<Route path={`profile`} element={<ProfilePage />} />
					<Route path={`message`} element={<MessagePage />} />
					<Route path={`messages`} element={<MessageListPage />} />
					<Route path={`pending_orders`} element={<PendingOrdersPage />} />
					<Route path={`transaction`} element={<TransactionPage />} />
					<Route path={`transactions`} element={<TransactionsListPage />} />
				</Routes>
			</ContainerDiv>
		</>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(UserRoutingPage);
