import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Stack from "@mui/material/Stack";

import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomFormLabelItem from "../../utils/forms/CustomFormLabelItem";
import { getCheckInRequiredText } from "../location/LocationUtils";
import { userCheckinRequiredBeforeProcessingOrder } from "../../store/CartUtils";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import Constants from "../../utils/Constants";

const PickupSpotsModalForm = ({ pickupSpotId, handleSelectDropOff, pickupSpots }) => (
	<FormControl>
		<RadioGroup
			aria-labelledby="pickup-spots-radio-group-label"
			name="pickup-spots-radio-group"
			value={pickupSpotId}
			onChange={handleSelectDropOff}
		>
			{pickupSpots
				.filter((p) => p.is_hidden === 0)
				.map((p) => {
					return <FormControlLabel value={p.pickupspotid} control={<Radio />} label={p.name} key={p.pickupspotid} />;
				})}
		</RadioGroup>
	</FormControl>
);

const CheckoutSectionHeader = ({
	cart,
	location,
	isCartEmpty,
	groupName,
	pickupSpotId,
	doUpdateCartPickupSpotId,
	showPickupSpotModal,
	setShowPickupSpotModal
}) => {
	const navigate = useNavigate();
	const { pickupOrDelivery } = cart;
	const { pickup_spots: pickupSpots, delivery_descriptive_name_override: deliveryDescriptiveNameOverride } = location;
	const [hideDropOffSelectModal, setHideDropOffSelectModal] = useState(false);

	const isCheckinRequired = userCheckinRequiredBeforeProcessingOrder(location, cart);

	const selectedPickupSpot = pickupSpots?.find((ps) => ps.pickupspotid === pickupSpotId) || {};
	const { description, name } = selectedPickupSpot;

	const goToLocation = (e) => {
		e && e.preventDefault();
		if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_SINGLE_ITEM_DIRECT_TO_CHECKOUT)) {
			navigate(`/${location.campusid}`);
		} else {
			navigate(`/${location.campusid}/${location.locationid}`);
		}
	};

	const handleOnCloseModal = () => {
		setShowPickupSpotModal(false);
		setHideDropOffSelectModal(false);
	};

	const handleSelectDropOff = (e) => {
		doUpdateCartPickupSpotId(location.locationid, parseInt(e.target.value));
		setHideDropOffSelectModal(true);
	};

	return (
		<Stack direction="column">
			<CustomFormLabelItem
				dense
				label={location.name}
				onClick={goToLocation}
				subLabel={groupName ? groupName : ""}
				icon_url={location.icon_picture_url_with_base ? location.icon_picture_url_with_base : undefined}
				Icon={!location.icon_picture_url_with_base ? RestaurantIcon : undefined}
				IconProps={{ alt: location.name }}
				showLargeIcon
				hideDivider={!isCheckinRequired && (isCartEmpty || pickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0)}
				isAltPresentation
			/>
			{isCheckinRequired && (
				<CustomFormLabelItem
					dense
					label={getCheckInRequiredText(location)}
					Icon={WarningAmberIcon}
					IconProps={{ alt: "Checkin Required" }}
					showLargeIcon
					isLabel={true}
					hideRightChevron={true}
				/>
			)}
			{!isCartEmpty && pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 && (
				<CustomFormLabelItem
					required
					dense
					label={name ? name : "Select Location for Drop Off"}
					subLabel={description ? description : ""}
					Icon={DeliveryDiningIcon}
					IconProps={{ alt: "Delivery" }}
					hideModal={hideDropOffSelectModal}
					hideDivider
					useCustomModal2
					showLargeIcon
					showModal={showPickupSpotModal}
					modalForm={PickupSpotsModalForm}
					modalFormProps={{ pickupSpotId, handleSelectDropOff, pickupSpots }}
					modalProps={{
						onClose: handleOnCloseModal,
						preventOutsideClick: true,
						modalLabel: deliveryDescriptiveNameOverride,
						title: deliveryDescriptiveNameOverride
					}}
				/>
			)}
		</Stack>
	);
};

export default CheckoutSectionHeader;
