import Masonry from "@mui/lab/Masonry";
import { cloneDeep, isEmpty } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { defaultMapStateToProps, defaultActionsAndCartActionsToProps } from "../store/Actions";
import { calculateCartItemCost, calculateItemCalories, createNewCartItem } from "../store/CartUtils";

import { classes } from "../theme/GlobalStyle";

import { getItemObjFromMenu } from "../utils/ObjectUtils";
import { LogDebug, getCurrencyString, setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import MenuListItemComboOptions from "../views/menu/MenuListItemComboOptions";
import MenuItemDetails from "../views/menu/MenuItemDetails";
import MenuItemOptions from "../views/menu/MenuItemOptions";
import useAppDefaults from "../utils/hooks/useAppDefaults";
import useCommonData from "../utils/hooks/useCommonData";
import Constants from "../utils/Constants";
import FeatureConstants from "../utils/FeatureConstants";
import { hasFeatureFlag } from "../utils/PermissionUtils";
import { Stack } from "@mui/system";

const MenuCustomizationPage = ({ doUpdateAppBarTitle, doAddItemToCart, doUpdateCartItem }) => {
	const navigate = useNavigate();
	const routerLocation = useLocation();
	const { upsell_upsellid, upsell_variantid } = routerLocation.state || {};
	const { inputsectionid, inputitemid, inputcartitemid } = useParams(); //from url
	const scrollToRef = useRef([]);
	const modalFormRef = useRef();

	const { campusID, locationID, location, locationCart, menu } = useCommonData();
	const { pickupOrDelivery } = locationCart;
	const [cartItem, setCartItem] = useState({});
	const { widgetSpacingSmall } = useAppDefaults();
	const isUpdatingCartItem = inputcartitemid !== undefined;

	const sectionID = parseInt(inputsectionid) || 0;
	const itemID = parseInt(inputitemid) || 0;
	const [menuItem, setMenuItem] = useState({});
	const [price, setPrice] = useState(0);
	const [calories, setCalories] = useState();

	const showCalories = hasFeatureFlag(location, FeatureConstants.FEA_LOC_MENU_CUSTOMIZE_SHOW_TOTAL_CALS_COUNT);

	const { checkout_addtocart_button_title } = location;

	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		setAppPageTitle("Menu Customization");
	}, [navigate]);

	const handleChangeTab = (_event, newTabValue) => {
		// Need to use setTimeout as if you select a tab item that overflows, it takes time to move that scroll over, and this prevents the scrollIntoView from firing
		if (scrollToRef.current) {
			setTimeout(() => {
				scrollToRef.current[newTabValue].scrollIntoView({ behavior: "smooth" });
			}, 500);
		}
		setTabValue(newTabValue);
	};

	useEffect(() => {
		LogDebug(`useEffect home initalize`);
		if (locationID === 0) {
			LogDebug("locationID = 0, should go to a blank / error page");
			navigate(`/${campusID}`, { replace: true });
		}
		setMenuItem(getItemObjFromMenu(itemID, sectionID, menu, location, pickupOrDelivery));
	}, []);

	useEffect(() => {
		if (!menuItem || !Object.keys(menuItem).length) {
			setCartItem(undefined);
		} else {
			//cartItem needs to be created from the menuItem
			//note cartItem is different than menuItem
			//cartItem references menuItem, but cartItem contains the user selections in the values array
			if (isUpdatingCartItem) {
				const updateItem = locationCart.items.find((c) => c.cartItemId === inputcartitemid);
				setCartItem(updateItem);
			} else {
				setCartItem(
					createNewCartItem(menuItem, sectionID, location, locationID, pickupOrDelivery, menu, upsell_upsellid, upsell_variantid)
				);
			}
			doUpdateAppBarTitle(menuItem.name);
		}
	}, [menuItem, itemID, sectionID, inputcartitemid]);

	const calculateNewCost = (updatedItem) => {
		setPrice(calculateCartItemCost(updatedItem));
	};

	const calculateCalories = (updatedItem) => {
		setCalories(calculateItemCalories(updatedItem));
	};

	useEffect(() => {
		if (cartItem?.itemid) {
			calculateNewCost(cartItem);
			calculateCalories(cartItem);
		}
	}, [cartItem]);

	useEffect(() => {
		LogDebug(`useEffect campusID=${campusID} locationID=${locationID}`);
		if (campusID === 0 || locationID === 0) {
			//redirect here since campusID not entered
			navigate(`/`, { replace: true });
		}
	}, [locationID, campusID]);

	//if campusid or locationid is not supplied, then invalid url
	if (campusID === 0 || locationID === 0 || sectionID === 0 || itemID === 0) return <></>;

	const handleUpdateSelectedCheckboxOption = (selected, valueid, optionid) => {
		const updatedItem = cloneDeep(cartItem);
		const option = updatedItem.options.find((o) => o.optionid === optionid);
		const value = option.values.find((v) => v.valueid === valueid);
		value.selected = selected;
		setCartItem(updatedItem);
	};

	const handleUpdateSelectedRadioOption = (valueid, optionid) => {
		const updatedItem = cloneDeep(cartItem);
		const option = updatedItem.options.find((o) => o.optionid === optionid);
		if (option.optionid === optionid) {
			option.values.forEach((v) => {
				v.selected = v.valueid === valueid;
			});
		}
		setCartItem(updatedItem);
	};

	const validateOptions = () => {
		let errorIndex = -1;
		const updatedCartItem = cloneDeep(cartItem);
		updatedCartItem.options.forEach((option, i) => {
			const numberOfSelectedValues = option.values.filter((value) => value.selected).length;
			const error = numberOfSelectedValues > option.maximum || numberOfSelectedValues < option.minimum;
			option.isError = error;
			if (error) {
				errorIndex = errorIndex === -1 ? i : errorIndex;
			}
		});

		if (errorIndex !== -1) {
			if (scrollToRef.current) {
				setTimeout(() => {
					scrollToRef.current[errorIndex].scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
			setCartItem(updatedCartItem);
			setTabValue(errorIndex);
		}
		return errorIndex;
	};

	const handleOnClickAddUpdate = () => {
		if (validateOptions() > -1) return;

		if (isUpdatingCartItem) {
			doUpdateCartItem(locationID, cartItem);
			navigate(`/${campusID}/${locationID}/checkout`);
		} else if ((upsell_upsellid && upsell_variantid) || hasFeatureFlag(location, FeatureConstants.FEA_LOC_ADDING_ITEM_GOES_TO_CHECKOUT)) {
			doAddItemToCart(locationID, cartItem);
			navigate(`/${campusID}/${locationID}/checkout`);
		} else {
			doAddItemToCart(locationID, cartItem);
			navigate(`/${campusID}/${locationID}`);
		}
	};

	const handleUpdateSelectedComboRadioOption = (e, optionid, value) => {
		value = cloneDeep(value);
		const updatedCartItem = cloneDeep(cartItem);
		const option = updatedCartItem.options.find((o) => o.optionid === optionid);
		if (option.optionid === optionid) {
			option.values.forEach((v) => {
				v.selected = v.valueid === value.valueid;
			});
		}

		option.isError = false;

		setCartItem(updatedCartItem);
	};

	const modalOnSetComboItemClick = (closeModalCallback) => {
		const comboItem = modalFormRef.current.getData();
		if (!comboItem) return;

		const updatedCartItem = cloneDeep(cartItem);
		const optionsIndex = updatedCartItem.options.findIndex((o) => o.optionid === comboItem.optionid);
		const updatedOptionValues = updatedCartItem.options[optionsIndex].values.map((v) => {
			if (v.valueid === comboItem.valueid) {
				v = comboItem;
				v.selected = true;
			} else {
				v.selected = false;
			}
			return v;
		});

		updatedCartItem.options[optionsIndex].values = updatedOptionValues;
		updatedCartItem.options[optionsIndex].isError = false;
		let price_total = 0;

		updatedCartItem.options.forEach((o) => {
			o.values.forEach((v) => {
				if (v.selected) {
					price_total += v.price_total;
				}
			});
		});
		updatedCartItem.price_total = price_total;
		setCartItem(updatedCartItem);
		closeModalCallback();
	};

	const footerButtonText = isUpdatingCartItem ? `Update` : `${checkout_addtocart_button_title || "Add To Order"}`;

	return (
		<CustomDivContainer
			tabs={cartItem?.options?.length > 0 && cartItem.options}
			tabValue={tabValue}
			tabLabel="name"
			tabId="optionid"
			tabsIsSkipLink
			ariaLabel="Customization tabs"
			tabStyleClasses={[classes.contentRootDiv]}
			styleClasses={[classes.menuContentDiv, classes.contentRootDiv]}
			hideAutoScrollPadding
			handleChangeTab={handleChangeTab}
			showLoading={!location || !menu || isEmpty(cartItem)}
			footerBarProps={{
				footerBarLocation: Constants.APP_LOCATION.CUSTOMIZATION_PAGE,
				footerBarButtonText: footerButtonText,
				footerBarButtonTextLeft: showCalories ? `${calories} Cals` : undefined,
				footBarButtonTextLeftBorderHide: true,
				footerBarButtonTextRight: getCurrencyString(price),
				footerBarOnClickButton: handleOnClickAddUpdate
			}}
		>
			<Stack spacing={widgetSpacingSmall}>
				<MenuItemDetails menuItem={cartItem} location={location} />
				{cartItem?.options &&
					cartItem?.options.map((option, i) => {
						return option.is_combo ? (
							<MenuListItemComboOptions
								key={option.optionid}
								option={option}
								ref={(el) => (scrollToRef.current[i] = el)}
								location={location}
								handleUpdateSelectedRadioOption={handleUpdateSelectedComboRadioOption}
								modalFormRef={modalFormRef}
								modalOnSetComboItemClick={modalOnSetComboItemClick}
							/>
						) : (
							<MenuItemOptions
								key={option.optionid}
								options={option}
								ref={(el) => (scrollToRef.current[i] = el)}
								location={location}
								handleUpdateSelectedCheckboxOption={handleUpdateSelectedCheckboxOption}
								handleUpdateSelectedRadioOption={handleUpdateSelectedRadioOption}
							/>
						);
					})}
			</Stack>
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(MenuCustomizationPage);
