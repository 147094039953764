import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import ApiEndpoints from "../../api/ApiEndpoints";

export const handleSendPhoneText = (phoneNum, doApiCall) => {
	if (!phoneNum) return;

	phoneNum = phoneNum.replaceAll("-", "");

	doApiCall(
		ApiEndpoints.RegisterEnterPhoneNumber,
		{ phone_number: phoneNum },
		undefined,
		{
			logRequest: true,
			logResponse: true,
			hideErrorToast: false,
			successMessage: `Text sent to ${phoneNum}`,
			toastTitle: `Text Sent`
		},
		(data) => {},
		(error) => {}
	);
};

export const isPhoneNoReqCheck = (campus, user) => {
	if (
		hasFeatureFlag(campus, FeatureConstants.FEA_REGISTER_EMAIL_PHONE_VERIFY_REQD) &&
		user?.registration_type.toUpperCase() === "E" &&
		user?.phone_number.length === 0 &&
		user?.phone_verified === 0
	) {
		return true;
	}
	return false;
};

export const isPhoneVerifiedCodeReqCheck = (campus, user) => {
	if (
		hasFeatureFlag(campus, FeatureConstants.FEA_REGISTER_EMAIL_PHONE_VERIFY_REQD) &&
		user?.registration_type.toUpperCase() === "E" &&
		user?.phone_number.length === 10 &&
		user?.phone_verified === 0
	) {
		return true;
	}
	return false;
};

export const isEmailReqCheck = (campus, user) => {
	if (
		!hasFeatureFlag(campus, FeatureConstants.FEA_REGISTER_EMAIL_PHONE_VERIFY_REQD) &&
		hasFeatureFlag(campus, FeatureConstants.FEA_REGISTER_EMAIL_EMAIL_VERIFY_REQD) &&
		user?.registration_type.toUpperCase() === "E" &&
		user?.email_verified === 0
	) {
		return true;
	}
	return false;
};
