import { userCheckinRequiredBeforeProcessingOrder } from "../../store/CartUtils";
import { getDateFormats, getDateObjFromUTCString } from "../../utils/DateUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import { getCreditCardType, getCurrencyString } from "../../utils/Utils";
import { getCheckInRequiredText } from "../location/LocationUtils";

export const getOrderStatusText = (location, cart, orderidShortString) => {
	let orderStatusTitle;
	let orderStatusMessage;
	if (location.isVirtualQueue) {
		orderStatusTitle = "RESERVATION CONFIRMED";
	} else {
		orderStatusTitle = `ORDER RECEIVED: #${orderidShortString}`;
	}

	if (userCheckinRequiredBeforeProcessingOrder(location, cart)) {
		orderStatusMessage = getCheckInRequiredText(location);
	} else if (hasFeatureFlag(location, FeatureConstants.FEA_LOC_MARK_COMPLETE_RIGHTAWAY)) {
		orderStatusMessage = "";
	} else if (location?.checkout_confirmed_text?.length > 0) {
		orderStatusMessage = location.checkout_confirmed_text;
	} else if (location?.isVirtualQueue) {
		orderStatusMessage = "Please arrive at your scheduled time.";
	} else {
		orderStatusMessage = "You will be notified when your order is ready!";
	}

	return { orderStatusTitle, orderStatusMessage };
};

export const getOrderTitleDisplayString = (currentOrder) => {
	let refundedTestString = `#${currentOrder.orderid_display} `;
	if (currentOrder.test_order === 1) {
		refundedTestString += "(TEST)";
	} else if (currentOrder.isrefunded === 1 && currentOrder.swipe_refund_owed === 1) {
		refundedTestString += "(CONTACT FOR REFUND)";
	} else if (currentOrder.isrefunded === 1) {
		refundedTestString += "(REFUNDED)";
	}
	return refundedTestString;
};

export const getRefundString = (order, campus) => {
	let refundedString;
	if (order.isrefunded === 1 && order.refunded_datetime !== "") {
		const refundDate = getDateObjFromUTCString(order.refunded_datetime);
		refundedString = `Refunded: ${getDateFormats(refundDate)?.localDateTimeMonthDayYearHourMin}`;
	}
	let refundOwedContactText = campus.refund_owed_contact_text;
	if (!refundOwedContactText || refundOwedContactText.length === 0) {
		refundOwedContactText = "Contact Campus Card Office for swipe refund.";
	}

	let refundInfo = "";
	if (order.swipe_refund_owed === 1) {
		refundInfo = refundOwedContactText;
	} else if (order.refunded_text?.length > 0) {
		refundInfo = order.refunded_text;
	}

	return { refundInfo, refundedString };
};

export const buildTransactionDetailsTotalsArray = (campus, order) => {
	let mealplanDiscountString = "Mealplan Discount";
	if (hasFeatureFlag(campus, FeatureConstants.FEA_MPDISCOUNT_TXT_TO_DISCAPPLIED)) {
		mealplanDiscountString = "Discount Applied";
	}

	const transactionDetailsTotalsList = Array.of({ label: "Subtotal:", value: getCurrencyString(order.location_subtotal) });

	if (order.mealplan_discount_amount > 0) {
		transactionDetailsTotalsList.push({
			label: mealplanDiscountString,
			value: `(${getCurrencyString(order.mealplan_discount_amount)})`
		});
	}

	const credit = order.account_credit_total + order.promo_credit_total + order.campaign_credit_total;
	const rewardCredit = order.punchcard_credit_total;

	if (credit > 0) {
		transactionDetailsTotalsList.push({
			label: "Credit / Promo Applied:",
			value: `(${getCurrencyString(credit)})`
		});
	}
	if (rewardCredit > 0) {
		transactionDetailsTotalsList.push({
			label: "Reward Redemption:",
			value: `(${getCurrencyString(rewardCredit)})`
		});
	}

	transactionDetailsTotalsList.push({ label: "Tax:", value: getCurrencyString(order.location_subtotal_tax) });

	if (order.hangry_takeout_fee > 0) {
		transactionDetailsTotalsList.push({ label: "Service Fee", value: getCurrencyString(order.hangry_takeout_fee) });
		transactionDetailsTotalsList.push({ label: "Service Fee Tax", value: getCurrencyString(order.hangry_takeout_tax) });
	}

	if (order.location_delivery_fee > 0) {
		transactionDetailsTotalsList.push({ label: "Delivery Fee", value: getCurrencyString(order.location_delivery_fee) });
		transactionDetailsTotalsList.push({ label: "Delivery Fee Tax", value: getCurrencyString(order.location_delivery_tax) });
	}
	return transactionDetailsTotalsList;
};

export const getTransactionDetailsTenderValues = (menu, order) => {
	const {
		campus_tenderid,
		creditcard_type,
		creditcard_last4,
		creditcard_total,
		mealplan_casheq_total,
		mealplan_casheq_count,
		mealplan_mealex_total,
		mealplan_mealex_count,
		mealplan_total,
		secondary_tenderid,
		secondary_tender_total
	} = order;
	const _campusTenders = menu?.tenders;
	let tenderType = "";
	let tenderDisplayValue = "";
	let secondaryTenderType = "";
	let secondaryTenderDisplayValue = "";

	if (campus_tenderid > 0) {
		const tender = _campusTenders?.find((tender) => tender.ct_id === campus_tenderid);
		tenderType = tender?.name;

		if (mealplan_casheq_count > 0 || mealplan_mealex_count > 0) {
			tenderDisplayValue = `${mealplan_casheq_count > 0 ? mealplan_casheq_count : mealplan_mealex_count} Used ${getCurrencyString(
				mealplan_casheq_total > 0 ? mealplan_casheq_total : mealplan_mealex_total
			)}`;
		} else if (mealplan_total > 0) {
			tenderDisplayValue = getCurrencyString(mealplan_total);
		}

		if (secondary_tenderid > 0 && secondary_tender_total > 0) {
			secondaryTenderType = _campusTenders?.find((tender) => tender?.ct_id === secondary_tenderid).name;
			secondaryTenderDisplayValue = getCurrencyString(secondary_tender_total);
		} else if (secondary_tenderid === -1 && secondary_tender_total > 0) {
			secondaryTenderType = `${getCreditCardType(creditcard_type)} ****${creditcard_last4}`;
			secondaryTenderDisplayValue = getCurrencyString(creditcard_total);
		}
	} else {
		tenderType = `${getCreditCardType(creditcard_type)} ****${creditcard_last4}`;
		tenderDisplayValue = getCurrencyString(creditcard_total);
	}

	return { tenderType, tenderDisplayValue, secondaryTenderType, secondaryTenderDisplayValue };
};
