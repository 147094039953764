import _ from "lodash";
import { Grid, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import { classes } from "../../theme/GlobalStyle";
import { enqueueSnackbar } from "../../store/Actions";
import { getScrollClassNameFromTheme, setRadioButtonSelected } from "../../utils/Utils";
import { isWebOrderLocal } from "../../utils/EnvUtils";
import ApiEndpoints from "../../api/ApiEndpoints";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import ConstantsLabels from "../../utils/ConstantsLabels";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomGridContainer from "../custom/CustomGridContainer";
import Loading from "../../utils/components/Loading";

const CheckoutSectionTendersModalForm = forwardRef((props, ref) => {
	const routerLocation = useLocation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const scrollClassName = getScrollClassNameFromTheme(theme);

	const {
		hidePaymentMethod,
		hideTenderSelectIfOnlyOne,
		paymentTypes,
		selectedPaymentType,
		campusTenders,
		selectedCampusTender,
		creditCardPaymentsAllowed,
		mealPlanPaymentsAllowed,
		doApiCall
	} = props;
	const [paymentTypesModal, setPaymentTypesModal] = useState(() => paymentTypes || []);
	const [selectedPaymentTypeModal, setSelectedPaymentTypeModal] = useState(() => selectedPaymentType);
	const [campusTendersModal, setCampusTendersModal] = useState(() => campusTenders);
	const [selectedCampusTenderModal, setSelectedCampusTenderModal] = useState(() => selectedCampusTender);

	const [deleteCardPaymentType, setDeleteCardPaymentType] = useState({});
	const [isConfirmDeleteCardVisible, setIsConfirmDeleteCardVisible] = useState(false);
	const [busy, setBusy] = useState(false);

	const creditCardCount = paymentTypes.filter((pt) => pt.paymentTypeId !== 0).length;

	useEffect(() => {
		const { selectedArray } = setRadioButtonSelected(paymentTypes, "paymentTypeId", selectedPaymentType?.paymentTypeId);
		setPaymentTypesModal(selectedArray);
	}, [paymentTypes]);

	const callbackRef = (el) => (ref.current[6] = el);
	useImperativeHandle(callbackRef, () => ({
		returnSelectedData() {
			return { selectedPaymentTypeModal, selectedCampusTenderModal };
		}
	}));

	const displayErrorMessage = (errorMsg, errorReason) => {
		dispatch(
			enqueueSnackbar({
				message: errorMsg,
				title: errorReason,
				excludeFromHistory: true,
				options: { variant: "error" }
			})
		);
	};

	const handleOnChangePaymentType = (e) => {
		const body = {
			card_slot: e.target.value
		};

		doApiCall(
			ApiEndpoints.ChangeDefaultPaymentMethod,
			body,
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				const { selectedArray, selectedObject } = setRadioButtonSelected(paymentTypesModal, "paymentTypeId", e.target.value);
				setPaymentTypesModal(selectedArray);
				setSelectedPaymentTypeModal(selectedObject);
			},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	const handleAddNewCard = () => {
		doApiCall(
			ApiEndpoints.AddCreditCardGetUrl,
			{
				redirect_url: `${routerLocation.pathname}/addcard`,
				is_local: isWebOrderLocal() ? 1 : 0
			},
			setBusy,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				if (data.payment_url !== "") {
					window.location.href = data.payment_url;
				} else {
					displayErrorMessage("Error adding new card. Please try again later.", "ERROR_ADDING_NEW_CARD");
				}
			},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	const handleOnChangeCampusTender = (e) => {
		const { selectedArray, selectedObject } = setRadioButtonSelected(campusTenders, "ct_id", e.target.value);
		setCampusTendersModal(selectedArray);
		setSelectedCampusTenderModal(selectedObject);
	};

	const handleClickDeleteCard = (paymentType) => {
		setDeleteCardPaymentType(paymentType);
		setIsConfirmDeleteCardVisible(true);
	};

	const handleCancelDeleteCard = () => {
		setIsConfirmDeleteCardVisible(false);
	};

	const deleteCard = () => {
		const body = {
			card_slot: deleteCardPaymentType.paymentTypeId.toString()
		};

		doApiCall(
			ApiEndpoints.RemovePaymentMethod,
			body,
			null,
			{ logRequest: true, logResponse: true, hideErrorToast: false },
			(data) => {
				setIsConfirmDeleteCardVisible(false);
				const _paymentTypesModal = paymentTypesModal.filter((pt) => pt.paymentTypeId !== deleteCardPaymentType.paymentTypeId);
				if (selectedPaymentTypeModal.paymentTypeId === deleteCardPaymentType.paymentTypeId) {
					const { selectedArray, selectedObject } = setRadioButtonSelected(
						_paymentTypesModal,
						"paymentTypeId",
						_paymentTypesModal[0]?.paymentTypeId
					);
					setPaymentTypesModal(selectedArray);
					setSelectedPaymentTypeModal(selectedObject);
				} else {
					setPaymentTypesModal(_paymentTypesModal);
				}
			},
			(errorMsg, errorReason) => {
				displayErrorMessage(errorMsg, errorReason);
			}
		);
	};

	return (
		<>
			<CustomGridContainer
				direction="column"
				justifyContent="space-evenly"
				alignItems="stretch"
				spacing={2}
				className={clsx(classes.checkoutSectionTenderModalDiv)}
			>
				{!hidePaymentMethod && (
					<Grid item>
						<RadioGroup aria-labelledby={`tender-radio-buttons-group`} name={`tender-radio-buttons-group`}>
							{paymentTypesModal?.map((paymentType) => {
								return (
									<Stack
										direction={"row"}
										alignItems="center"
										paddingLeft={1.5}
										justifyContent={"space-between"}
										key={paymentType.paymentTypeId}
									>
										<FormControlLabel
											control={
												<Radio
													value={paymentType.paymentTypeId}
													checked={paymentType.selected}
													onChange={(e) => handleOnChangePaymentType(e)}
												/>
											}
											label={paymentType.name}
										/>
										{paymentType.paymentTypeId !== 0 && (
											<Tooltip
												aria-hidden="true"
												title={ConstantsLabels.labelButtonDeleteCreditCard}
												disableFocusListener
												id={`tooltip-Delete ${paymentType.name}`}
											>
												<IconButton
													aria-hidden="false"
													role={"button"}
													aria-label={`Delete ${paymentType.name} `}
													onClick={() => handleClickDeleteCard(paymentType)}
													aria-describedby={`tooltip-Delete ${paymentType.name}`}
												>
													<DeleteForeverIcon fontSize="small" />
												</IconButton>
											</Tooltip>
										)}
									</Stack>
								);
							})}
						</RadioGroup>
					</Grid>
				)}

				{paymentTypes.length === 0 && (
					<Grid item xs="auto">
						<Typography variant="body1" align="center" pb={2}>
							You currently don't have any Payment Cards on file.
						</Typography>
					</Grid>
				)}

				{creditCardCount < 2 && creditCardPaymentsAllowed && (
					<Grid item>
						<CustomFormButton
							variant="text"
							onClick={handleAddNewCard}
							color="secondary"
							size="medium"
							ref={(el) => (ref.current[7] = el)}
							startIcon={<AddIcon />}
							buttonText={"Add Credit Card"}
							disableDefaultStyle
							key={"add-credit-card-button"}
							className={clsx(classes.widthAuto)}
						/>
					</Grid>
				)}
			</CustomGridContainer>
			{selectedPaymentTypeModal?.paymentTypeId === 0 &&
				mealPlanPaymentsAllowed &&
				campusTendersModal.length > 0 &&
				!(hideTenderSelectIfOnlyOne && campusTendersModal.length === 1) && (
					<CustomGridContainer direction="column" spacing={2} mt={2} pl={2}>
						{!hidePaymentMethod && (
							<Grid item pb={2}>
								<Typography variant="h6" variantMapping={{ h6: "h2" }} align="center">
									{ConstantsLabels.labelCheckoutSelectTender}
								</Typography>
							</Grid>
						)}
						<Grid item className={clsx(classes.checkoutSectionTenderModalDiv, classes.fullWidth)}>
							<RadioGroup
								aria-labelledby={`campus-tenders-radio-buttons-group`}
								name={`campus-tenders-radio-buttons-group`}
								className={clsx(scrollClassName)}
								sx={{ flexWrap: "nowrap", overflowY: "auto", pl: 1.5 }}
							>
								{campusTendersModal.map((tender) => {
									return (
										<FormControlLabel
											control={
												<Radio
													value={tender.ct_id}
													checked={tender.selected}
													onChange={(e) => handleOnChangeCampusTender(e)}
												/>
											}
											label={tender.name}
											key={tender.ct_id}
										/>
									);
								})}
							</RadioGroup>
						</Grid>
					</CustomGridContainer>
				)}
			<Loading open={busy} />
			<ConfirmAlertDialog
				isVisible={isConfirmDeleteCardVisible}
				setIsVisible={setIsConfirmDeleteCardVisible}
				title={"Remove Card"}
				description={`Are you sure you want to unlink your ${deleteCardPaymentType.name} from the App?`}
				confirmButtonText={"Remove"}
				cancelCallback={handleCancelDeleteCard}
				confirmCallback={deleteCard}
				confirmDanger
			/>
		</>
	);
});

export default CheckoutSectionTendersModalForm;
