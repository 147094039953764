import React from "react";
import { styled } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

import { classes, CustomFormStyled } from "./CustomFormStyles";

const ContainerDiv = styled("div")(({ theme }) => CustomFormStyled(theme));

const CustomFormSwitch = ({
	inputID,
	inputLabel,
	setValue,
	isChecked,
	isReverse,
	isDisabled,
	setDirtyBit,
	helperText,
	helperTextTopPadding,
	marginTop,
	marginBottom,
	minHeight,
	onChange,
	icon
}) => {
	const theStyle = { marginTop, marginBottom };
	const helperTextID = inputID + "-helper";

	//e.preventdefault caused problems here
	const handleOnChange = (event) => {
		setValue(!isChecked);
		setDirtyBit && setDirtyBit(true);
		if (onChange) onChange(event);
	};

	return (
		<ContainerDiv className={classes.defaultPageContainer} style={theStyle}>
			<div style={{ minHeight: minHeight }}>
				<FormControlLabel
					classes={{ labelPlacementStart: classes.switchFormControlLabelPlacement, label: classes.switchFormControlLabelPlacement }}
					labelPlacement={"start"}
					label={
						<div style={{ display: "flex", alignItems: "center" }}>
							{icon}
							{inputLabel}
						</div>
					}
					control={
						<Switch
							id={inputID}
							edge="end"
							checked={isReverse ? !isChecked : isChecked}
							onChange={handleOnChange}
							disabled={isDisabled}
							color="secondary"
							inputProps={{ "aria-describedby": helperTextID }}
						/>
					}
				/>
			</div>
			{helperText && (
				<div style={{ padding: `${helperTextTopPadding ?? 0}px 15px` }}>
					<FormHelperText id={helperTextID}>{helperText}</FormHelperText>
				</div>
			)}
		</ContainerDiv>
	);
};

export default CustomFormSwitch;
