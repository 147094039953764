import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import { defaultMapStateToProps, defaultActionsToProps } from "../../store/Actions";
import { classes } from "../../theme/GlobalStyle";
import { isDeviceMobileFx, getScrollClassName } from "../../utils/Utils";
import CustomFormScheduleTimePicker from "../../utils/forms/CustomFormScheduleTimePicker";

import _ from "lodash";
import Loading from "../../utils/components/Loading";
import { Box, Divider, Stack, Typography } from "@mui/material";
import CustomAppBarSearch from "./CustomAppBarSearch";
import CustomFooterBar from "./CustomFooterBar";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import FeatureConstants from "../../utils/FeatureConstants";

const CustomDivContainer = (props) => {
	const {
		children,
		campus,
		tabValue,
		handleChangeTab,
		location,
		locationid,
		tabs,
		tabLabel,
		tabId,
		tabsIsSkipLink,
		settingsData,
		headerComponent,
		childrenHeaderComponent,
		scheduleProps,
		footerBarProps,
		showLoading,
		showLoadingOnTop,
		showSearch,
		hideAutoScrollPadding,
		doCreateCart,
		ariaLabel,
		orientationIsVertical
	} = props;
	const { themeType } = settingsData;
	const [childrenHeight, setChildrenHeight] = useState(240);
	const [headerRef, setHeaderRef] = useState(null);
	const [bodyRef, setBodyRef] = useState(null);
	const [footerRef, setFooterRef] = useState(null);
	const [scrollbarShowing, setScrollbarShowing] = useState(false);

	const scrollClassName = getScrollClassName(themeType);
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const styleClasses = props.styleClasses ? props.styleClasses : [];
	const tabStyleClasses = props.tabStyleClasses ? props.tabStyleClasses : [];

	useEffect(() => {
		// headerRef?.offsetHeight should be the height of the tab bar etc
		// bodyRef?.offsetTop should be the height of the AppBar + padding
		// footerRef?.offsetTop should be the height of the footer bar + 8 for the bottom margin + padding
		// isDeviceMobile checks if we've added a margin to top and bottom
		setChildrenHeight(
			(headerRef?.offsetHeight || 0) +
				(bodyRef?.offsetTop || 64) +
				(footerRef?.offsetHeight + (isDeviceMobile ? 0 : 8) || 0) +
				(isDeviceMobile ? 0 : 16)
		);
	}, [headerRef?.offsetHeight, bodyRef?.offsetTop]);

	return showLoading ? (
		<Loading />
	) : (
		<div className={classes.contentDivMargin} ref={(node) => setBodyRef(node)}>
			{showLoadingOnTop && <Loading />}
			{(tabs || scheduleProps || headerComponent) && (
				<Paper variant="outlined" square className={clsx(classes.tabMenuBar, [...tabStyleClasses])} ref={(node) => setHeaderRef(node)}>
					{scheduleProps && !scheduleProps.hideSchedule && (
						<CustomFormScheduleTimePicker
							locationid={locationid}
							scheduleProps={scheduleProps}
							doCreateCart={doCreateCart}
							isSkipLink
						/>
					)}
					{headerComponent && headerComponent}
					{tabs && (
						<Stack direction="row" alignItems="center" divider={<Divider role="presentation" orientation="vertical" flexItem />}>
							{showSearch && <CustomAppBarSearch />}
							<Tabs
								value={tabValue}
								onChange={handleChangeTab}
								variant="scrollable"
								scrollButtons="auto"
								aria-label={ariaLabel}
								allowScrollButtonsMobile
								orientation={orientationIsVertical ? "vertical" : undefined}
							>
								{tabs.map((tab, i) => {
									return (
										<Tab
											label={tab[tabLabel]}
											key={tab[tabId]}
											className={clsx({ [classes.errorTextRedItalicsBold]: tab.isError })}
											tabIndex={tabsIsSkipLink && i === 0 ? 0 : undefined}
											id={tabsIsSkipLink && i === 0 ? "skip_link" : undefined}
										/>
									);
								})}
							</Tabs>
						</Stack>
					)}
					{(hasFeatureFlag(campus, FeatureConstants.FEA_ORDER_SHOWNUTRITIONDISCLAIMER) || location?.header_text.length > 0) && (
						<>
							<Divider role="presentation" />
							<Box padding={1}>
								<Typography variant="caption">
									{location?.header_text ? location?.header_text : ConstantsLabels.labelNutritionDisclaimer}
								</Typography>
							</Box>
						</>
					)}
				</Paper>
			)}
			<Box
				sx={{
					height: `calc((100vh) - ${childrenHeight}px)`,
					overflow: "auto"
				}}
				className={clsx({ [scrollClassName]: !isDeviceMobile, [classes.scrollbarPadding]: scrollbarShowing && !hideAutoScrollPadding }, [
					...styleClasses
				])}
				ref={(el) => {
					setScrollbarShowing(el?.scrollHeight > el?.clientHeight || false);
				}}
			>
				<>
					{childrenHeaderComponent && childrenHeaderComponent}
					{children}
				</>
			</Box>
			{footerBarProps && !footerBarProps.hideFooterBar && <CustomFooterBar {...footerBarProps} setFooterRef={setFooterRef} />}
		</div>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(CustomDivContainer);
