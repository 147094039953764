import { Stack, Typography } from "@mui/material";
import React from "react";

import ConstantsLabels from "../ConstantsLabels";
import { getVersionInfo } from "../EnvUtils";


export default function TransactCompanyInfoFooter(showVersion = false) {
	const versionString = getVersionInfo();
	return (
		<Stack>
			{showVersion && <Typography variant="caption" align="center" gutterBottom>
				{versionString}
			</Typography>}
			<Typography variant="caption" align="center">
				{ConstantsLabels.labelRightsReserved}
			</Typography>
		</Stack>
	);
}
