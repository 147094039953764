import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import React from "react";
import { LinkItUrl, LinkItEmail } from "react-linkify-it";

import { styled } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import { classes, SupportModalStyle } from "./SupportStyle";

const RootAccordion = styled(Accordion)(({ theme }) => SupportModalStyle(theme));

const Faq = ({ faq, faqExpanded, handleExpandFaq, isSkipLink }) => {
	const { answer, question, faqid, sectionid } = faq;
	const key = `panel_${sectionid}_${faqid}`;
	return (
		<RootAccordion
			disableGutters
			elevation={0}
			square
			className={classes.faqAccordion}
			expanded={faqExpanded === key}
			onChange={handleExpandFaq(key)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`${key}-content`}
				className={classes.faqAccordionSummary}
				tabIndex={isSkipLink ? 0 : undefined}
				id={isSkipLink ? "skip_link" : `${key}-header`}
			>
				<Typography variant="h6" variantMapping={{ h6: "h2" }}>
					{question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.faqAccordionDetails}>
				<Typography variant="body1" paddingLeft={4}>
					<LinkItUrl>
						<LinkItEmail>{answer}</LinkItEmail>
					</LinkItUrl>
				</Typography>
			</AccordionDetails>
		</RootAccordion>
	);
};

export default Faq;
