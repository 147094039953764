import clsx from "clsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";
import { useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TransactionItemDetails from "./TransactionItemDetails";
import { getOrderTitleDisplayString, getOrderStatusText, getRefundString } from "./TransactionUtils";

import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import { calculateCartItemCost, createReorderedCartItem } from "../../store/CartUtils";
import { classes } from "../../theme/GlobalStyle";
import Constants from "../../utils/Constants";
import ConstantsLabels from "../../utils/ConstantsLabels";
import { getDateFormats, getDateObjFromUTCString } from "../../utils/DateUtils";
import FeatureConstants from "../../utils/FeatureConstants";
import { getItemObjFromMenu, getObjectFromArray } from "../../utils/ObjectUtils";
import { hasFeatureFlag } from "../../utils/PermissionUtils";
import ConfirmAlertDialog from "../../utils/alertdialog/ConfirmAlertDialog";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import useCommonData from "../../utils/hooks/useCommonData";
import QRCode from "react-qr-code";
import useCommonApiCalls from "../../utils/hooks/useCommonApiCalls";

const TransactionListItem = ({ heading, details }) => {
	return (
		<ListItem dense>
			<Stack alignItems={"center"} className={classes.fullWidth}>
				<Typography variant={"h6"} variantMapping={{ h6: "h2" }}>
					{heading}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{details}
				</Typography>
			</Stack>
		</ListItem>
	);
};

const TransactionItem = ({
	appLocation,
	currentOrder,
	doAddItemToCart,
	doApiCall,
	doClearCart,
	doUpdateCartPickupDelivery,
	doUpdateAppBarTitle,
	hideBarcode
}) => {
	const navigate = useNavigate();
	const { cafeterias, campus, getCartFromLocationId, getLocationWithDisabledFromId, getMenuFromLocationId, menu, user } = useCommonData();
	const { getMenu } = useCommonApiCalls();

	const cafeteria = getObjectFromArray(currentOrder?.cafeteriaid, cafeterias, "cafeteriaid");
	const location = getLocationWithDisabledFromId(currentOrder?.locationid);
	const locationCart = getCartFromLocationId(currentOrder?.locationid);

	const [isVisible, setIsVisible] = useState(false);
	const [reorderCartItems, setReorderCartItems] = useState([]);
	const [reorderUnavailableItems, setReorderUnavailableItems] = useState([]);

	const [refundObject, setRefundObject] = useState({});

	const [titleText, setTitleText] = useState("");

	const isConfirmationPage = appLocation === Constants.APP_LOCATION.CONFIRMATION_PAGE;
	const isTransactionHistoryPage = appLocation === Constants.APP_LOCATION.TRANSACTION_HISTORY_PAGE;

	useEffect(() => {
		const title = getOrderTitleDisplayString(currentOrder);
		setTitleText(title);
		doUpdateAppBarTitle(isConfirmationPage ? ConstantsLabels.labelPageTitles.confirmation : title);

		const { refundInfo, refundedString } = getRefundString(currentOrder, campus);
		setRefundObject({ refundInfo, refundedString });
	}, [currentOrder]);

	useEffect(() => {
		// If there's no menu, this might be a new device.  To enable reorder, we'll need to make sure we have a menu in redux
		if (!menu) {
			getMenu(currentOrder.locationid);
		}
	}, []);

	if (!cafeteria || !location) return;

	const {
		complete_datetime: completedDate,
		order_datetime: orderDate,
		preferred_target_datetime: targetDate,
		orderid_short_string: orderidShortString
	} = currentOrder;
	const localOrderDateObj = getDateObjFromUTCString(orderDate);
	const localOrderDate = getDateFormats(localOrderDateObj)?.localDateTimeMonthDayYearHourMin;

	const localTargetDateObj = getDateObjFromUTCString(targetDate);
	const localTargetDate = getDateFormats(localTargetDateObj)?.localDateTimeMonthDayYearHourMin;

	const localCompletedDateObj = getDateObjFromUTCString(completedDate);
	const localCompletedDate = localCompletedDateObj ? getDateFormats(localCompletedDateObj)?.localDateTimeMonthDayYearHourMin : null;

	const hideTargetTime = hasFeatureFlag(location, FeatureConstants.FEA_LOC_RECEIPT_HIDE_TARGETTIME);

	const { orderStatusTitle, orderStatusMessage } = getOrderStatusText(location, locationCart, orderidShortString);

	const handleReorder = () => {
		const locationid = currentOrder.locationid;

		const unavailableItems = [];
		const newCart = [];
		const menu = getMenuFromLocationId(locationid);
		currentOrder.items.forEach((item) => {
			if (item.comboHide) return;
			const itemid = item.menu_itemid;
			const sectionid = item.menu_sectionid;

			const menuItem = getItemObjFromMenu(itemid, sectionid, menu, location, currentOrder.retrieval_types);
			const { cartItem, unavailableItems: ui } = createReorderedCartItem(
				menuItem,
				sectionid,
				location,
				currentOrder.retrieval_type,
				currentOrder.orderid,
				item.options,
				menu
			);

			unavailableItems.push(...ui);
			// If item has been removed, cartItem will be null here
			if (cartItem) {
				calculateCartItemCost(cartItem);
				newCart.push(cartItem);
				setReorderCartItems(newCart);
			}
		});

		if (unavailableItems.length > 0) {
			setReorderUnavailableItems(unavailableItems);
			setIsVisible(true);
		} else {
			reorderNavigateToCheckout(newCart);
		}
	};

	const reorderNavigateToCheckout = (newCart) => {
		const locationid = currentOrder.locationid;
		const _localCart = newCart ? newCart : reorderCartItems;
		doClearCart(locationid);

		let paymentMethodDefault = "1";
		if (currentOrder.campus_tenderid >= 0) {
			paymentMethodDefault = "0";
		} else if (currentOrder.creditcard_last4 === user.creditcard_2_last4) {
			paymentMethodDefault = "2";
		}

		if (user.payment_method_default.toString() !== paymentMethodDefault) {
			const body = {
				card_slot: paymentMethodDefault
			};

			doApiCall(
				ApiEndpoints.ChangeDefaultPaymentMethod,
				body,
				null,
				{ logRequest: true, logResponse: true, hideErrorToast: false },
				(data) => {},
				(errorMsg, errorReason) => {}
			);
		}

		if (_localCart.length > 0) {
			_localCart.forEach((cartItem) => {
				doAddItemToCart(currentOrder.locationid, cartItem);
			});
			doUpdateCartPickupDelivery(locationid, currentOrder.retrieval_type);
		}

		navigate(`/${currentOrder.campusid}/${locationid}/checkout`, { state: { order: currentOrder } });
	};

	const handleGoHome = () => {
		navigate(`/${currentOrder.campusid}`);
	};

	return (
		<>
			{currentOrder && (
				<Card className={clsx(classes.noBorder, classes.noBoxShadow)}>
					<CardHeader
						title={isConfirmationPage ? orderStatusTitle : titleText}
						titleTypographyProps={{ textAlign: "center" }}
						subheader={isConfirmationPage && (orderStatusMessage || ConstantsLabels.labelTransactionConfirmationMessage)}
						subheaderTypographyProps={{ textAlign: "center" }}
					/>
					<CardContent sx={{ textAlign: "center" }} className={classes.noPaddingOnSmallScreen}>
						{isTransactionHistoryPage && !hideBarcode && (
							<div className={classes.QRCodeContainer}>{<QRCode value={currentOrder.barcode_token} level="Q" size={250} />}</div>
						)}
						{!isTransactionHistoryPage && (
							<CustomFormButton
								buttonText="View Order Status"
								onClick={handleGoHome}
								inputID={"skip_link"}
								disableFullWidth
								disableDefaultStyle
							/>
						)}
						{currentOrder.virtualqueue === 1 && (
							<Typography variant="h5" variantMapping={{ h5: "h2" }}>
								#{currentOrder.orderid_short_string}
							</Typography>
						)}
						<List disablePadding className={classes.listTransparentNoBorder}>
							{refundObject.refundInfo && refundObject.refundedString && (
								<TransactionListItem heading={refundObject.refundInfo} details={refundObject.refundedString} />
							)}

							<TransactionListItem heading={"Ordered"} details={`${localOrderDate}`} />

							{!hideTargetTime && <TransactionListItem heading={"Target"} details={localTargetDate} />}

							{isTransactionHistoryPage && (
								<TransactionListItem heading={"Completed"} details={`${localCompletedDate ? localCompletedDate : "-"}`} />
							)}

							<ListItem dense divider key={`${currentOrder.orderid_display}-location`}>
								<Stack alignItems={"stretch"} className={classes.fullWidth}>
									<Stack direction="row" textAlign={"center"} display={"block"}>
										<Avatar
											srcSet={location.icon_picture_url_with_base}
											alt={location.name}
											sx={{ width: 56, height: 56, margin: "auto" }}
										/>
										<Typography
											variant="h6"
											variantMapping={{ h6: "h2" }}
											color="text.secondary"
											sx={{ fontWeight: "bold" }}
										>
											{`${location.name}`}
										</Typography>
										<Typography
											variant="h6"
											variantMapping={{ h6: "h2" }}
											color="text.secondary"
											mb={!cafeteria?.group_display_subtitle ? 2 : null}
										>
											{`${cafeteria?.group_display_name}`}
										</Typography>
										{cafeteria?.group_display_subtitle ? (
											<Typography variant="h6" variantMapping={{ h6: "h2" }} color="text.secondary" mb={2}>
												{`${cafeteria?.group_display_subtitle}`}
											</Typography>
										) : (
											<>
												<Typography variant="h6" variantMapping={{ h6: "h2" }} color="text.secondary">{`${
													cafeteria?.address1
												}${!!cafeteria?.address2 ? `, ${cafeteria?.address2}` : ""}`}</Typography>
												<Typography
													variant="h6"
													variantMapping={{ h6: "h2" }}
													color="text.secondary"
												>{`${cafeteria?.city}, ${cafeteria?.province}`}</Typography>
												<Typography
													variant="h6"
													variantMapping={{ h6: "h2" }}
													color="text.secondary"
												>{`${location?.phone_number}`}</Typography>
											</>
										)}

										<Typography variant="h6" variantMapping={{ h6: "h2" }} color="text.secondary" gutterBottom>
											{`${campus?.name}`}
										</Typography>
									</Stack>
								</Stack>
							</ListItem>

							{isTransactionHistoryPage && location.web_order_enabled > 0 && (
								<ListItem
									dense
									divider
									key={`${currentOrder.orderid_display}-reorder`}
									className={clsx(classes.justifyCenter)}
								>
									<CustomFormButton buttonText="Reorder" disableFullWidth disableDefaultStyle onClick={handleReorder} />
								</ListItem>
							)}
						</List>
						<TransactionItemDetails orderData={currentOrder} locationData={location}></TransactionItemDetails>
					</CardContent>
				</Card>
			)}

			<ConfirmAlertDialog
				isVisible={isVisible}
				setIsVisible={setIsVisible}
				title={"ITEMS/MODIFIERS NO LONGER AVAILABLE"}
				listItems={reorderUnavailableItems}
				listHeaderText={"The following are no longer available and will be removed if you continue:"}
				confirmText={"Continue"}
				cancelCallback={() => setIsVisible(false)}
				confirmCallback={reorderNavigateToCheckout}
			/>
		</>
	);
};

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps)(TransactionItem);
