import { getIsHidden } from "./Utils";

//standard method for most backend Objects
export const getObjectFromArray = (id, thearray, key, defaultResponse) => {
	if (!thearray || !id || !key) return defaultResponse;

	const targetID = parseInt(id) || 0;
	for (let i = 0; i < thearray.length; i++) {
		if (targetID === thearray[i][key]) return thearray[i];
	}
	return defaultResponse;
};

export const getMenuSectionsFromMenu = (location, menu, pickupOrDelivery) => {
	if (!menu || !menu.sections_1) return [];
	return menu.sections_1.filter((s) => s.is_deleted === 0 && s.currently_available === 1 && !getIsHidden(location, s, pickupOrDelivery));
};

export const getMenuItemsFromMenuSection = (location, menuSection, pickupOrDelivery) => {
	if (!menuSection || !menuSection.items) return [];
	return menuSection.items.filter((i) => i.is_deleted === 0 && !getIsHidden(location, i, pickupOrDelivery));
};

export const getMenuOptionsFromMenuItem = (location, menuItem, pickupOrDelivery) => {
	if (!menuItem || !menuItem.options) return [];
	return menuItem.options.filter((o) => o.is_deleted === 0 && !getIsHidden(location, o, pickupOrDelivery));
};

export const getMenuValuesFromMenuOption = (location, menuOption, pickupOrDelivery) => {
	if (!menuOption || !menuOption.values) return [];
	return menuOption.values.filter((v) => v.is_deleted === 0 && !getIsHidden(location, v, pickupOrDelivery));
};

export const getMenuComboValuesFromMenuOption = (location, menuOption, pickupOrDelivery) => {
	if (!menuOption || !menuOption.values) return [];
	return menuOption.values.filter((v) => v.is_deleted === 0 && !getIsHidden(location, v, pickupOrDelivery) && v.combo_hide === 0);
};

export const getSectionObjFromMenu = (sectionid, location, menu, pickupOrDelivery) => {
	if (!menu || !menu.sections_1 || sectionid <= 0) return undefined;

	let obj = undefined;

	const sections = getMenuSectionsFromMenu(location, menu, pickupOrDelivery);
	sections.forEach((s) => {
		if (s.sectionid === sectionid) {
			obj = s;
		}
	});

	return obj;
};

export const getItemObjFromMenu = (itemid, sectionid, menu, location, pickupOrDelivery) => {
	if (!menu || !menu.sections_1 || sectionid <= 0) return undefined;

	const section = getSectionObjFromMenu(sectionid, location, menu, pickupOrDelivery);
	if (!section) return undefined;

	let obj = undefined;

	const items = getMenuItemsFromMenuSection(location, section, pickupOrDelivery);
	items.forEach((i) => {
		if (i.itemid === itemid) obj = i;
	});

	return obj;
};
