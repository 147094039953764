import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { defaultMapStateToProps, defaultActionsToProps } from "../store/Actions";

import { GlobalStyle, classes } from "../theme/GlobalStyle";

import Loading from "../utils/components/Loading";
import { setAppPageTitle } from "../utils/Utils";

import CustomDivContainer from "../views/custom/CustomDivContainer";
import RewardsDetails from "../views/rewards/RewardsDetails";

const RewardsPage = ({ userData, campusData, doApiCall }) => {
	const navigate = useNavigate();
	const [busy, setBusy] = useState(false);
	const { user } = userData;
	const { campus } = campusData;

	const theme = useTheme();

	useEffect(() => {
		setAppPageTitle("Rewards");
	}, [navigate]);

	//wait for the theme to load to avoid strange color mismatch bug
	if (!theme.isThemeLoaded) return <></>;

	//show loading while we are initializing
	if (busy) return <Loading />;

	if (!campus || !user) return <Loading />;

	return (
		<CustomDivContainer styleClasses={[classes.contentDivContainer]}>
			<RewardsDetails />
		</CustomDivContainer>
	);
};

export default connect(defaultMapStateToProps, defaultActionsToProps)(RewardsPage);
