import SaveIcon from "@mui/icons-material/SaveOutlined";
import { Grid, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
// import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import ApiEndpoints from "../../api/ApiEndpoints";
import { defaultActionsToProps, defaultMapStateToProps } from "../../store/Actions";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import CustomFormErrorAlert from "../../utils/forms/CustomFormErrorAlert";
import ImageUploaderStyle, { classes } from "../../utils/imageupload/ImageUploaderStyle";
import { canvasPreview, centerAspectCrop, getCroppedBase64ImgDataUrl, useDebounceEffect } from "../../utils/ImageUtils";
import { isDarkThemeWithObj, isDeviceMobileFx, LogDebug } from "../../utils/Utils";
import Webcam from "react-webcam";

const Root = styled("div")(({ theme }) => ImageUploaderStyle(theme));
const defaultCropProps = { aspect: 1.0, unit: "%", width: 100 };

const ProfileCameraImageUploader = React.forwardRef(({ userData, doApiCall, successCallback }, ref) => {
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const { user } = userData;
	const theme = useTheme();
	const isDarkTheme = isDarkThemeWithObj(theme);
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);

	const [busy, setBusy] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState("");

	const [cropperBackgroundLight, setCropperBackgroundLight] = useState(!isDarkTheme);
	const [imageData, setImageData] = useState("");
	const [convertFromPngToJpeg, setConvertFromPngToJpeg] = useState(false);

	const [crop, setCrop] = useState(defaultCropProps);
	const [completedCrop, setCompletedCrop] = useState();

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0);
			}
		},
		100,
		[completedCrop]
	);

	const saveClicked = () => {
		setFormErrorMessage("");

		const croppedImageData = getCroppedBase64ImgDataUrl(previewCanvasRef, false, convertFromPngToJpeg);

		const reqBody = {
			image_data: croppedImageData
		};
		if (croppedImageData.length < 20) return;

		doApiCall(
			ApiEndpoints.ChangePhoto,
			reqBody,
			setBusy,
			{
				logRequest: true,
				logResponse: true,
				successMessage: "Profile Picture Uploaded",
				//hideErrorToast: true,
				setFormErrorMessage: setFormErrorMessage,
				toastTitle: "" + user.full_name
			},
			(data) => {
				//should return user onbject
				successCallback && successCallback(data);
			}
		);
	};

	const doCaptureAgain = () => {
		setImageData(""); //clear
	};

	const onImageLoaded = (imageRef) => {
		const { width, height } = imageRef.currentTarget;
		setCrop(centerAspectCrop(width, height, 1));
	};

	const onCropChange = (crop, percentCrop) => {
		if (crop.width === 0 && crop.height === 0) setCrop(defaultCropProps);
		else setCrop(percentCrop);
	};

	const webcamRef = React.useRef(null);
	const capture = React.useCallback(() => {
		setCrop(undefined); // Makes crop preview update between images.
		const imageSrc = webcamRef.current.getScreenshot();
		setImageData(imageSrc);
	}, [webcamRef, setImageData]);

	const onComplete = (c) => {
		setCompletedCrop(c);
	};

	const hasCapturedImage = imageData?.length > 0;
	const errorAlertAria = "profile-upload-image-form-error";

	return (
		<Root className={classes.outterContainer}>
			<div className={classes.selectFileDivProfile}>
				{!hasCapturedImage && (
					<>
						<Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className={classes.selectFileDivProfile} />
						<CustomFormButton
							buttonText="CAPTURE PHOTO...."
							className={classes.bottomButton}
							variant="outlined"
							style={{ minWidth: 200, height: 56 }}
							disabled={busy}
							disableElevation
							disableDefaultStyle
							disableFullWidth
							onClick={capture}
						/>
					</>
				)}

				{hasCapturedImage && (
					<>
						<div
							className={clsx(classes.cropperDiv, {
								[classes.cropperDivLight]: cropperBackgroundLight,
								[classes.cropperDivDark]: !cropperBackgroundLight
							})}
						>
							<ReactCrop disabled={busy} crop={crop} onChange={onCropChange} onComplete={onComplete} circularCrop aspect={1}>
								<img
									ref={imgRef}
									src={imageData}
									alt={"New Profile Upload"}
									style={{ margin: "0 auto", maxHeight: 380, minHeight: 100 }}
									onLoad={onImageLoaded}
								/>
							</ReactCrop>
							<canvas
								ref={previewCanvasRef}
								style={{
									display: "none"
								}}
							/>
						</div>
						<div className={classes.bottomButtonsDivProfile}>
							<Grid
								container
								spacing={2}
								direction="row"
								alignItems="center"
								justifyContent={isDeviceMobile ? "center" : "space-between"}
							>
								<Grid item>
									<CustomFormButton
										buttonText="CAPTURE NEW PHOTO...."
										className={classes.bottomButton}
										variant="outlined"
										style={{ minWidth: 200, height: 56 }}
										disabled={busy}
										disableElevation
										disableDefaultStyle
										disableFullWidth
										onClick={doCaptureAgain}
									/>
								</Grid>
								<Grid item>
									<CustomFormButton
										buttonText="SAVE"
										className={classes.bottomButton}
										variant="contained"
										style={{ minWidth: 200, height: 56 }}
										startIcon={<SaveIcon />}
										disabled={busy}
										disableElevation
										disableDefaultStyle
										disableFullWidth
										onClick={saveClicked}
									/>
								</Grid>
							</Grid>
							<div className={classes.bottomButtonsInnerDiv}>
								{formErrorMessage.length > 0 && (
									<CustomFormErrorAlert
										theID={errorAlertAria}
										setFormErrorMessage={setFormErrorMessage}
										formErrorMessage={formErrorMessage}
										margin={"20px auto 0"}
									/>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</Root>
	);
});

export default connect(defaultMapStateToProps, defaultActionsToProps, null, { forwardRef: true })(ProfileCameraImageUploader);
