import { Avatar, Box, ListItemButton, Stack } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NoteAltIcon from "@mui/icons-material/NoteAlt";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CustomFormErrorAlertRedux from "./CustomFormErrorAlertRedux";

import { classes } from "../../theme/GlobalStyle";
import CustomGridContainer from "../../views/custom/CustomGridContainer";
import CustomModal2 from "../../views/modals/CustomModal2";

const CustomFormLabelItem = ({
	colorRed,
	dense,
	errorKey,
	fullBorder,
	hideRightChevron,
	hideDivider,
	hideIcon,
	Icon,
	IconProps,
	icon_url,
	iconVariant,
	isDisabled,
	fullHeight,
	label,
	labelVariantMapping,
	onClick,
	required,
	rightDisplayElement,
	rightDisplayText,
	rightDisplayTextClassName,
	rightDisplayTextVariant = "body1",
	subLabel,
	subLabelElement,
	subLabelError,
	hideModal,
	showModal,
	showLargeIcon,
	modalProps,
	modalForm: ModalForm,
	modalFormProps = {},
	isLabel = false,
	isAltPresentation = false,
	isSkipLink = false
}) => {
	const { modalFormRef, ...other } = modalFormProps;
	useEffect(() => {
		if (showModal) {
			setIsShowingModal(true);
		}
	}, [showModal]);

	useEffect(() => {
		if (hideModal) {
			setIsShowingModal(false);
		}
	}, [hideModal]);

	const [isShowingModal, setIsShowingModal] = useState(false);
	const handleSetIsShowingModal = () => {
		setIsShowingModal(!isShowingModal);
	};

	const handleOnClick = (e) => {
		e && e.preventDefault();
		if (onClick) {
			onClick();
		} else {
			handleSetIsShowingModal();
		}
	};

	return (
		<Stack className={clsx(classes.fullWidth, { [classes.fullHeight]: fullHeight })}>
			<ListItemButton
				className={clsx(classes.customLabelItemListButton, classes.fullWidth, {
					[classes.buttonAsLabel]: isLabel,
					[classes.errorTextRed]: colorRed,
					[classes.customLabelItemGridDense]: dense,
					[classes.customLabelItemListButtonBorder]: fullBorder
				})}
				disableGutters
				divider={!hideDivider && !fullBorder}
				onClick={handleOnClick}
				disabled={isDisabled}
				component={isLabel ? "label" : "button"}
				role={isLabel ? "label" : "button"}
				tabIndex={isSkipLink ? 0 : undefined}
				id={isSkipLink ? "skip_link" : undefined}
			>
				<CustomGridContainer
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					className={clsx(classes.fullHeight, classes.widthInherit)}
				>
					{!hideIcon && (
						<Grid item xs="auto">
							{icon_url ? (
								<Avatar
									color="primary"
									srcSet={icon_url}
									alt={isAltPresentation ? "" : label}
									className={clsx({ [classes.iconAvatar]: !showLargeIcon, [classes.iconAvatarLarge]: showLargeIcon })}
									variant={iconVariant ? iconVariant : "circular"}
								/>
							) : (
								<Box className={clsx({ [classes.iconSpacingLarge]: showLargeIcon })}>
									{Icon ? (
										<Icon role="img" className={classes.iconVerticalAlign} {...IconProps} />
									) : (
										<NoteAltIcon className={classes.iconVerticalAlign} />
									)}
								</Box>
							)}
						</Grid>
					)}
					<Grid
						item
						xs
						className={clsx({
							[classes.customLabelItemListButtonDivider]: !hideIcon
						})}
					>
						<Stack direction={"column"}>
							<Stack direction={"row"} spacing={0.5}>
								<Typography variant={"body1"} variantMapping={{ body1: labelVariantMapping ? labelVariantMapping : "span" }}>
									{label}
								</Typography>
								{required && (
									<Typography
										variant={"body1"}
										variantMapping={{ body1: labelVariantMapping ? labelVariantMapping : "span" }}
										color="error.main"
									>
										*
									</Typography>
								)}
							</Stack>

							{subLabel && (
								<Typography
									variant={"subtitle2"}
									variantMapping={{ subtitle2: labelVariantMapping ? labelVariantMapping : "span" }}
									className={clsx({ [classes.errorTextRedItalicsBold]: subLabelError })}
								>
									{subLabel}
								</Typography>
							)}
							{subLabelElement && subLabelElement}
						</Stack>
					</Grid>
					{rightDisplayText && (
						<Grid item xs="auto">
							<Typography variant={rightDisplayTextVariant} className={rightDisplayTextClassName}>
								{rightDisplayText}
							</Typography>
						</Grid>
					)}
					{rightDisplayElement && (
						<Grid item xs="auto">
							{rightDisplayElement}
						</Grid>
					)}
					{!hideRightChevron && (
						<Grid item xs="auto">
							{<ChevronRightIcon className={classes.iconVerticalAlign} />}
						</Grid>
					)}
				</CustomGridContainer>
				{ModalForm && (
					<CustomModal2 setIsShowing={setIsShowingModal} isShowing={isShowingModal} {...modalProps}>
						<ModalForm ref={modalFormRef} {...other} />
					</CustomModal2>
				)}
			</ListItemButton>
			{errorKey && <CustomFormErrorAlertRedux errorKey={errorKey} />}
		</Stack>
	);
};

export default CustomFormLabelItem;
