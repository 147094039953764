import _ from "lodash";
import { getAnalytics, logEvent, isSupported as analyticsIsSupported } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { fb_app } from "./fb-config";

// Initialize Analytics and get a reference to the service
let analytics;
if (analyticsIsSupported()) {
	analytics = getAnalytics(fb_app);
	console.log("Analytics initialized!");
} else {
	analytics = null;
	console.warn("Analytics is not supported...");
}

const useGoogleAnalytics = () => {
	const location = useLocation();

	useEffect(() => {
		const currentPath = location.pathname + location.search + location.hash;
		logAnalyticEvent("page_view", { page: currentPath });
	}, [location]);

	const logAnalyticEvent = (eventName, eventParams) => {
		if (_.isEmpty(analytics)) {
			analytics = getAnalytics(fb_app);
		}
		logEvent(analytics, eventName, eventParams);
	};

	return { logEvent, analyticsIsSupported, logAnalyticEvent };
};
export default useGoogleAnalytics;
