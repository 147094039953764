import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Tab } from "@mui/material";

import ConstantsLabels from "../ConstantsLabels";
import { getFormattedHourString } from "../DateUtils";
import FeatureConstants from "../FeatureConstants";
import { hasFeatureFlag } from "../PermissionUtils";

import { defaultActionsAndCartActionsToProps, defaultMapStateToProps } from "../../store/Actions";

import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import RestaurantIcon from "@mui/icons-material/Restaurant";

import Loading from "../components/Loading";

import useCommonApiCalls from "../hooks/useCommonApiCalls";
import useCommonData from "../hooks/useCommonData";
import Constants from "../Constants";
import { classes, CustomFormStyled } from "./CustomFormStyles";

const ButtonRoot = styled(Button)(({ theme }) => CustomFormStyled(theme));

const PickupOrDeliverySelect = ({ selectedPickupOrDelivery, handleChangePickupDelivery }) => {
	return (
		<Stack direction="row" justifyContent={"space-around"}>
			<ButtonRoot
				onClick={(e) => handleChangePickupDelivery(e, Constants.pickupDeliveryTypeEnum.PICKUP_0)}
				variant={selectedPickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0 ? "contained" : "outlined"}
				className={classes.pickupDeliveryButton}
				aria-selected={selectedPickupOrDelivery === Constants.pickupDeliveryTypeEnum.PICKUP_0}
			>
				<Stack alignItems="center">
					<RestaurantIcon />
					<Typography variant="body1" align="center">
						{ConstantsLabels.labelPickup}
					</Typography>
				</Stack>
			</ButtonRoot>
			<ButtonRoot
				onClick={(e) => handleChangePickupDelivery(e, Constants.pickupDeliveryTypeEnum.DELIVERY_1)}
				variant={selectedPickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 ? "contained" : "outlined"}
				className={classes.pickupDeliveryButton}
				aria-selected={selectedPickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1}
			>
				<Stack alignItems="center">
					<DeliveryDiningIcon />
					<Typography variant="body1" align="center">
						{ConstantsLabels.labelDelivery}
					</Typography>
				</Stack>
			</ButtonRoot>
		</Stack>
	);
};

const CustomFormModalScheduleTime = forwardRef((props, ref) => {
	const { doUpdateScheduledTime, doClearErrorAlert, doUpdateCartPickupDelivery } = props;
	const { getMenu } = useCommonApiCalls();
	const { carts, locationCart, location, locationID, menuSlot } = useCommonData();
	const { delivery, scheduling_orders_days_ahead, takeout } = location;
	const { menu_date_slots, menu_time_slots, asap_min, asap_max } = menuSlot;

	const { pickupOrDelivery } = locationCart;

	const [selectedPickupOrDelivery, setSelectedPickupOrDelivery] = useState(pickupOrDelivery);

	const [tabValue, setTabValue] = React.useState(locationCart?.end_time || location.allow_asap_orders === 0 ? "2" : "1");
	const [timeArray, setTimeArray] = React.useState([]);
	const [availableMinutes, setAvailableMinutes] = useState([]);
	const [selectedDateLocal, setSelectedDateLocal] = React.useState("");
	const [selectedHour, setSelectedHour] = useState("");
	const [selectedEndTime, setSelectedEndTime] = useState("");
	const [busy, setBusy] = useState(false);

	const isHideTimeSelectionIfOnlyOneSlot = hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_TIME_IF_ONE_SLOT);

	useImperativeHandle(ref, () => ({
		saveScheduledTime() {
			doClearErrorAlert();
			if (tabValue === "1") {
				doUpdateScheduledTime(locationID, "", "", "", "");
			} else {
				const date_slot = menu_date_slots.find((mds) => mds.date_local === selectedDateLocal);
				const time_slot = menu_time_slots.find((t) => t.end_time === selectedEndTime);
				doUpdateScheduledTime(
					locationID,
					date_slot?.date_display || "",
					date_slot?.date_local || "",
					time_slot?.end_time || "",
					time_slot?.start_time || ""
				);
			}
		}
	}));

	const getAvailableTimeObjects = (updatedSelectedHour) => {
		const _hours = [];
		const _time = [];
		menu_time_slots.forEach((mts) => {
			const { end_time, start_time } = mts;
			const [_hour, _minutes] = end_time?.split(":");

			const hoursDisplay = getFormattedHourString(end_time);

			if (!_hours.includes(_hour)) {
				_hours.push(_hour);
				_time.push({ hour: _hour, hoursDisplay, minutes: [{ value: _minutes, end_time, start_time }] });
			} else {
				_time.find((t) => t.hour === _hour).minutes.push({ value: _minutes, end_time, start_time });
			}
		});

		setTimeArray(_time);
		const hourAvailable = _time.find((t) => t.hour === (updatedSelectedHour || selectedHour));
		setAvailableMinutes(hourAvailable?.minutes || _time[0]?.minutes || []);

		return { _time, defaultTime: _time[0] };
	};

	useEffect(() => {
		getAvailableTimeObjects();
	}, [menu_time_slots]);

	useEffect(() => {
		const { date_local, end_time } = locationCart;
		const [endTimeHour] = end_time?.split(":");
		setSelectedDateLocal(date_local && date_local !== "" ? date_local : menu_date_slots[0]?.date_local);

		const { _time, defaultTime } = getAvailableTimeObjects(endTimeHour);

		if (end_time !== "") {
			const hourAvailable = _time.find((t) => t.hour === endTimeHour);
			if (hourAvailable) {
				setSelectedHour(endTimeHour);
			} else {
				setSelectedHour(defaultTime?.hour || "");
			}
			setSelectedEndTime(end_time);
		} else {
			setSelectedHour(defaultTime?.hour || "");
			setSelectedEndTime(defaultTime?.minutes[0].end_time || "");
		}
	}, [carts]);

	useEffect(() => {
		const selectedTime = timeArray.find((t) => t.hour === selectedHour);
		if (selectedTime) {
			setAvailableMinutes(selectedTime.minutes);
			const _selectedMinutes = selectedTime.minutes.find((m) => m.value === selectedEndTime.split(":")[1]);
			setSelectedEndTime(_selectedMinutes?.end_time || selectedTime.minutes[0].end_time);
		}
	}, [selectedHour]);

	const handleChangePickupDelivery = (e, newPickupOrDeliveryValue) => {
		e.currentTarget.blur();
		if (pickupOrDelivery.toString() === newPickupOrDeliveryValue) return;
		doUpdateCartPickupDelivery(parseInt(locationID), parseInt(newPickupOrDeliveryValue));
		setSelectedPickupOrDelivery(parseInt(newPickupOrDeliveryValue));

		getMenu(locationID, { setBusy, newPickupOrDeliveryValue });
	};

	const handleChangeTab = (event, newValue) => {
		getAvailableTimeObjects();
		setTabValue(newValue);
	};

	const handleChangeDate = (event) => {
		const newDate = event.target.value;
		setSelectedDateLocal(newDate);
		const date_slot = menu_date_slots.find((mds) => mds.date_local === event.target.value);
		getMenu(locationID, { setBusy, target_date: newDate, date_display: date_slot.date_display });
	};

	const handleChangeHours = (event) => {
		setSelectedHour(event.target.value);
	};

	const handleChangeMinutes = (event) => {
		setSelectedEndTime(event.target.value);
	};

	return (
		<Box>
			<Loading open={busy} />
			<Stack spacing={3} divider={<Divider role="presentation" />}>
				{delivery === 1 && takeout === 1 && (
					<PickupOrDeliverySelect
						selectedPickupOrDelivery={selectedPickupOrDelivery}
						handleChangePickupDelivery={handleChangePickupDelivery}
					/>
				)}
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList onChange={handleChangeTab} aria-label="schedule time picker" centered variant="fullWidth">
							{location.allow_asap_orders === 1 && <Tab label="ASAP" value="1" />}
							<Tab label="Schedule" value="2" />
						</TabList>
					</Box>
					<TabPanel value="1" align="center">
						<Typography variant="h5" variantMapping={{ h5: "span" }}>{`Estimated time: ${asap_min} - ${asap_max} mins`}</Typography>
					</TabPanel>
					<TabPanel value="2">
						<Stack spacing={2}>
							{scheduling_orders_days_ahead === 0 ? (
								<Typography variant="h5" variantMapping={{ h5: "span" }} paddingLeft={1} gutterBottom>
									Today
								</Typography>
							) : (
								<FormControl fullWidth>
									<InputLabel id="select-label-day">Day</InputLabel>
									<Select
										labelId="select-label-day"
										id="select-day"
										value={selectedDateLocal}
										label="Day"
										onChange={handleChangeDate}
									>
										{menu_date_slots?.length > 0 &&
											menu_date_slots?.map((mds) => {
												return (
													<MenuItem value={mds.date_local} key={mds.date_local}>
														{mds.date_display}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							)}
							{!hasFeatureFlag(location, FeatureConstants.FEA_LOC_HIDE_PICKUP_TIME_ESTIMATE) &&
								!(isHideTimeSelectionIfOnlyOneSlot && menu_time_slots.length === 1) &&
								(timeArray.length > 0 ? (
									<Stack spacing={2} direction="row">
										<FormControl fullWidth>
											<InputLabel id="select-label-hours">Hours</InputLabel>
											<Select
												labelId="select-label-hours"
												id="select-hours"
												value={selectedHour}
												label="Hours"
												onChange={handleChangeHours}
											>
												{timeArray?.length > 0 &&
													timeArray?.map((t) => {
														return (
															<MenuItem value={t.hour} key={t.hour}>
																{t.hoursDisplay}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>

										<FormControl fullWidth>
											<InputLabel id="select-label-pickup">Minutes</InputLabel>
											<Select
												labelId="select-label-minutes"
												id="select-minutes"
												value={selectedEndTime}
												label="Minutes"
												onChange={handleChangeMinutes}
											>
												{availableMinutes.length > 0 &&
													availableMinutes?.map((m) => {
														return (
															<MenuItem value={m.end_time} key={m.end_time}>
																{m.value}
															</MenuItem>
														);
													})}
											</Select>
										</FormControl>
									</Stack>
								) : (
									<Typography variant="h4" variantMapping={{ h4: "span" }} align="center" pt={2}>
										{ConstantsLabels.labelNoAvailableScheduleTimes}
									</Typography>
								))}
						</Stack>
					</TabPanel>
				</TabContext>
			</Stack>
		</Box>
	);
});

export default connect(defaultMapStateToProps, defaultActionsAndCartActionsToProps, null, { forwardRef: true })(CustomFormModalScheduleTime);
