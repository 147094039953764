import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import clsx from "clsx";
import { getScrollClassNameFromTheme, setRadioButtonSelected } from "../Utils";
import { useTheme } from "@mui/material/styles";

import { classes, CustomFormStyled } from "./CustomFormStyles";

const RootRadioGroup = styled(RadioGroup)(({ theme }) => CustomFormStyled(theme));

const CustomFormModalRadioButtonGroup = forwardRef((props, ref) => {
	const theme = useTheme();
	const scrollClassName = getScrollClassNameFromTheme(theme);

	const { dataArray = [], radioId, radioLabel, checkedString="selected", refIdNumber, ariaLabel } = props;
	const [radioButtonArray, setRadioButtonArray] = useState(dataArray);
	const [selectedRadioButton, setSelectedRadioButton] = useState({});

	useEffect(() => {
		setSelectedRadioButton(radioButtonArray.find(radio => radio.selected))
	}, []);

	const callbackRef = (el) => (ref.current[refIdNumber] = el);
	useImperativeHandle(callbackRef, () => ({
		returnSelectedData() {
			return selectedRadioButton;
		}
	}));

	const handleOnChange = (e) => {
		const { selectedArray, selectedObject } = setRadioButtonSelected(radioButtonArray, radioId, e.target.value);

		setRadioButtonArray(selectedArray);
		setSelectedRadioButton(selectedObject);
	};

	return (
		<RootRadioGroup
			aria-labelledby={`${ariaLabel}-radio-buttons-group`}
			name={`${ariaLabel}-radio-buttons-group`}
			className={clsx(scrollClassName, classes.radioButtonGroup)}
		>
			{radioButtonArray?.map((radio) => {
				return (
					<FormControlLabel
						control={
							<Radio
								value={radio[radioId]}
								checked={radio[checkedString]}
								onChange={(e) => handleOnChange(e)}
							/>
						}
						label={radio[radioLabel]}
						key={radio[radioId]}
					/>
				);
			})}
		</RootRadioGroup>
	);
});

export default CustomFormModalRadioButtonGroup;
