import CloseIcon from "@mui/icons-material/Clear";
import { styled, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { classes, CustomModalStyle } from "./CustomModalStyle";

import { getScrollClassNameFromTheme, isDeviceMobileFx } from "../../utils/Utils";
import CustomFormButton from "../../utils/forms/CustomFormButton";
import { GlobalStyle } from "../../theme/GlobalStyle";

const RootModal = styled(Modal)(({ theme }) => CustomModalStyle(theme));
const RootGrid = styled(Grid)(({ theme }) => GlobalStyle(theme));

// Updated version of Custom Modal - switched to use Grid & MUI based formatting vs lots of css.  Hopefully be able to move everything over to this eventually
const CustomModal2 = ({
	children,
	modalLabel,
	isShowing,
	setIsShowing,
	title,
	titlePaddingBottom = 3,
	subtitle,
	noTitleBox,
	preventOutsideClick,
	onClose = () => {},
	onClickCloseButton = () => {},
	onButtonClick = () => {},
	closeModalOnButtonClick,
	showButton,
	buttonText,
	isButtonDisabled,
	hideXButton,
	hideLeftButton,
	leftCornerButtonIcon,
	leftCornerButtonTooltip,
	leftCornerButtonCallback,
	maxHeight,
	maxWidth = 600,
	minWidth = 300,
	startIcon,
	turnCapitalizeTitleOff = false
}) => {
	const isDeviceMobile = useMediaQuery(isDeviceMobileFx);
	const theme = useTheme();
	const [scrollbarShowing, setScrollbarShowing] = useState(false);
	const scrollClassName = getScrollClassNameFromTheme(theme);

	// OnClose method in modal doesn't always fire, so adding this here to capture it
	useEffect(() => {
		if (!isShowing) {
			onClose();
		}
	}, [isShowing]);

	const closeButton = !hideXButton ? (
		<div className={classes.closeButtonDiv}>
			<Tooltip
				aria-hidden="true"
				title={`Close${isEmpty(modalLabel) ? "" : ` ${modalLabel}`}`}
				placement={"right"}
				disableFocusListener
				id={`tooltip-Close${isEmpty(modalLabel) ? "" : ` ${modalLabel}`}`}
			>
				<IconButton
					aria-hidden="false"
					role={"button"}
					className={clsx(classes.closeIconButton)}
					aria-label={`Close${isEmpty(modalLabel) ? "" : ` ${modalLabel}`}`}
					onClick={() => {
						onClickCloseButton();
						setIsShowing(false);
					}}
					disabled={false}
					edge="end"
					size="large"
					aria-describedby={`tooltip-Close${isEmpty(modalLabel) ? "" : ` ${modalLabel}`}`}
				>
					<CloseIcon />
				</IconButton>
			</Tooltip>
		</div>
	) : (
		<></>
	);

	const clickLeftCornerButton = (e) => {
		e && e.currentTarget.blur();
		leftCornerButtonCallback();
	};

	const leftCornerButton =
		!hideLeftButton && leftCornerButtonIcon && leftCornerButtonCallback && leftCornerButtonTooltip ? (
			<div className={classes.leftCornerButtonDiv}>
				<Tooltip aria-hidden="true" title={leftCornerButtonTooltip} placement={"right"} disableFocusListener>
					<IconButton
						aria-hidden="false"
						role={"button"}
						className={clsx(classes.leftCornerIconButton)}
						aria-label={leftCornerButtonTooltip}
						onClick={clickLeftCornerButton}
						disabled={false}
						edge="end"
						size="large"
						color="inherit"
					>
						{leftCornerButtonIcon}
					</IconButton>
				</Tooltip>
			</div>
		) : undefined;

	return (
		<RootModal
			role="dialog"
			aria-label={modalLabel}
			aria-labelledby="modalTitle"
			open={isShowing}
			onClose={(event, reason) => {
				if (preventOutsideClick && reason && reason === "backdropClick") return;
				// onClose(); moving to useEffect() above
				setIsShowing(false);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
			// Needed to prevent a bug where clicks on the modal would propagate up to parent components. i.e. <CheckoutOptionItem...>
			onClick={(event) => event.stopPropagation()}
			onMouseDown={(event) => event.stopPropagation()}
			tabIndex={-1}
		>
			<RootGrid
				container
				direction="column"
				// justifyContent="center"
				alignItems="stretch"
				margin={0}
				padding={2}
				paddingLeft={0}
				spacing={2}
				className={classes.modal2Base}
				sx={{ maxHeight, maxWidth, minWidth }}
			>
				{leftCornerButton}
				{closeButton}
				{!noTitleBox && (
					<Grid item paddingBottom={titlePaddingBottom}>
						<Typography variant="h6" variantMapping={{ h6: "h2" }} id="modalTitle" align={"center"} paddingTop={2}>
							{turnCapitalizeTitleOff ? title : title?.toUpperCase()}
						</Typography>
						{subtitle && (
							<Typography
								variant="subtitle1"
								variantMapping={{ subtitle1: "h3" }}
								align={"center"}
								sx={{ overflowWrap: "break-word" }}
							>
								{subtitle}
							</Typography>
						)}
					</Grid>
				)}
				<Grid
					item
					xs
					className={clsx(classes.modal2GridItemContent, !isDeviceMobile ? scrollClassName : "", {
						[classes.modal2GridItemContentButton]: showButton,
						[classes.modal2ScrollbarPadding]: scrollbarShowing
					})}
					ref={(el) => {
						setScrollbarShowing(el?.scrollHeight > el?.clientHeight || false);
					}}
				>
					{children}
				</Grid>
				{showButton && (
					<Grid item container>
						<CustomFormButton
							handleClick={() => {
								onButtonClick(() => closeModalOnButtonClick && setIsShowing(false));
							}}
							isDisabled={isButtonDisabled}
							buttonText={buttonText}
							startIcon={startIcon}
						/>
					</Grid>
				)}
			</RootGrid>
		</RootModal>
	);
};

export default CustomModal2;
