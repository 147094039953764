import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Log, scrubUnsafeCharacters, scrubNonAlphaNumeric, scrubNonAlphaNumericUnderscore, scrubSpaces } from "../../utils/Utils";
import makeStyles from "@mui/styles/makeStyles";
import CustomFormStyles from "./CustomFormStyles";
import ConstantsLabels from "../../utils/ConstantsLabels";

import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => {
	return CustomFormStyles(theme);
});

const CustomFormTextInput = ({
	inputID,
	inputLabel,
	setValue,
	value,
	setError,
	errorText,
	onBlurCallback,
	placeholderText,
	labelAlwaysAtTop = true,
	prefixHashtag,
	setDirtyBit,
	isDisabled,
	helperText,
	marginTop,
	marginBottom,
	maxLength,
	maxWidth,
	display,
	doNotTrimString,
	doNotRemoveUnsafeCharacters,
	removeAllSpaces,
	removeNonAlphaNumeric,
	removeNonAlphaNumericUnderscore,
	toUpperCase,
	toLowerCase,
	autoCompleteType,
	isAutoFocus,
	isOptional,
	multiline,
	rows,
	maxRows,
	fieldType,
	isSearch
}) => {
	const classes = useStyles();
	const theStyle = { marginTop, marginBottom, maxWidth, display };

	const theMaxLength = maxLength && maxLength > 0 ? maxLength : 1000;
	const autoCompleteProps = autoCompleteType ? { autoComplete: autoCompleteType } : { autoComplete: "off", "data-lpignore": "true" }; //off by default
	const [subText, setSubText] = useState(errorText ? errorText : helperText);
	const hideClearButton = !value || value.length === 0;
	const showingClearButtonStyle = value && value.length > 0 ? {} : { display: "none" };

	const handleOnChange = (e) => {
		e && e.preventDefault();
		setValue(e.target.value);
		setError && setError("");
		setDirtyBit && setDirtyBit(true);
	};

	const handleOnBlur = (e) => {
		e && e.preventDefault();
		let theValue = value; //run the validation on blur
		if (!theValue) theValue = "";
		theValue = theValue.length > maxLength ? theValue.substring(0, theValue.length) : theValue; //trim if too long
		if (!doNotTrimString) theValue = theValue.trim(); //trim leading or trailing white space by default
		if (!doNotRemoveUnsafeCharacters) theValue = scrubUnsafeCharacters(theValue); //replace potential malicious code characters = ; <> by default
		if (removeAllSpaces) theValue = scrubSpaces(theValue);
		if (removeNonAlphaNumeric) theValue = scrubNonAlphaNumeric(theValue); //keeps spaces since you can remove with the removeAllSpaces command
		if (removeNonAlphaNumericUnderscore) theValue = scrubNonAlphaNumericUnderscore(theValue); //removes spaces and replaced with _. underscores allowed
		if (toUpperCase) theValue = theValue.toUpperCase();
		if (toLowerCase) theValue = theValue.toLowerCase();
		setValue(theValue);
		onBlurCallback && onBlurCallback(theValue);
	};

	const ClearTooltipWrapper = ({ children }) => {
		return value && value.length > 0 ? (
			<Tooltip title={ConstantsLabels.labelButtonSearchClear} disableFocusListener>
				{children}
			</Tooltip>
		) : (
			<>{children}</>
		);
	};

	const clearClicked = (e) => {
		e && e.preventDefault();
		setValue("");
	};

	//hex color hashtag symbol
	let upperCaseInputProps = {};
	if (prefixHashtag) {
		const inputPropsAdornment = {
			startAdornment: (
				<InputAdornment position="start" className={classes.textfieldStartAdornmentContainer}>
					{<span style={{ fontSize: 26 }}>{"#"}</span>}
				</InputAdornment>
			)
		};
		upperCaseInputProps = { InputProps: inputPropsAdornment };
	} else if (isSearch) {
		const inputPropsAdornment = {
			startAdornment: (
				<InputAdornment position="start" className={classes.searchIconButtonContainer}>
					<SearchIcon />
				</InputAdornment>
			),
			endAdornment: (
				<InputAdornment position="end" className={classes.clearIconButtonContainer} style={showingClearButtonStyle}>
					<ClearTooltipWrapper>
						<span>
							<IconButton
								role={"button"}
								className={clsx(classes.clearIconButton, {
									[classes.clearIconButtonHidden]: hideClearButton,
									[classes.clearIconButtonVisible]: !hideClearButton
								})}
								aria-label={ConstantsLabels.labelButtonSearchClear}
								onClick={clearClicked}
								disabled={hideClearButton || isDisabled}
								edge="end"
								size="large"
							>
								<ClearIcon />
							</IconButton>
						</span>
					</ClearTooltipWrapper>
				</InputAdornment>
			)
		};
		upperCaseInputProps = { InputProps: inputPropsAdornment };
	}

	//forces the label to stay and the placeholder text to always be visible, even if input is currently empty
	const InputLabelProps = labelAlwaysAtTop ? { shrink: true } : {};

	useEffect(() => {
		setSubText(errorText ? errorText : helperText);
	}, [errorText, helperText]);

	return (
		<TextField
			className={classes.textfieldInput}
			style={theStyle}
			id={inputID}
			label={inputLabel}
			value={value}
			aria-label={`Enter ${inputLabel}`}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			type={fieldType ? fieldType : "text"}
			error={errorText !== undefined && errorText.length > 0}
			placeholder={placeholderText}
			helperText={subText}
			variant="outlined"
			margin="normal"
			fullWidth
			inputProps={{
				maxLength: theMaxLength,
				...autoCompleteProps
			}}
			{...upperCaseInputProps}
			InputLabelProps={{ ...InputLabelProps, shrink: true }}
			disabled={isDisabled}
			required={isOptional ? false : true}
			multiline={multiline ? true : false}
			minRows={rows}
			maxRows={maxRows}
			inputRef={(input) => errorText?.length > 0 && input?.focus()}
		/>
	);
};

export default CustomFormTextInput;
