import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";
import { removeSnackbar } from "../../store/Actions";

import SnackbarStyles from "./SnackbarStyles";

import IconButton from "@mui/material/IconButton";
import SuccessIcon from "@mui/icons-material/CheckCircleSharp";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import DismissIcon from "@mui/icons-material/ArrowForwardIos"; //KeyboardArrowRight
import { makeStyles } from "@mui/styles";

import useCommonData from "../hooks/useCommonData";

const useSnackbarStyle = makeStyles((theme) => {
	return SnackbarStyles(theme);
});

let displayed = [];

const notistackRef = React.createRef();
const handleCloseSnackbar = (key) => () => {
	notistackRef && notistackRef.current.closeSnackbar(key);
};

const Notifier = () => {
	const dispatch = useDispatch();

	const notifications = useSelector((store) => store.snackbarData.notifications || []);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};

	const removeDisplayed = (id) => {
		displayed = [...displayed.filter((key) => id !== key)];
	};

	React.useEffect(() => {
		notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key);
				return;
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return;

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey);
					}
				},
				onExited: (event, myKey) => {
					// remove this snackbar from redux store
					dispatch(removeSnackbar(myKey));
					removeDisplayed(myKey);
				}
			});

			// keep track of snackbars that we've displayed
			storeDisplayed(key);
		});
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

	return null;
};

const SnackbarNotifier = ({ children }) => {
	const snackbarClasses = useSnackbarStyle();
	const { extendNotificationDuration } = useCommonData();

	const snackbarProps = {
		autoHideDuration: extendNotificationDuration ? 10000 : 5000,
		anchorOrigin: {
			vertical: "top",
			horizontal: "right"
		},
		disableWindowBlurListener: true,
		classes: {
			root: snackbarClasses.root,
			variantSuccess: snackbarClasses.success,
			variantError: snackbarClasses.error,
			variantWarning: snackbarClasses.warning,
			variantInfo: snackbarClasses.info
		},
		iconVariant: {
			success: <SuccessIcon />,
			error: <ErrorIcon />,
			warning: <WarningIcon />,
			info: <InfoIcon />
		},
		ref: notistackRef,
		action: (key) => (
			<IconButton
				role={"button"}
				aria-label="Close Alert"
				onClick={handleCloseSnackbar(key)}
				className={snackbarClasses.closeButton}
				size="large"
			>
				<DismissIcon />
			</IconButton>
		)
	};

	return (
		<SnackbarProvider {...snackbarProps} aria-live="polite" id="notistack-snackbar">
			<Notifier />
			{children}
		</SnackbarProvider>
	);
};

export default SnackbarNotifier;
