import React, { useContext, useState } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { SearchContext } from "../../utils/contexts/Contexts";
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { classes, CustomAppStyle } from "./CustomAppStyle";

const SearchDiv = styled("div")(({ theme }) => CustomAppStyle(theme));

const CustomAppBarSearch = () => {
	const { searchValue, setSearchValue } = useContext(SearchContext);

	const [hasFocus, setHasFocus] = useState(false);

	const handleSearchValueChange = (value) => {
		setSearchValue(value);
	};

	const handleClearSearch = () => {
		setSearchValue("");
		setHasFocus(false);
	};

	return (
		<SearchDiv className={classes.searchDiv}>
			<InputBase
				className={classes.searchInputBase}
				placeholder="Search…"
				value={searchValue}
				onChange={(e) => handleSearchValueChange(e.target.value)}
				inputProps={{ "aria-label": "Search" }}
				onFocus={() => setHasFocus(true)}
				onBlur={() => setHasFocus(false)}
				startAdornment={
					<InputAdornment position="start" disablePointerEvents>
						<SearchIcon />
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						<Tooltip aria-hidden="true" title={"Clear Search"} id={`tooltip-Clear Search`}>
							<IconButton
								aria-hidden="false"
								role={"button"}
								// onClick={handleClearSearch}
								onMouseDown={handleClearSearch}
								aria-describedby={`tooltip-Clear Search`}
							>
								<ClearIcon />
							</IconButton>
						</Tooltip>
					</InputAdornment>
				}
			/>
		</SearchDiv>
	);
};

export default CustomAppBarSearch;
