import React, { useState } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

const CustomExpandMoreIcon = styled((props) => {
	const { expand, ...other } = props;
	return (
		<IconButton role={"button"} {...other}>
			<ExpandMore />
		</IconButton>
	);
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest
	})
}));

export default CustomExpandMoreIcon;
