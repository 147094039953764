import Constants from "./Constants";
import { getFormattedTimeString, isDateStringSameAsToday } from "./DateUtils";
import FeatureConstants from "./FeatureConstants";
import { getObjectFromArray } from "./ObjectUtils";
import { hasFeatureFlag } from "./PermissionUtils";
import { getCreditCardType } from "./Utils";

export const getConfirmDialogDetails = (cafeteriaName, currentLocation, menu, menuslots, theCart, pickupOrDelivery, user) => {
	const { cart: serverCart } = theCart.server;
	const menuSlot = getObjectFromArray(currentLocation.locationid, menuslots, "locationid");
	const { menu_date_slots, menu_time_slots } = menuSlot;

	let confirmationTitlePickupString = "Pick up";
	if (
		hasFeatureFlag(currentLocation, FeatureConstants.FEA_LOC_CHECKOUT_REPLACE_PICKUP_WITH_READY) ||
		currentLocation.virtualqueue === 1 ||
		pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1
	) {
		confirmationTitlePickupString = "Ready";
	}

	let extraDateInfo = ""; //if not today, then we append the dateString to the msg
	if (currentLocation.scheduling_orders_days_ahead > 0 && isDateStringSameAsToday(theCart.date_local)) {
		extraDateInfo = menu_date_slots.find((ds) => ds.date_local === theCart.date_local).date_display;
		if (extraDateInfo.toUpperCase() === "TODAY") {
			extraDateInfo = "Today ";
		} else {
			extraDateInfo = extraDateInfo.length === 0 ? "" : " on " + extraDateInfo;
		}
	}

	let pickupName = cafeteriaName;
	if (currentLocation.delivery === 1 && pickupOrDelivery === Constants.pickupDeliveryTypeEnum.DELIVERY_1 && theCart.pickupSpotId > 0) {
		let dropoff = getPickupSpotName(currentLocation, theCart.pickupSpotId);
		//if no dropoff is specified, show the pickup spot instead
		if (dropoff.length === 0) {
			dropoff = cafeteriaName;
		}
		pickupName = dropoff;
	}

	let confirmationMessage = confirmationTitlePickupString + " in " + theCart.server.cart?.pickup_time_max + " minutes at " + pickupName + "."; //default asap
	const hidePickupTimeEstimate = hasFeatureFlag(currentLocation, FeatureConstants.FEA_LOC_HIDE_PICKUP_TIME_ESTIMATE);
	const hideTimeifOneSlot = hasFeatureFlag(currentLocation, FeatureConstants.FEA_LOC_HIDE_TIME_IF_ONE_SLOT) && menu_time_slots.length <= 1;
	const allPremadeNoPrepTime = hasFeatureFlag(currentLocation, FeatureConstants.FEA_LOC_ALL_PREMADE_NO_PREPTIME);

	if (hidePickupTimeEstimate || hideTimeifOneSlot) {
		confirmationMessage = confirmationTitlePickupString + " at " + pickupName + ".";
	} else if (allPremadeNoPrepTime && serverCart.pickup_time_max === 1 && serverCart.pickup_time_min === 1) {
		confirmationMessage = confirmationTitlePickupString + " now at " + pickupName + ".";
	} else if (serverCart?.target_time?.length > 0) {
		let pickupTime = getFormattedTimeString(serverCart.target_time);
		let theAt = " at ";
		if (extraDateInfo.length > 0) {
			//since extradateinfo has a new line
			theAt = "at ";
		}

		confirmationMessage = confirmationTitlePickupString + extraDateInfo + theAt + pickupTime + " at " + pickupName;
	}

	let confirmationTenderString;
	if (!hasFeatureFlag(currentLocation, FeatureConstants.FEA_LOC_HIDE_TENDER_NAME_IN_PAYMENT_CONFIRM)) {
		//get tender name
		let tenderName = "";
		let ct_id = theCart.ct_id;
		let tender = menu.tenders.find((t) => t.ct_id.toString() === ct_id.toString());
		// let ct_id = Mediator.getInstance().getDefaultTenderIdForLocation(Mediator.getInstance().getCurrentLocation().locationid);
		// let tender = Mediator.getInstance().getTenderFromLocationTenderId(Mediator.getInstance().getCurrentLocation(), ct_id);

		let paymentMethod = user.payment_method_default;
		if (!!tender && paymentMethod === 0) {
			//payment method default = 0 is meal plan
			tenderName = tender.name;
		} else if ((paymentMethod === 1 || paymentMethod === 2) && serverCart.grand_total > 0) {
			//credit card
			let firstTwo = user.creditcard_1_type;
			if (paymentMethod === 2) firstTwo = user.creditcard_2_type;

			tenderName = getCreditCardType(firstTwo);
		}

		if (tenderName?.length > 0) confirmationTenderString = `Paying with ${tenderName}.`;
	}

	let confirmationPositiveButton = "Pay";
	if (serverCart?.grand_total === 0) confirmationPositiveButton = "Confirm";

	//override confirm pay alert title
	if (currentLocation.checkout_pay_button_title?.length > 0) {
		confirmationPositiveButton = currentLocation.checkout_pay_button_title;
	}

	//override confirm Pay alert message
	if (currentLocation.checkout_pay_confirm_text?.length > 0) {
		confirmationMessage = currentLocation.checkout_pay_confirm_text;
	}

	return { confirmationMessage, confirmationTenderString, confirmationPositiveButton, confirmationTitlePickupString };
};

const getPickupSpotName = (theLocation, pickupSpotId) => {
	let name = "Select Location for Delivery";
	if (theLocation.delivery_descriptive_name_override?.length > 0) {
		name = "Select Location for " + theLocation.delivery_descriptive_name_override;
	}
	const pickupSpot = theLocation.pickup_spots.find((ps) => ps.pickupspotid === pickupSpotId);
	if (pickupSpot) {
		name = pickupSpot.name;
	}
	return name;
};
